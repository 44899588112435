import { toast } from 'react-toastify';

import axios from 'axios';
import { call, put } from 'redux-saga/effects';

import Connector from 'services/axios/axios';

import { StoreModule } from 'helpers/enum/StoreModule';

import { addLoadingModule, removeLoadingModule } from '../loading/actions';
import {
  recoveryPasswordError,
  recoveryPasswordSuccess,
  setNewPasswordError,
  setNewPasswordSuccess
} from './actions';
import { RecoverPasswordAction } from './types';

export function* recoverPassword(action: RecoverPasswordAction) {
  yield put(addLoadingModule(StoreModule.RECOVERPASSWORD));

  try {
    const { email } = action.payload;

    yield call(
      Connector(false, false).get,
      `/users/request-recover-password?email=${email}`
    );

    yield put(recoveryPasswordSuccess());

    toast.success('Token encaminhado, verifique seu email');
  } catch (err) {
    toast.error('Falha ao enviar email');
    yield put(recoveryPasswordError());
  } finally {
    yield put(removeLoadingModule(StoreModule.RECOVERPASSWORD));
  }
}

export function* setNewPassword(action: RecoverPasswordAction) {
  yield put(addLoadingModule(StoreModule.RECOVERPASSWORD));
  const { token, password } = action.payload;

  try {
    const data = {
      code: token,
      password
    };

    yield call(Connector(false, false).put, '/users/recover-password', data);

    yield put(setNewPasswordSuccess());

    window.location.href = '/login';
  } catch (err) {
    toast.error('A operação falhou, verique o token');
    yield put(setNewPasswordError());
  } finally {
    yield put(removeLoadingModule(StoreModule.RECOVERPASSWORD));
  }
}
