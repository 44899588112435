import { Audio } from 'helpers/models';

export enum AudioTypes {
  SET_ACTIVE_AUDIO = '@AUDIO/SET_ACTIVE_AUDIO',
  SET_AUDIO_SUCCESS = '@AUDIO/SET_AUDIO_SUCCESS',
  SET_AUDIO_ERROR = '@AUDIO/SET_AUDIO_ERROR',
}

export interface AudioState {
  readonly audio: Audio;
  readonly playlistName: string;
}

export type AudioAction = {
  type: AudioTypes;
  payload: {
    audio: Audio;
    playlistName: string;
  };
};