import { audioInitialState, playlistInitialState } from 'helpers/initialState';

import { PlayerState } from './types';

export const PLAYER_INITIAL_STATE: PlayerState  = {
  isPlayerOpen: false,
  currentAudioId: 0,
  currentAudio: audioInitialState,
  playlist: playlistInitialState,
  isPaused: true,
};