import { Fragment, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import { resetAccountStatusState } from 'store/ducks/account';

import { PRIVATE_ROUTES } from 'helpers/constants';
import { accountSelector } from 'helpers/selectors';
import { getFormattedPromotionalValue } from 'utils/getFormattedPromotionalValue';

import { HTMLButton as Button, ButtonLink as Link } from 'components/Button';

import * as S from './styled';

const MySubscription = () => {
  const {
    account: { subscription, isAdmin },
    isLoading
  } = useSelector(accountSelector);

  const dispatch = useDispatch();

  const [showConfirmation, setShowConfirmation] = useState(false);

  const subscriptionExpirationDate = useMemo(() => {
    if (!subscription) {
      return '';
    }

    return moment(subscription.expiresAt).format('DD/MM/YYYY');
  }, [subscription]);

  const promotionExpirationDate = useMemo(() => {
    if (!subscription || !subscription.promotion) {
      return '';
    }

    return moment(subscription.promotion.expireAt).format('DD/MM/YYYY');
  }, [subscription]);

  const currentPlanValue = useMemo(() => {
    if (!subscription) {
      return '';
    }

    const { promotion, plan } = subscription;

    return promotion
      ? getFormattedPromotionalValue(
          plan.value,
          promotion.discount,
          promotion.discountType
        )
      : plan.value.toFixed(2).replace('.', ',');
  }, [subscription]);

  const standardPlanValue = useMemo(() => {
    if (!subscription) {
      return '';
    }

    return subscription.plan.value.toFixed(2).replace('.', ',');
  }, [subscription]);

  const getInTestSubscription = (expiresTrialIn?: Date) => {
    if (subscription) {
      const trialRemainingDays =
        expiresTrialIn &&
        moment(expiresTrialIn).diff(new Date(Date.now()), 'days');

      const { promotion, plan } = subscription;

      if (promotion) {
        return (
          <Fragment>
            <S.PlanTestTime>{plan.name}</S.PlanTestTime>
            <S.PlanTestTime>
              Em período de teste: {trialRemainingDays} dias restantes
            </S.PlanTestTime>
            <S.PlanTestTime>
              Após o período de testes será cobrado o valor promocional de: R$
              {currentPlanValue}/mês até a data de término da promoção
            </S.PlanTestTime>
            <S.PlanTestTime>
              Valor promocional válido até: {promotionExpirationDate}
            </S.PlanTestTime>
            <br />
            <S.PlanValue>
              Sua assinatura e acesso aos áudios se renova em:{' '}
              {subscriptionExpirationDate}
            </S.PlanValue>
            <br />
            <S.PlanTestTime>
              *Após o término do período promocional, será cobrado o valor
              integral da assinatura:
              {` R$ ${standardPlanValue}/mês`}
            </S.PlanTestTime>
          </Fragment>
        );
      }

      return (
        <Fragment>
          <S.PlanTestTime>{plan.name}</S.PlanTestTime>
          <S.PlanTestTime>
            Em período de teste: {trialRemainingDays} dias restantes
          </S.PlanTestTime>
          <S.PlanTestTime>
            Após o período de testes será cobrado o valor de: R${' '}
            {currentPlanValue}
            /mês
          </S.PlanTestTime>
          <br />
          <S.PlanValue>
            Sua assinatura e acesso aos áudios se renova em:{' '}
            {subscriptionExpirationDate}
          </S.PlanValue>
        </Fragment>
      );
    }
  };

  const getActiveSubscription = () => {
    if (subscription) {
      const { promotion, plan } = subscription;

      if (promotion) {
        return (
          <Fragment>
            <S.PlanValue>{plan.name}</S.PlanValue>
            <S.PlanValue>
              Assinatura promocional ativa: R$
              {currentPlanValue}/mês
            </S.PlanValue>
            <S.PlanValue>
              Valor promocional válido até: {promotionExpirationDate}
            </S.PlanValue>
            <S.PlanValue>
              Sua assinatura e acesso aos áudios se renova em:{' '}
              {subscriptionExpirationDate}
            </S.PlanValue>
            <S.PlanValue>
              *Após o término do período promocional, será cobrado o valor
              integral da assinatura:
              {` R$ ${standardPlanValue}/mês`}
            </S.PlanValue>
          </Fragment>
        );
      }

      return (
        <Fragment>
          <S.PlanValue>{plan.name}</S.PlanValue>
          <S.PlanValue>
            Assinatura ativa: R$
            {currentPlanValue}/mês
          </S.PlanValue>
          <S.PlanValue>
            Sua assinatura e acesso aos áudios se renova em:{' '}
            {subscriptionExpirationDate}
          </S.PlanValue>
        </Fragment>
      );
    }
  };

  const getCanceledSubscription = (canceledAt?: Date) => {
    if (subscription) {
      const remainingTime = moment(subscription.expiresAt).diff(
        moment(),
        'days'
      );

      const subscriptionCanceledAt = moment(subscription.canceledAt).format(
        'DD/MM/YYYY'
      );

      return (
        <Fragment>
          <S.PlanValue>
            Assinatura cancelada em: {subscriptionCanceledAt}
            <br />
          </S.PlanValue>
          <S.PlanValue>
            Seu acesso aos áudios {remainingTime >= 0 ? 'expira' : 'expirou'}{' '}
            em: {subscriptionExpirationDate}
            <br />
          </S.PlanValue>
          <S.PlanValue>
            Para ter acesso completo a plataforma reative sua assinatura!
          </S.PlanValue>
        </Fragment>
      );
    }
  };

  const getOverdueSubscription = () => {
    if (subscription) {
      const remainingTime = moment(subscription.expiresAt).diff(
        new Date(Date.now()),
        'days'
      );

      return (
        <Fragment>
          <S.PlanValue>{subscription.plan.name}</S.PlanValue>
          <S.PlanValue>
            Pagamento da assinatura pendente: R$ {currentPlanValue}/mês
          </S.PlanValue>
          <S.PlanValue>Verifique e atualize os dados do seu cartão</S.PlanValue>
          <S.PlanValue>
            Seu acesso aos áudios {remainingTime >= 0 ? 'expira' : 'expirou'}{' '}
            em:
            <br />
            {subscriptionExpirationDate}
          </S.PlanValue>
        </Fragment>
      );
    }
  };

  const getSubscriptionData = () => {
    if (!subscription) {
      if (isLoading) {
        return <Fragment />;
      }

      return (
        <S.PlanValue>
          Nenhuma assinatura válida foi encontrada para sua conta. Prossiga com
          ativação da sua assinatura clicando no botão acima, caso tenha mais
          dúvidas entre em contato com nosso suporte.
        </S.PlanValue>
      );
    }

    switch (subscription.subscriptionStatus) {
      case 'CANCELED':
        return getCanceledSubscription(subscription.canceledAt);
      case 'OVERDUE':
        return getOverdueSubscription();
      case 'IN_TEST':
        return getInTestSubscription(subscription.expiresTrialIn);
      default:
        return getActiveSubscription();
    }
  };

  const onCancelClick = () => {
    setShowConfirmation(!showConfirmation);
    dispatch(resetAccountStatusState());
  };

  const getSubscriptionActions = () => {
    if (!subscription) {
      if (isLoading) {
        return <Fragment />;
      }

      return (
        <Link variant="link" to={PRIVATE_ROUTES.REACTIVATE_ACCOUNT}>
          Ativar assinatura
        </Link>
      );
    }

    switch (subscription?.subscriptionStatus) {
      case 'CANCELED':
      case 'OVERDUE': {
        return (
          <Link variant="link" to={PRIVATE_ROUTES.REACTIVATE_ACCOUNT}>
            Reativar
          </Link>
        );
      }
      default: {
        return (
          <Button variant="link" onClick={onCancelClick}>
            Cancelar assinatura
          </Button>
        );
      }
    }
  };

  return isAdmin ? (
    <Fragment />
  ) : (
    <S.MySubscriptionWrapper>
      <S.AccountFormHeader>
        <h3>Minha assinatura</h3>

        {getSubscriptionActions()}
      </S.AccountFormHeader>

      <S.AccountSubscription>{getSubscriptionData()}</S.AccountSubscription>

      {showConfirmation && (
        <S.CancelSubscription>
          <S.CancelSubscriptionMessage>
            Deseja realmente cancelar a sua assinatura?
          </S.CancelSubscriptionMessage>

          <S.CancelSubscriptionActions>
            <Button variant="line" onClick={() => setShowConfirmation(false)}>
              Não
            </Button>
            <Link variant="yellow" to={PRIVATE_ROUTES.CANCEL_SUBSCRIPTION}>
              Sim
            </Link>
          </S.CancelSubscriptionActions>
        </S.CancelSubscription>
      )}
    </S.MySubscriptionWrapper>
  );
};

export default MySubscription;
