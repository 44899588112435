import { Action } from 'redux';
import { action } from 'typesafe-actions';

import { ModalItem } from 'helpers/types/ModalItem';

import { ModalTypes } from './types';

export const openModal = (modalItem: ModalItem): Action =>
  action(ModalTypes.OPEN_MODAL, {
    modalItem
  });

export const closeModal = (): Action => action(ModalTypes.CLOSE_MODAL);
