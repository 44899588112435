const prepareSearch = (term: string) => term
      .toUpperCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace('º', '')
      .replace('°', '')
      .replace('ª', '')
      .replace('.', '')
      .replace(',', '')
      .replace('/', '');
  
  export default prepareSearch;
  