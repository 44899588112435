import { Link } from 'react-router-dom';

import styled from 'styled-components';

export const RecentNewsWrapper = styled.div`
  ${({ theme }) => theme.bp.Fluid};
  margin-top: 40px;
  padding-bottom: 100px;
`;

export const RecentNewsContainer = styled.div`
  ${({ theme }) => theme.bp.Large};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RecentNewsHeader = styled.header`
  width: 100%;

  h1 {
    font-size: 14px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.yellow};
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  h2 {
    font-size: 32px;
    font-weight: 900;
    color: ${({ theme }) => theme.colors.blue};
    margin-bottom: 50px;
  }
`;

export const NewsGrid = styled.section`
  display: grid;
  gap: 30px;
  width: 100%;

  ${({ theme }) => theme.mq.lessThan('sm')`
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 50px;
  `}
  
  ${({ theme }) => theme.mq.greaterThan('sm')`
    grid-template-columns: repeat(3, 1fr);  
    margin-bottom: 80px;
  `}
`;

export const NewsWrapper = styled.div`
  height: 250px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px ${({ theme }) => theme.colors.greyLight};
  position: relative;
`;

export const NewsPreviewLink = styled(Link)`
  border-radius: inherit;

  .news-preview__link {
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.white};
    transition: color 0.3s;
  }

  &:hover {
    .news-preview__link {
        color: ${({ theme }) => theme.colors.yellow};
    }
  }
`;

export const NewsPreview = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(39,47,76,0.6);
  padding: 30px;
  border-radius: inherit;
  color: ${({ theme }) => theme.colors.white};

  .news-preview__date {
    font-size: 14px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.yellow};
    margin-bottom: 8px;
  }

  .news-preview__title {
    font-size: 18px;
    font-weight: 700;
    flex-grow: 1;
  }
`;

interface NewsThumbBgProps {
  cover: string;
}

export const NewsThumb = styled.div<NewsThumbBgProps>`
  width: 100%;
  height: 100%;
  background-image: url(${({ cover }) => cover});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: inherit;
`;

export const MoreNewsLink = styled.button`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blue};
  margin: 0 0 100px;
  
  span {
    margin-bottom: 14px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.yellow};


    svg {
      path {
        stroke: ${({ theme }) => theme.colors.yellow};
      }
    }
  }
`;