import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchFaqData } from 'store/ducks/institutional';

import { ApplicationState } from 'helpers/types';

import * as S from './styled';

const FAQ = () => {
  const dispatch = useDispatch();
  const { faq } = useSelector((state: ApplicationState) => state.institutional);

  // Scrolls to top smoothly
  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    dispatch(fetchFaqData());
  }, []);

  return (
    <S.FaqWrapper>
      <S.FaqContainer>
        <S.FaqHeader>
          <h2>Tire suas dúvidas rapidamente</h2>
          <h3>Perguntas Frequentes</h3>
        </S.FaqHeader>

        {faq
          .sort((a, b) => a.id - b.id)
          .map((question) => (
            <S.FaqItem key={question.id}>
              <S.FaqQuestion>{question.title}</S.FaqQuestion>
              {/* TODO: adjust line height */}
              <S.FaqAnswer
                as="p"
                dangerouslySetInnerHTML={{ __html: question.description }}
              />
            </S.FaqItem>
          ))}
      </S.FaqContainer>
    </S.FaqWrapper>
  );
};

export default FAQ;
