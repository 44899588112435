import { iAccount } from 'helpers/interfaces/iAccount';
import { iAccountSubscriptionAddress } from 'helpers/interfaces/iAccountSubscriptionAddress';
import { Plan } from 'helpers/models';
import { IuguCreditCard } from 'helpers/types/Iugu';

export enum AccountTypes {
  RESET_STATUS_STATE = '@ACCOUNT/RESET_STATUS_STATE',
  RESET_ACCOUNT_STATE = '@ACCOUNT/RESET_ACCOUNT_STATE',
  SET_PLAN_ID = '@ACCOUNT/SET_PLAN_ID',
  SET_SUBSCRIBER_DATA = '@ACCOUNT/SET_SUBSCRIBER_DATA',

  VALIDATE_ACCOUNT_REQUEST = '@ACCOUNT/VALIDATE_ACCOUNT_REQUEST',
  VALIDATE_ACCOUNT_SUCCESS = '@ACCOUNT/VALIDATE_ACCOUNT_SUCCESS',
  VALIDATE_ACCOUNT_FAILURE = '@ACCOUNT/VALIDATE_ACCOUNT_FAILURE',

  FETCH_ACCOUNT_REQUEST = '@ACCOUNT/FETCH_ACCOUNT_REQUEST',
  FETCH_ACCOUNT_SUCCESS = '@ACCOUNT/FETCH_ACCOUNT_SUCCESS',
  FETCH_ACCOUNT_FAILURE = '@ACCOUNT/FETCH_ACCOUNT_FAILURE',

  CREATE_ACCOUNT_REQUEST = '@ACCOUNT/CREATE_ACCOUNT_REQUEST',
  CREATE_ACCOUNT_SUCCESS = '@ACCOUNT/CREATE_ACCOUNT_SUCCESS',
  CREATE_ACCOUNT_FAILURE = '@ACCOUNT/CREATE_ACCOUNT_FAILURE',

  ACTIVATE_ACCOUNT_REQUEST = '@ACCOUNT/ACTIVATE_ACCOUNT_REQUEST',
  ACTIVATE_ACCOUNT_SUCCESS = '@ACCOUNT/ACTIVATE_ACCOUNT_SUCCESS',
  ACTIVATE_ACCOUNT_FAILURE = '@ACCOUNT/ACTIVATE_ACCOUNT_FAILURE',

  REACTIVATE_ACCOUNT_REQUEST = '@ACCOUNT/REACTIVATE_ACCOUNT_REQUEST',
  REACTIVATE_ACCOUNT_SUCCESS = '@ACCOUNT/REACTIVATE_ACCOUNT_SUCCESS',
  REACTIVATE_ACCOUNT_FAILURE = '@ACCOUNT/REACTIVATE_ACCOUNT_FAILURE',

  CANCEL_ACCOUNT_REQUEST = '@ACCOUNT/CANCEL_ACCOUNT_REQUEST',
  CANCEL_ACCOUNT_SUCCESS = '@ACCOUNT/CANCEL_ACCOUNT_SUCCESS',
  CANCEL_ACCOUNT_FAILURE = '@ACCOUNT/CANCEL_ACCOUNT_FAILURE',

  UPDATE_ACCOUNT_CREDIT_CARD_REQUEST = '@ACCOUNT/UPDATE_ACCOUNT_CREDIT_CARD_REQUEST',
  UPDATE_ACCOUNT_CREDIT_CARD_SUCCESS = '@ACCOUNT/UPDATE_ACCOUNT_CREDIT_CARD_SUCCESS',
  UPDATE_ACCOUNT_CREDIT_CARD_FAILURE = '@ACCOUNT/UPDATE_ACCOUNT_CREDIT_CARD_FAILURE',

  UPDATE_ACCOUNT_DATA_REQUEST = '@ACCOUNT/UPDATE_ACCOUNT_DATA_REQUEST',
  UPDATE_ACCOUNT_DATA_SUCCESS = '@ACCOUNT/UPDATE_ACCOUNT_DATA_SUCCESS',
  UPDATE_ACCOUNT_DATA_FAILURE = '@ACCOUNT/UPDATE_ACCOUNT_DATA_FAILURE',

  UPDATE_ACCOUNT_AVATAR_REQUEST = '@ACCOUNT/UPDATE_ACCOUNT_AVATAR_REQUEST',
  UPDATE_ACCOUNT_AVATAR_SUCCESS = '@ACCOUNT/UPDATE_ACCOUNT_AVATAR_SUCCESS',
  UPDATE_ACCOUNT_AVATAR_FAILURE = '@ACCOUNT/UPDATE_ACCOUNT_AVATAR_FAILURE',

  DELETE_ACCOUNT_AVATAR_REQUEST = '@ACCOUNT/DELETE_ACCOUNT_AVATAR_REQUEST',
  DELETE_ACCOUNT_AVATAR_SUCCESS = '@ACCOUNT/DELETE_ACCOUNT_AVATAR_SUCCESS',
  DELETE_ACCOUNT_AVATAR_FAILURE = '@ACCOUNT/DELETE_ACCOUNT_AVATAR_FAILURE'
}

export interface AccountState {
  readonly account: iAccount;
  readonly isAccountFetched: boolean;
  readonly isLoading: boolean;
  readonly hasError: boolean;
  readonly hadSucceed: boolean;
  readonly errorCode?: string | number;
  readonly errorMessage: string;
  readonly selectedPlan: Plan;
  readonly selectedPromotionId: number | null;
  readonly subscriptionAddress: iAccountSubscriptionAddress;
}

export type AccountAction = {
  type: AccountTypes;
  payload: {
    account: iAccount;
    isAccountFetched: boolean;
    isLoading: boolean;
    hasError: boolean;
    hadSucceed: boolean;
    errorCode?: string | number;
    errorMessage: string;
    subscriptionAddress: iAccountSubscriptionAddress;
    creditCard: IuguCreditCard;
    selectedPlan: Plan;
    selectedPromotionId: number | null;
    cancelationReason: string;
    name: string;
    email: string;
    password: string;
    cpf: string;
    updateAccount: {
      currentPassword: string;
      newPassword?: string;
      name: string;
      email: string;
      phone: string;
    };
    avatar: File;
  };
};
