import styled from 'styled-components';

export const AudioListWrapper = styled.div`
  width: 100%;
  max-width: 870px;
`;

export const AudioList = styled.ul`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};

  ${({ theme }) => theme.mq.lessThan('sm')`
    margin-bottom: 50px;
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    margin-bottom: 30px;
  `}
`;

export const PlaylistAction = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 38px;

  button {
    margin-right: 24px;
  }
`;

export const AudioItem = styled.li`
  .btn__link {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    color: ${({ theme }) => theme.colors.white};

    &:hover,
    &--is-selected {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.yellow};
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  }
`;

export const AudioName = styled.span`
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase; 
  text-align: left;
  margin-right: 20px;
`;

export const AudioDuration = styled.span`
  font-size: 14px;
  font-weight: 300;
`;

export const ShowMoreAudios = styled.button`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  
  svg {
    path {
      stroke: ${({ theme }) => theme.colors.white};
      transition: stroke 0.3s;
    }
  }

  ${({ theme }) => theme.mq.greaterThan('sm')`
    &:hover {
      svg {
        path {
          stroke: ${({ theme }) => theme.colors.yellow};
        }
      }
    }
  `}
`;