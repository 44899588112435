import { combineReducers } from 'redux';

import { accountReducer as account } from './ducks/account';
import { audioReducer as audio } from './ducks/audio';
import { categoryReducer as category } from './ducks/category';
import { contactReducer as contact } from './ducks/contact';
import { filterReducer as filter } from './ducks/filter';
import { institutionalReducer as institutional } from './ducks/institutional';
import { loadingReducer as loading } from './ducks/loading';
import { loginReducer as login } from './ducks/login';
import { modalReducer as modal } from './ducks/modal';
import { overlayReducer as overlay } from './ducks/overlay';
import { planReducer as plan } from './ducks/plan';
import { playerReducer as player } from './ducks/player';
import { playlistReducer as playlist } from './ducks/playlist';
import { popUpReducer as popUp } from './ducks/popUp';
import { recoverPasswordReducer as recoverPassword } from './ducks/recoverPassword';
import { searchReducer as search } from './ducks/search';
import { studentReducer as student } from './ducks/student';
import { teachingInstitutionReducer as teachingInstitution } from './ducks/teachingInstitution';
import { toastifyReducer as toastify } from './ducks/toastify';

export default combineReducers({
  account,
  audio,
  category,
  contact,
  filter,
  institutional,
  loading,
  login,
  modal,
  overlay,
  plan,
  player,
  playlist,
  popUp,
  recoverPassword,
  search,
  student,
  teachingInstitution,
  toastify
});
