/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import classNames from 'classnames';

import { fetchTermsData } from 'store/ducks/institutional';

import { PUBLIC_ROUTES } from 'helpers/constants';
import { ApplicationState } from 'helpers/types';

import { ButtonLink as Button } from 'components/Button';

import * as S from './styled';

const Legal = () => {
  const dispatch = useDispatch();
  const { terms } = useSelector(
    (state: ApplicationState) => state.institutional
  );

  const location = useLocation();

  const [activeContent, setActiveContent] = useState('');

  // Scrolls to top smoothly
  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    dispatch(fetchTermsData());
  }, []);

  useEffect(() => {
    switch (location.pathname) {
      case PUBLIC_ROUTES.TERMS_OF_USE:
        setActiveContent(terms.termsOfUse);
        break;
      case PUBLIC_ROUTES.PRIVACY_POLICY:
        setActiveContent(terms.privacyPolicies);
        break;
      case PUBLIC_ROUTES.COOKIES_POLICY:
        setActiveContent(terms.cookiesPolicy);
        break;
      default:
        break;
    }
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, [location, terms]);

  const isUrlActive = (url: string) => url === location.pathname;

  return (
    <S.LegalWrapper>
      <S.LegalContainer>
        <S.LegalHeader>
          <h2>Termos e condições de uso da Playlegis</h2>
          <h3>Legal</h3>
        </S.LegalHeader>

        <S.LegalTabs>
          <S.LegalToggler>
            <Button
              variant="link"
              to={PUBLIC_ROUTES.TERMS_OF_USE}
              className={classNames({
                'btn--is-active': isUrlActive(PUBLIC_ROUTES.TERMS_OF_USE)
              })}
            >
              Termos de uso
            </Button>
            <Button
              variant="link"
              to={PUBLIC_ROUTES.PRIVACY_POLICY}
              className={classNames({
                'btn--is-active': isUrlActive(PUBLIC_ROUTES.PRIVACY_POLICY)
              })}
            >
              Políticas de Privacidade
            </Button>
            <Button
              variant="link"
              to={PUBLIC_ROUTES.COOKIES_POLICY}
              className={classNames({
                'btn--is-active': isUrlActive(PUBLIC_ROUTES.COOKIES_POLICY)
              })}
            >
              Políticas de Cookies
            </Button>
          </S.LegalToggler>

          <S.LegalContent dangerouslySetInnerHTML={{ __html: activeContent }} />
        </S.LegalTabs>
      </S.LegalContainer>
    </S.LegalWrapper>
  );
};

export default Legal;
