import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Swiper, SwiperSlide } from 'swiper/react';

import { Testimonial } from 'helpers/models';
import * as types from 'helpers/types';

import * as S from './styled';

const Testimonials = () => {
  const [isLoading, setLoading] = useState(false);
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);

  const {
    institutional: {
      home: { testimonials: stateTestimonials },
      isHomeLoading
    }
  } = useSelector((state: types.ApplicationState) => state);

  useEffect(() => {
    setLoading(isHomeLoading);
  }, [isHomeLoading]);

  const handleTestimonialData = (data: Testimonial) => {
    if (!data.showInHome) {
      return <Fragment />;
    }

    return (
      <SwiperSlide key={data.contact}>
        <S.CarouselItem className="carousel__item">
          <S.CarouselDescription>{data.message}</S.CarouselDescription>
          <S.CarouselIdentification $avatarUrl={data.avatarUrl}>
            <span className="carousel__user-avatar" />
            <p>{data.contact}</p>
          </S.CarouselIdentification>
        </S.CarouselItem>
      </SwiperSlide>
    );
  };

  useEffect(() => {
    setTestimonials(stateTestimonials);
  }, [stateTestimonials]);

  const params = {
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: true
    },
    pagination: {
      el: '.swiper__pagination',
      clickable: true
    },
    slidesPerView: 1,
    spaceBetween: 0,
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 32
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 52
      }
    }
  };

  return isLoading || !testimonials.length ? (
    <Fragment />
  ) : (
    <S.TestimonialsWrapper>
      <S.Container>
        <S.TestimonialsHeader>
          <h3>Depoimentos</h3>
          <h4>O que estão falando de nós</h4>
        </S.TestimonialsHeader>

        <Swiper {...params}>
          {testimonials.map((item) => handleTestimonialData(item))}

          <div className="swiper__pagination" />
        </Swiper>
      </S.Container>
    </S.TestimonialsWrapper>
  );
};

export default Testimonials;
