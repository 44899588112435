import {
  ChangeEvent,
  FormEvent,
  KeyboardEvent,
  useEffect,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { ValidationError as YupValidationError } from 'yup';

import { updateAccountDataRequest } from 'store/ducks/account';

import {
  passwordChecklistRulesInitialState,
  userEditInputErrorInitialState
} from 'helpers/initialState';
import { accountSelector } from 'helpers/selectors';
import { PasswordChecklistRules, UserEditInputError } from 'helpers/types';
import { userEditValidation } from 'helpers/validation/user';

import { HTMLButton as Button } from 'components/Button';
import Input from 'components/Input';
import PasswordChecklist from 'components/PasswordChecklist';

import * as S from './styled';

const MyDataForm = () => {
  const dispatch = useDispatch();
  const { account } = useSelector(accountSelector);

  const [errors, setErrors] = useState<UserEditInputError>(
    userEditInputErrorInitialState
  );
  const [isEditable, setEditable] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordAgain, setNewPasswordAgain] = useState('');
  const [showPasswordChangeInput, setShowPasswordChangeInput] = useState(false);
  const [name, setName] = useState(account.name);
  const [phone, setPhone] = useState(account.subscription?.phone || '');
  const [email, setEmail] = useState(account.email);

  const [passwordValidation, setPasswordValidation] =
    useState<PasswordChecklistRules>(passwordChecklistRulesInitialState);

  useEffect(() => {
    setName(account.name);
    setPhone(account.subscription?.phone || '');
    setEmail(account.email);
  }, [account]);

  const handleEditAction = () => {
    setEditable(!isEditable);
  };

  const handleCancelAction = () => {
    setName(account.name);
    setPhone(account.subscription?.phone || '');
    setEmail(account.email);
    setCurrentPassword('');
    setEditable(!isEditable);
  };

  const handleSubmit = (event?: FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }

    toast.dismiss();

    let errs: UserEditInputError = userEditInputErrorInitialState;

    try {
      userEditValidation(
        {
          password: currentPassword,
          data: {
            name,
            email,
            phone,
            password: newPassword
          }
        },
        showPasswordChangeInput,
        newPasswordAgain,
        passwordValidation
      );

      dispatch(
        updateAccountDataRequest({
          currentPassword,
          newPassword,
          name,
          email,
          phone
        })
      );
      setEditable(false);
      handleCancelAction();
    } catch (error) {
      if (error instanceof YupValidationError) {
        error.inner.forEach((e) => {
          if (e.path) {
            errs = {
              ...errs,
              [`${e.path}`]: true
            };
          }

          toast.error(e.message, { autoClose: false });
        });
      }
    } finally {
      setErrors(errs);
    }
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const handleChangePasswordAgain = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPasswordAgain(e.target.value);
  };

  const toggleChangePasswordInputsVisibility = () => {
    setShowPasswordChangeInput(!showPasswordChangeInput);
  };

  const onEnterKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    } else {
      return true;
    }
  };

  return (
    <S.MyDataFormWrapper>
      <S.AccountFormHeader>
        <h3>Meus dados</h3>

        <Button variant="link" onClick={handleEditAction}>
          Editar
        </Button>
      </S.AccountFormHeader>

      <S.AccountForm onSubmit={handleSubmit} onKeyDown={onEnterKeyDown}>
        <Input
          type="text"
          id="name"
          name="name"
          placeholder="Nome"
          className="input__user input__user--is-name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled={!isEditable}
          hasError={errors.name}
        />

        <Input
          type="text"
          id="email"
          name="email"
          placeholder="Email"
          className="input__user input__user--is-email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={!isEditable}
          hasError={errors.email}
        />

        <Input
          type="text"
          id="phone"
          name="phone"
          placeholder="Telefone"
          className="input__user input__user--is-phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          disabled={!isEditable}
          hasError={errors.phone}
        />

        <Input
          type="text"
          id="cpf"
          name="cpf"
          placeholder="CPF"
          className="input__user input__user--is-cpf"
          value={account.cpf}
          disabled
        />

        <Input
          type="password"
          id="currentPassword"
          name="currentPassword"
          placeholder={isEditable ? 'Confirmar a senha atual' : 'Senha'}
          className="input__user input__user--is-current-password"
          value={currentPassword}
          onChange={(e) => {
            setCurrentPassword(e.target.value);
          }}
          disabled={!isEditable}
          hasError={errors.currentPassword}
        />

        {isEditable && (
          <S.ChangePasswordInputs>
            <Button
              type="button"
              variant="link"
              onClick={toggleChangePasswordInputsVisibility}
            >
              {showPasswordChangeInput ? 'Cancelar' : 'Trocar senha'}
            </Button>

            {showPasswordChangeInput && (
              <PasswordChecklist
                value={newPassword}
                onChange={handleChangePassword}
                valueAgain={newPasswordAgain}
                onChangeAgain={handleChangePasswordAgain}
                onChecklistChange={setPasswordValidation}
                placeholders={{
                  password: 'Nova senha',
                  passwordAgain: 'Confirmar a nova senha'
                }}
                inputClasses={{
                  password: 'input__user input__user--is-current-password',
                  passwordAgain: 'input__user input__user--is-current-password'
                }}
                hasError={{
                  password: errors.password || errors.passwordsDontMatch,
                  passwordAgain:
                    errors.passwordAgain || errors.passwordsDontMatch,
                  passwordValidation: errors.passwordValidation
                }}
              />
            )}

            <S.ButtonGroup>
              <Button variant="line" onClick={handleCancelAction}>
                Cancelar
              </Button>

              <Button variant="yellow" type="submit">
                Salvar
              </Button>
            </S.ButtonGroup>
          </S.ChangePasswordInputs>
        )}
      </S.AccountForm>
    </S.MyDataFormWrapper>
  );
};

export default MyDataForm;
