import { ChangeEvent, FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { ValidationError } from 'yup';

import { setAccountUserData as setAccountSubscriptionUserData } from 'store/ducks/account';

import { PUBLIC_ROUTES } from 'helpers/constants';
import { SubscriptionUserData } from 'helpers/models';
import { ApplicationState } from 'helpers/types';
import { subscriptionUserDataValidation } from 'helpers/validation/subscription';
import { useWebviewChecker } from 'hooks/useWebviewChecker';

import { ButtonLink as Link, HTMLButton as Button } from 'components/Button';
import Input from 'components/Input';
import InputMask from 'components/InputMask';
import SignHeader from 'components/SignHeader';
import {
  useNavigation,
  NavigationActionTypes
} from 'contexts/NavigationContext';

import * as GS from '../../styles';
import * as S from './styles';

const SubscriptionDataForm = () => {
  const { account } = useSelector((state: ApplicationState) => state);

  const [subscriptionUserData, setSubscriptionUserData] = useState(
    account.subscriptionAddress
  );

  const dispatchRedux = useDispatch();
  const { dispatch: dispatchNavigation } = useNavigation();

  const { onWebview } = useWebviewChecker();

  const handleUserDataSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      subscriptionUserDataValidation(subscriptionUserData);

      dispatchRedux(setAccountSubscriptionUserData(subscriptionUserData));
      dispatchNavigation({ type: NavigationActionTypes.INCREMENT });
    } catch (error) {
      if (error instanceof ValidationError) {
        error.inner.forEach((e) => {
          toast.error(e.message);
        });
      }
    }
  };

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    const phone = event.target.value.replace(/(\(|\)|\s|_)*/g, '');

    setSubscriptionUserData({
      ...subscriptionUserData,
      phone
    });
  };

  return (
    <GS.ActivateAccountContainer className="container__form container__form--is-user-data">
      <S.ActivateAccountUserData>
        <SignHeader
          title="Assinatura Playlegis"
          subtitle="Cadastrar endereço"
        />

        <GS.ActivateAccountForm onSubmit={handleUserDataSubmit}>
          <InputMask
            type="text"
            id="phone"
            name="phone"
            placeholder="Telefone com DDD"
            className="ActivateAccount__input ActivateAccount__input--is-phone"
            value={subscriptionUserData.phone}
            onChange={handlePhoneChange}
            hasShadow
            mask="(99) 9999 99999"
          />

          <Input
            type="text"
            id="address"
            name="address"
            placeholder="Endereço"
            className="ActivateAccount__input ActivateAccount__input--is-name"
            value={subscriptionUserData.address}
            onChange={(e) =>
              setSubscriptionUserData({
                ...subscriptionUserData,
                address: e.target.value
              })
            }
            hasShadow
          />

          <Input
            type="text"
            id="city"
            name="city"
            placeholder="Cidade"
            className="ActivateAccount__input ActivateAccount__input--is-email"
            value={subscriptionUserData.city}
            onChange={(e) =>
              setSubscriptionUserData({
                ...subscriptionUserData,
                city: e.target.value
              })
            }
            hasShadow
          />

          <Input
            type="text"
            id="state"
            name="state"
            placeholder="Estado"
            className="ActivateAccount__input ActivateAccount__input--is-email"
            value={subscriptionUserData.state}
            onChange={(e) =>
              setSubscriptionUserData({
                ...subscriptionUserData,
                state: e.target.value
              })
            }
            hasShadow
          />

          <Input
            type="text"
            id="country"
            name="country"
            placeholder="País"
            className="ActivateAccount__input ActivateAccount__input--is-cpf"
            value={subscriptionUserData.country}
            onChange={(e) =>
              setSubscriptionUserData({
                ...subscriptionUserData,
                country: e.target.value
              })
            }
            hasShadow
          />

          <Button type="submit" variant="yellow">
            Continuar
          </Button>
        </GS.ActivateAccountForm>
      </S.ActivateAccountUserData>

      {!onWebview && (
        <S.ActivateAccountLoginLink>
          Já possui uma conta?{' '}
          <Link to={PUBLIC_ROUTES.LOGIN} variant="link">
            Faça Login
          </Link>
          .
        </S.ActivateAccountLoginLink>
      )}
    </GS.ActivateAccountContainer>
  );
};

export default SubscriptionDataForm;
