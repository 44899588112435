import { action } from 'typesafe-actions';

import { iAccount } from 'helpers/interfaces/iAccount';
import { iAccountSubscriptionAddress } from 'helpers/interfaces/iAccountSubscriptionAddress';
import { Plan } from 'helpers/models';
import { IuguCreditCard } from 'helpers/types';

import { AccountTypes } from './types';

export const resetAccountStatusState = () =>
  action(AccountTypes.RESET_STATUS_STATE);

export const resetAccountState = () => action(AccountTypes.RESET_ACCOUNT_STATE);

export const setSelectedPlan = (
  selectedPlan: Plan,
  selectedPromotionId: number | null
) => action(AccountTypes.SET_PLAN_ID, { selectedPlan, selectedPromotionId });

export const setAccountUserData = (
  subscriptionAddress: iAccountSubscriptionAddress
) => action(AccountTypes.SET_SUBSCRIBER_DATA, { subscriptionAddress });

export const validateAccountRequest = (account: iAccount) =>
  action(AccountTypes.VALIDATE_ACCOUNT_REQUEST, { account });

export const validateAccountSuccess = () =>
  action(AccountTypes.VALIDATE_ACCOUNT_SUCCESS);

export const validateAccountFailure = (
  errorMessage: string,
  errorCode?: string | number
) => action(AccountTypes.VALIDATE_ACCOUNT_FAILURE, { errorMessage, errorCode });

export const fetchAccountRequest = () =>
  action(AccountTypes.FETCH_ACCOUNT_REQUEST);

export const fetchAccountSuccess = (account: iAccount) =>
  action(AccountTypes.FETCH_ACCOUNT_SUCCESS, { account });

export const fetchAccountFailure = (
  errorMessage: string,
  errorCode?: string | number
) => action(AccountTypes.FETCH_ACCOUNT_FAILURE, { errorMessage, errorCode });
export const createAccountRequest = (creditCard: IuguCreditCard) =>
  action(AccountTypes.CREATE_ACCOUNT_REQUEST, {
    creditCard
  });

export const createAccountSuccess = () =>
  action(AccountTypes.CREATE_ACCOUNT_SUCCESS);

export const createAccountFailure = (
  errorMessage: string,
  errorCode?: string | number
) => action(AccountTypes.CREATE_ACCOUNT_FAILURE, { errorMessage, errorCode });

export const activateAccountRequest = (creditCard: IuguCreditCard) =>
  action(AccountTypes.ACTIVATE_ACCOUNT_REQUEST, {
    creditCard
  });

export const activateAccountSuccess = () =>
  action(AccountTypes.ACTIVATE_ACCOUNT_SUCCESS);

export const activateAccountFailure = (
  errorMessage: string,
  errorCode?: string | number
) => action(AccountTypes.ACTIVATE_ACCOUNT_FAILURE, { errorMessage, errorCode });

export const reactivateAccountRequest = (creditCard: IuguCreditCard) =>
  action(AccountTypes.REACTIVATE_ACCOUNT_REQUEST, { creditCard });

export const reactivateAccountSuccess = () =>
  action(AccountTypes.REACTIVATE_ACCOUNT_SUCCESS);

export const reactivateAccountFailure = (
  errorMessage: string,
  errorCode?: string | number
) =>
  action(AccountTypes.REACTIVATE_ACCOUNT_FAILURE, {
    errorMessage,
    errorCode
  });

export const cancelAccountRequest = (cancelationReason: string) =>
  action(AccountTypes.CANCEL_ACCOUNT_REQUEST, {
    cancelationReason
  });

export const cancelAccountSuccess = () =>
  action(AccountTypes.CANCEL_ACCOUNT_SUCCESS);

export const cancelAccountFailure = (
  errorMessage: string,
  errorCode?: string | number
) => action(AccountTypes.CANCEL_ACCOUNT_FAILURE, { errorMessage, errorCode });

export const updateAccountCreditCardRequest = (creditCard: IuguCreditCard) =>
  action(AccountTypes.UPDATE_ACCOUNT_CREDIT_CARD_REQUEST, { creditCard });

export const updateAccountCreditCardSuccess = () =>
  action(AccountTypes.UPDATE_ACCOUNT_CREDIT_CARD_SUCCESS);

export const updateAccountCreditCardFailure = (
  errorMessage: string,
  errorCode?: string | number
) =>
  action(AccountTypes.UPDATE_ACCOUNT_CREDIT_CARD_FAILURE, {
    errorMessage,
    errorCode
  });

export const updateAccountDataRequest = (updateAccount: {
  currentPassword: string;
  newPassword?: string;
  name: string;
  email: string;
  phone: string;
}) => action(AccountTypes.UPDATE_ACCOUNT_DATA_REQUEST, { updateAccount });

export const updateAccountDataSuccess = () =>
  action(AccountTypes.UPDATE_ACCOUNT_DATA_SUCCESS);

export const updateAccountDataFailure = (
  errorMessage: string,
  errorCode?: string | number
) =>
  action(AccountTypes.UPDATE_ACCOUNT_DATA_FAILURE, {
    errorMessage,
    errorCode
  });

export const updateAccountAvatarRequest = (avatar: File) =>
  action(AccountTypes.UPDATE_ACCOUNT_AVATAR_REQUEST, { avatar });

export const updateAccountAvatarSuccess = () =>
  action(AccountTypes.UPDATE_ACCOUNT_AVATAR_SUCCESS);

export const updateAccountAvatarFailure = (
  errorMessage: string,
  errorCode?: string | number
) =>
  action(AccountTypes.UPDATE_ACCOUNT_AVATAR_FAILURE, {
    errorMessage,
    errorCode
  });

export const deleteAccountAvatarRequest = () =>
  action(AccountTypes.DELETE_ACCOUNT_AVATAR_REQUEST);

export const deleteAccountAvatarSuccess = () =>
  action(AccountTypes.DELETE_ACCOUNT_AVATAR_SUCCESS);

export const deleteAccountAvatarFailure = (
  errorMessage: string,
  errorCode?: string | number
) =>
  action(AccountTypes.DELETE_ACCOUNT_AVATAR_FAILURE, {
    errorMessage,
    errorCode
  });
