/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';

import { ValidationError } from 'yup';

export function showValidationErrorToast<T>(error: any, initialState: T) {
  let errs: T = initialState;

  if (error instanceof ValidationError) {
    error.inner.forEach((e) => {
      if (e.path) {
        errs = {
          ...errs,
          [`${e.path}`]: true
        };
      }

      toast.error(e.message, { autoClose: false });
    });
  }

  return errs;
}
