import styled from 'styled-components';

export const AudiosWrapper = styled.div`
  ${({ theme }) => theme.bp.Fluid};
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mq.greaterThan('md')`
    padding-bottom: 100px;
  `};
`;

export const AudiosContainer = styled.div`
  ${({ theme }) => theme.bp.Medium};
  padding-bottom: 100px;
`;