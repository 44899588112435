export function validateCpf(cpf: string | undefined) {
  if (!cpf) {
    return false;
  }

  const serializedCPF = cpf.replace(/[\s.-]*/igm, '');

  const filter = !Array
    .from(serializedCPF)
    .filter((e) => e !== serializedCPF[0]).length;

  if (serializedCPF.length !== 11 || filter) {
    return false;
  }

  let sum = 0;
  let rest;

  for (let i = 1; i <= 9; i += 1) {
    sum += parseInt(serializedCPF.substring(i - 1, i), 10) * (11 - i);
  }

  rest = (sum * 10) % 11;
  if ((rest === 10) || (rest === 11)) rest = 0;
  if (rest !== parseInt(serializedCPF.substring(9, 10), 10)) return false;

  sum = 0;
  for (let i = 1; i <= 10; i += 1) {
    sum += parseInt(serializedCPF.substring(i - 1, i), 10) * (12 - i);
  }

  rest = (sum * 10) % 11;
  if ((rest === 10) || (rest === 11)) rest = 0;
  if (rest !== parseInt(serializedCPF.substring(10, 11), 10)) return false;

  return true;
}