import styled from 'styled-components';

export const CategoriesWrapper = styled.div`
  ${({ theme }) => theme.mq.lessThan('sm')`
    max-width: 90vw;
  `}
`;

export const CategoryContainer = styled.div`
  margin-bottom: 40px;

  h3 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
  }
`;

export const CategoryHeader = styled.header`
  display: flex;

  h3 {
    flex-grow: 1;
  }

  .btn__link {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 300;

    &:hover {
      color: ${({ theme }) => theme.colors.yellow};
    }
  }
`;

export const ShowMore = styled.button`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  transition: color 0.3s;

  svg {
    margin-top: 12px;

    path {
      stroke: ${({ theme }) => theme.colors.white};
      transition: stroke 0.3s;
    }
  }

  ${({ theme }) => theme.mq.greaterThan('sm')`
    &:hover {
      color: ${({ theme }) => theme.colors.yellow};

      svg {
        path {
          stroke: ${({ theme }) => theme.colors.yellow};
        }
      }
    }
  `}
`;