import { Fragment, useEffect, useRef, useState } from 'react';
import { HiChevronDoubleUp, HiChevronDoubleDown } from 'react-icons/hi';

import classNames from 'classnames';

import { HTMLButton as Button } from 'components/Button';

import { iPlayerTranscription } from './interfaces/iPlayerTranscription';
import * as S from './PlayerTranscription.styled';

type TranscriptionFontSize = '10px' | '12px' | '14px' | '16px' | '18px';

export const PlayerTranscription = (props: iPlayerTranscription) => {
  const { transcription, isPlayerOpen } = props;

  const isIphone = !!navigator.userAgent.match(/iPhone/);

  const [showTranscription, setShowTranscription] = useState(false);
  const [fontSize, setFontSize] = useState<TranscriptionFontSize>('14px');
  const [classSuffix, setClassSuffix] = useState('--transcription-hide');

  const transcriptionBodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setClassSuffix(
      showTranscription ? '--transcription-visible' : '--transcription-hidden'
    );
  }, [showTranscription]);

  useEffect(() => {
    if (!isPlayerOpen) {
      setTimeout(() => {
        setFontSize('14px');
        setShowTranscription(false);
        setClassSuffix('--transcription-hidden');
      }, 1000);
    }
  }, [isPlayerOpen]);

  useEffect(() => {
    const transcriptionBody = transcriptionBodyRef.current;

    if (transcriptionBody) {
      transcriptionBody.scrollTop = 0;
    }
  }, [transcription]);

  const handleIncreaseFontSize = () => {
    if (fontSize === '18px') return;

    let newFontSize: TranscriptionFontSize = fontSize;

    switch (fontSize) {
      case '10px':
        newFontSize = '12px';
        break;
      case '12px':
        newFontSize = '14px';
        break;
      case '14px':
        newFontSize = '16px';
        break;
      case '16px':
        newFontSize = '18px';
        break;
      default:
        break;
    }

    setFontSize(newFontSize);
  };

  const handleDecreaseFontSize = () => {
    if (fontSize === '10px') return;

    let newFontSize: TranscriptionFontSize = fontSize;

    switch (fontSize) {
      case '18px':
        newFontSize = '16px';
        break;
      case '16px':
        newFontSize = '14px';
        break;
      case '14px':
        newFontSize = '12px';
        break;
      case '12px':
        newFontSize = '10px';
        break;
      default:
        break;
    }

    setFontSize(newFontSize);
  };

  const handleTranscriptionTogglerClick = () =>
    setShowTranscription(!showTranscription);

  const getTranscriptionText = () => {
    let transcriptionText = transcription;

    if (!transcription) {
      transcriptionText = 'Áudio sem transcrição.';
    }

    return transcriptionText;
  };

  return (
    <S.PlayerTranscriptionContainer>
      <S.PlayerTranscriptionToggler
        onClick={handleTranscriptionTogglerClick}
        className={classNames(
          'player-transcription__toggler',
          `player-transcription__toggler${classSuffix}`
        )}
      >
        {showTranscription ? (
          <Fragment>
            Fechar texto da lei
            <HiChevronDoubleDown />
          </Fragment>
        ) : (
          <Fragment>
            Ver texto da lei
            <HiChevronDoubleUp />
          </Fragment>
        )}
      </S.PlayerTranscriptionToggler>
      <S.PlayerTranscriptionBodyWrapper
        className={classNames(
          'player-transcription__body-wrapper',
          `player-transcription__body-wrapper${classSuffix}`
        )}
      >
        <S.PlayerTranscriptionZoomContainer
          className={classNames(
            'player-transcription__zoom-container',
            `player-transcription__zoom-container${classSuffix}`,
            {
              'player-transcription__zoom-container--no-transcription':
                !transcription
            }
          )}
        >
          <Button
            onClick={handleIncreaseFontSize}
            variant="blue"
            disabled={fontSize === '18px'}
          >
            +
          </Button>
          <Button
            onClick={handleDecreaseFontSize}
            variant="blue"
            disabled={fontSize === '10px'}
          >
            -
          </Button>
        </S.PlayerTranscriptionZoomContainer>
        <S.PlayerTranscriptionBody
          ref={transcriptionBodyRef}
          className={classNames(
            'player-transcription__body',
            `player-transcription__body${classSuffix}`,
            {
              'player-transcription__body--no-transcription': !transcription
            }
          )}
          $isIphone={isIphone}
        >
          <S.PlayerTranscriptionBodyText
            className={classNames(
              'player-transcription__body-text',
              `player-transcription__body-text${classSuffix}`,
              {
                'player-transcription__body-text--has-transcription':
                  !!transcription
              }
            )}
            $fontSize={fontSize}
            dangerouslySetInnerHTML={{ __html: getTranscriptionText() }}
          />
        </S.PlayerTranscriptionBody>
      </S.PlayerTranscriptionBodyWrapper>
    </S.PlayerTranscriptionContainer>
  );
};
