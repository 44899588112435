import { Reducer } from 'redux';

import { FilterTypes, FilterState, FilterAction } from './types';
import { FILTER_INITIAL_STATE } from './utils';

const reducer: Reducer<FilterState, FilterAction> = (
  state: FilterState = FILTER_INITIAL_STATE,
  action: FilterAction,
) => {
  switch (action.type) {
    case FilterTypes.SET_CATEGORY_FILTER: {
      return {
        ...state,
        selectedCategory: action.payload.selectedCategory,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;