import Axios from 'axios';
import Cookies from 'js-cookie';

import forbidden from './response';

const defaultUrlV1 = process.env.REACT_APP_API_URL_V1;
const defaultUrlV2 = process.env.REACT_APP_API_URL_V2;

const Connector = (useV2 = false, useInterceptors = true, customToken = '') => {
  const cookiesToken = Cookies.get('al-web-token');

  const conn = Axios.create({
    baseURL: useV2 ? defaultUrlV2 : defaultUrlV1,
    headers: {
      Authorization: `Bearer ${customToken || cookiesToken}`
    }
  });

  if (useInterceptors) {
    conn.interceptors.response.use(
      forbidden.responseInterceptor,
      forbidden.responseInterceptorError
    );
  }

  return conn;
};

export { defaultUrlV1, defaultUrlV2 };

export default Connector;
