import styled from 'styled-components';

export const TypeToggler = styled.div`
  background-color: ${({ theme }) => theme.colors.grey};
  padding: 2px;
  border-radius: 16px;

  button {
    padding: 8px 36px;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 300;
    transition: all 0.3s;
    text-transform: capitalize;

    &.btn--is-selected {
      background-color: ${({ theme }) => theme.colors.blue};
      color: ${({ theme }) => theme.colors.yellow};
      font-weight: 700;
    }
  }
`;