import styled from 'styled-components';

export const PopUpContainer = styled.div`
  position: fixed;
  width: 100%;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  /* background-color: black; */
`;

export const PopUpItem = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 8px ${({ theme }) => theme.colors.blueDarker};
  font-weight: 300;
  font-size: 14px;
  display: flex;
  position: relative;

  .btn__link {
    text-decoration: underline;
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 26px;
    max-width: 92%;

    .btn__link {
      font-size: 14px;
      margin-bottom: 16px;
    }

    .btn__blue {
      align-self: center;
      padding: 10px 28px;
      font-weight: 700;
    }

    .popup__action-button {
      width: 100%;
      margin-top: 12px;
    }
  `}

  ${({ theme }) => theme.mq.between('sm', 'lgLessOne')`
    .popup__action-button {
      max-width: 150px;
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('lg')`
    .popup__action-button {
      max-width: 200px;
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    align-items: center;
    padding: 12px 30px;
    max-width: 1240px;
    width: 80%;

    .btn__link {
      margin-left: 4px;
      margin-right: 32px;
    }

    .btn__blue {
      &:hover {
        color: ${({ theme }) => theme.colors.yellow};
      }
    }

    .popup__action-button {
      width: 100%;
      margin-left: 20px;
    }
  `}

  & + .popup__item {
    margin-top: 12px;
  }
`;

export const PopUpItemDescription = styled.div`
  flex-grow: 1;
`;

export const PopUpItemCloseButton = styled.button`
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: ${({ theme }) => theme.colors.blue};
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid white;

  svg {
    color: white;
  }
`;
