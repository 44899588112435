import styled from 'styled-components';

export const UserMenuWrapper = styled.div<{ $isOpen: boolean }>`
  position: relative;
  border-radius: 16px 16px 0 0;
  transition: background-color 0.3s;

  ${({ theme, $isOpen }) => $isOpen && `
    background-color: ${theme.colors.grey};
  `}
`;

export const MenuButton = styled.button`
  min-width: 200px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;

  .nav__user-initials,
  .nav__user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 25px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(219, 178, 0, 0.20);
    margin-right: 12px;
  }
  
  .nav__user-initials {
    color: white;
    background: ${({ theme }) => theme.colors.grey};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav__user-avatar {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
    }
  }

  .nav__user-name {
    color: ${({ theme }) => theme.colors.white};
    margin-right: 8px;

    &--is-light-mode {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`;

export const MenuListWrapper = styled.div<{ $isOpen: boolean }>`
  width: 100%;
  position: absolute;
  padding: 12px 10px;
  opacity: 0;
  z-index: -1;
  overflow: hidden;
  transition: opacity 0.3s, z-index 0.3s;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.grey};
  border-radius: 0 0 16px 16px;

  ${({ $isOpen }) => $isOpen && `
    opacity: 1;
    z-index: 10;
  `}
`;

export const MenuList = styled.ul`
`;

export const MenuItem = styled.li`
  font-size: 14px;
  font-weight: 300;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  a {
    color: ${({ theme }) => theme.colors.white};

    &:hover {
      text-decoration: underline;
    }
  }
`;