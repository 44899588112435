import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import classNames from 'classnames';

import { resetAccountStatusState, setSelectedPlan } from 'store/ducks/account';

import { ReactComponent as NavigationArrow } from 'assets/icons/navigation/arrowLeft.svg';
import { planInitialState } from 'helpers/initialState';
import { accountSelector } from 'helpers/selectors';

import { HTMLButton as Button } from 'components/Button';
import { useNavigation } from 'contexts/NavigationContext';
import { NavigationActionTypes } from 'contexts/NavigationContext/types';

import * as S from './styled';

export interface SignHeaderProps {
  title: string;
  subtitle: string;
  withBackButton?: boolean;
  className?: string;
  onBack?: () => void;
}

const SignHeader = (props: SignHeaderProps) => {
  const { title, subtitle, withBackButton, className, onBack } = props;

  const { hasError } = useSelector(accountSelector);
  const {
    state: { step },
    dispatch: dispatchNavigation
  } = useNavigation();
  const dispatchRedux = useDispatch();
  const { search } = useLocation();
  const [showBack, setShowBack] = useState(withBackButton);

  const navigate = useNavigate();

  useEffect(() => {
    if (search.includes('isWebview')) {
      setShowBack(step > 1);
    } else {
      const show = withBackButton !== null ? withBackButton : true;

      setShowBack(show);
    }
  }, [step, search]);

  const handleBackAction = () => {
    if (step < 0) {
      return;
    }

    if (hasError) {
      dispatchRedux(resetAccountStatusState());
    }

    if (onBack) {
      onBack();
    }

    switch (step) {
      case 1:
        navigate(-1);
        break;
      case 2:
        dispatchRedux(setSelectedPlan(planInitialState, null));
        dispatchNavigation({ type: NavigationActionTypes.DECREMENT });
        break;
      default:
        dispatchNavigation({ type: NavigationActionTypes.DECREMENT });
        break;
    }
  };

  return (
    <S.SignUpHeader
      className={classNames(className, {
        signup__header: step > 1,
        'signup__header--is-form-header': step > 1
      })}
    >
      {showBack && (
        <S.SignUpBackButton>
          <Button variant="link" onClick={handleBackAction}>
            <NavigationArrow />
            Voltar
          </Button>
        </S.SignUpBackButton>
      )}

      <h1>{title}</h1>
      <h2>{subtitle}</h2>
    </S.SignUpHeader>
  );
};

SignHeader.defaultProps = {
  withBackButton: null,
  className: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onBack: () => {}
};

export default memo(SignHeader);
