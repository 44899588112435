import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';

import { openModal } from 'store/ducks/modal';

import { PRIVATE_ROUTES, PUBLIC_ROUTES } from 'helpers/constants';
import { Playlist } from 'helpers/models';
import { accountSelector } from 'helpers/selectors';
import useAuth from 'hooks/useAuth';
import { formatSecondsAsHourTime } from 'utils/timeFormat';

import * as S from './styled';

interface CategoryThumbLinkProps {
  playlist: Playlist;
}

const CategoryThumbLink = (props: CategoryThumbLinkProps) => {
  const { playlist } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { account } = useSelector(accountSelector);
  const { isUserAuthenticated } = useAuth();

  const handlePlaylistClick = () => {
    if (isUserAuthenticated) {
      if (!account.subscription) {
        if (account.isAdmin) {
          navigate(`${PRIVATE_ROUTES.PLAYLIST}/${playlist.id}`);
        } else {
          dispatch(
            openModal({
              title: 'Playlist',
              subtitle: 'Ative sua assinatura para ter acesso às Playlists',
              primaryButton: {
                linkTo: PRIVATE_ROUTES.ACTIVATE_ACCOUNT,
                variant: 'blue',
                label: 'Ativar assinatura agora'
              },
              secondaryButton: {
                linkTo: PRIVATE_ROUTES.ACCOUNT,
                label: (
                  <Fragment>
                    Ver <span>minha conta</span>
                  </Fragment>
                ),
                variant: 'link'
              }
            })
          );
        }
      } else {
        const remainingTime = moment(account.subscription.expiresAt).diff(
          moment(),
          'days'
        );

        const isNotCanceled =
          account.subscription.subscriptionStatus !== 'CANCELED';
        const isNotOverdue =
          account.subscription.subscriptionStatus !== 'OVERDUE';
        const isSubscriptionActive =
          remainingTime >= 0 ||
          (isNotCanceled && isNotOverdue) ||
          account.isAdmin;

        if (isSubscriptionActive) {
          navigate(`${PRIVATE_ROUTES.PLAYLIST}/${playlist.id}`);
        } else {
          dispatch(
            openModal({
              title: 'Playlist',
              subtitle: 'Reative sua assinatura para ter acesso às Playlists',
              primaryButton: {
                linkTo: PRIVATE_ROUTES.REACTIVATE_ACCOUNT,
                variant: 'blue',
                label: 'Reativar assinatura agora'
              },
              secondaryButton: {
                linkTo: PRIVATE_ROUTES.ACCOUNT,
                label: (
                  <Fragment>
                    Ver <span>minha conta</span>
                  </Fragment>
                ),
                variant: 'link'
              }
            })
          );
        }
      }
    } else {
      dispatch(
        openModal({
          title: 'Playlist',
          subtitle: 'Se cadastre para ter acesso às Playlists',
          primaryButton: {
            linkTo: PUBLIC_ROUTES.SIGNUP,
            variant: 'blue',
            label: 'Fazer cadastro agora'
          },
          secondaryButton: {
            linkTo: PUBLIC_ROUTES.SIGNUP,
            variant: 'link',
            label: (
              <Fragment>
                ou faça <span>Login</span>
              </Fragment>
            )
          }
        })
      );
    }
  };

  return (
    <S.ThumbButton type="button" onClick={handlePlaylistClick}>
      <S.AudioItem className="audio__item">
        <S.AudioInfo>
          <S.AudioInfoLeft>
            <h4>{playlist.name}</h4>
          </S.AudioInfoLeft>
          <S.AudioInfoRight>
            <span>{formatSecondsAsHourTime(playlist.duration)}</span>
          </S.AudioInfoRight>
        </S.AudioInfo>
        <S.AudioThumb coverUrl={playlist.coverUrl} />
      </S.AudioItem>
    </S.ThumbButton>
  );
};

export default CategoryThumbLink;
