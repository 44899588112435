/* eslint-disable react/display-name */
import { forwardRef, InputHTMLAttributes, useEffect, useState } from 'react';

import classNames from 'classnames';

import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as EyeClosedIcon } from 'assets/icons/eyeClosed.svg';

import * as S from './styled';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  hasShadow?: boolean;
  icon?: string;
  hasError?: boolean;
  hideSpinButton?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref) => {
    const {
      hasShadow,
      className,
      children,
      icon,
      type: originalType,
      hasError,
      hideSpinButton,
      ...rest
    } = props;

    const [passwordShown, setPasswordShown] = useState(false);
    const [type, setType] = useState(originalType);

    useEffect(() => {
      if (originalType === 'password') {
        if (!passwordShown) {
          setType(originalType);
        } else {
          setType('text');
        }
      }
    }, [passwordShown]);

    return (
      <S.InputWrapper
        className={classNames({
          [`${className}`]: className
        })}
        onMouseLeave={() =>
          originalType === 'password' && setPasswordShown(false)
        }
      >
        <S.Input
          className={classNames({
            'input--has-shadow': hasShadow,
            'input--has-error': hasError,
            'hide-spin': hideSpinButton
          })}
          ref={ref}
          $iconUrl={icon}
          type={type}
          {...rest}
        />
        {originalType === 'password' && (
          <S.EyeButton
            type="button"
            onClick={() => setPasswordShown(!passwordShown)}
            title="Mostrar senha"
            tabIndex={-1}
          >
            {passwordShown && <EyeClosedIcon />}
            {!passwordShown && <EyeIcon />}
          </S.EyeButton>
        )}
      </S.InputWrapper>
    );
  }
);

Input.defaultProps = {
  hasShadow: false,
  icon: '',
  hasError: false,
  hideSpinButton: false
};

export default Input;
