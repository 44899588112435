import { Reducer } from 'redux';

import { SearchTypes, SearchState, SearchAction } from './types';
import { SEARCH_INITIAL_STATE } from './utils';

const reducer: Reducer<SearchState, SearchAction> = (
  state: SearchState = SEARCH_INITIAL_STATE,
  action: SearchAction,
) => {
  switch (action.type) {
    case SearchTypes.SEARCH_REQUEST: {
      return {
        ...state,
        terms: action.payload.terms,
      };
    }
    case SearchTypes.SEARCH_SUCCESS: {
      return {
        ...state,
        response: action.payload.response,
      };
    }
    case SearchTypes.SET_SEARCHING: {
      return {
        ...state,
        searching: action.payload.searching
      }
    }
    default: {
      return state;
    }
  }
};

export default reducer;