import { Reducer } from 'redux';
import { action } from 'typesafe-actions';

import { AccountAction, AccountState, AccountTypes } from './types';
import { ACCOUNT_INITIAL_STATE } from './utils';

const reducer: Reducer<AccountState, AccountAction> = (
  state: AccountState = ACCOUNT_INITIAL_STATE,
  action: AccountAction
) => {
  switch (action.type) {
    case AccountTypes.RESET_STATUS_STATE: {
      return {
        ...state,
        hasError: false,
        isLoading: false,
        hadSucceed: false,
        errorMessage: '',
        errorCode: 0
      };
    }

    case AccountTypes.RESET_ACCOUNT_STATE: {
      return {
        ...state,
        account: ACCOUNT_INITIAL_STATE.account,
        isAccountFetched: false
      };
    }

    case AccountTypes.FETCH_ACCOUNT_REQUEST: {
      return {
        ...state,
        hasError: false,
        isLoading: true,
        hadSucceed: false,
        isAccountFetched: false
      };
    }

    case AccountTypes.FETCH_ACCOUNT_SUCCESS: {
      return {
        ...state,
        account: action.payload.account,
        hasError: false,
        isLoading: false,
        hadSucceed: true,
        isAccountFetched: true
      };
    }

    case AccountTypes.FETCH_ACCOUNT_FAILURE: {
      return {
        ...state,
        hasError: true,
        isLoading: false,
        hadSucceed: false,
        isAccountFetched: false,
        errorMessage: action.payload.errorMessage,
        errorCode: action.payload.errorCode
      };
    }

    case AccountTypes.VALIDATE_ACCOUNT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        hasError: false,
        hadSucceed: false,
        errorMessage: '',
        errorCode: 0,
        account: action.payload.account
      };
    }

    case AccountTypes.VALIDATE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        hasError: false,
        hadSucceed: true
      };
    }

    case AccountTypes.VALIDATE_ACCOUNT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        hadSucceed: false,
        errorMessage: action.payload.errorMessage,
        errorCode: action.payload.errorCode
      };
    }

    case AccountTypes.SET_PLAN_ID: {
      return {
        ...state,
        selectedPlan: action.payload.selectedPlan,
        selectedPromotionId: action.payload.selectedPromotionId,
        hasError: false,
        isLoading: false,
        hadSucceed: false
      };
    }

    case AccountTypes.SET_SUBSCRIBER_DATA: {
      return {
        ...state,
        subscriptionAddress: action.payload.subscriptionAddress,
        hasError: false,
        isLoading: false,
        hadSucceed: false
      };
    }

    case AccountTypes.CREATE_ACCOUNT_REQUEST: {
      return {
        ...state,
        hasError: false,
        isLoading: true,
        hadSucceed: false,
        isAccountFetched: false,
        errorMessage: '',
        errorCode: 0,
        creditCard: action.payload.creditCard
      };
    }

    case AccountTypes.CREATE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        hasError: false,
        isLoading: false,
        hadSucceed: true
      };
    }

    case AccountTypes.CREATE_ACCOUNT_FAILURE: {
      return {
        ...state,
        hasError: true,
        isLoading: false,
        hadSucceed: false,
        errorMessage: action.payload.errorMessage,
        errorCode: action.payload.errorCode
      };
    }

    case AccountTypes.ACTIVATE_ACCOUNT_REQUEST: {
      return {
        ...state,
        hasError: false,
        isLoading: true,
        hadSucceed: false,
        isAccountFetched: false,
        errorMessage: '',
        errorCode: 0,
        creditCard: action.payload.creditCard
      };
    }

    case AccountTypes.ACTIVATE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        hasError: false,
        isLoading: false,
        hadSucceed: true
      };
    }

    case AccountTypes.ACTIVATE_ACCOUNT_FAILURE: {
      return {
        ...state,
        hasError: true,
        isLoading: false,
        hadSucceed: false,
        errorMessage: action.payload.errorMessage,
        errorCode: action.payload.errorCode
      };
    }

    case AccountTypes.REACTIVATE_ACCOUNT_REQUEST: {
      return {
        ...state,
        hasError: false,
        isLoading: true,
        hadSucceed: false,
        isAccountFetched: false,
        errorMessage: '',
        errorCode: 0,
        creditCard: action.payload.creditCard
      };
    }

    case AccountTypes.REACTIVATE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        hasError: false,
        isLoading: false,
        hadSucceed: true
      };
    }

    case AccountTypes.REACTIVATE_ACCOUNT_FAILURE: {
      return {
        ...state,
        hasError: true,
        isLoading: false,
        hadSucceed: false,
        errorMessage: action.payload.errorMessage,
        errorCode: action.payload.errorCode
      };
    }

    case AccountTypes.CANCEL_ACCOUNT_REQUEST: {
      return {
        ...state,
        hasError: false,
        isLoading: true,
        hadSucceed: false,
        errorMessage: '',
        errorCode: 0,
        cancelationReason: action.payload.cancelationReason
      };
    }

    case AccountTypes.CANCEL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        hasError: false,
        isLoading: false,
        hadSucceed: true
      };
    }

    case AccountTypes.CANCEL_ACCOUNT_FAILURE: {
      return {
        ...state,
        hasError: true,
        isLoading: false,
        hadSucceed: false,
        errorMessage: action.payload.errorMessage,
        errorCode: action.payload.errorCode
      };
    }

    case AccountTypes.UPDATE_ACCOUNT_DATA_REQUEST: {
      return {
        ...state,
        hasError: false,
        isLoading: true,
        hadSucceed: false,
        errorMessage: '',
        errorCode: 0
      };
    }

    case AccountTypes.UPDATE_ACCOUNT_DATA_SUCCESS: {
      return {
        ...state,
        hasError: false,
        isLoading: false,
        hadSucceed: true
      };
    }

    case AccountTypes.UPDATE_ACCOUNT_DATA_FAILURE: {
      return {
        ...state,
        hasError: true,
        isLoading: false,
        hadSucceed: false,
        errorMessage: action.payload.errorMessage,
        errorCode: action.payload.errorCode
      };
    }

    case AccountTypes.UPDATE_ACCOUNT_AVATAR_REQUEST: {
      return {
        ...state,
        hasError: false,
        isLoading: true,
        hadSucceed: false,
        errorMessage: '',
        errorCode: 0
      };
    }

    case AccountTypes.UPDATE_ACCOUNT_AVATAR_SUCCESS: {
      return {
        ...state,
        hasError: false,
        isLoading: false,
        hadSucceed: true
      };
    }

    case AccountTypes.UPDATE_ACCOUNT_AVATAR_FAILURE: {
      return {
        ...state,
        hasError: true,
        isLoading: false,
        hadSucceed: false,
        errorMessage: action.payload.errorMessage,
        errorCode: action.payload.errorCode
      };
    }

    case AccountTypes.DELETE_ACCOUNT_AVATAR_REQUEST: {
      return {
        ...state,
        hasError: false,
        isLoading: true,
        hadSucceed: false,
        errorMessage: '',
        errorCode: 0
      };
    }

    case AccountTypes.DELETE_ACCOUNT_AVATAR_SUCCESS: {
      return {
        ...state,
        hasError: false,
        isLoading: false,
        hadSucceed: true
      };
    }

    case AccountTypes.DELETE_ACCOUNT_AVATAR_FAILURE: {
      return {
        ...state,
        hasError: true,
        isLoading: false,
        hadSucceed: false,
        errorMessage: action.payload.errorMessage,
        errorCode: action.payload.errorCode
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
