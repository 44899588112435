import classNames from 'classnames';

import * as S from './styled';

interface TypeTogglerProps {
  typeState: number;
  typeHandler: (newType: number) => void;
  typeList: number[];
}

const TypeToggler = (props: TypeTogglerProps) => {
  const { typeState, typeHandler, typeList } = props;

  return (
    <S.TypeToggler>
      {typeList.map((type) => (
        <button
          key={type}
          type="button"
          className={classNames({
            'btn--is-selected': type === typeState
          })}
          onClick={() => typeHandler(type)}
        >
          {type === 1 ? 'Concursos' : 'Categorias'}
        </button>
      ))}
    </S.TypeToggler>
  );
};

export default TypeToggler;
