import { Fragment, FunctionComponent, useMemo } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import classNames from 'classnames';
import { SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { generateNewUID } from 'helpers/generateUID';
import { Plan } from 'helpers/models';
import useResponsive from 'hooks/useResponsive';

import { Desktop, Mobile } from 'components/Responsive';

import { iPlanViewerProps } from './interfaces/iPlanViewerProps';
import * as S from './styles';
import utils from './utils';

const generateElementKey = (name: string, id: number) =>
  `${name}-${id}-${generateNewUID()}`.trim().replaceAll(' ', '');

const PlanViewer: FunctionComponent<iPlanViewerProps> = (props) => {
  const {
    onPlanSelection,
    trialPlan,
    plans,
    promotions,
    isHomePage,
    showPromotions
  } = props;
  const { isMobile } = useResponsive();

  const trialPlanMemo = useMemo(() => {
    if (!trialPlan || trialPlan.id === 0) {
      return [];
    }

    return [utils.getTrialPlanItem(trialPlan as Plan)];
  }, [trialPlan, isMobile]);

  const planItemsMemo = useMemo(() => {
    const filteredPlans = plans.filter((item) => item.status === 'ENABLED');

    if (isMobile) {
      return filteredPlans.map((plan) =>
        utils.getPlanItem(plan, () => onPlanSelection(plan, null), true)
      );
    }

    return filteredPlans.map((plan) => (
      <SwiperSlide key={generateElementKey('plan', plan.id)}>
        {utils.getPlanItem(plan, () => onPlanSelection(plan, null))}
      </SwiperSlide>
    ));
  }, [isMobile, plans]);

  const promotionItemsMemo = useMemo(() => {
    if (showPromotions) {
      return [];
    }

    const filteredPromotions = promotions.filter((item) => item.status);

    if (isMobile) {
      return filteredPromotions.map((promotion) =>
        utils.getPromotionItem(
          promotion,
          () => onPlanSelection(promotion.plan!, promotion.id),
          true
        )
      );
    }

    return filteredPromotions.map((promotion) => (
      <SwiperSlide key={generateElementKey('promotion', promotion.id)}>
        {utils.getPromotionItem(promotion, () =>
          onPlanSelection(promotion.plan!, promotion.id)
        )}
      </SwiperSlide>
    ));
  }, [isMobile, promotions]);

  const getDesktopItems = () => {
    const items = [...promotionItemsMemo, ...planItemsMemo];

    const params: SwiperOptions = {
      autoplay: false,
      updateOnWindowResize: true,
      loop: false,
      navigation: {
        prevEl: '.swiper-navigation--is-prev',
        nextEl: '.swiper-navigation--is-next',
        disabledClass: 'swiper-navigation--is-disabled'
      },
      slidesPerView: isHomePage ? 3 : 4,
      spaceBetween: 12
    };

    if (!items.length) {
      return;
    }

    const SwiperContainer = (
      <S.SwiperContainer $isHomePage={!!isHomePage}>
        <S.SwiperNavigationContainer>
          <div className="swiper-navigation swiper-navigation--is-prev">
            <FaChevronLeft size={32} />
          </div>

          <div className="swiper-navigation swiper-navigation--is-next">
            <FaChevronRight size={32} />
          </div>
        </S.SwiperNavigationContainer>

        <Swiper {...params}>{items}</Swiper>
      </S.SwiperContainer>
    );

    return isHomePage ? (
      <S.PlanViewerContainer>
        {trialPlanMemo}
        {SwiperContainer}
      </S.PlanViewerContainer>
    ) : (
      SwiperContainer
    );
  };

  const getMobileItems = () => (
    <S.PlanViewerContainer>
      {trialPlanMemo}
      {promotionItemsMemo}
      {planItemsMemo}
    </S.PlanViewerContainer>
  );

  return (
    <Fragment>
      <Desktop>{getDesktopItems()}</Desktop>
      <Mobile>{getMobileItems()}</Mobile>
    </Fragment>
  );
};

export default PlanViewer;
