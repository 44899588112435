/* eslint-disable no-console */
import * as yup from 'yup';

import { PasswordChecklistRules } from 'helpers/types';

const { ValidationError } = yup;

export const validateRecoverStep1 = (email: string) => {
  const errors = [];

  try {
    yup
      .object()
      .shape({
        email: yup
          .string()
          .email('O formato digitado para o email é inválido')
          .required('O campo email é obrigatório')
      })
      .validateSync({ email }, { abortEarly: false });
  } catch (yupError) {
    if (yupError instanceof ValidationError) {
      errors.push(...yupError.inner);
    }
  }

  if (errors.length) {
    throw new ValidationError(errors);
  }
};

export const validateRecoverStep2 = (
  token: string,
  password: string,
  passwordAgain: string,
  passwordRules: PasswordChecklistRules
) => {
  const errors = [];

  try {
    yup
      .object()
      .shape({
        token: yup.string().required('O campo token é obrigatório'),
        password: yup.string().required('O campo senha é obrigatório'),
        passwordAgain: yup
          .string()
          .required('O campo confirmar a senha é obrigatório')
      })
      .validateSync({ token, password, passwordAgain }, { abortEarly: false });
  } catch (yupError) {
    if (yupError instanceof ValidationError) {
      errors.push(...yupError.inner);
    }
  }

  if (password && passwordAgain && !passwordRules.match) {
    const t = new ValidationError(
      'As senhas não conferem',
      '',
      'passwordsDontMatch'
    );
    errors.push(
      new ValidationError('As senhas não conferem', '', 'passwordsDontMatch')
    );
  }

  if (!passwordRules.minLength) {
    errors.push(
      new ValidationError(
        'A senha deve conter ao menos 8 caracteres',
        '',
        'passwordValidation'
      )
    );
  }

  if (!passwordRules.hasNumber) {
    errors.push(
      new ValidationError(
        'A senha deve conter ao menos 1 número',
        '',
        'passwordValidation'
      )
    );
  }

  if (errors.length) {
    throw new ValidationError(errors);
  }
};
