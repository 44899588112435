import { call, put } from 'redux-saga/effects';

import Connector from 'services/axios/axios';

import { StoreModule } from 'helpers/enum/StoreModule';
import { planInitialState } from 'helpers/initialState';
import { Plan, Promotion } from 'helpers/models';
import { ResponseGeneratorTyped } from 'helpers/types/ResponseGenerator';

import { addLoadingModule, removeLoadingModule } from '../loading/actions';
import { fetchPlansSuccess, fetchPlansError } from './actions';

export function* fetchPlans() {
  yield put(addLoadingModule(StoreModule.PLAN));

  try {
    const { data }: ResponseGeneratorTyped<Plan[]> = yield call(
      Connector().get,
      '/landing-page/plans?status=ENABLED'
    );

    let freeTrialPlan = planInitialState;
    const promotions: Promotion[] = [];

    const plans: Plan[] = data
      ? data
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((plan) => {
            if (plan.name === '7 dias grátis') {
              freeTrialPlan = plan;
            }

            plan.promotions.forEach((promo) => {
              const promotion = {
                ...promo,
                plan
              };

              promotions.push(promotion);
            });

            return plan.name !== '7 dias grátis';
          })
      : [];

    yield put(fetchPlansSuccess(plans, promotions, freeTrialPlan));
  } catch (err) {
    yield put(fetchPlansError());
  } finally {
    yield put(removeLoadingModule(StoreModule.PLAN));
  }
}
