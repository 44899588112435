import { FormEvent, FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import { ValidationError } from 'yup';

import { loginRequest, logoutRequest } from 'store/ducks/login';

import { ReactComponent as Logo } from 'assets/icons/playlegis-icon.svg';
import { PUBLIC_ROUTES } from 'helpers/constants';
import { loadingSelector, loginSelector } from 'helpers/selectors';
import { ApplicationState, LoginInfo } from 'helpers/types';
import loginValidation from 'helpers/validation/login';

import { HTMLButton as Button, ButtonLink as Link } from 'components/Button';
import Input from 'components/Input';

import * as S from './styled';

type LoginProps = {
  type?: 'login' | 'logout';
};

const LoginPage: FunctionComponent<LoginProps> = (props: LoginProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { type } = props;

  const { loginError, loginErrorData, loading } = useSelector(loginSelector);

  const [loginInfo, setLoginInfo] = useState<LoginInfo>({
    email: '',
    password: ''
  });

  useEffect(() => {
    if (type === 'logout') {
      dispatch(logoutRequest());
    }
  }, [dispatch, type]);

  const handleFormSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    try {
      loginValidation(loginInfo);

      const path = location.search.replace('?/', '');

      dispatch(loginRequest(loginInfo, path));
    } catch (error) {
      if (error instanceof ValidationError) {
        error.inner.forEach((e) => {
          toast.error(e.message);
        });
      }
    }
  };

  useEffect(() => {
    if (loginError) {
      if (loginErrorData) {
        toast.error(loginErrorData.name);
      } else {
        toast.error('Falha na autenticação');
      }
    }
  }, [loginError]);

  const getLoginErrorMessage = () =>
    loginError &&
    loginErrorData && (
      <S.LoginErrorMessage>{loginErrorData.message}</S.LoginErrorMessage>
    );

  return (
    <S.LoginWrapper>
      {type !== 'logout' && (
        <S.LoginContainer>
          <S.LoginContent>
            <Logo />

            <h1>Login</h1>
            <p>Acesse sua conta e fique com seus estudos em dia</p>

            <S.LoginForm method="post" onSubmit={handleFormSubmit}>
              <fieldset>
                <Input
                  type="text"
                  value={loginInfo.email}
                  onChange={(e) =>
                    setLoginInfo({
                      ...loginInfo,
                      email: e.target.value.trim()
                    })
                  }
                  className="login__input login__input--is-email"
                  placeholder="Email"
                  disabled={loading}
                  autoCapitalize="off"
                />

                <Input
                  type="password"
                  placeholder="Senha"
                  value={loginInfo.password}
                  onChange={(e) =>
                    setLoginInfo({
                      ...loginInfo,
                      password: e.target.value
                    })
                  }
                  className="login__input login__input--is-password"
                  disabled={loading}
                />

                <S.RecoverPasswordLink>
                  <Link to={PUBLIC_ROUTES.RECOVER_PASSWORD} variant="link">
                    Esqueci minha senha
                  </Link>
                </S.RecoverPasswordLink>

                {getLoginErrorMessage()}
              </fieldset>

              <Button type="submit" variant="yellow">
                Continuar
              </Button>
            </S.LoginForm>
          </S.LoginContent>

          <S.SignupLink>
            Não possui uma conta?
            <Link to={PUBLIC_ROUTES.SIGNUP} variant="link">
              Faça seu cadastro.
            </Link>
          </S.SignupLink>
        </S.LoginContainer>
      )}
    </S.LoginWrapper>
  );
};

LoginPage.defaultProps = {
  type: 'login'
};

export default LoginPage;
