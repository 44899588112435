import { Action } from 'redux';
import { action } from 'typesafe-actions';

import { Category, SuperCategory } from 'helpers/models';

import { CategoryTypes } from './types';

export const fetchSuperCategoryData = (id: number): Action => (
  action(CategoryTypes.FETCH_SUPER_CATEGORY_REQUEST, { id })
);

export const fetchSuperCategorySuccess = (
  superCategory: SuperCategory
): Action => (
  action(CategoryTypes.FETCH_SUPER_CATEGORY_SUCCESS, { superCategory })
);

export const fetchSuperCategoryError = (): Action => (
  action(CategoryTypes.FETCH_SUPER_CATEGORY_ERROR)
);

export const fetchSuperCategoryLastUpdatesData = (): Action => (
  action(CategoryTypes.FETCH_SUPER_CATEGORY_LAST_UPDATES_REQUEST)
);

export const fetchSuperCategoryLastUpdatesSuccess = (
  lastUpdates: Category
): Action => (
  action(CategoryTypes.FETCH_SUPER_CATEGORY_LAST_UPDATES_SUCCESS, { lastUpdates })
);

export const fetchSuperCategoryLastUpdatesError = (): Action => (
  action(CategoryTypes.FETCH_SUPER_CATEGORY_LAST_UPDATES_ERROR)
);
