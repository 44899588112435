import { call, put } from 'redux-saga/effects';

import Connector from 'services/axios/axios';

import { StoreModule } from 'helpers/enum/StoreModule';

import { addLoadingModule, removeLoadingModule } from '../loading/actions';
import { fetchPlaylistSuccess, fetchPlaylistError } from './actions';
import { PlaylistAction } from './types';

export function* fetchPlaylist(action: PlaylistAction) {
  yield put(addLoadingModule(StoreModule.PLAYLIST));

  try {
    const { id } = action.payload;

    const { data } = yield call(Connector().get, `/playlists?id=${id}`);

    yield put(fetchPlaylistSuccess(data));
  } catch (err) {
    yield put(fetchPlaylistError());
  } finally {
    yield put(removeLoadingModule(StoreModule.PLAYLIST));
  }
}
