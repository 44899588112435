import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import wavesBackground from 'assets/img/home/ourDifferences/waves.png';
import { PUBLIC_ROUTES } from 'helpers/constants';
import { ApplicationState } from 'helpers/types';

import { ButtonLink as Button } from 'components/Button';

import * as S from './styled';

const OurDifferences = () => {
  const {
    home: { differentials }
  } = useSelector((state: ApplicationState) => state.institutional);

  return (
    <S.OurDifferencesWrapper>
      <S.ContentContainer backgroundImage={wavesBackground}>
        <h2>Nossos diferenciais</h2>
        <h3>Vantagens que só a Playlegis tem para você</h3>

        <S.ItemList>
          {differentials
            .sort((a, b) => a.x.id - b.x.id)
            .map((item) => (
              <S.ItemRow key={item.x.title}>
                <S.ItemIcon>
                  <div>
                    <img
                      src={item.iconUrl}
                      alt="Ícone simulando aplicativo"
                      title={item.x.title}
                    />
                  </div>
                </S.ItemIcon>
                <S.ItemDescription>
                  <h4>{item.x.title}</h4>
                  <p>{item.x.description}</p>
                </S.ItemDescription>
              </S.ItemRow>
            ))}
        </S.ItemList>
      </S.ContentContainer>

      <S.ContainerCTA>
        <Button to={PUBLIC_ROUTES.AUDIOS} variant="blue">
          Conhecer os conteúdos
        </Button>
        <Button to={PUBLIC_ROUTES.SIGNUP} variant="link">
          É universitário? Temos um plano para você
        </Button>
      </S.ContainerCTA>
    </S.OurDifferencesWrapper>
  );
};

export default OurDifferences;
