
import styled from 'styled-components';

export { AccountFormHeader, AccountForm } from '../../styled';

export const MyDataFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 50px;

  input:disabled {
    background-color: ${({ theme }) => theme.colors.greyLight};
  }

  .password__checklist-description {
    color: ${({ theme }) => theme.colors.greyLight};
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-top: 12px;

  button {
    width: 100%;

    &:not(:last-child) {
      margin-right: 40px;
    }
  }
`;

export const ChangePasswordInputs = styled.div`
  .btn__link {
    width: 100%;
    color: white;
    text-align: right;
    margin-bottom: 12px;
  }
`;