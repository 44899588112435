import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';

import { fetchNewsData } from 'store/ducks/institutional';

import { ReactComponent as ArrowDown } from 'assets/icons/news/arrowDown.svg';
import { PUBLIC_ROUTES } from 'helpers/constants';
import { ApplicationState } from 'helpers/types';

import * as S from './styled';

const RecentNews = () => {
  const dispatch = useDispatch();
  const { news: stateNewsList } = useSelector(
    (state: ApplicationState) => state.institutional
  );

  const [newsToShow, setNewsToShow] = useState(9);
  const [news, setNews] = useState(stateNewsList.slice(0, newsToShow));

  useEffect(() => {
    setNews(stateNewsList.slice(0, newsToShow));
  }, [stateNewsList, newsToShow]);

  useEffect(() => {
    if (!stateNewsList.length) {
      dispatch(fetchNewsData());
    }
  }, []);

  return (
    <S.RecentNewsWrapper>
      <S.RecentNewsContainer>
        <S.RecentNewsHeader>
          <h1>Notícias</h1>
          <h2>Publicações recentes</h2>
        </S.RecentNewsHeader>

        <S.NewsGrid>
          {news.map((item) => (
            <S.NewsWrapper key={item.id}>
              <S.NewsPreviewLink to={`${PUBLIC_ROUTES.NEWS}/${item.id}`}>
                <S.NewsPreview>
                  <span className="news-preview__date">
                    {moment(item.createdAt).format('DD-MM-YY')}
                  </span>
                  <h3 className="news-preview__title">{item.title}</h3>
                  <span className="news-preview__link">Ler notícia</span>
                </S.NewsPreview>
                <S.NewsThumb cover={item.coverUrl} />
              </S.NewsPreviewLink>
            </S.NewsWrapper>
          ))}
        </S.NewsGrid>

        {stateNewsList.length > news.length && (
          <S.MoreNewsLink onClick={() => setNewsToShow(newsToShow + 3)}>
            <span>Ver mais</span>
            <ArrowDown />
          </S.MoreNewsLink>
        )}
      </S.RecentNewsContainer>
    </S.RecentNewsWrapper>
  );
};

export default RecentNews;
