import { Fragment, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import Cookies from 'js-cookie';

import { addPopUpItem } from 'store/ducks/popUp';

import { PRIVATE_ROUTES, PUBLIC_ROUTES } from 'helpers/constants';
import { ApplicationState } from 'helpers/types';
import useAuth from 'hooks/useAuth';

import Link from 'components/Button/ButtonLink';
import Modal from 'components/Modal/Modal';
import Overlay from 'components/Overlay';
import { PopUp } from 'components/PopUp/PopUp';
import Toastify from 'components/Toast';
import LoadingOverlay from 'containers/LoadingOverlay';

const globalComponentTag = document.getElementById('global-components');
const cookieAccepted = Cookies.get('COOKIES_TERMS_ACCEPTED');

const GlobalComponents = () => {
  if (!globalComponentTag) {
    return <Fragment />;
  }

  const dispatch = useDispatch();

  const { account, isAccountFetched } = useSelector(
    (state: ApplicationState) => state.account
  );
  const { isUserAuthenticated } = useAuth();

  useEffect(() => {
    if (!cookieAccepted || cookieAccepted !== 'true') {
      dispatch(
        addPopUpItem(
          'COOKIES',
          <Fragment>
            Usamos cookies para garantir que você obtenha a melhor experiência
            em nosso site. Veja mais em:
            <Link to={PUBLIC_ROUTES.COOKIES_POLICY} variant="link">
              Política de cookies.
            </Link>
          </Fragment>,
          true,
          {
            label: 'Eu concordo',
            variant: 'blue',
            onClick: () => {
              const expires = new Date('2999-09-06T06:30:00.000Z');
              Cookies.set('COOKIES_TERMS_ACCEPTED', 'true', { expires });
            }
          }
        )
      );
    }
  }, []);

  useEffect(() => {
    if (isUserAuthenticated) {
      const { subscription } = account;

      if (subscription) {
        if (
          subscription.plan.id === 2 &&
          !account.studentDocumentSended &&
          account.subscription?.subscriptionStatus !== 'CANCELED'
        ) {
          dispatch(
            addPopUpItem(
              'STUDENT_REQUEST_DOCUMENTS',
              <Fragment>
                Não recebemos seus documentos comprovatórios para validar sua
                Assinatura no Plano Especial. Você deve realizar o envio
                clicando no botão antes do término do período de testes, caso
                contrário você não participará da promoção e será cobrado o
                valor integral vigente. Ficou com alguma dúvida? Entre em
                contato com nosso suporte.
              </Fragment>,
              true,
              {
                label: 'Enviar agora',
                variant: 'yellow',
                linkTo: PRIVATE_ROUTES.SEND_DOCUMENTS
              }
            )
          );
        }

        if (account.subscription?.subscriptionStatus === 'OVERDUE') {
          dispatch(
            addPopUpItem(
              'ACCOUNT_PAYMENT_PENDENT',
              <Fragment>
                O pagamento da sua assinatura está pendente! Verifique e
                atualize os dados do seu cartão no perfil de usuário da sua
                conta. Caso já tenha atualizado, desconsidere essa mensagem e
                aguarde a validação do seu pagamento. Entre em contato em caso
                de dúvidas.
              </Fragment>,
              false,
              {
                label: 'Atualizar cartão',
                variant: 'yellow',
                linkTo: PRIVATE_ROUTES.ACCOUNT
              }
            )
          );
        }
      } else if (isAccountFetched && !account.isAdmin) {
        dispatch(
          addPopUpItem(
            'ACCOUNT_WITH_NO_SUBSCRIPTION',
            <Fragment>
              Encontramos um problema na sua conta, no momento sua Assinatura
              está inválida devido a uma falha com o meio de pagamento que você
              cadastrou. Você deve prosseguir com a ativação da Assinatura
              clicando no botão. Ficou com alguma dúvida? Entre em contato com
              nosso suporte.
            </Fragment>,
            true,
            {
              label: 'Criar assinatura',
              variant: 'yellow',
              linkTo: PRIVATE_ROUTES.ACTIVATE_ACCOUNT
            }
          )
        );
      }
    }
  }, [isUserAuthenticated, account, isAccountFetched]);

  const getComponents = () => (
    <Fragment>
      <Modal />
      <PopUp />
      <Overlay />
      <LoadingOverlay />
      <Toastify />
    </Fragment>
  );

  return createPortal(getComponents(), globalComponentTag);
};

export default GlobalComponents;
