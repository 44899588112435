import { Status } from 'helpers/enums';

import { PlaylistState } from './types';

export const PLAYLIST_INITIAL_STATE: PlaylistState = {
  id: 0,
  playlist: {
    id: 0,
    name: '',
    description: '',
    status: Status.DISABLED,
    coverUrl: '',
    coverUrlFormat: '',
    removed: false,
    createdAt: new Date(),
    updatedAt: new Date(),
    removedAt: new Date(),
    userId: 0,
    audios: [],
    categories: [],
    favorite: false,
    duration: 0,
  },
}