import styled from 'styled-components';

export const PlanViewerContainer = styled.div`
  display: flex;

  ${({ theme }) => theme.mq.lessThan('md')`
    flex-direction: column;
    align-items: stretch;
  `}

  ${({ theme }) => theme.mq.greaterThan('md')`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const SwiperContainer = styled.div<{ $isHomePage: boolean }>`
  position: relative;
  width: ${({ $isHomePage }) => ($isHomePage ? '75%' : '100%')};

  .swiper-slide {
    padding: 8px;
  }
`;

export const SwiperNavigationContainer = styled.div`
  display: flex;
  margin-bottom: 8px;

  .swiper-navigation {
    cursor: pointer;
    transition: opacity 0.3s, color 0.3s;

    &:hover {
      color: ${({ theme }) => theme.colors.yellowDark};
    }

    svg {
      filter: drop-shadow(0px 0px 2px #aaa);
    }

    &--is-disabled {
      color: ${({ theme }) => theme.colors.greyDark};
      pointer-events: none;
    }
  }
`;

export const PlanItem = styled.div`
  display: flex;
  flex-direction: column;

  &.plan__item {
    &--trial {
      .plan__item--name {
        font-size: 32px;
        font-weight: 900;
        color: ${({ theme }) => theme.colors.blue};
      }

      .plan__item--description {
        font-size: 16px;
        font-weight: 300;
        color: ${({ theme }) => theme.colors.blue};
      }
    }

    &--promotion {
      background-color: ${({ theme }) => theme.colors.blue};
      color: ${({ theme }) => theme.colors.blueOpacity1};
      border-radius: 8px;
      box-shadow: 0px 0px 8px ${({ theme }) => theme.colors.blueDarker};

      .plan__item--name {
        font-size: 18px;
        font-weight: 900;
        color: ${({ theme }) => theme.colors.white};
      }

      .plan__item--plan-value {
        color: ${({ theme }) => theme.colors.yellow};
      }

      .plan__item--promotional-value {
        color: ${({ theme }) => theme.colors.yellow};

        &-highlight {
          font-size: 32px;
          font-weight: 700;
        }

        &:after {
          content: '';
          display: flex;
          border: 1px solid ${({ theme }) => theme.colors.white};
          width: 65%;
        }
      }

      .plan__item--description {
        font-size: 16px;
        font-weight: 300;
        color: ${({ theme }) => theme.colors.white};
      }

      .btn__link--yellow {
        font-size: 16px;
        font-weight: 700;
        color: ${({ theme }) => theme.colors.yellow};
      }
    }

    &--regular {
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.blueDarker};
      border-radius: 8px;
      box-shadow: 0px 0px 8px;

      .plan__item--name {
        font-weight: 700;
        font-size: 18px;
        color: ${({ theme }) => theme.colors.blue};
      }

      .plan__item--value {
        font-size: 32px;
        font-weight: 900;
        color: ${({ theme }) => theme.colors.yellow};

        &:after {
          content: '';
          display: flex;
          border: 1px solid ${({ theme }) => theme.colors.blueDarker};
          width: 65%;
        }
      }

      .plan__item--description {
        font-size: 16px;
        font-weight: 300;
        color: ${({ theme }) => theme.colors.blue};
      }

      .btn__link--yellow {
        color: ${({ theme }) => theme.colors.yellow};
      }
    }

    ${({ theme }) => theme.mq.lessThan('md')`
      padding: 0 52px;

      &--trial {
        align-items: center;
        margin-bottom: 53px;

        .plan__item--name {
          margin: 23px 0 13px;
        }

        .plan__item--description {
          text-align: center;
        }
      }

      &--promotion {
        padding: 32px 30px;
        margin: 0px 12.235% 30px;
  
        &:last-child {
          margin-bottom: 0px;
        }
  
        .plan__item--name {
          margin-bottom: 8px;
        }

        .plan__item--plan-value {
          margin-bottom: 4px;
        }

        .plan__item--promotional-value {
          &:after {
            margin-top: 13px;
          }
        }
  
        .plan__item--description {
          margin: 16px 0px 24px;
        }
      }

      &--regular {
        padding: 32px 30px;
        margin: 0px 12.235% 30px;
  
        &:last-child {
          margin-bottom: 0px;
        }
  
        .plan__item--name {
          margin-bottom: 4px;
        }
  
        .plan__item--value {
          &:after {
            margin-top: 13px;
          }
        }
  
        .plan__item--description {
          margin: 16px 0px 24px;
        }
      }
    `};

    ${({ theme }) => theme.mq.greaterThan('sm')`
      &--trial {
        max-width: 260px;

        .plan__item--name {
          margin: 26px 0 8px;
        }
  
        .plan__item--description {
          margin-bottom: 24px;
          font-weight: 300;
          font-size: 16px;
        }
      }
  
      &--promotion {
        padding: 32px;

        .plan__item--name {
          margin-bottom: 8px;
        }

        .plan__item--promotional-value {
          &:after {
            margin-top: 13px;
          }
        }
  
        .plan__item--description {
          margin: 16px 0 24px;
        }
      }
  
      &--regular {
        padding: 32px;

        .plan__item--name {
          margin-bottom: 8px;
        }
  
        .plan__item--value {
          &:after {
            margin-top: 8px;
          }
        }
  
        .plan__item--description {
          margin: 16px 0 24px;
        }
      }
    `};
  }
`;
