import { ChangeEvent, useState, useEffect } from 'react';

import classNames from 'classnames';

import { PasswordChecklistRules } from 'helpers/types';
import { usePasswordValidation } from 'hooks/usePasswordValidation';

import Input from 'components/Input';

import * as S from './styled';

interface PasswordChecklistProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  valueAgain: string;
  onChangeAgain: (event: ChangeEvent<HTMLInputElement>) => void;
  onChecklistChange: (rules: PasswordChecklistRules) => void;
  inputClasses?: {
    password: string;
    passwordAgain: string;
  };
  placeholders?: {
    password: string;
    passwordAgain: string;
  };
  checklistDescription?: string;
  disabled?: boolean;
  hasError?: {
    password: boolean;
    passwordAgain: boolean;
    passwordValidation: boolean;
  };
}

const PasswordChecklist = (props: PasswordChecklistProps) => {
  const {
    value: firstPassword,
    onChange,
    valueAgain: secondPassword,
    onChangeAgain,
    onChecklistChange,
    inputClasses,
    placeholders,
    checklistDescription,
    disabled,
    hasError
  } = props;

  const validationConfig = {
    firstPassword,
    secondPassword,
    settings: { minLength: 8 }
  };

  const [minLength, hasNumber, match] = usePasswordValidation(validationConfig);

  useEffect(() => {
    onChecklistChange({
      minLength,
      hasNumber,
      match
    });
  }, [minLength, hasNumber, match]);

  return (
    <S.PasswordChecklistWrapper>
      <Input
        type="password"
        id="password"
        name="password"
        placeholder={(placeholders && placeholders.password) || 'Senha'}
        className={classNames('password__input', {
          [`${inputClasses?.password}`]: inputClasses !== undefined
        })}
        value={firstPassword}
        onChange={onChange}
        hasShadow
        autoComplete="on"
        disabled={disabled}
        hasError={hasError?.password || hasError?.passwordValidation}
      />

      <Input
        type="password"
        id="password-again"
        name="password-again"
        placeholder={
          (placeholders && placeholders.passwordAgain) || 'Confirmar senha'
        }
        className={classNames('password__input', {
          [`${inputClasses?.passwordAgain}`]: inputClasses !== undefined
        })}
        value={secondPassword}
        onChange={onChangeAgain}
        hasShadow
        autoComplete="on"
        disabled={disabled}
        hasError={hasError?.passwordAgain || hasError?.passwordValidation}
      />

      <S.PasswordChecklistDescription
        className={classNames('password__checklist-description', {
          'description--has-error': hasError?.passwordValidation
        })}
      >
        {checklistDescription}
      </S.PasswordChecklistDescription>
    </S.PasswordChecklistWrapper>
  );
};

PasswordChecklist.defaultProps = {
  inputClasses: {
    password: '',
    passwordAgain: ''
  },
  placeholders: {
    password: '',
    passwordAgain: ''
  },
  checklistDescription:
    'Sua senha não pode ter menos de 8 caracteres e deve possuir pelo menos um número.',
  disabled: false,
  hasError: {
    password: false,
    passwordAgain: false,
    validPassword: false
  }
};

export default PasswordChecklist;
