import styled from 'styled-components';

export const FileInputWrapper = styled.div`
  position: relative;
  margin-bottom: 15px;

  input {
    display: none;
  }
`;

interface FileInputLabelProps {
  $iconUrl?: string;
}

export const FileInputLabel = styled.label<FileInputLabelProps>`
  display: block;
  width: 100%;
  padding: 14px 18px;
  position: relative;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  color: ${({ theme }) => theme.colors.blue};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0px 0px 8px ${({ theme }) => theme.colors.greyLight};
  cursor: pointer;

  ${({ $iconUrl }) => $iconUrl && `
    background-image: url(${$iconUrl});
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 97%;
  `}

  &.file-input--has-error {
    border: 1px solid ${({ theme }) => theme.colors.error};
    background-color: #f3dfdf;
  }
`;