import styled from 'styled-components';

export const PlaylistHeaderWrapper = styled.header`
  margin-bottom: 48px;
`;

export const PlaylistBackButtonWrapper = styled.div`
  margin-bottom: 30px;
  font-size: 14px;

  .btn__link {
    color: white;
    border-bottom: 1px solid transparent;
    border-radius: 0;
    font-weight: 300;

    svg {
      margin-right: 6px;

      path {
        fill: none;
      }
    }

    &:hover {
      text-decoration: none;
      border-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const PlaylistHeaderContent = styled.div`
  display: flex;
  align-items: center;
`;

export const PlaylistInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 30px;
`;

export const PlaylistSubtitle = styled.h2`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.yellow};
`;

export const PlaylistTitle = styled.h1`
  margin-bottom: 16px;
  font-size: 32px;
  font-weight: 900;
  color: ${({ theme }) => theme.colors.white};
`;

export const PlaylistDescription = styled.p`
  font-size: 16px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.white};
`;

export const PlaylistCover = styled.div`
  position: relative;
  width: 100%;
  max-width: 270px;
  height: 90px;
`;

export const PlaylistCoverFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: rgba(39, 47, 76, 0.2);

  svg {
    width: 42px;
    height: 42px;
  }
`;

export const PlaylistCoverImage = styled.div<{ $url: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  background-image: url(${({ $url }) => $url});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.05);
`;