import styled from 'styled-components';

export const SelectWrapper = styled.div`
  position: relative;

  ${({ theme }) => theme.mq.lessThan('sm')`
    width: 100%;
    margin-bottom: 8px;
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    &.withMarginRight {
      margin-right: 30px;
    }

    margin-bottom: 0;
  `}
`;

interface SelectInputProps {
  $arrowUpIcon?: string;
  $arrowDownIcon?: string;
}

export const SelectInput = styled.input<SelectInputProps>`
  width: 100%;
  min-width: 270px;
  padding: 10px 50px 10px 24px;
  position: relative;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.grey};
  border: 0;
  border-radius: 16px;
  cursor: pointer;
  transition: box-shadow 0.3s, border-radius 0.3s;
  
  &.select__input {
    &--is-option-list-open {
      border-radius: 16px 16px 0 0;
      
      ${({ $arrowUpIcon }) => $arrowUpIcon && `
        background-image: url(${$arrowUpIcon});
      `}
    }
  }
      
  ${({ $arrowDownIcon }) => $arrowDownIcon && `
    background-image: url(${$arrowDownIcon});
    background-repeat: no-repeat;
    background-position: calc(100% - 24px) center;
    transition: background-image 0.3s;
  `}

`;

export const SelectSearch = styled.div`
  padding: 0;
  border-radius: 0;
  position: sticky;
  top: 0;
  width: 100%;

  input {
    border: 1px solid ${({ theme }) => theme.colors.greyLighter};
    border-radius: 4px;
    background-color: transparent;
    padding: 8px;
    width: 100%;
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 12px;
    font-size: 14px;

    /* Chrome, Firefox, Opera, Safari 10.1+ */
    &::placeholder {
      color: ${({ theme }) => theme.colors.white};
      opacity: 1; /* Firefox */
      font-size: 12px;
    }
    
    /* Internet Explorer 10-11 */
    &:-ms-input-placeholder {
      color: ${({ theme }) => theme.colors.white};
      font-size: 12px;
    }

    /* Microsoft Edge */
    &::-ms-input-placeholder {
      color: ${({ theme }) => theme.colors.white};
      font-size: 12px;
    }
  }
`;

export const OptionsWrapper = styled.div`
  width: 100%;
  position: absolute;
  padding: 14px 26px;
  background-color: ${({ theme }) => theme.colors.grey};
  border-radius: 0 0 16px 16px;
  opacity: 0;
  z-index: -1;
  overflow: hidden;
  transition: opacity 0.3s, z-index 0.3s;
  top: 36px;

  &.options__wrapper {
    &--is-open {
      opacity: 1;
      z-index: 10;
    }
  }
`;

export const OptionsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.grey};
  max-height: 320px;
  overflow-y: scroll;
  overflow-x: hidden;

  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.colors.blue} rgba(255, 255, 255, 0.5);

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.5);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    width: 1px;
    padding: 2px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.blue};
    transition: background-color 0.3s;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.colors.blueDark};
  }
`;

export const FilterOptionButton = styled.button`
  transition: background-color 0.3s, color 0.3s;
  text-align: left;
  border-radius: 0;
  font-weight: 300;
  font-size: 14px;
  
  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.yellow};
  }

  &.select__option--is-selected {
    font-weight: 500;
  }
`;