/* eslint-disable @typescript-eslint/no-explicit-any */
import { action } from 'typesafe-actions';

import { RecoverPasswordTypes } from './types';

export const recoveryPasswordRequest = (email: string) =>
  action(RecoverPasswordTypes.RECOVER_PASSWORD_REQUEST, { email });

export const recoveryPasswordSuccess = () =>
  action(RecoverPasswordTypes.RECOVER_PASSWORD_SUCCESS);

export const recoveryPasswordError = () =>
  action(RecoverPasswordTypes.RECOVER_PASSWORD_ERROR);

export const setNewPasswordRequest = (token: string, password: string) =>
  action(RecoverPasswordTypes.SET_NEW_PASSWORD_REQUEST, { token, password });

export const setNewPasswordSuccess = () =>
  action(RecoverPasswordTypes.SET_NEW_PASSWORD_SUCCESS);

export const setNewPasswordError = () =>
  action(RecoverPasswordTypes.SET_NEW_PASSWORD_ERROR);
