import { planInitialState } from 'helpers/initialState';

import { PlanState } from './types';

export const PLAN_INITIAL_STATE: PlanState = {
  id: 0,
  plans: [],
  promotions: [],
  freeTrialPlan: planInitialState
};
