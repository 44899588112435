import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchAboutUsData } from 'store/ducks/institutional';

import MockupPostHeader from 'assets/img/playlegis-mockup.webp';
import { PUBLIC_ROUTES } from 'helpers/constants';
import { ApplicationState } from 'helpers/types';

import { ButtonLink } from 'components/Button';

import * as S from './styled';

const About = () => {
  const dispatch = useDispatch();
  const { aboutUs } = useSelector(
    (state: ApplicationState) => state.institutional
  );

  // Scrolls to top smoothly
  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    dispatch(fetchAboutUsData());
  }, []);

  return (
    <S.AboutWrapper>
      <S.AboutContainer>
        <S.AboutHeader>
          <h2>Sobre nós</h2>
          <h3>Playlegis</h3>
        </S.AboutHeader>

        <S.AboutContent>
          <S.AboutDescription
            dangerouslySetInnerHTML={{
              __html: aboutUs.description
            }}
          />

          <S.AboutRow>
            <ButtonLink variant="blue" to={PUBLIC_ROUTES.SIGNUP}>
              Assinar Playlegis
            </ButtonLink>

            <img
              src={MockupPostHeader}
              alt="Tela inicial do Playlegis em telas de computador e celular"
              title="Aplicativo Playlegis"
            />
          </S.AboutRow>
        </S.AboutContent>
      </S.AboutContainer>
    </S.AboutWrapper>
  );
};

export default About;
