import { Log } from 'components/Log/Log';

const handlePlay = (audio: HTMLAudioElement) => {
  const playPromise = audio.play();

  if (playPromise !== undefined) {
    playPromise
      .then((_) => {
        // Automatic playback started!
        // Show playing UI.
      })
      .catch((error) => {
        // Auto-play was prevented
        // Show paused UI.
        Log.error('AudioError', error);
      });
  }
};

export { handlePlay };
