import { FilterState } from './types';

export const FILTER_INITIAL_STATE: FilterState = {
  categoryId: 0,
  selectedCategory: {
    id: 0,
    label: '',
    name: '',
    description: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    superCategoryId: 0,
    playlists: [],
  },
};