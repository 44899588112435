import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as types from 'helpers/types';

import CategoryHeader from 'containers/PostHeader/PostHeader';

import CategoryList from './components/CategoryList';
import * as S from './styled';

const filterSelector = (state: types.ApplicationState) => state.filter;

const CategoryPage = () => {
  const {
    selectedCategory: { name }
  } = useSelector(filterSelector);

  useEffect(() => {
    document.body.style.backgroundColor = '#272F4C';

    const onUnmount = () => {
      document.body.style.backgroundColor = '#FFF';
    };

    return onUnmount;
  }, []);

  return (
    <S.CategoryWrapper>
      <S.CategoryContainer>
        <CategoryHeader title="Categoria" subtitle={name} mode="CATEGORY" />

        <CategoryList />
      </S.CategoryContainer>
    </S.CategoryWrapper>
  );
};

export default CategoryPage;
