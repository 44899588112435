/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action } from 'redux';
import { action } from 'typesafe-actions';

import { LoginResponse, LoginInfo } from 'helpers/types';
import { LoginErrorData } from 'helpers/types/LoginErrorData';

import { LoginTypes } from './types';

export const loginRequest = (input: LoginInfo, path: string): Action =>
  action(LoginTypes.LOGIN_REQUEST, { input, path });

export const loginSuccess = (response: LoginResponse): Action =>
  action(LoginTypes.LOGIN_SUCCESS, { response });

export const loginError = (loginErrorData?: LoginErrorData): Action =>
  action(LoginTypes.LOGIN_ERROR, { loginErrorData });

export const logoutRequest = (): Action => action(LoginTypes.LOGOUT_REQUEST);

export const logoutSuccess = (): Action => action(LoginTypes.LOGOUT_SUCCESS);

export const logoutError = (): Action => action(LoginTypes.LOGOUT_ERROR);
