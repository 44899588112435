import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay
} from 'swiper';

import { fetchHomeData } from 'store/ducks/institutional';

import OurDifferences from './components/OurDifferences';
import PostHeader from './components/PostHeader/HomePostHeader';
import SubscriptionPlans from './components/SubscriptionPlans';
import Testimonials from './components/Testimonials';
import * as S from './Home.styled';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const HomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    dispatch(fetchHomeData());
  }, []);

  return (
    <S.HomeWrapper>
      <PostHeader />
      <OurDifferences />
      <SubscriptionPlans />
      <Testimonials />
    </S.HomeWrapper>
  );
};

export default HomePage;
