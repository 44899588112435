import { FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { recoveryPasswordRequest } from 'store/ducks/recoverPassword';

import { ReactComponent as Logo } from 'assets/icons/playlegis-icon.svg';
import { recoverPasswordInputErrorInitialState } from 'helpers/initialState';
import { loadingSelector } from 'helpers/selectors';
import { RecoverPasswordInputError } from 'helpers/types';
import { validateRecoverStep1 } from 'helpers/validation/recoverPassword';
import { showValidationErrorToast } from 'utils/validationError';

import { HTMLButton as Button, ButtonLink as Link } from 'components/Button';
import Input from 'components/Input';
import {
  NavigationActionTypes,
  useNavigation
} from 'contexts/NavigationContext';

import * as S from '../../styled';

const RecoverStepOne = () => {
  const { modules } = useSelector(loadingSelector);

  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState<RecoverPasswordInputError>(
    recoverPasswordInputErrorInitialState
  );

  const dispatchRedux = useDispatch();
  const { dispatch: dispatchNavigation } = useNavigation();

  const handleFormSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    let validationErrors: RecoverPasswordInputError =
      recoverPasswordInputErrorInitialState;

    try {
      validateRecoverStep1(email);

      dispatchRedux(recoveryPasswordRequest(email));

      dispatchNavigation({ type: NavigationActionTypes.INCREMENT });
    } catch (error) {
      validationErrors = showValidationErrorToast<RecoverPasswordInputError>(
        error,
        validationErrors
      );
    } finally {
      setErrors(validationErrors);
    }
  };

  return (
    <S.RecoverPasswordContent>
      <Logo />

      <h1>Esqueci minha senha</h1>
      <p>Digite seu email para iniciar o processo de recuperação</p>

      <S.RecoverPasswordForm method="post" onSubmit={handleFormSubmit}>
        <fieldset>
          <Input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="login__input login__input--is-email"
            placeholder="Email"
            disabled={modules.length > 0}
            hasError={errors.email}
          />
        </fieldset>

        <Button type="submit" variant="yellow">
          Continuar
        </Button>
      </S.RecoverPasswordForm>
    </S.RecoverPasswordContent>
  );
};

export default RecoverStepOne;
