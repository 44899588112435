import styled from 'styled-components';

export const TestimonialsWrapper = styled.div`
  ${({ theme }) => theme.bp.Fluid};
  background-color: ${({ theme }) => theme.colors.greyLighter};

  ${({ theme }) => theme.mq.lessThan('sm')`
    padding-top: 100px;
  `}

  ${({ theme }) => theme.mq.lessThan('md')`
    max-width: 100vw;
  `}
`;

export const Container = styled.div`
  ${({ theme }) => theme.bp.Medium};

  .swiper__pagination {
    padding: 100px 0 110px;
    position: relative;
    width: 100%;
    text-align: center;

    .swiper-pagination-bullet {
      width: 70px;
      height: 8px;
      background-color: ${({ theme }) => theme.colors.greyLight};
      border-radius: 4px;
      opacity: 1;

      &-active {
        background-color: ${({ theme }) => theme.colors.yellow};
      }
    }
  }
`;

export const TestimonialsHeader = styled.header`
  margin-bottom: 90px !important;

  h3 {
    font-size: 14px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.yellow};
    text-transform: uppercase;
  }

  h4 {
    font-size: 32px;
    font-weight: 900;
    color: ${({ theme }) => theme.colors.blue};
  }
`;

export const CarouselContainer = styled.div`
  display: flex;
`;

export const CarouselItem = styled.div``;

export const CarouselDescription = styled.p`
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 24px;
`;

export const CarouselIdentification = styled.div<{ $avatarUrl: string }>`
  display: flex;
  align-items: center;

  .carousel__user-avatar {
    border-radius: 50%;
    margin-right: 30px;
    width: 70px;
    height: 70px;
    background-image: url(${({ $avatarUrl }) => $avatarUrl});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  p {
    font-size: 18px;
    font-weight: 700;
    max-width: 70%;
  }
`;
