import { action } from 'typesafe-actions';

import { StoreModule } from 'helpers/enum/StoreModule';

import { LoadingTypes } from './types';

export const addLoadingModule = (latestModule: StoreModule) =>
  action(LoadingTypes.ADD_LOADING_MODULE, { latestModule });

export const removeLoadingModule = (latestModule: StoreModule) =>
  action(LoadingTypes.REMOVE_LOADING_MODULE, { latestModule });
