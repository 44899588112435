import { DiscountValueTypeEnum } from 'helpers/types/DiscountValueTypeEnum';

export const getFormattedPromotionalValue = (
  planValue: number,
  discount: number,
  discountType: DiscountValueTypeEnum
) => {
  const promotionalValue =
    discountType === DiscountValueTypeEnum.MONETARY
      ? planValue - discount
      : planValue - planValue * (discount / 100);

  return promotionalValue.toFixed(2).replace('.', ',');
};
