import { Action } from 'redux';
import { action } from 'typesafe-actions';

import { SearchResponse } from 'helpers/models';

import { SearchTypes } from './types';

export const searchRequest = (terms: string): Action => (
  action(SearchTypes.SEARCH_REQUEST, { terms })
);

export const searchSuccess = (response: SearchResponse): Action => (
  action(SearchTypes.SEARCH_SUCCESS, { response })
);

export const searchError = (): Action => (
  action(SearchTypes.SEARCH_ERROR)
);

export const setSearching = (searching: boolean) => (
  action(SearchTypes.SET_SEARCHING, { searching })
)
