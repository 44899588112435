/* eslint-disable @typescript-eslint/no-empty-interface */
import 'styled-components';
import * as breakpoints from './theme/_breakpoints';
import * as colors from './theme/_colors';
import * as mediaQueries from './theme/_mediaQueries';
import * as variables from './theme/_variables';

export const globalTheme = {
  ...variables,
  colors,
  bp: breakpoints,
  mq: mediaQueries.default,
};

type GlobalTheme = typeof globalTheme;

declare module 'styled-components' {
  export interface DefaultTheme extends GlobalTheme { }
}
