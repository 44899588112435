import axios from 'axios';
import Cookies from 'js-cookie';
import { call, put, select } from 'redux-saga/effects';

import Connector from 'services/axios/axios';

import { StoreModule } from 'helpers/enum/StoreModule';

import { addLoadingModule, removeLoadingModule } from '../loading/actions';
import { fetchInstitutionsSuccess, fetchInstitutionsFailure } from './actions';

export function* fetchInstitutions() {
  yield put(addLoadingModule(StoreModule.TEACHINGINSTITUTION));

  try {
    const cookieToken = Cookies.get('al-web-token');

    const { data } = yield call(
      Connector(false, false, cookieToken).get,
      '/teaching-institutions/list'
    );

    yield put(fetchInstitutionsSuccess(data));
  } catch (fetchError) {
    yield put(fetchInstitutionsFailure(true));
  } finally {
    yield put(removeLoadingModule(StoreModule.TEACHINGINSTITUTION));
  }
}
