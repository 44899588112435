import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import {
  fetchPlaylistData,
  fetchPlaylistSuccess
} from 'store/ducks/playlist/actions';

import { playlistInitialState } from 'helpers/initialState';
import { playlistSelector } from 'helpers/selectors';

import { AudioList } from 'containers/AudioList/AudioList';

import PlaylistHeader from './components/Header';
import * as S from './styled';

type PlaylistParams = {
  playlistId: string;
};

const Playlist = () => {
  const dispatch = useDispatch();
  const { playlistId } = useParams<PlaylistParams>();
  const { playlist } = useSelector(playlistSelector);

  useEffect(() => {
    if (playlistId) {
      dispatch(fetchPlaylistData(+playlistId));
      document.body.style.backgroundColor = '#272F4C';

      const onUnmount = () => {
        document.body.style.backgroundColor = '#FFF';
        dispatch(fetchPlaylistSuccess(playlistInitialState));
      };

      return onUnmount;
    }
  }, []);

  return (
    <S.PlaylistWrapper>
      <S.PlaylistContainer>
        <PlaylistHeader />
        <AudioList playlist={playlist} />
      </S.PlaylistContainer>
    </S.PlaylistWrapper>
  );
};

export default Playlist;
