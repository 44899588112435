import { ReactNode } from 'react';

import { ModalItem } from 'helpers/types/ModalItem';

export enum ModalTypes {
  OPEN_MODAL = '@POPUP/OPEN_MODAL',
  CLOSE_MODAL = '@POPUP/CLOSE_MODAL'
}

export interface ModalState {
  readonly modalItem: ModalItem;
}

export type ModalAction = {
  type: ModalTypes;
  payload: {
    modalItem: ModalItem;
  };
};
