import { useParams, Navigate, useNavigate, useLocation } from 'react-router';

import { PRIVATE_ROUTES, PUBLIC_ROUTES } from 'helpers/constants';

type RedirectParams = {
  mode: 'audio' | 'playlist';
};

const RedirectPage = () => {
  const { mode } = useParams<RedirectParams>();

  const location = useLocation();

  const handleRedirect = () => {
    try {
      const id = location.search.replace('?id=', '');

      const idParsed = Number(id);

      if (Number.isNaN(idParsed)) {
        throw new Error('Id is inválid');
      }

      switch (mode) {
        case 'audio':
          return <Navigate to={`${PUBLIC_ROUTES.AUDIOS}/${idParsed}`} />;

        case 'playlist':
          return <Navigate to={`${PRIVATE_ROUTES.PLAYLIST}/${idParsed}`} />;

        default:
          throw new Error('Mode is inválid');
      }
    } catch (error) {
      return <Navigate to="/" />;
    }
  };

  return handleRedirect();
};

export default RedirectPage;
