import { call, put } from 'redux-saga/effects';

import Connector from 'services/axios/axios';

import { StoreModule } from 'helpers/enum/StoreModule';
import prepareSearch from 'utils/prepareSearch';

import { addLoadingModule, removeLoadingModule } from '../loading';
import { searchError, searchSuccess, setSearching } from './actions';
import { SearchAction } from './types';

export function* searchTerms(action: SearchAction) {
  yield put(addLoadingModule(StoreModule.SEARCH));
  yield put(setSearching(true));

  try {
    const { terms } = action.payload;

    const preparedTerm = prepareSearch(terms);

    const { data } = yield call(
      Connector().get,
      `/searchs?term=${preparedTerm}`
    );

    yield put(searchSuccess(data));
  } catch (err) {
    yield put(searchError());
  } finally {
    yield put(setSearching(false));
    yield put(removeLoadingModule(StoreModule.SEARCH));
  }
}
