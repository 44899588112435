import {
  ButtonHTMLAttributes,
  FunctionComponent,
  ReactNode,
  useEffect,
  useState
} from 'react';

import classNames from 'classnames';
import Cookies from 'js-cookie';

import arrowDownIcon from 'assets/icons/audios/arrowDown.svg';
import arrowUpIcon from 'assets/icons/audios/arrowUp.svg';
import { Category } from 'helpers/models';

import * as S from './styled';

interface OptionProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
}

const FilterOption: FunctionComponent<OptionProps> = (props: OptionProps) => {
  const { children, className, ...rest } = props;

  return (
    <S.FilterOptionButton
      type="button"
      className={classNames({
        select__option: true,
        [`${className}`]: className
      })}
      {...rest}
    >
      {children}
    </S.FilterOptionButton>
  );
};

interface SelectProps {
  selected: Category;
  placeholder: string;
  className?: string;
  categories: Category[];
  onSelection: (data: Category) => void;
}

export const FilterSelector: FunctionComponent<SelectProps> = (
  props: SelectProps
) => {
  const {
    selected,
    placeholder: pholder,
    className,
    onSelection,
    categories
  } = props;

  const token = Cookies.get('al-web-token');

  const [placeholder, setPlaceholder] = useState(pholder);
  const [showOptionList, toggleOptionList] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [items, setItems] = useState<Category[]>([]);

  useEffect(() => {
    if (searchValue !== '') {
      const serialized = categories.filter((item) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setItems(serialized);
    } else {
      setItems(categories);
    }
  }, [searchValue]);

  useEffect(() => {
    if (selected.name) {
      setPlaceholder(selected.name);
      toggleOptionList(false);
    } else {
      setPlaceholder(pholder);
    }
  }, [selected]);

  useEffect(() => {
    setItems(categories);
  }, [categories]);

  return (
    <S.SelectWrapper
      className={classNames({
        select__wrapper: true,
        [`${className}`]: className,
        withMarginRight: !!token
      })}
      onMouseLeave={() => showOptionList && toggleOptionList(false)}
    >
      <S.SelectInput
        type="button"
        value={placeholder}
        onClick={() => toggleOptionList(!showOptionList)}
        className={classNames({
          select__input: true,
          'select__input--is-option-list-open': showOptionList
        })}
        $arrowUpIcon={arrowUpIcon}
        $arrowDownIcon={arrowDownIcon}
      />

      <S.OptionsWrapper
        className={classNames({
          options__wrapper: true,
          'options__wrapper--is-open': showOptionList
        })}
      >
        <S.SelectSearch>
          <input
            type="text"
            name="search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Digite o nome de uma categoria"
          />
        </S.SelectSearch>

        <S.OptionsList
          className={classNames({
            options__list: true
          })}
        >
          {items.map((category) => (
            <FilterOption
              key={category.id}
              onClick={() => onSelection(category)}
              className={classNames({
                'select__option--is-selected': selected.id === category.id
              })}
            >
              {category.name}
            </FilterOption>
          ))}
        </S.OptionsList>
      </S.OptionsWrapper>
    </S.SelectWrapper>
  );
};

FilterSelector.defaultProps = {
  className: ''
};

export default FilterSelector;
