import styled from 'styled-components';

import { ReactivateContainer } from '../../styles';

export const SpecialPlanDocumentFormContainer = styled(ReactivateContainer)`
  background-position: center bottom;
  padding-bottom: 0;

  @media (width: 1366px) {
    background-size: 38%;
  }
`;

export const SpecialPlanDocumentFormMessage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  h3 {
    font-size: 18px;
    font-weight: 700;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 30px;
    text-align: center;
    max-width: 570px;
    line-height: 24px;
  }

  .btn__yellow {
    max-width: 300px;
    width: 100%;
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    h3 {
      text-align: center;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 24px;
    }

    .btn__yellow {
      margin-bottom: 27px;
    }

    img {
      width: 100%;
    }
`}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    h3 {
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 30px;
    }
  `}
`;

export const ReactivateStudentInputGroup = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mq.lessThan('sm')`
    width: 100%;
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    .select__wrapper {
      width: 400px;
    }
  `}
`;
