import styled, { css } from 'styled-components';

import { HTMLButton as Button } from 'components/Button';

export const PlayerWrapper = styled.div<{ $isOpen: boolean }>`
  ${({ theme }) => theme.bp.Fluid};
  position: fixed;
  bottom: 16px;
  transition: right 1s ease-in-out, transform 0.5s;
  transform: scale(0);
  z-index: 10;

  ${({ $isOpen }) =>
    $isOpen &&
    `
    transform: scale(1);
  `}
`;

export const PlayerContainer = styled.div`
  ${({ theme }) => theme.bp.Medium};
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.blueDark};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
  color: ${({ theme }) => theme.colors.white};
  position: relative;

  ${({ theme }) => theme.mq.lessThan('sm')`
    flex-direction: column;
    padding: 18px 16px;
    align-items: center;
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    padding: 18px 30px;
  `}
`;

export const CloseButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -5px;
  top: -5px;
  padding: 5px !important;
  background: ${({ theme }) => theme.colors.blue3};
  border-radius: 50px;
  opacity: 1;
  transition: opacity 0.3s;

  svg {
    rect {
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    width: 25px;
    height: 25px;
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    width: 20px;
    height: 20px;

    &:hover {
      opacity: 0.95;
    }
  `}
`;

export const PlayerAudioMeta = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${({ theme }) => theme.mq.lessThan('sm')`
    margin-bottom: 26px;
    width: 100%;
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    margin-right: 95px;
  `}
`;

export const AudioTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const PlaylistName = styled.div`
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 12px;
`;

export const AudioTimer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${({ theme }) => theme.mq.greaterThan('sm')`
    max-width: 338px;
  `}
`;

export const ActionWithStatusButton = styled(Button)<{ $isActive?: boolean }>`
  color: ${({ theme }) => theme.colors.white};
  transition: color 0.3s, opacity 0.3s;

  &:disabled {
    background-color: transparent !important;
    opacity: 0.2;
  }

  svg {
    path {
      transition: fill 0.3s;
      fill: ${({ theme }) => theme.colors.white};
    }

    tspan {
      transition: fill 0.3s;
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  ${({ theme, $isActive }) =>
    $isActive &&
    `
    color: ${theme.colors.yellow};

    svg {
      path {
        fill: ${theme.colors.yellow};
      }

      tspan {
        fill: ${theme.colors.yellow};
      }
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')` 
    &:hover {
      color: ${({ theme }) => theme.colors.yellowDarker};

      svg {
        path {
          fill: ${({ theme }) => theme.colors.yellowDarker};
        }

        tspan {
          fill: ${({ theme }) => theme.colors.yellowDarker};
        }
      }
    }
  `}
`;

export const PlayerActionButton = styled(Button)`
  transition: color 0.3s, opacity 0.3s;

  &:disabled {
    background-color: transparent !important;
    opacity: 0.2;
  }

  &:hover {
    text-decoration: none !important;
  }

  &:not(:last-child) {
    margin-right: 12px;
  }

  &.button--is-white {
    color: ${({ theme }) => theme.colors.white};

    svg {
      path {
        fill: ${({ theme }) => theme.colors.white};
      }

      tspan {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
  }

  &.button--is-yellow {
    color: ${({ theme }) => theme.colors.yellow};

    svg {
      path {
        fill: ${({ theme }) => theme.colors.yellow};
      }

      tspan {
        fill: ${({ theme }) => theme.colors.yellow};
      }
    }
  }

  svg {
    path {
      transition: fill 0.3s;
    }

    tspan {
      transition: fill 0.3s;
    }
  }

  ${({ theme }) => theme.mq.greaterThan('sm')`
    &.button--is-white {
      &:hover {
        color: ${({ theme }) => theme.colors.yellow};

        svg {
          path {
            fill: ${({ theme }) => theme.colors.yellow};
          }

          tspan {
            fill: ${({ theme }) => theme.colors.yellow};
          }
        }
      }
    }

    &.button--is-yellow {
      &:hover {
        color: ${({ theme }) => theme.colors.white};

        svg {
          path {
            fill: ${({ theme }) => theme.colors.white};
          }

          tspan {
            fill: ${({ theme }) => theme.colors.white};
          }
        }
      }
    }
  `}
`;

const SliderBase = css`
  width: 10px;
  height: 10px;
  border-radius: 25px;
  background: ${({ theme }) => theme.colors.yellow};
  cursor: pointer;
`;

export const Slider = styled.input`
  -webkit-appearance: none;
  margin: 0 5px;
  flex-grow: 1;
  appearance: none;
  height: 2px;
  background: linear-gradient(
    to right,
    #dbb200 0%,
    #dbb200 0%,
    #7c8299 50%,
    #7c8299 100%
  );
  /* background: ${({ theme }) => theme.colors.grey}; */
  outline: none;
  opacity: 1;
  transition: opacity 0.2s;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    ${SliderBase};
  }

  &::-moz-range-thumb {
    ${SliderBase};
  }
`;
