import { call, put } from 'redux-saga/effects';

import Connector from 'services/axios/axios';

import { StoreModule } from 'helpers/enum/StoreModule';
import { Category, SuperCategory } from 'helpers/models';

import {
  fetchSuperCategoryLastUpdatesError,
  fetchSuperCategoryLastUpdatesSuccess
} from '.';
import { addLoadingModule, removeLoadingModule } from '../loading';
import { fetchSuperCategorySuccess, fetchSuperCategoryError } from './actions';
import { CategoryAction } from './types';

export function* fetchHome(action: CategoryAction) {
  const { id } = action.payload;
  yield put(addLoadingModule(StoreModule.CATEGORY));

  try {
    const { data }: { data: SuperCategory } = yield call(
      Connector().get,
      `/public/super-categories?id=${id}`
    );

    data.categories.forEach((ccategory) =>
      ccategory.playlists.sort((p1, p2) =>
        p1.name
          .replace('.', '')
          .localeCompare(p2.name.replace('.', ''), undefined, {
            numeric: true,
            sensitivity: 'base',
            ignorePunctuation: true
          })
      )
    );

    yield put(fetchSuperCategorySuccess(data));
  } catch (err) {
    yield put(fetchSuperCategoryError());
  } finally {
    yield put(removeLoadingModule(StoreModule.CATEGORY));
  }
}

export function* fetchLastUpdates(action: CategoryAction) {
  yield put(addLoadingModule(StoreModule.CATEGORY));

  try {
    const { data } = yield call(Connector().get, '/playlists/last-playlist');

    const category: Category = {
      id: 0,
      label: 'Últimas atualizações',
      name: 'Últimas atualizações',
      description: '',
      createdAt: new Date(),
      updatedAt: new Date(),
      superCategoryId: 0,
      playlists: data
    };

    yield put(fetchSuperCategoryLastUpdatesSuccess(category));
  } catch (err) {
    yield put(fetchSuperCategoryLastUpdatesError());
  } finally {
    yield put(removeLoadingModule(StoreModule.CATEGORY));
  }
}
