import { Reducer } from 'redux';

import { PlaylistAction, PlaylistState, PlaylistTypes } from './types';
import { PLAYLIST_INITIAL_STATE } from './utils';

const reducer: Reducer<PlaylistState, PlaylistAction> = (
  state: PlaylistState = PLAYLIST_INITIAL_STATE,
  action: PlaylistAction,
) => {
  switch (action.type) {
    case PlaylistTypes.FETCH_PLAYLIST_REQUEST: {
      return {
        ...state,
        id: action.payload.id,
      };
    }
    case PlaylistTypes.FETCH_PLAYLIST_SUCCESS: {
      return {
        ...state,
        playlist: action.payload.playlist,
      };
    }
    default: {
      return state;
    }
  }
}

export default reducer;