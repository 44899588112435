import * as yup from 'yup';

import { iAccount } from 'helpers/interfaces/iAccount';
import { PasswordChecklistRules } from 'helpers/types';
import { validateCpf } from 'utils/validateCpf';

import { UserEdit } from '../models';

const accountSchema = yup.object().shape({
  name: yup.string().required('O campo nome é obrigatório'),
  email: yup
    .string()
    .email('O formato digitado para o email é inválido')
    .required('O campo email é obrigatório'),
  cpf: yup.string().required('O campo CPF é obrigatório'),
  password: yup.string().required('O campo senha é obrigatório'),
  passwordAgain: yup
    .string()
    .required('O campo confirmar a senha é obrigatório')
});

export const accountValidation = (
  account: iAccount,
  passwordAgain: string,
  passwordRules: PasswordChecklistRules
) => {
  const { ValidationError } = yup;
  const errors = [];

  try {
    accountSchema.validateSync(
      { ...account, passwordAgain },
      { abortEarly: false }
    );
  } catch (yupError) {
    if (yupError instanceof ValidationError) {
      errors.push(...yupError.inner);
    }
  }

  if (account.cpf && !validateCpf(account.cpf)) {
    errors.push(new ValidationError('O CPF digitado é inválido', '', 'cpf'));
  }

  if (account.password && passwordAgain && !passwordRules.match) {
    errors.push(
      new ValidationError('As senhas não conferem', '', 'passwordsDontMatch')
    );
  }

  if (!passwordRules.minLength) {
    errors.push(
      new ValidationError(
        'A senha deve conter ao menos 8 caracteres',
        '',
        'passwordValidation'
      )
    );
  }

  if (!passwordRules.hasNumber) {
    errors.push(
      new ValidationError(
        'A senha deve conter ao menos 1 número',
        '',
        'passwordValidation'
      )
    );
  }

  if (errors.length) {
    throw new ValidationError(errors);
  }
};

const accountDataSchema = yup.object().shape({
  name: yup.string().required('O campo nome é obrigatório'),
  email: yup
    .string()
    .email('O formato digitado para o email é inválido')
    .required('O campo email é obrigatório'),
  phone: yup.string().required('O campo telefone é obrigatório'),
  password: yup.string()
});

export const accountEditValidation = (
  account: UserEdit,
  hasChangedPassword: boolean,
  passwordAgain: string,
  passwordRules: PasswordChecklistRules
) => {
  const { ValidationError } = yup;
  const errors = [];

  try {
    accountDataSchema.validateSync(account.data, { abortEarly: false });
  } catch (yupError) {
    if (yupError instanceof ValidationError) {
      errors.push(...yupError.inner);
    }
  }

  if (hasChangedPassword) {
    if (!account.password) {
      errors.push(
        new ValidationError(
          'O campo confirmar a senha atual é obrigatório',
          '',
          'currentPassword'
        )
      );
    }

    if (!account.data.password) {
      errors.push(
        new ValidationError('O campo nova senha é obrigatório', '', 'password')
      );
    }

    if (!passwordAgain) {
      errors.push(
        new ValidationError(
          'O campo confirmar a nova senha é obrigatório',
          '',
          'passwordAgain'
        )
      );
    }

    if (account.data.password && passwordAgain && !passwordRules.match) {
      errors.push(
        new ValidationError('As senhas não conferem', '', 'passwordsDontMatch')
      );
    }

    if (!passwordRules.minLength) {
      errors.push(
        new ValidationError(
          'A senha deve conter ao menos 8 caracteres',
          '',
          'passwordValidation'
        )
      );
    }

    if (!passwordRules.hasNumber) {
      errors.push(
        new ValidationError(
          'A senha deve conter ao menos 1 número',
          '',
          'passwordValidation'
        )
      );
    }
  } else if (!account.password) {
    errors.push(
      new ValidationError('O campo senha é obrigatório', '', 'currentPassword')
    );
  }

  if (errors.length) {
    throw new ValidationError(errors);
  }
};
