import { FormEvent, FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import * as Yup from 'yup';

import { cancelAccountRequest } from 'store/ducks/account';

import Button from 'components/Button/HTMLButton';
import TextArea from 'components/Textarea';

import * as S from './styled';

interface CancelFormProps {
  onSubmit: () => void;
}

const CancelForm: FunctionComponent<CancelFormProps> = (props) => {
  const { onSubmit } = props;

  const [cancelReason, setCancelReasonChange] = useState('');
  const dispatch = useDispatch();

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit();

    try {
      Yup.object()
        .shape({
          cancelReason: Yup.string().required(
            'O campo comentário é obrigatório'
          )
        })
        .validateSync(
          { cancelReason },
          {
            abortEarly: false
          }
        );

      dispatch(cancelAccountRequest(cancelReason));
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e) => {
          toast.error(e.message);
        });
      }
    }
  };

  return (
    <S.CancelForm onSubmit={handleFormSubmit}>
      <S.CancelFormFieldset>
        <legend>
          Antes de ir embora, deixe aqui abaixo um comentário sobre o motivo da
          sua saída, nós buscamos uma evolução constante dos nossos serviços e
          com a sua ajuda poderemos melhorar ainda mais.
        </legend>

        <TextArea
          value={cancelReason}
          placeholder="Escreva o motivo da sua saída..."
          onChange={(e) => setCancelReasonChange(e.target.value)}
        />

        <Button type="submit" variant="yellow">
          Cancelar assinatura
        </Button>
      </S.CancelFormFieldset>
    </S.CancelForm>
  );
};

export default CancelForm;
