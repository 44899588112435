import { ReactNode } from 'react';

export enum NavigationActionTypes {
  INCREMENT = '@Navigation/Increment',
  DECREMENT = '@Navigation/Decrement',
  RESET = '@Navigation/Reset'
}

export type NavigationAction = {
  type: NavigationActionTypes;
};

export type NavigationDispatch = (action: NavigationAction) => void;

export type NavigationState = { step: number };

export type NavigationProviderProps = {
  children: ReactNode;
  initialStep?: number;
};
