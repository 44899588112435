import styled from 'styled-components';

export const ReactivateContainer = styled.div`
  ${({ theme }) => theme.bp.Medium};
  min-height: 100vh;
  padding-bottom: 40px;
  margin: 0 auto;

  ${({ theme }) => theme.mq.lessThan('fhd')`
    padding-top: 40px;
  `};

  ${({ theme }) => theme.mq.greaterThan('fhd')`
    padding-top: 70px;
  `};

  &.container__form {
    max-width: 420px;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
`;

export const ReactivateForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  .reactivate__input,
  .password__input {
    margin-bottom: 15px;
  }

  .btn__yellow {
    max-width: 300px;
    width: 100%;
  }
`;
