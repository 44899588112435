import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';

import { ThemeProvider } from 'styled-components';

import store from 'store';

import { GlobalStyle, theme } from 'assets/styled';

import Router from './router/Router';

import './assets/css/fonts.css';

const rootElement = document.getElementById('root') as Element;
const root = createRoot(rootElement);

const App = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Router />
  </ThemeProvider>
);

const Playlegis = () => (
  // <StrictMode>
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>
  // </StrictMode>
);

root.render(<Playlegis />);
