import { useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';

import FullFooter from './components/FullFooter/FullFooter';
import MiniFooter from './components/MiniFooter/MiniFooter';
import { iFooterProps } from './interfaces/iFooterProps';
import utils from './utils';

const Footer = (props: iFooterProps) => {
  const { hide, isUserAuthenticated } = props;

  const location = useLocation();

  const [miniFooter, setMiniFooter] = useState(false);

  useEffect(() => {
    const miniFooterFilter = utils.pagesWithMiniFooter.filter((page: string) =>
      location.pathname.match(page)
    );

    setMiniFooter(miniFooterFilter.length > 0);
  }, [location]);

  const getFooter = () => {
    if (miniFooter) {
      return <MiniFooter isUserAuthenticated={isUserAuthenticated} />;
    }

    return <FullFooter />;
  };

  return hide ? <Fragment /> : getFooter();
};

Footer.defaultProps = {
  hide: false
};

export default Footer;
