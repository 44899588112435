import { useEffect } from 'react';

import { Desktop, Mobile } from 'components/Responsive';

import AvatarForm from './components/AvatarForm';
import CreditCardForm from './components/CreditCardForm';
import MyDataForm from './components/MyDataForm';
import MySubscription from './components/MySubscription';
import * as S from './styled';

const AccountPage = () => {
  useEffect(() => {
    document.body.style.backgroundColor = '#272F4C';

    const onUnmount = () => {
      document.body.style.backgroundColor = '#FFF';
    };

    return onUnmount;
  }, []);

  return (
    <S.AccountWrapper>
      <S.AccountContainer>
        <S.AccountHeader>
          <h1>Minha conta</h1>
          <h2>Perfil de usuário</h2>
        </S.AccountHeader>
        <S.FormGroup>
          <S.AccountColumn>
            <Mobile>
              <AvatarForm />
            </Mobile>
            <MyDataForm />
            <CreditCardForm />
          </S.AccountColumn>
          <S.AccountColumn>
            <Desktop>
              <AvatarForm />
            </Desktop>
            <MySubscription />
          </S.AccountColumn>
        </S.FormGroup>
      </S.AccountContainer>
    </S.AccountWrapper>
  );
};

export default AccountPage;
