import { accountInitialState, planInitialState } from 'helpers/initialState';

import { AccountState } from './types';

export const ACCOUNT_INITIAL_STATE: AccountState = {
  account: accountInitialState,
  isAccountFetched: false,
  isLoading: false,
  hasError: false,
  hadSucceed: false,
  errorMessage: '',
  selectedPlan: planInitialState,
  selectedPromotionId: null,
  subscriptionAddress: {
    address: '',
    city: '',
    state: '',
    country: '',
    phone: ''
  }
};
