import {
  ButtonHTMLAttributes,
  FunctionComponent,
  ReactNode,
  useEffect,
  useState
} from 'react';

import classNames from 'classnames';

import * as S from './styled';

interface OptionProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
}

export const Option: FunctionComponent<OptionProps> = (props: OptionProps) => {
  const { children, className, ...rest } = props;

  return (
    <S.OptionButton
      type="button"
      className={classNames({
        select__option: true,
        [`${className}`]: className
      })}
      {...rest}
    >
      {children}
    </S.OptionButton>
  );
};

interface SelectProps {
  selected: string;
  placeholder: string;
  className?: string;
  icon?: string;
  data: { name: string; id: string }[];
  onSelection: (data: { name: string; id: string }) => void;
  hasError?: boolean;
}

export const Select: FunctionComponent<SelectProps> = (props: SelectProps) => {
  const {
    selected,
    placeholder: pholder,
    className,
    icon,
    data,
    onSelection,
    hasError
  } = props;

  const [placeholder, setPlaceholder] = useState(pholder);
  const [showOptionList, toggleOptionList] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [items, setItems] = useState([{ name: '', id: '' }]);

  useEffect(() => {
    if (selected) {
      setPlaceholder(selected);
      toggleOptionList(!showOptionList);
    } else {
      setPlaceholder(pholder);
    }
  }, [selected]);

  useEffect(() => {
    setItems(data);
  }, [data]);

  useEffect(() => {
    if (searchValue !== '') {
      const serialized = data.filter((item) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setItems(serialized);
    } else {
      setItems(data);
    }
  }, [searchValue]);

  return (
    <S.SelectWrapper
      className={classNames({
        select__wrapper: true,
        [`${className}`]: className
      })}
      onMouseLeave={() => showOptionList && toggleOptionList(false)}
    >
      <S.SelectInput
        type="button"
        value={placeholder}
        onClick={() => toggleOptionList(!showOptionList)}
        className={classNames({
          select__input: true,
          'select__input--is-option-list-open': showOptionList,
          'select__input--has-error': hasError
        })}
        $iconUrl={icon}
      />

      <S.SelectDropdown
        className={classNames({
          'select__dropdown-list': true,
          'select__dropdown-list--is-open': showOptionList
        })}
      >
        <S.SelectSearch>
          <input
            type="text"
            name="search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Digite o nome de sua universidade"
          />
        </S.SelectSearch>

        <S.SelectOptionList>
          {items.map((item) => (
            <Option key={item.id} onClick={() => onSelection(item)}>
              {item.name}
            </Option>
          ))}
        </S.SelectOptionList>
      </S.SelectDropdown>
    </S.SelectWrapper>
  );
};

Select.defaultProps = {
  className: '',
  icon: '',
  hasError: false
};

export default Select;
