import { MouseEvent } from 'react';

import classNames from 'classnames';

import { ReactComponent as FavoriteIcon } from 'assets/icons/plans/favorite.svg';
import { generateNewUID } from 'helpers/generateUID';
import { Plan, Promotion } from 'helpers/models';
import { getFormattedPromotionalValue } from 'utils/getFormattedPromotionalValue';

import { HTMLButton as Button } from 'components/Button';

import * as S from './styles';

const getTrialPlanItem = (item: Plan) => (
  <S.PlanItem
    key={generateNewUID() + item.id}
    className={classNames('plan__item', 'plan__item--trial')}
  >
    <FavoriteIcon />

    <span className="plan__item--name">{item.name}</span>

    <p
      className="plan__item--description"
      dangerouslySetInnerHTML={{ __html: item.description }}
    />
  </S.PlanItem>
);

const getPromotionItem = (
  item: Promotion,
  onClickCallback: () => void,
  shouldAddKey = true
) => {
  const formattedPromotionalValue = getFormattedPromotionalValue(
    item.plan!.value,
    item.discount,
    item.discountType
  );

  return (
    <S.PlanItem
      key={shouldAddKey ? generateNewUID() + item.id : undefined}
      className={classNames({
        plan__item: true,
        'plan__item--promotion': true
      })}
    >
      {/* TODO: verificar tags e estilos */}
      <span className="plan__item--name">{item.name}</span>

      <span className="plan__item--plan-value">
        De <s>R$ {item.plan?.value}</s>
      </span>

      <span className="plan__item--promotional-value">
        Por
        <span className="plan__item--promotional-value-highlight">
          {` R$ ${formattedPromotionalValue}`}
        </span>
      </span>

      <p
        className="plan__item--description"
        dangerouslySetInnerHTML={{
          __html: item.description
        }}
      />
      <Button
        onClick={(e) => {
          e.stopPropagation();
          onClickCallback();
        }}
        variant="link"
        className="btn__link--yellow"
      >
        Assinar agora
      </Button>
    </S.PlanItem>
  );
};

const getPlanItem = (
  item: Plan,
  onClickCallback: () => void,
  shouldAddKey = true
) => (
  <S.PlanItem
    key={shouldAddKey ? generateNewUID() + item.id : undefined}
    className={classNames('plan__item', 'plan__item--regular')}
  >
    <span className="plan__item--name">{item.name}</span>

    <span className="plan__item--value">
      R$ {item.value.toFixed(2).replace('.', ',')}
    </span>

    <p
      className="plan__item--description"
      dangerouslySetInnerHTML={{ __html: item.description }}
    />

    <Button
      onClick={(e) => {
        e.stopPropagation();
        onClickCallback();
      }}
      variant="link"
      className="btn__link--yellow"
    >
      Assinar agora
    </Button>
  </S.PlanItem>
);

export default { getTrialPlanItem, getPlanItem, getPromotionItem };
