import styled from 'styled-components';

interface SearchInputProps {
  $icon: string;
}

export const SearchInput = styled.input<SearchInputProps>`
  min-width: 270px;
  width: 100%;
  padding: 10px 50px 10px 24px;
  border: 0;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.grey};
  background-image: url(${({ $icon }) => $icon});
  background-position: calc(100% - 24px) 50%;
  background-repeat: no-repeat;

  &::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.white};
  }
`;