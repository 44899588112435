import styled from 'styled-components';

import { SignUpContainer as SUC } from '../../styled';

export const SignUpContainer = styled(SUC)`
  position: relative;

  ${({ theme }) => theme.mq.lessThan('sm')`
    padding-bottom: 0px;

    img {
      margin-top: 53px;
      width: 100%;
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    img {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  `}
`;

export const PlansList = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  z-index: 1;

  ${({ theme }) => theme.mq.lessThan('sm')`
    flex-direction: column;
    align-items: center;
  `}
`;

export const Plan = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;

  &.plan__item {
    &--test {
      h4 {
        font-size: 32px;
        font-weight: 900;
        color: ${({ theme }) => theme.colors.blue};
      }

      p {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
      }
    }

    &--regular {
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: 8px;
      box-shadow: 0px 0px 8px ${({ theme }) => theme.colors.blueDarker};

      h4 {
        font-weight: 700;
        font-size: 18px;
        color: ${({ theme }) => theme.colors.blue};
      }

      h5 {
        font-size: 32px;
        font-weight: 900;
        color: ${({ theme }) => theme.colors.yellow};

        &:after {
          content: '';
          display: flex;
          border: 1px solid ${({ theme }) => theme.colors.blueDarker};
          width: 65%;
        }
      }

      p {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
      }

      .btn__link--yellow {
        color: ${({ theme }) => theme.colors.yellow};
      }
    }
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    &.plan__item {
      padding: 0 32px;

      &--test {
        align-items: center;
        margin-bottom: 53px;

        h4 {
          margin: 23px 0 13px;
        }

        p {
          margin-bottom: 33px;
          text-align: center;
        }
      }

      &--regular {
        padding: 32px 30px;
        margin: 0px 32px 30px;

        &:last-child {
          margin-bottom: 0px;
        }

        h4 {
          margin-bottom: 2px;
        }

        h5 {
          &:after {
            margin-top: 13px;
          }
        }

        p {
          margin: 16px 0 24px;
          line-height: 24px;
        }

        .btn__link--yellow {
          color: ${({ theme }) => theme.colors.yellow};
        }
      }
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    &.plan__item {
      max-width: 270px;

      &--test {
        max-width: 220px;
        margin-right: 40px;

        h4 {
          margin: 26px 0 8px;
        }

        p {
          margin-bottom: 24px;
          font-weight: 300;
          font-size: 16px;
        }
      }

      &--regular {
        padding: 30px;
        margin-right: 30px;

        h4 {
          margin-bottom: 8px;
        }

        h5 {
          &:after {
            margin-top: 8px;
          }
        }

        p {
          margin: 16px 0 24px;
        }
      }
    }
  `}
`;

export const PlansListMobile = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const SwiperContainer = styled.div`
  width: 100%;
  position: relative;

  .swiper-slide {
    padding: 8px;
  }

  .swiper__navigation {
    &--disabled {
      color: ${({ theme }) => theme.colors.greyDark};
      pointer-events: none;
    }

    &--prev,
    &--next {
      position: absolute;
      top: 50%;
      cursor: pointer;
      transition: opacity 0.3s, color 0.3s;

      &:hover {
        color: ${({ theme }) => theme.colors.yellowDark};
      }

      svg {
        filter: drop-shadow(0px 0px 2px #aaa);
      }
    }

    &--prev {
      left: -30px;
    }

    &--next {
      right: -30px;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  &.plan__item {
    &--trial,
    &--regular,
    &--promotion {
      p {
        font-weight: 300;
        font-size: 16px;
      }
    }

    &--trial,
    &--promotion {
      h4 {
        font-weight: 900;
        font-size: 32px;
      }
    }

    &--trial,
    &--regular {
      p {
        color: ${({ theme }) => theme.colors.blue};
      }
    }

    &--promotion,
    &--regular {
      border-radius: 8px;
      box-shadow: 0px 0px 8px;
    }

    &--trial {
      h4 {
        color: ${({ theme }) => theme.colors.blue};
      }
    }

    &--promotion {
      background-color: ${({ theme }) => theme.colors.blue};
      color: ${({ theme }) => theme.colors.blueOpacity1};

      h4 {
        color: ${({ theme }) => theme.colors.yellow};
      }

      p {
        color: ${({ theme }) => theme.colors.white};
      }

      .btn__link--white {
        font-size: 16px;
        font-weight: 700;
        color: ${({ theme }) => theme.colors.white};
      }
    }

    &--regular {
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.blueDarker};

      h4 {
        font-weight: 700;
        font-size: 18px;
        color: ${({ theme }) => theme.colors.blue};
      }

      h5 {
        font-size: 32px;
        font-weight: 900;
        color: ${({ theme }) => theme.colors.yellow};

        &:after {
          content: '';
          display: flex;
          border: 1px solid ${({ theme }) => theme.colors.blueDarker};
          width: 65%;
        }
      }

      .btn__link--yellow {
        color: ${({ theme }) => theme.colors.yellow};
      }
    }
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    &.plan__item {
      padding: 0 52px;

      &--trial {
        align-items: center;
        margin-bottom: 53px;

        h4 {
          margin: 23px 0 13px;
        }

        p {
          margin-bottom: 33px;
          text-align: center;
        }
      }

      &--promotion,
      &--regular {
        padding: 32px 30px;
        margin: 0px 12.235% 30px;

        &:last-child {
          margin-bottom: 0px;
        }

        h4 {
          margin-bottom: 2px;
        }

        p {
          margin: 16px 0 51px;
        }
      }

      &--regular {
        h5 {
          &:after {
            margin-top: 13px;
          }
        }
      }
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    &.plan__item {
      &--trial {
        h4 {
          margin: 26px 0 8px;
        }

        p {
          margin-bottom: 24px;
          font-weight: 300;
          font-size: 16px;
        }
      }

      &--promotion,
      &--regular {
        padding: 32px;

        p {
          margin: 16px 0 24px;
        }
      }

      &--regular {
        h4 {
          margin-bottom: 8px;
        }

        h5 {
          &:after {
            margin-top: 8px;
          }
        }
      }
    }
  `}
`;
