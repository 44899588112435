import { Action } from 'redux';
import { action } from 'typesafe-actions';

import { Audio, Playlist } from 'helpers/models';

import { PlayerTypes } from './types';

export const openPlayer = (): Action => (
  action(PlayerTypes.OPEN_PLAYER, { isPlayerOpen: true })
);
export const closePlayer = (): Action => (
  action(PlayerTypes.CLOSE_PLAYER, { isPlayerOpen: false })
);

export const setAudioSuccess = () => action(PlayerTypes.PLAYER_SUCCESS);

export const setAudioError = () => action(PlayerTypes.PLAYER_ERROR);

export const setPlayerPlaylist = (playlist: Playlist) => (
  action(PlayerTypes.SET_CURRENT_PLAYLIST, { playlist })
);

export const setPlayerCurrentAudio = (currentAudioId: number) => (
  action(PlayerTypes.SET_CURRENT_AUDIO, { currentAudioId })
);

export const setPlayerPaused = (isPaused: boolean) => (
  action(PlayerTypes.SET_PLAYER_PAUSED, { isPaused })
);