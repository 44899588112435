import styled from 'styled-components';

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

interface StyledInputProps {
  $iconUrl?: string;
}

export const Input = styled.input<StyledInputProps>`
  width: 100%;
  padding: 14px 18px;
  font-size: 14px;
  font-weight: 300;
  border: 0;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.blue};
  display: flex;

  &.input--has-shadow {
    box-shadow: 0px 0px 8px ${({ theme }) => theme.colors.blueDarker};
  }

  &.input--has-error {
    border: 1px solid ${({ theme }) => theme.colors.error};
    background-color: #f3dfdf;
  }

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }

  ${({ $iconUrl }) =>
    $iconUrl &&
    `
    background-image: url(${$iconUrl});
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 97%;
  `}
`;
