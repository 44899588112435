import { Reducer } from 'redux';

import {
  ContactState,
  ContactTypes,
  ContactAction
} from './types';
import { CONTACT_INITIAL_STATE } from './utils';

const reducer: Reducer<ContactState, ContactAction> = (
  state: ContactState = CONTACT_INITIAL_STATE,
  action: ContactAction,
) => {
  switch (action.type) {
    case ContactTypes.SEND_CONTACT_REQUEST: {
      return {
        ...state,
        contact: action.payload.contact,
      };
    }
    default: {
      return state;
    }
  }
}

export default reducer;