import styled from 'styled-components';

export const SignUpHeader = styled.header`
  display: flex;
  flex-direction: column;

  h1 {
    font-weight: 300;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.yellow};
    margin-bottom: 8px;
  }

  h2 {
    font-weight: 900;
    color: ${({ theme }) => theme.colors.blue};
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    align-items: center;

    h1 {
      font-size: 14px;
    }

    h2 {
      font-size: 24px;
      margin-bottom: 50px;
      text-align: center;
    }

    &.signup__header--is-confirm {
      h2 {
        margin-bottom: 60px;
      }
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    h1 {
      font-size: 14px;
    }

    h2 {
      font-size: 32px;
    }

    &:not(.signup__header--is-form-header):not(.signup__header--is-confirm)  {
      h2 {
        margin-bottom: 62px;
      }
    }

    &.signup__header--is-form-header,
    &.signup__header--is-confirm {
      align-items: center;

      h2 {
        margin-bottom: 50px;
      }
    }
  `}
`;

export const SignUpBackButton = styled.div`
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 300;

  .btn__link {
    border-bottom: 1px solid transparent;
    border-radius: 0;

    svg {
      margin-right: 6px;

      path {
        fill: none;
        stroke: ${({ theme }) => theme.colors.blue};
      }
    }

    &:hover {
      text-decoration: none;
      border-color: ${({ theme }) => theme.colors.blue};
    }
  }
`;