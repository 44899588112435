import styled from 'styled-components';

export const RichTextSection = styled.section`
  .ql-align-justify {
    text-align: justify;
  }

  .ql-align-left {
    text-align: left;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-indent-1 {
    margin-left: 48px;
  }

  .ql-indent-2 {
    margin-left: 96px;
  }

  .ql-font-serif {
    font-family: serif;
  }

  .ql-font-monospace {
    font-family: monospace;
  }

  h1,
  h2,
  h3 {
    font-weight: 700;
    margin-bottom: 12px;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 18px;
  }

  p {
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  ul,
  ol {
    margin-left: 24px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  a {
    text-decoration: underline;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.5;
    }
  }

  blockquote {
    border-left: 2px solid currentColor;
    padding-left: 22px;
    margin: 0;
  }

  img {
    width: 100%;
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    .ql-video {
      width: 100%;
      height: 240px;
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    .ql-video {
      width: 100%;
      height: 440px;
    }
  `}

  .ql-size-small {
    font-size: 10px;
  }

  .ql-size-normal {
    font-size: 14px;
  }

  .ql-size-large {
    font-size: 20px;
  }

  .ql-size-huge {
    font-size: 24px;
  }
`;
