import { AxiosError } from 'axios';
import { call, put, select } from 'redux-saga/effects';

import Connector, { defaultUrlV1 } from 'services/axios/axios';

import { StoreModule } from 'helpers/enum/StoreModule';
import { ApplicationState } from 'helpers/types';

import { addLoadingModule, removeLoadingModule } from '../loading';
import { createStudentSuccess, createStudentFailure } from './actions';
import { StudentAction } from './types';

export function* createStudent(action: StudentAction) {
  yield put(addLoadingModule(StoreModule.STUDENT));
  const { files, teachingInstitutionId } = action.payload;

  const { userToken, studentRequestId } = yield select(
    (state: ApplicationState) => state.student
  );

  try {
    const requestFiles = new FormData();

    files.forEach((file) => {
      requestFiles.append('arquivos', file);
    });

    requestFiles.append('teachingInstitutionId', `${teachingInstitutionId}`);

    yield call(
      Connector(false, false, userToken).put,
      `/student-request-services/upload?id=${studentRequestId}`,
      requestFiles
    );

    yield put(createStudentSuccess());
  } catch (error) {
    let errorMessage =
      'Houve uma falha ao enviar o comprovante. Tente novamente ou caso tenha mais dúvidas, entre em contato com o nosso suporte';
    let errorCode;

    if (error instanceof AxiosError) {
      if (error.response) {
        if (typeof error.response.data === 'object') {
          errorMessage = error.response.data.message;
          errorCode = error.response.data.code;
        } else {
          errorCode = error.response.status;
        }
      }
    }

    yield put(createStudentFailure(errorMessage, errorCode));
  } finally {
    yield put(removeLoadingModule(StoreModule.STUDENT));
  }
}
