import { FunctionComponent, ReactNode } from 'react';
import { Navigate, Outlet, Route } from 'react-router';
import { RouteProps, useLocation } from 'react-router-dom';

import Cookies from 'js-cookie';
import moment from 'moment';

import Layout from 'containers/Layout/Layout';

interface AuthProps {
  type?: 'private' | 'public' | 'sign';
  element: ReactNode;
}

const AuthRoute: FunctionComponent<AuthProps> = (props: AuthProps) => {
  const { type, element } = props;
  const { pathname } = useLocation();

  const token = Cookies.get('al-web-token') || '';
  const tokenExpires = localStorage.getItem('al-web-token-expires-in');
  const isTokenValid = tokenExpires
    ? moment().diff(moment(tokenExpires), 'days') < 0
    : false;
  const isUserAuthenticated = token ? isTokenValid : false;

  if (type === 'private' && !isUserAuthenticated) {
    const params = {
      pathname: '/login',
      search: pathname
    };

    return <Navigate to={params} />;
  }

  if (isUserAuthenticated && pathname.includes('/login')) {
    return <Navigate to="/audios" />;
  }

  return (
    <Layout
      renderPublic={type === 'public'}
      renderPrivate={type === 'private'}
      renderSign={type === 'sign'}
    >
      {element}
    </Layout>
  );
};

AuthRoute.defaultProps = {
  type: 'public'
};

export default AuthRoute;
