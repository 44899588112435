import { ToastType } from 'helpers/types/Toast';

export enum ToastifyTypes {
  SHOW_TOAST = '@TOAST/SHOW_TOAST'
}

export interface ToastifyState {
  readonly title: string;
  readonly message: string;
  readonly type: ToastType;
  readonly visible: boolean;
}