import { Category } from 'helpers/models';

export enum FilterTypes {
  SET_CATEGORY_FILTER = '@FILTERS/SET_CATEGORY_FILTER',
  CATEGORY_FILTER_SUCCESS = '@FILTERS/CATEGORY_FILTER_SUCCESS',
  CATEGORY_FILTER_ERROR = '@FILTERS/CATEGORY_FILTER_ERROR'
}

export interface FilterState {
  readonly categoryId: number;
  readonly selectedCategory: Category;
}

export type FilterAction = {
  type: FilterTypes;
  payload: {
    categoryId: number,
    selectedCategory: Category
  }
};