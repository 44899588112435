import { Reducer } from 'redux';

import { PlanAction, PlanState, PlanTypes } from './types';
import { PLAN_INITIAL_STATE } from './utils';

const reducer: Reducer<PlanState, PlanAction> = (
  state: PlanState = PLAN_INITIAL_STATE,
  action: PlanAction
) => {
  switch (action.type) {
    case PlanTypes.FETCH_PLANS_SUCCESS: {
      return {
        ...state,
        plans: action.payload.plans,
        promotions: action.payload.promotions,
        freeTrialPlan: action.payload.freeTrialPlan
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
