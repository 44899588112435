import styled from 'styled-components';

export const ShareButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
`;

export const ShareButtonsTitle = styled.span`
  font-weight: 600;
  margin-bottom: 16px;
`;

export const ShareButtonsGroup = styled.span`
  a:not(:last-child) {
    margin-right: 12px;
  }
`;