import styled from 'styled-components';

export { AccountForm, AccountFormHeader } from '../../styled';

export const AccountBillingData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  input:disabled {
    background-color: ${({ theme }) => theme.colors.greyLight};
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    margin-bottom: 52px;
  `}
`;

export const AccountBillingActions = styled.div`
  .btn__link {
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
`;

export const InputGroup = styled.div`
  display: flex;

  &:not(:last-of-type) {
    margin-bottom: 15px;
  }

  .input__card {
    &:not(:last-child) {
      margin-right: 30px;
      margin-bottom: 0;
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-top: 30px;

  button {
    width: 100%;

    &:not(:last-child) {
      margin-right: 40px;
    }
  }
`;