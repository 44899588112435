import styled from 'styled-components';

export const PlayerTranscriptionContainer = styled.div`
  ${({ theme }) => theme.bp.Medium};
  display: flex;
  flex-direction: column;
  margin-bottom: -15px !important;
`;

export const PlayerTranscriptionToggler = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  color: ${({ theme }) => theme.colors.blue};
  background-color: ${({ theme }) => theme.colors.yellow};
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 0px 8px ${({ theme }) => theme.colors.greyOpacity1}!important;
  font-size: 12px;
  font-weight: 700;
  transition: background-color 0.3s, padding-bottom 0.3s;

  &.player-transcription__toggler--transcription-visible {
    z-index: 1;
  }

  &.player-transcription__toggler--transcription-hidden {
    padding-bottom: 22px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.yellowDark};
  }

  svg {
    margin-left: 7px;
  }
`;

export const PlayerTranscriptionBodyWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};

  &.player-transcription__body-wrapper {
    &--transcription-visible {
      padding: 20px 20px 35px;
    }

    &--transcription-hidden {
      padding: 0;
    }
  }
`;

export const PlayerTranscriptionBody = styled.div<{ $isIphone: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  overflow-x: hidden;
  padding-right: 30px;

  &.player-transcription__body--no-transcription {
    overflow-y: hidden;
  }

  &:not(.player-transcription__body--no-transcription) {
    overflow-y: scroll;

    ${({ $isIphone }) =>
      $isIphone &&
      `
      border-right: 4px solid rgba(39, 47, 76, 0.3);
    `}
  }

  // TODO: verificar com o Lucas como proceder com a barra de rolagem no iPhone

  ${({ $isIphone, theme }) =>
    !$isIphone &&
    `
    scrollbar-width: thin;
    scrollbar-color: ${theme.colors.blue};

    -webkit-overflow-scrolling: auto !important;
    -webkit-overflow-scrolling: auto;
  
    /* width */
    ::-webkit-scrollbar {
      width: 4px;
      border-radius: 8px;
    }
  
    /* Track */
    ::-webkit-scrollbar-track {
      background-color: ${theme.colors.blue};
    }
  
    /* Handle */
    ::-webkit-scrollbar-thumb {
      width: 1px;
      padding: 2px;
      border-radius: 8px;
      background-color: ${theme.colors.blueLighter};
      transition: background-color 0.3s;
    }
  
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background-color: ${theme.colors.blueOpacity1};
    }
  `}
`;

export const PlayerTranscriptionBodyText = styled.div<{ $fontSize: string }>`
  font-size: ${({ $fontSize }) => $fontSize};
  transition: max-height 0.3s;
  white-space: pre-line;

  &.player-transcription__body-text--transcription-visible.player-transcription__body-text--has-transcription {
    min-height: 60px;
  }

  &.player-transcription__body-text {
    &--transcription-visible {
      ${({ theme }) => theme.mq.lessThan('xs')`
        max-height: 38vh;
      `}

      ${({ theme }) => theme.mq.between('xsPlusOne', 'mdLessOne')`
        max-height: 54vh;
      `}

      ${({ theme }) => theme.mq.greaterThan('md')`
        max-height: 30vh;
      `}
    }

    &--transcription-hidden {
      max-height: 0;
    }
  }
`;

export const PlayerTranscriptionZoomContainer = styled.div`
  position: absolute;
  right: 30px;
  top: 20px;
  display: flex;
  flex-direction: column;

  &.player-transcription__zoom-container {
    &--transcription-visible {
      display: visible;
    }

    &--transcription-hidden,
    &--no-transcription {
      display: none;
    }
  }

  .btn__blue {
    padding: 4px 6px;
    font-size: 18px;
    width: 100%;
    box-shadow: none;

    &:first-child {
      border-radius: 6px 6px 0 0;
      margin-bottom: 2px;
    }

    &:last-child {
      border-radius: 0 0 6px 6px;
    }
  }

  ${({ theme }) => theme.mq.lessThan('mdLessOne')`
    right: 34px;
  `};

  ${({ theme }) => theme.mq.greaterThan('md')`
    right: 44px;
  `};
`;
