import styled from 'styled-components';

export const PostHeaderWrapper = styled.header`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 8px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.yellow};
  }

  h2 {
    font-weight: 900;
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    padding-top: 28px;
    margin-bottom: 50px;

    h2 {
      font-size: 24px;
      margin-bottom: 24px;
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    margin-bottom: 56px;

    h2 {
      flex-grow: 1;
      font-size: 32px;
    }
  `}
`;

export const PostHeaderRow = styled.div`
  display: flex;
  align-items: center;

  &.post-header__input-title {
    align-items: flex-start;
    margin-bottom: 16px;
  }

  &.post-header__back-button {
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 300;

    .btn__link {
      color: white;
      border-bottom: 1px solid transparent;
      border-radius: 0;

      svg {
        margin-right: 6px;

        path {
          fill: none;
        }
      }

      &:hover {
        text-decoration: none;
        border-color: ${({ theme }) => theme.colors.white};
      }
    }
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    &.post-header__type-toggler {
      margin-bottom: 8px;
    }

    &.post-header__input-title {
      flex-direction: column;
    }
  `}
`;

export const PostHeaderInputs = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mq.lessThan('sm')`
    flex-direction: column;
    align-items: flex-start;

    .select__wrapper {
      margin-right: 0;
      width: 100%;
    }
  `}
`;