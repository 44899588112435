import { Reducer } from 'redux';

import {
  TeachingInstitutionTypes,
  TeachingInstitutionState,
  TeachingInstitutionAction
} from './types';
import { TEACHING_INSTITUTION_INITIAL_STATE } from './utils';

const reducer: Reducer<TeachingInstitutionState, TeachingInstitutionAction> = (
  state: TeachingInstitutionState = TEACHING_INSTITUTION_INITIAL_STATE,
  action: TeachingInstitutionAction
) => {
  switch (action.type) {
    case TeachingInstitutionTypes.FETCH_INSTITUTIONS_SUCCESS: {
      return {
        ...state,
        teachingInstitutions: action.payload.teachingInstitutions
      };
    }
    case TeachingInstitutionTypes.FETCH_INSTITUTIONS_FAILURE: {
      return {
        ...state,
        error: action.payload.error
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
