/* eslint-disable @typescript-eslint/no-explicit-any */
import { Reducer } from 'redux';

import { LoginResponse, LoginInfo } from 'helpers/types';
import { LoginErrorData } from 'helpers/types/LoginErrorData';

import { LoginTypes, LoginState } from './types';

export type Action = {
  type: LoginTypes;
  payload: {
    input: LoginInfo;
    response: LoginResponse;
    path: string;
    loginError: boolean;
    loginErrorData: LoginErrorData;
    logoutError: boolean;
  };
};

export const INITIAL_STATE: LoginState = {
  input: {
    email: '',
    password: ''
  },
  response: {
    token: '',
    expireIn: new Date(),
    admin: false,
    userId: 0
  },
  loginError: false,
  logoutError: false,
  logoutSuccess: false,
  loading: false
};

const reducer: Reducer<LoginState, Action> = (
  state: LoginState = INITIAL_STATE,
  action: Action
) => {
  switch (action.type) {
    case LoginTypes.LOGIN_REQUEST: {
      return {
        ...state,
        loginError: false,
        input: action.payload.input,
        loading: true
      };
    }
    case LoginTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        loginError: false,
        response: action.payload.response,
        loading: false
      };
    }
    case LoginTypes.LOGIN_ERROR: {
      return {
        ...state,
        loginError: true,
        loginErrorData: action.payload.loginErrorData,
        loading: false
      };
    }
    case LoginTypes.LOGOUT_REQUEST: {
      return {
        ...state,
        logoutError: false,
        logoutSuccess: false,
        loading: true
      };
    }
    case LoginTypes.LOGOUT_SUCCESS: {
      return {
        ...state,
        logoutError: false,
        logoutSuccess: true,
        loading: false
      };
    }
    case LoginTypes.LOGOUT_ERROR: {
      return {
        ...state,
        logoutError: true,
        logoutSuccess: true,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
