import styled from 'styled-components';

export const CheckboxWrapper = styled.button`
  width: 100%;
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.blue};

  &.checkbox--has-shadow {
    box-shadow: 0px 0px 8px ${({ theme }) => theme.colors.blueDarker};
  }

  &:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 0px 4px ${({ theme }) => theme.colors.blueDarker};
    border-radius: 4px;
    margin-right: 15px;
    transition: background-color 0.5s;
  }

  &.checkbox--is-active {
    &:before {
      background-color: ${({ theme }) => theme.colors.blue};
      border: 3px solid ${({ theme }) => theme.colors.white};
    }
  }
`;