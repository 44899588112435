import styled from 'styled-components';

import { RichTextSection } from 'components/RichText';

export const FaqWrapper = styled.div`
  ${({ theme }) => theme.bp.Fluid};
  margin: 40px 0 100px;
`;

export const FaqContainer = styled.div`
  ${({ theme }) => theme.bp.Large};
`;

export const FaqHeader = styled.header`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 50px;

  h2 {
    font-size: 32px;
    font-weight: 900;
  }

  h3 {
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.yellow};
    margin-bottom: 8px;
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    h2 {
      font-size: 24px;
    }
  `}
`;

export const FaqItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

export const FaqQuestion = styled.h4`
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 700;
`;

export const FaqAnswer = styled(RichTextSection)`
  font-size: 16px;
  font-weight: 300;
`;
