import { ContactState } from './types';

export const CONTACT_INITIAL_STATE: ContactState = {
  contact: {
    name: '',
    email: '',
    phone: '',
    message: '',
    acceptedTerm: false,
  }
}