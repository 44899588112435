import styled from 'styled-components';

export const CategoryItem = styled.div`
  position: relative;

  .swiper {
    &-container {
      /* width: calc(100% + 30px); */
    }

    &-wrapper {
      /* padding: 8px; */
    }

    &-navigation {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      bottom: 0;
      z-index: 1;
      opacity: 1;
      transition: opacity 0.3s;
      cursor: pointer;
      outline: none;

      &--is-prev {
        left: -25px;
        transform: rotate(180deg);
        padding-left: 10px;
      }

      &--is-next {
        right: -25px;
        padding-left: 10px;
      }

      &.swiper-button {
        &-disabled, &-is-disabled {
          opacity: 0;
          cursor: default;
        }
      }
    }
  }
`;