import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

type ButtonProps = {
  hasIcon?: boolean;
};

export const ButtonBase = css<ButtonProps>`
  width: fit-content;
  padding: 12px 32px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s, fill 0.3s, stroke 0.3s;
  --box-shadow-color: none;

  svg {
    path {
      fill: currentColor;
    }
  }

  &.btn__white {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.blue};
    --box-shadow-color: ${({ theme }) => theme.colors.whiteDarker};
    box-shadow: 0px 0px 8px var(--box-shadow-color);
    -webkit-box-shadow: 0px 0px 8px var(--box-shadow-color);

    svg {
      fill: ${({ theme }) => theme.colors.blue};
    }

    &:hover {
      color: ${({ theme }) => theme.colors.yellow};

      svg {
        fill: ${({ theme }) => theme.colors.yellow};
      }
    }
  }

  &.btn__yellow {
    background-color: ${({ theme }) => theme.colors.yellow};
    color: ${({ theme }) => theme.colors.white};
    --box-shadow-color: ${({ theme }) => theme.colors.yellowDarker};
    box-shadow: 0px 0px 8px var(--box-shadow-color);
    -webkit-box-shadow: 0px 0px 8px var(--box-shadow-color);

    svg {
      fill: ${({ theme }) => theme.colors.white};
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.yellowDark};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.grey};
      --box-shadow-color: ${({ theme }) => theme.colors.greyDark}
    }
  }

  &.btn__blue {
    background-color: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
    --box-shadow-color: ${({ theme }) => theme.colors.blueDarker};
    box-shadow: 0px 0px 8px var(--box-shadow-color);
    -webkit-box-shadow: 0px 0px 8px var(--box-shadow-color);

    svg {
      fill: ${({ theme }) => theme.colors.white};
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.blueLight};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.grey};
      --box-shadow-color: ${({ theme }) => theme.colors.greyDark}
    }
  }

  &.btn__link {
    padding: 0px;
    color: ${({ theme }) => theme.colors.blue};
    transition: all 0.3s;

    &:hover {
      text-decoration: underline;
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.grey};
      --box-shadow-color: ${({ theme }) => theme.colors.greyDark}
    }
  }

  &.btn__line {
    background-color: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
    --box-shadow-color: ${({ theme }) => theme.colors.blueDarker};
    box-shadow: 0px 0px 8px var(--box-shadow-color);
    -webkit-box-shadow: 0px 0px 8px var(--box-shadow-color);
    border: 1px solid ${({ theme }) => theme.colors.white};

    svg {
      fill: ${({ theme }) => theme.colors.white};
    }

    &:hover {
      color: ${({ theme }) => theme.colors.blue};
      background-color: ${({ theme }) => theme.colors.white};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.grey};
      --box-shadow-color: ${({ theme }) => theme.colors.greyDark}
    }
  }

  &.btn--has-icon {
    svg {
      margin-right: 16px;
    }
  }
`;

export const ButtonLink = styled(Link)`
  ${ButtonBase};
`;

export const HTMLButton = styled.button`
  ${ButtonBase};
`;
