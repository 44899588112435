import { generateMedia } from 'styled-media-query';

const mediaQueries = generateMedia({
  xs: '375px',
  xsPlusOne: '376px',
  smLessOne: '575px',
  sm: '576px',
  mdLessOne: '767px',
  md: '768px',
  lgLessOne: '991px',
  lg: '992px',
  xgLessOne: '1199px',
  xg: '1200px',
  hdLessOne: '1364px',
  hd: '1366px',
  fhdLessOne: '1919px',
  fhd: '1920px',
});

export default mediaQueries;
