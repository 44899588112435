import { CustomWindow, IuguCreditCard } from 'helpers/types';

const { Iugu } = (window as unknown) as CustomWindow;

export const getCompleteCard = (
  card: IuguCreditCard,
  fullName: string
): IuguCreditCard => {
  const { getFirstLastNameByFullName } = Iugu.utils;

  const [firstName, lastName] = fullName 
    ? getFirstLastNameByFullName(fullName)
    : ['', ''];
  
  return {
    ...card,
    first_name: firstName,
    last_name: lastName,
    brand: Iugu.utils.getBrandByCreditCardNumber(card.number),
  }
}