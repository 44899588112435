import styled from 'styled-components';

export const SignUpUserData = styled.div`

  .signup__checkbox {
    margin-top: 5px;
    margin-bottom: 20px;

    .checkbox__label {
      text-align: left;
      width: fit-content;
    }

    .btn__link {
      font-weight: 300;
      text-decoration: underline;
      font-size: 14px;

      &:hover {
        color: ${({ theme }) => theme.colors.yellow};
      }
    }
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    margin-bottom: 8px;
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    flex-grow: 1;
  `}
`;

export const SignUpLoginLink = styled.div`
  text-align: center;
  font-weight: 300;
  font-size: 14px;
  
  .btn__link {
    font-weight: 300;
    text-decoration: underline;
    font-size: 14px;

    &:hover {
      color: ${({ theme }) => theme.colors.yellow};
    } 
  }
`;