import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { PRIVATE_ROUTES, PUBLIC_ROUTES } from 'helpers/constants';

import {
  HomePage,
  AboutPage,
  ContactPage,
  LoginPage,
  FaqPage,
  LegalPage,
  SignUpPage,
  RecentNewsPage,
  NewsArticlePage,
  AudiosPage,
  CategoryPage,
  PlaylistPage,
  AccountPage,
  CancelSubscriptionPage,
  RecoverPasswordPage,
  ReactivateAccountPage
} from 'pages';
import ActivateAccountPage from 'pages/private/ActivateAccount';
import RedirectPage from 'pages/private/Redirect';
import SendDocumentsPage from 'pages/private/SendDocuments/SendDocuments';
import SearchPage from 'pages/public/Search';

import AuthRoute from './components/AuthRoute';

const Router = () => (
  <BrowserRouter>
    <Routes>
      {/* Rotas públicas */}
      <Route
        path={PUBLIC_ROUTES.LOGIN}
        element={<AuthRoute type="sign" element={<LoginPage />} />}
      />

      <Route
        path={PUBLIC_ROUTES.RECOVER_PASSWORD}
        element={<AuthRoute type="sign" element={<RecoverPasswordPage />} />}
      />

      <Route
        path={PUBLIC_ROUTES.LOGOUT}
        element={
          <AuthRoute type="sign" element={<LoginPage type="logout" />} />
        }
      />

      <Route
        path={PUBLIC_ROUTES.SIGNUP}
        element={<AuthRoute type="sign" element={<SignUpPage />} />}
      />

      <Route
        path={PUBLIC_ROUTES.HOME}
        element={<AuthRoute type="public" element={<HomePage />} />}
      />

      <Route
        path={PUBLIC_ROUTES.ABOUT}
        element={<AuthRoute type="public" element={<AboutPage />} />}
      />

      <Route
        path={PUBLIC_ROUTES.CONTACT}
        element={<AuthRoute type="public" element={<ContactPage />} />}
      />

      <Route
        path={PUBLIC_ROUTES.TERMS_OF_USE}
        element={<AuthRoute type="public" element={<LegalPage />} />}
      />

      <Route
        path={PUBLIC_ROUTES.PRIVACY_POLICY}
        element={<AuthRoute type="public" element={<LegalPage />} />}
      />

      <Route
        path={PUBLIC_ROUTES.COOKIES_POLICY}
        element={<AuthRoute type="public" element={<LegalPage />} />}
      />

      <Route
        path={PUBLIC_ROUTES.FAQ}
        element={<AuthRoute type="public" element={<FaqPage />} />}
      />

      <Route
        path={PUBLIC_ROUTES.NEWS}
        element={<AuthRoute type="public" element={<RecentNewsPage />} />}
      />

      <Route
        path={`${PUBLIC_ROUTES.NEWS}/:articleId`}
        element={<AuthRoute type="public" element={<NewsArticlePage />} />}
      />

      <Route
        path={PUBLIC_ROUTES.AUDIOS}
        element={<AuthRoute type="public" element={<AudiosPage />} />}
      />

      <Route
        path={`${PUBLIC_ROUTES.SEARCH}/:term`}
        element={<AuthRoute type="public" element={<SearchPage />} />}
      />

      <Route
        path={`${PUBLIC_ROUTES.AUDIOS}/:categoryId`}
        element={<AuthRoute type="public" element={<CategoryPage />} />}
      />

      <Route
        path={`${PRIVATE_ROUTES.REACTIVATE_ACCOUNT}`}
        element={<AuthRoute type="sign" element={<ReactivateAccountPage />} />}
      />

      <Route
        path={`${PRIVATE_ROUTES.ACTIVATE_ACCOUNT}`}
        element={<AuthRoute type="sign" element={<ActivateAccountPage />} />}
      />

      <Route
        path={`${PRIVATE_ROUTES.SEND_DOCUMENTS}`}
        element={<AuthRoute type="sign" element={<SendDocumentsPage />} />}
      />

      <Route
        path={`${PRIVATE_ROUTES.CANCEL_SUBSCRIPTION}`}
        element={
          <AuthRoute type="private" element={<CancelSubscriptionPage />} />
        }
      />

      <Route
        path={`${PRIVATE_ROUTES.PLAYLIST}/:playlistId`}
        element={<AuthRoute type="private" element={<PlaylistPage />} />}
      />

      <Route
        path={PRIVATE_ROUTES.ACCOUNT}
        element={<AuthRoute type="private" element={<AccountPage />} />}
      />

      <Route
        path={`${PRIVATE_ROUTES.REDIRECT}/:mode`}
        element={<AuthRoute type="private" element={<RedirectPage />} />}
      />
    </Routes>
  </BrowserRouter>
);

export default Router;
