import styled from 'styled-components';

export const ModalOverlay = styled.div<{ $isActive: boolean }>`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 101;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0);
  backdrop-filter: blur(0px);
  transform: scale(0);
  transition: backdrop-filter 0.2s ease-out, transform 0.05s;

  ${({ $isActive }) =>
    $isActive &&
    `
    background-color: rgba(0, 0, 0, 0.5);
    transform: scale(1);
    backdrop-filter: blur(10px);
  `};
`;

export const ModalContainer = styled.div`
  position: relative;
  max-width: 570px;
  max-height: 300px;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const ModalCloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const ModalHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.yellow};
  }

  h3 {
    font-size: 18px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.blue};
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    margin-bottom: 30px;
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    margin-bottom: 56px;
  `}
`;

export const ModalBody = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .btn__blue {
    width: 100%;
    margin-bottom: 8px;
    padding: 18px 0;
  }

  .btn__link {
    span {
      text-decoration: underline;
    }
  }

  ${({ theme }) => theme.mq.greaterThan('sm')`
    .btn__blue {
      max-width: 300px;
    }
  `}
`;
