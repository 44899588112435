import { Playlist } from 'helpers/models';

export enum PlaylistTypes {
  FETCH_PLAYLIST_REQUEST = '@PLAYLIST/FETCH_PLAYLIST_REQUEST',
  FETCH_PLAYLIST_SUCCESS = '@PLAYLIST/FETCH_PLAYLIST_SUCCESS',
  FETCH_PLAYLIST_ERROR = '@PLAYLIST/FETCH_PLAYLIST_ERROR',
}

export interface PlaylistState {
  readonly id: number;
  readonly playlist: Playlist;
}

export type PlaylistAction = {
  type: PlaylistTypes;
  payload: {
    id: number;
    playlist: Playlist;
  }
}