import { Reducer } from 'redux';

import { OverlayTypes, OverlayState } from './types';

export type Action = {
  type: OverlayTypes;
  payload: {
    isOverlayActive: boolean;
  };
};

const INITIAL_STATE: OverlayState = {
  isOverlayActive: false
};

const reducer: Reducer<OverlayState, Action> = (
  state: OverlayState = INITIAL_STATE,
  action: Action
) => {
  switch (action.type) {
    case OverlayTypes.SET_OVERLAY_ACTIVE: {
      return {
        ...state,
        isOverlayActive: action.payload.isOverlayActive
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
