/* eslint-disable no-console */

export const Log = {
  default: (component: string, message: string) => {
    console.log(`${component} error: ${message}`);
  },

  warn: (component: string, message: string) => {
    console.warn(`${component} error: ${message}`);
  },

  error: (component: string, message: string) => {
    console.error(`${component} error: ${message}`);
  }
};
