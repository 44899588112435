import styled from 'styled-components';

import { RichTextSection } from 'components/RichText';

export const LegalWrapper = styled.div`
  ${({ theme }) => theme.bp.Fluid};
  margin: 40px 0 100px;
`;

export const LegalContainer = styled.div`
  ${({ theme }) => theme.bp.Large};
  display: flex;
  flex-direction: column;
`;

export const LegalHeader = styled.header`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 24px;

  h2 {
    font-size: 32px;
    font-weight: 900;
  }

  h3 {
    font-size: 14px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.yellow};
    text-transform: uppercase;
    margin-bottom: 8px;
  }
`;

export const LegalTabs = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LegalToggler = styled.div`
  display: flex;
  margin-bottom: 52px;

  .btn__link {
    border-radius: 0;
    border-bottom: 2px solid ${({ theme }) => theme.colors.white};
    padding-bottom: 3px;

    &:hover {
      text-decoration: none;
      border-color: ${({ theme }) => theme.colors.blue};
    }

    &.btn--is-active {
      border-color: ${({ theme }) => theme.colors.blue};
    }
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    flex-direction: column;

    .btn__link {
      margin-bottom: 10px;
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    .btn__link {
      margin-right: 50px;
    }
  `}
`;

export const LegalContent = styled(RichTextSection)`
  font-size: 16px;
  font-weight: 300;
`