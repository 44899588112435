/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Cookies from 'js-cookie';
import * as yup from 'yup';

import { createStudentRequest } from 'store/ducks/student';
import { fetchInstitutionsRequest } from 'store/ducks/teachingInstitution';

import arrowDown from 'assets/icons/signup/arrow-down.svg';
import uploadFile from 'assets/icons/signup/upload-file.svg';
import backgroundUrl from 'assets/img/signup/signup-complete.png';
import {
  institutionInitialState,
  studentRequestInputErrorInitialState
} from 'helpers/initialState';
import { TeachingInstitution } from 'helpers/models';
import {
  accountSelector,
  studentSelector,
  teachingInstitutionSelector
} from 'helpers/selectors';
import { StudentRequestInputError } from 'helpers/types';
import { studentRequestValidation } from 'helpers/validation/studentRequestValidation';

import { HTMLButton as Button } from 'components/Button';
import FileInput from 'components/FileInput';
import { Select } from 'components/Select';
import SignHeader from 'components/SignHeader';
import {
  NavigationActionTypes,
  useNavigation
} from 'contexts/NavigationContext';

import * as S from './styles';

interface SpecialPlanDocumentFormProps {
  isWebview: boolean;
  handleNextOnWebview: () => void;
}

const SpecialPlanDocumentForm = (props: SpecialPlanDocumentFormProps) => {
  const { isWebview, handleNextOnWebview } = props;

  const [errors, setErrors] = useState<StudentRequestInputError>(
    studentRequestInputErrorInitialState
  );

  const dispatch = useDispatch();
  const { dispatch: dispatchNavigation } = useNavigation();

  const { hadSucceed, isError, errorCode, errorMessage, loading } =
    useSelector(studentSelector);
  const {
    hasError: hasAccountCreationError,
    hadSucceed: hasAccountCreationSucceed
  } = useSelector(accountSelector);
  const { teachingInstitutions } = useSelector(teachingInstitutionSelector);

  const [selectedInstitution, setSelectedInstitution] =
    useState<TeachingInstitution>(institutionInitialState);
  const [files, setFiles] = useState<File[]>([]);
  const [isFinished, setFinished] = useState(false);

  useEffect(() => {
    if (!hasAccountCreationError && hasAccountCreationSucceed) {
      dispatch(fetchInstitutionsRequest());
    }
  }, [hasAccountCreationError, hadSucceed]);

  useEffect(() => {
    if (!loading && isFinished) {
      if (isError) {
        toast.error(errorMessage, { autoClose: false });
      } else if (hadSucceed) {
        dispatchNavigation({ type: NavigationActionTypes.INCREMENT });
      }
    }
  }, [loading, isError, isFinished, hadSucceed]);

  const handleFormSubmit = () => {
    toast.dismiss();
    setFinished(false);

    let errs: StudentRequestInputError = studentRequestInputErrorInitialState;

    try {
      studentRequestValidation({
        files,
        teachingInstitution: selectedInstitution
      });

      dispatch(createStudentRequest(files, selectedInstitution.id));

      if (isWebview) {
        handleNextOnWebview();
      }

      setFinished(true);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        error.inner.forEach((e) => {
          if (e.path) {
            errs = { ...errs, [`${e.path}`]: true };
          }

          toast.error(e.message, { autoClose: false });
        });
      }
    } finally {
      setErrors(errs);
    }
  };

  return (
    <S.SpecialPlanDocumentFormContainer>
      <SignHeader
        title="Assinatura Playlegis"
        subtitle="Parabéns"
        className="signup__header signup__header--is-confirm"
        withBackButton={false}
      />

      <S.SpecialPlanDocumentFormMessage>
        <h3>Sua assinatura foi realizada com sucesso.</h3>

        <p>
          Agora você precisa nos enviar o seu comprovante de estudante, assim
          poderemos validar a sua assinatura para garantir o desconto na
          mensalidade após o teste de 7 dias grátis.
        </p>

        <S.ActivateAccountStudentInputGroup>
          <Select
            placeholder="Selecione sua instituição"
            icon={arrowDown}
            selected={selectedInstitution.name}
            onSelection={setSelectedInstitution as any}
            data={teachingInstitutions as any}
            hasError={errors.teachingInstitution}
          />

          <FileInput
            multiple
            id="studentFiles"
            name="studentFiles"
            placeholder="Clique para fazer upload do arquivo..."
            icon={uploadFile}
            files={files}
            setFiles={setFiles}
            hasError={errors.files}
          />
        </S.ActivateAccountStudentInputGroup>

        <Button onClick={handleFormSubmit} variant="yellow">
          Enviar e prosseguir
        </Button>

        <img
          src={backgroundUrl}
          alt="Pessoas comemorando"
          title="Assinatura realizada com sucesso"
        />
      </S.SpecialPlanDocumentFormMessage>
    </S.SpecialPlanDocumentFormContainer>
  );
};

export default SpecialPlanDocumentForm;
