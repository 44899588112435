import styled from 'styled-components';

export const OurDifferencesWrapper = styled.div`
  ${({ theme }) => theme.bp.Fluid};
`;

type ContainerProps = {
  backgroundImage?: string;
}

export const ContentContainer = styled.div<ContainerProps>`
  ${({ theme }) => theme.bp.Medium};
  position: relative;
  top: -120px;
  background-color: ${({ theme }) => theme.colors.yellow};
  ${(props) => props.backgroundImage && `
    background-image: url(${props.backgroundImage});
  `}
  background-repeat: no-repeat;
  background-position: right;
  box-shadow: 0px 0px 16px ${({ theme }) => theme.colors.blueDarker};
  border-radius: 16px;

  h2 {
    margin-bottom: 8px;
    font-weight: 300;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.blue};
  }

  h3 {
    margin-bottom: 32px;
    font-weight: 900;
    color: ${({ theme }) => theme.colors.blue};
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
      padding: 24px 24px;

      h2 {
        font-size: 12px;
      }

      h3 {
        font-size: 24px;
      }
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
      padding: 50px 100px;

      h2 {
        font-size: 14px;
      }

      h3 {
        font-size: 32px;
      }
  `}
`;

export const ItemList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemRow = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    flex-direction: column;
  `}
`;

export const ItemIcon = styled.div`
  div {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors.blue};
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    margin-bottom: 16px;
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    margin-right: 32px;
  `}
`;

export const ItemDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.colors.blue};

  h4 {
    font-weight: 700;
    margin-bottom: 8px;
  }

  p {
    font-weight: 300;
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    h4 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    h4 {
      font-size: 18px;
    }

    p {
      font-size: 16px;
    }
  `}
`;

export const ContainerCTA = styled.div`
  ${({ theme }) => theme.bp.ExtraLarge};
  display: flex;
  flex-direction: column;
  align-items: center;

  .btn__blue {
    margin-bottom: 8px;
  }

  .btn__link {
    font-size: 14px;
    font-weight: 300;
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    margin-bottom: 62px!important;

    .btn__blue {
      padding: 18px 62px;
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    margin-bottom: 100px!important;

    .btn__blue {
      padding: 18px 100px;
    }
  `}
`