/* eslint-disable no-console */
import { FormEvent, Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastOptions } from 'react-toastify';

import { ValidationError } from 'yup';

import { createAccountRequest } from 'store/ducks/account';
import { logoutError } from 'store/ducks/login';

import { PUBLIC_ROUTES } from 'helpers/constants';
import {
  verifyLengthAndApply,
  verifyAndFormatCardNumber
} from 'helpers/helpers';
import {
  creditCardInitialState,
  creditCardInputErrorInitialState
} from 'helpers/initialState';
import { accountSelector } from 'helpers/selectors';
import { CreditCardInputError, IuguCreditCard } from 'helpers/types';
import { DiscountValueTypeEnum } from 'helpers/types/DiscountValueTypeEnum';
import { creditCardValidation } from 'helpers/validation/creditCard';
import { useIugu } from 'hooks/useIugu';
import { useWebviewChecker } from 'hooks/useWebviewChecker';
import { getCompleteCard } from 'utils/getCompleteCard';
import { getFormattedPromotionalValue } from 'utils/getFormattedPromotionalValue';

import { ButtonLink as Link, HTMLButton as Button } from 'components/Button';
import Input from 'components/Input';
import SignHeader from 'components/SignHeader';
import {
  useNavigation,
  NavigationActionTypes
} from 'contexts/NavigationContext';

import * as GS from '../../styled';
import * as S from './styled';

const SignUpCreditCardForm = () => {
  const dispatchRedux = useDispatch();
  const { dispatch: dispatchNavigation } = useNavigation();

  const {
    hasError,
    hadSucceed,
    selectedPlan,
    errorCode,
    errorMessage,
    isLoading,
    selectedPromotionId
  } = useSelector(accountSelector);

  const [errors, setErrors] = useState<CreditCardInputError>(
    creditCardInputErrorInitialState
  );
  const [card, setCard] = useState<IuguCreditCard>(creditCardInitialState);
  const [fullName, setFullName] = useState('');
  const [isFinished, setFinished] = useState(false);

  const Iugu = useIugu();
  const { onWebview } = useWebviewChecker();

  useEffect(() => {
    if (process.env.REACT_APP_IUGU_MODE === 'TEST') {
      Iugu.setTestMode(true);
    }

    Iugu.setAccountID('E5548FCA165037A49A21A7934D51AD8E');
  }, []);

  useEffect(() => {
    if (hasError) {
      if (errorCode === '-1') {
        toast.error('Falha ao registrar cartão', { autoClose: false });
      } else {
        setFinished(true);
      }
    } else if (hadSucceed) {
      dispatchNavigation({ type: NavigationActionTypes.INCREMENT });
    }
  }, [hasError, hadSucceed]);

  useEffect(() => {
    if (!isLoading && hasError && isFinished) {
      toast.error(errorMessage, { autoClose: false });
    }
  }, [isLoading, hasError, isFinished]);

  const handleCardDataSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFinished(false);

    const unformatCardNumber = card.number.replaceAll(' ', '');

    const toastOptions: ToastOptions = { autoClose: false };
    toast.dismiss();

    let errs: CreditCardInputError = creditCardInputErrorInitialState;

    try {
      const completedCard = getCompleteCard(
        { ...card, number: unformatCardNumber },
        fullName
      );

      creditCardValidation(completedCard);

      dispatchRedux(createAccountRequest(completedCard));
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log('[CardDataSubmitError]', error);
      }

      if (error instanceof ValidationError) {
        error.inner.forEach((e) => {
          if (e.path) {
            errs = { ...errs, [`${e.path}`]: true };
          }

          toast.error(e.message, toastOptions);
        });
      }
    } finally {
      setErrors(errs);
    }
  };

  const getPaymentDescription = () => {
    const { value: planValue } = selectedPlan;
    const formattedPlanValue = selectedPlan.value.toFixed(2).replace('.', ',');

    if (selectedPromotionId) {
      const { discount, discountType, promoPeriod } =
        selectedPlan.promotions.find(
          (promotion) => promotion.id === selectedPromotionId
        )!;

      const formattedPromotionalValue = getFormattedPromotionalValue(
        planValue,
        discount,
        discountType
      );

      return (
        <Fragment>
          {`R$ ${formattedPromotionalValue}/mês durante ${promoPeriod} ${
            promoPeriod > 1 ? 'meses' : 'mês'
          }`}
          <br />
          Após o período promocional: R$ {formattedPlanValue}/mês
        </Fragment>
      );
    }

    return (
      <Fragment>
        Teste gratuito durante 7 dias
        <br />
        R$ {planValue}/mês após o período de testes
      </Fragment>
    );
  };

  return (
    <GS.SignUpContainer className="container__form container__form--is-card-data">
      <S.SignUpPayment>
        <SignHeader title="Assinatura Playlegis" subtitle="Pagamento" />

        <GS.SignUpForm onSubmit={handleCardDataSubmit} noValidate>
          <S.SignUpPaymentSectionTitle className="payment__title--is-details">
            Detalhes do cartão
          </S.SignUpPaymentSectionTitle>

          <Input
            type="text"
            id="fullname"
            name="fullname"
            placeholder="Nome do titular"
            className="signup__input signup__input--is-fullname"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            hasShadow
            hasError={errors.fullname}
          />

          <Input
            type="text"
            id="cardNumber"
            name="cardNumber"
            placeholder="Número do cartão"
            className="signup__input signup__input--is-card-number"
            value={card.number}
            onChange={(e) => {
              verifyAndFormatCardNumber(
                e.target.value,
                19,
                'number',
                card,
                setCard
              );
            }}
            hasShadow
            hideSpinButton
            hasError={errors.number}
          />

          <S.InputGroupRow>
            <Input
              type="number"
              id="cardMonth"
              name="cardMonth"
              placeholder="MM"
              className="signup__input signup__input--is-card-month"
              value={card.month}
              onChange={(e) =>
                verifyLengthAndApply(e.target.value, 2, 'month', card, setCard)
              }
              hasShadow
              hideSpinButton
              hasError={errors.month || errors.fulldate}
            />

            <Input
              type="number"
              id="cardYear"
              name="cardYear"
              placeholder="AAAA"
              className="signup__input signup__input--is-card-year"
              value={card.year}
              onChange={(e) =>
                verifyLengthAndApply(e.target.value, 4, 'year', card, setCard)
              }
              hasShadow
              hideSpinButton
              hasError={errors.year || errors.fulldate}
            />

            <Input
              type="number"
              id="verificationValue"
              name="verificationValue"
              placeholder="CVV"
              className="signup__input signup__input--is-verification-value"
              value={card.verification_value}
              onChange={(e) =>
                verifyLengthAndApply(
                  e.target.value,
                  4,
                  'verification_value',
                  card,
                  setCard
                )
              }
              hasShadow
              hideSpinButton
              hasError={errors.verification_value}
            />
          </S.InputGroupRow>

          <S.SignUpPaymentData>
            <S.SignUpPaymentSectionTitle className="payment__title--is-resume">
              Resumo da assinatura
            </S.SignUpPaymentSectionTitle>

            <S.SignUpPaymentDescription className="is-resume">
              {getPaymentDescription()}
            </S.SignUpPaymentDescription>

            <Button type="submit" variant="yellow">
              Assinar
            </Button>

            <S.SignUpPaymentDescription className="is-notice">
              Você será cobrado somente após o término do teste gratuito. Você
              pode cancelar a qualquer momento antes de acabar os 7 dias do
              teste.
            </S.SignUpPaymentDescription>
          </S.SignUpPaymentData>
        </GS.SignUpForm>
      </S.SignUpPayment>

      {!onWebview && (
        <S.SignUpFooter>
          Dúvidas?{' '}
          <Link to={PUBLIC_ROUTES.CONTACT} variant="link">
            Entre em contato
          </Link>
          .
        </S.SignUpFooter>
      )}
    </GS.SignUpContainer>
  );
};

export default SignUpCreditCardForm;
