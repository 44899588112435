import { ReactNode } from 'react';

import { ItemButtonProps } from 'helpers/types/ItemButtonProps';
import { PopUpItem } from 'helpers/types/PopUpItem';

export enum PopUpTypes {
  ADD_POP_UP_ITEM = '@POPUP/ADD_POP_UP_ITEM',
  REMOVE_POP_UP_ITEM = '@POPUP/REMOVE_POP_UP_ITEM',
  REMOVE_ALL_POP_UP_ITEMS = '@POPUP/REMOVE_ALL_POP_UP_ITEMS'
}

export interface PopUpState {
  readonly items: PopUpItem[];
}

export type PopUpAction = {
  type: PopUpTypes;
  payload: {
    items: PopUpItem[];
    popUpItemId: string;
    itemTitle: string;
    itemDescription: string;
    itemButton: ItemButtonProps;
    itemShouldClose: boolean;
  };
};
