import { Fragment, ReactNode, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import Cookies from 'js-cookie';
import moment from 'moment';

import { ApplicationState } from 'helpers/types';

import Footer from 'components/Footer/Footer';
import GlobalComponents from 'components/GlobalComponents/GlobalComponents';
import GlobalNavbar from 'components/GlobalNavbar';
import { Player } from 'containers/Player/Player';

interface LayoutProps {
  renderPublic?: boolean;
  renderPrivate?: boolean;
  renderSign?: boolean;
  children: ReactNode;
}

const Layout = (props: LayoutProps) => {
  const { children, renderPublic, renderPrivate, renderSign } = props;

  const { logoutSuccess } = useSelector(
    (state: ApplicationState) => state.login
  );

  const token = Cookies.get('al-web-token') || '';
  const tokenExpires = localStorage.getItem('al-web-token-expires-in');
  const isTokenValid = tokenExpires
    ? moment().diff(moment(tokenExpires), 'days') < 0
    : false;
  const isUserAuthenticated = token ? isTokenValid : false;

  // Layout render rules
  const isPublicTop = useMemo(
    () =>
      !renderSign && ((renderPublic && !isUserAuthenticated) || logoutSuccess),
    [logoutSuccess, renderPublic, isUserAuthenticated, renderSign]
  );

  const isPrivateTop = useMemo(
    () =>
      !renderSign && (renderPrivate || (isUserAuthenticated && !logoutSuccess)),
    [logoutSuccess, renderPrivate, isUserAuthenticated, renderSign]
  );

  return (
    <Fragment>
      <GlobalComponents />
      <GlobalNavbar renderPublic={isPublicTop} renderPrivate={isPrivateTop} />
      <Player />
      {children}
      <Footer isUserAuthenticated={isUserAuthenticated} hide={renderSign} />
    </Fragment>
  );
};

Layout.defaultProps = {
  renderPublic: false,
  renderPrivate: false,
  renderSign: false
};

export default Layout;
