import { useState, useEffect } from 'react';

interface PasswordValidationProps {
  firstPassword: string;
  secondPassword: string;
  settings: {
    minLength: number,
  }
}

export const usePasswordValidation = (props: PasswordValidationProps) => {
  const { firstPassword, secondPassword, settings } = props;
  const [minLength, setMinLength] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [match, setMatch] = useState(false);

  useEffect(() => {
    const isMinLength = firstPassword.length >= settings.minLength;
    const isMatch = firstPassword
      && firstPassword === secondPassword
      || false;

    setMinLength(isMinLength)
    setHasNumber(/\d/.test(firstPassword));
    setMatch(isMatch);
  }, [firstPassword, secondPassword]);

  return [minLength, hasNumber, match];
};