import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import {
  openPlayer,
  setPlayerCurrentAudio,
  setPlayerPaused,
  setPlayerPlaylist
} from 'store/ducks/player';
import { searchRequest } from 'store/ducks/search';

import { searchInitialState, playlistInitialState } from 'helpers/initialState';
import { searchSelector } from 'helpers/selectors';
import { formatSecondsAsTime } from 'utils/timeFormat';

import Button from 'components/Button/HTMLButton';
import CategoryThumbLink from 'components/CategoryThumbLink';
import PostHeader from 'containers/PostHeader/PostHeader';

import * as S from './styled';

type SearchPageParams = {
  term: string;
};

const SearchPage = () => {
  const dispatch = useDispatch();
  const { response: stateResponse, searching } = useSelector(searchSelector);

  const { term: param } = useParams<SearchPageParams>();

  const [term, setTerm] = useState(param);
  const [response, setResponse] = useState(searchInitialState);
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {
    document.body.style.backgroundColor = '#272F4C';

    const onUnmount = () => {
      document.body.style.backgroundColor = '#FFF';
    };

    return onUnmount;
  }, []);

  useEffect(() => {
    setTerm(param);
  }, [param]);

  useEffect(() => {
    dispatch(searchRequest(term || ''));
  }, [term]);

  useEffect(() => {
    setResponse(stateResponse);

    setNoResults(
      !stateResponse.playlists.length && !stateResponse.audios.length
    );
  }, [stateResponse]);

  const handleAudioItemClick = (index: number) => {
    dispatch(
      setPlayerPlaylist({
        ...playlistInitialState,
        audios: [response.audios[index]]
      })
    );
    dispatch(setPlayerCurrentAudio(0));
    dispatch(openPlayer());
    dispatch(setPlayerPaused(false));
  };

  return (
    <S.SearchWrapper>
      <S.SearchContainer>
        <PostHeader title="Pesquisa" subtitle="Resultados:" mode="CATEGORY" />

        {!searching && noResults ? (
          <S.NoResultsFound>Nenhum resultado encontrado...</S.NoResultsFound>
        ) : (
          <Fragment>
            <S.SearchPlaylistsWrapper>
              <S.SearchResultType>Playlists</S.SearchResultType>
              <S.SearchPlaylistsGrid>
                {response.playlists.map((playlist) => (
                  <CategoryThumbLink playlist={playlist} />
                ))}
              </S.SearchPlaylistsGrid>
            </S.SearchPlaylistsWrapper>

            <S.SearchAudiosWrapper>
              <S.SearchResultType>Áudios</S.SearchResultType>

              <S.SearchAudiosList>
                {response.audios.map((audio, index) => (
                  <S.SearchAudioItem key={audio.id}>
                    <Button
                      onClick={() => handleAudioItemClick(index)}
                      variant="link"
                    >
                      <S.SearchAudioName>{audio.title}</S.SearchAudioName>
                      <S.SearchAudioDuration>
                        {formatSecondsAsTime(audio.duration)}
                      </S.SearchAudioDuration>
                    </Button>
                  </S.SearchAudioItem>
                ))}
              </S.SearchAudiosList>
            </S.SearchAudiosWrapper>
          </Fragment>
        )}
      </S.SearchContainer>
    </S.SearchWrapper>
  );
};

export default SearchPage;
