import { TeachingInstitution } from 'helpers/models';

export enum TeachingInstitutionTypes {
  FETCH_INSTITUTIONS_REQUEST = '@TEACHING/FETCH_INSTITUTION_REQUEST',
  FETCH_INSTITUTIONS_SUCCESS = '@TEACHING/FETCH_INSTITUTION_SUCCESS',
  FETCH_INSTITUTIONS_FAILURE = '@TEACHING/FETCH_INSTITUTION_FAILURE'
}

export interface TeachingInstitutionState {
  readonly teachingInstitutions: TeachingInstitution[];
  readonly error: boolean;
}

export interface TeachingInstitutionAction {
  type: TeachingInstitutionTypes;
  payload: TeachingInstitutionState;
}
