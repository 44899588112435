import { ModalState } from './types';

export const MODAL_INITIAL_STATE: ModalState = {
  modalItem: {
    show: false,
    title: '',
    subtitle: '',
    primaryButton: {
      label: '',
      linkTo: ''
    }
  }
};
