import { Link as RouterLink } from 'react-router-dom';

import styled from 'styled-components';

export const FullFooterWrapper = styled.div`
  ${({ theme }) => theme.bp.Fluid};
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
`;

export const Container = styled.div`
  ${({ theme }) => theme.bp.Medium};
  display: flex;
  flex-direction: column;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;

  &.footer__row-logo {
    margin: 60px 0 55px;

    svg {
      width: 270px;
      height: auto;
    }
  }

  &.footer__row-menu {
    margin-bottom: 65px;
  }

  &.footer__row-credits {
    font-size: 14px;
    font-weight: 300;
  }

  ${({ theme }) => theme.mq.lessThan('md')`
    &.footer__row-menu {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    &.footer__row-credits {
      margin-bottom: 26px;
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('md')`
    margin-bottom: 16px;
  `}
`;

export const NavMenu = styled(Column)`
  h3 {
    font-size: 14;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  nav a {
    margin-bottom: 24px;
    transition: color 0.3s;
    font-weight: 700;

    &:hover {
      color: ${({ theme }) => theme.colors.yellow};
    }
  }

  &.footer__column-links {
    &.is--download a,
    &.is--social a {
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.8;
      }
    }

    &.is--download a {
      margin-bottom: 20px;
    }

    &.is--social a {
      margin-right: 10px;
    }
  }

  ${({ theme }) => theme.mq.lessThan('xs')`
    &.is--download a {
      svg {
        width: 90%;
      }
    }
  `};

  ${({ theme }) => theme.mq.lessThan('md')`
    &.footer__column-links {
      &.is--download,
      &.is--social {
        margin-top: 52px;
      }
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('md')`
    &.footer__column-links {
      &:not(:last-child) {
        margin-right: 140px;
      }
    }
  `}
`;

export const Link = styled(RouterLink)`
  display: flex;
`;
