import { Fragment, FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal } from 'store/ducks/modal';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ApplicationState } from 'helpers/types';

import { ButtonLink as Button } from 'components/Button';

import * as S from './Modal.styles';

const Modal: FunctionComponent = () => {
  const dispatch = useDispatch();

  const { modalItem } = useSelector((state: ApplicationState) => state.modal);

  const handleClose = (callback?: () => void) => {
    dispatch(closeModal());

    if (callback) {
      callback();
    }
  };

  return (
    <S.ModalOverlay $isActive={modalItem.show!}>
      {modalItem.show ? (
        <S.ModalContainer>
          <S.ModalCloseButton type="button" onClick={() => handleClose()}>
            <CloseIcon />
          </S.ModalCloseButton>

          <S.ModalHeader>
            <h2>{modalItem.title}</h2>
            <h3>{modalItem.subtitle}</h3>
          </S.ModalHeader>

          <S.ModalBody>
            <Button
              variant={modalItem.primaryButton.variant}
              to={modalItem.primaryButton.linkTo!}
              onClick={() => handleClose(modalItem.primaryButton.onClick)}
            >
              {modalItem.primaryButton.label}
            </Button>
            {modalItem.secondaryButton ? (
              <Button
                variant={modalItem.secondaryButton.variant}
                to={modalItem.secondaryButton.linkTo!}
                onClick={() => handleClose(modalItem.secondaryButton?.onClick)}
              >
                {modalItem.secondaryButton.label}
              </Button>
            ) : (
              <Fragment />
            )}
          </S.ModalBody>
        </S.ModalContainer>
      ) : (
        <Fragment />
      )}
    </S.ModalOverlay>
  );
};

export default Modal;
