import { Link as RouterLink, LinkProps } from 'react-router-dom';

import styled from 'styled-components';

import keyframes from './keyframes';

export const NavbarContainer = styled.div`
  ${({ theme }) => theme.bp.Fluid};
  padding: 40px 0;
  overflow: hidden;

  &.nav--light-mode {
    background-color: ${({ theme }) => theme.colors.white};
  }

  &.nav--dark-mode {
    background-color: ${({ theme }) => theme.colors.blue};
  }
`;

export const Row = styled.div`
  ${({ theme }) => theme.bp.Medium};
  display: flex;

  ${({ theme }) => theme.mq.lessThan('md')`
    padding: 32px 0;
    align-items: center;
  `}
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;

  svg {
    width: 170px;
    height: auto;
    transition: fill 1s;
  }

  &.logo--is-dark {
    .cls-1, .cls-2 {
      fill: ${({ theme }) => theme.colors.blue};
    }
  }

  &.logo--is-light {
    .cls-1 {
      fill: ${({ theme }) => theme.colors.yellow};
    }
  }

  ${({ theme }) => theme.mq.lessThan('md')`
    flex-grow: 1;
    z-index: 11;
  `}
`;

export const Nav = styled.nav`
  display: flex;

  ${({ theme }) => theme.mq.lessThan('md')`
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: 0;
    right: 999px;
    height: 100%;
    width: 100%;
    z-index: 10;
    
    &.nav--is-open {
      background-color: ${theme.colors.white};
      animation: ${keyframes.nav.show} 0.3s;
      right: 0px;
    }

    &.nav--is-closed {
      background-color: ${theme.colors.white};
      animation: ${keyframes.nav.hide} 0.8s;
      right: 999px;
    }

    .nav__wrapper {
      margin-top: 158px;
      width: 100%;
      text-align: right;
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('md')`
    /* Definições */
    flex-direction: row;
    justify-content: flex-end;
    align-items: stretch;
    flex-grow: 1;

    .btn__white, .btn__blue {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 170px;
      margin-left: 12px;

      .nav__item {       
        display: flex;
      }
    }
  `}
`;

interface NavLinkProps extends LinkProps {
  $isLightMode?: boolean;
}

export const Link = styled(RouterLink) <NavLinkProps>`
  transition: background-color 0.1s, color 0.1s;
  font-weight: 700;

  ${({ theme }) => theme.mq.lessThan('md')`
    font-size: 24px;
    font-weight: 700;
    color: ${theme.colors.blue};
    width: fit-content;

    &.link--is-small {
      font-size: 14px;
      
      .nav__item {
        padding-left: 30px;
        padding-right: 30px;
      }
    }

    &.link--has-separator {
      &:before {
        content: '';
        display: inline-block;
        width: 24px;
        margin: 24px 20px;
        border-top: 1px solid ${theme.colors.blue};
      }
    }

    &:hover,
    &:hover.active {
      color: ${theme.colors.yellowDarker};
    }

    &.active {
      color: ${theme.colors.yellow};
    }

    .nav__item {
      padding: 16px 20px;
    }
  `}

  ${({ $isLightMode, theme }) => theme.mq.greaterThan('md')`
    font-size: 16px;
    color: ${$isLightMode ? theme.colors.blue : theme.colors.white};
    
    &:hover,
    &:hover.active {
      color: ${theme.colors.yellow};
    }

    &.active {
      color: ${theme.colors.yellow};
    }

    padding: 0 24px;

    .nav__item {
      display: flex;
      height: 100%;
      align-items: center;

      svg {
        margin-right: 16px;
      }
    }

    &:not(:first-child) {
      border-top: unset;
    }

    &:nth-child(3) {
      position: relative;
      display: flex;
      align-items: center;

      div {
        margin-right: 12px;
      }

      &::after {
        content: "";
        display: block;
        background-color: ${$isLightMode ? theme.colors.blue : theme.colors.white};
        width: 1px;
        height: 20px;
        position: absolute;
        right: 0;
      }
    }

    &:nth-child(4) {
      margin-left: 8px;
    }
  `}
`;

interface NavDisplaySwitchProps {
  $isLightMode?: boolean;
}

export const NavDisplaySwitch = styled.button<NavDisplaySwitchProps>`
  display: flex;
  z-index: 1;
  font-weight: 900;
  transition: background-color 0.2s, color 0.2s;
  font-size: 24px;

  &.nav--is-open {
    z-index: 10;
  }

  ${({ $isLightMode, theme }) => $isLightMode && `
    svg {
      color: ${theme.colors.blue};

      rect {
        fill: currentColor;
      }
    }
  `}
`;
