/* eslint-disable react/display-name */
import { forwardRef, ButtonHTMLAttributes, useState } from 'react';

import classNames from 'classnames';

import * as S from './styled';

interface CheckboxProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isActive?: boolean;
  className?: string;
}

const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  (props: CheckboxProps, ref) => {
    const { isActive, className, children, ...rest } = props;

    return (
      <S.CheckboxWrapper
        className={classNames({
          [`${className}`]: className,
          'checkbox--is-active': isActive
        })}
        ref={ref}
        type="button"
        {...rest}
      >
        {children}
      </S.CheckboxWrapper>
    );
  }
);

Checkbox.defaultProps = {
  isActive: false,
  className: ''
};

export default Checkbox;
