import styled from 'styled-components';

export const LoadingOverlayWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 101;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  transition: transform 0.3s;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const LoadingOverlayContainer = styled.div`
  max-height: 200px;
  max-width: 200px;
  height: 100%;
  width: 100%;
`;
