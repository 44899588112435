import { ReactComponent as PlaylegisWhiteLogo } from 'assets/icons/playlegis-logo-white.svg';
import { footerMenu } from 'helpers/links';

import utils from '../../utils';
import * as S from './FullFooter.styles';

const FullFooter = () => {
  const { serviceMenu, legalMenu, downloadMenu, socialNetworksMenu } =
    footerMenu;
  const textLinks = [serviceMenu, legalMenu];

  return (
    <S.FullFooterWrapper>
      <S.Container>
        <S.Row className="footer__row-logo">
          <PlaylegisWhiteLogo />
        </S.Row>
        <S.Row className="footer__row-menu">
          {textLinks.map((menu) => (
            <S.NavMenu key={menu.title} className="footer__column-links">
              <h3>{menu.title}</h3>
              <nav>
                <ul>
                  {menu.links.map((link) => (
                    <li key={link.label}>
                      <S.Link to={link.to}>{link.label}</S.Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </S.NavMenu>
          ))}
          <S.NavMenu className="footer__column-links is--download">
            <h3>{downloadMenu.title}</h3>
            <S.Column>
              {downloadMenu.links.map((link) => (
                <a
                  key={link.label}
                  href={link.to}
                  title={link.label}
                  target="_blank"
                  rel="noreferrer"
                >
                  {utils.getIcon(link)}
                </a>
              ))}
            </S.Column>
          </S.NavMenu>
          <S.NavMenu className="footer__column-links is--social">
            <h3>{socialNetworksMenu.title}</h3>
            <S.Row>
              {socialNetworksMenu.links.map((link) => (
                <a
                  key={link.label}
                  href={link.to}
                  title={link.label}
                  target="_blank"
                  rel="noreferrer"
                >
                  {utils.getIcon(link)}
                </a>
              ))}
            </S.Row>
          </S.NavMenu>
        </S.Row>
        <S.Row className="footer__row-credits">
          <p>
            Copyright © 2021 PEP Cursos - Ensino Profissional e Preparatório
            para Concursos LTDA - CNPJ 18.003.427/0001-09. Todos os direitos
            reservados. | Design e Desenvolvimento:
            <a
              href="https://agencia14bis.com.br"
              target="_blank"
              rel="noreferrer"
            >
              14BIS Agência de Marketing
            </a>
            .
          </p>
        </S.Row>
      </S.Container>
    </S.FullFooterWrapper>
  );
};

export default FullFooter;
