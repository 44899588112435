import { footerMenu } from 'helpers/links';

import { iMiniFooterProps } from 'components/Footer/interfaces/iMiniFooterProps';
import { Desktop, Mobile } from 'components/Responsive';

import utils from '../../utils';
import * as S from './MiniFooter.styles';

const MiniFooter = (props: iMiniFooterProps) => {
  const { isUserAuthenticated } = props;

  const { miniFooterMenu, downloadMenu } = footerMenu;

  return (
    <S.MiniFooterWrapper>
      <S.MiniFooterContainer>
        <S.MiniFooterGeneral>
          <S.MiniFooterMenu>
            <nav>
              {miniFooterMenu(isUserAuthenticated).links.map((link) => (
                <S.Link to={link.to} key={link.label}>
                  {link.label}
                </S.Link>
              ))}
            </nav>
          </S.MiniFooterMenu>
          <Mobile>
            <S.MiniFooterStores>
              {downloadMenu.links.map((link) => (
                <a
                  key={link.label}
                  href={link.to}
                  title={link.label}
                  target="_blank"
                  rel="noreferrer"
                >
                  {utils.getIcon(link)}
                </a>
              ))}
            </S.MiniFooterStores>
          </Mobile>
          <p>
            Copyright © 2021 PEP Cursos - Ensino Profissional e Preparatório
            para Concursos LTDA - CNPJ 18.003.427/0001-09. Todos os direitos
            reservados. | Design e Desenvolvimento:
            <a
              href="https://agencia14bis.com.br"
              target="_blank"
              rel="noreferrer"
            >
              14BIS Agência de Marketing
            </a>
            .
          </p>
        </S.MiniFooterGeneral>
        <Desktop>
          <S.MiniFooterStores>
            {downloadMenu.links.map((link) => (
              <a
                key={link.label}
                href={link.to}
                title={link.label}
                target="_blank"
                rel="noreferrer"
              >
                {utils.getIcon(link)}
              </a>
            ))}
          </S.MiniFooterStores>
        </Desktop>
      </S.MiniFooterContainer>
    </S.MiniFooterWrapper>
  );
};

export default MiniFooter;
