import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';

import {
  openPlayer,
  setPlayerPlaylist,
  setPlayerCurrentAudio,
  setPlayerPaused
} from 'store/ducks/player';

import { ReactComponent as ArrowDown } from 'assets/icons/news/arrowDown.svg';
import { ReactComponent as PlaylistPause } from 'assets/icons/player/playlist-pause.svg';
import { ReactComponent as PlaylistPlay } from 'assets/icons/player/playlist-play.svg';
import { Audio, Playlist } from 'helpers/models';
import { playerSelector } from 'helpers/selectors';
import { formatSecondsAsTime, formatSecondsAsHourTime } from 'utils/timeFormat';

import { HTMLButton as Button } from 'components/Button';

import * as S from './styled';

interface AudioListProps {
  playlist: Playlist;
}

export const AudioList = ({ playlist }: AudioListProps) => {
  const dispatch = useDispatch();

  const {
    isPlayerOpen,
    playlist: currentPlaylist,
    currentAudioId,
    isPaused
  } = useSelector(playerSelector);

  const [audios, setAudios] = useState<Audio[]>([]);
  const [partials, setPartials] = useState<Audio[]>([]);
  const [audiosToShow, setAudiosToShow] = useState(20);
  const [playlistDuration, setPlaylistDuration] = useState(0);
  const [isNewPlaylist, setNewPlaylist] = useState(false);

  useEffect(() => {
    setAudios(playlist.audios);
  }, [playlist]);

  useEffect(() => {
    if (audios.length > 0) {
      setPartials(audios.slice(0, audiosToShow));

      const duration = audios
        .map((audio) => audio.duration)
        .reduce((prev, next) => prev + next);

      setPlaylistDuration(duration);
    }
  }, [audios]);

  useEffect(() => {
    setPartials(audios.slice(0, audiosToShow));
  }, [audiosToShow]);

  useEffect(() => {
    setNewPlaylist(currentPlaylist.id !== playlist.id);
  }, [currentPlaylist, playlist]);

  const handleAudioItemClick = (index: number) => {
    dispatch(setPlayerPlaylist(playlist));
    dispatch(setPlayerCurrentAudio(index));
    dispatch(openPlayer());
    dispatch(setPlayerPaused(false));
  };

  const handlePlayback = () => {
    if (!isPlayerOpen || isNewPlaylist) {
      handleAudioItemClick(0);
    } else {
      dispatch(setPlayerPaused(false));
    }
  };

  return (
    <S.AudioListWrapper>
      <S.PlaylistAction>
        {isPaused || isNewPlaylist ? (
          <button
            type="button"
            onClick={handlePlayback}
            className="button__playlist-play"
            disabled={!audios || !audios.length}
          >
            <PlaylistPlay />
          </button>
        ) : (
          <button
            type="button"
            onClick={() => dispatch(setPlayerPaused(true))}
            className="button__playlist-pause"
            disabled={!audios || !audios.length}
          >
            <PlaylistPause />
          </button>
        )}

        {formatSecondsAsHourTime(playlistDuration)}
      </S.PlaylistAction>

      <S.AudioList>
        {partials.map((audio, index) => (
          <S.AudioItem key={audio.id}>
            <Button
              onClick={() => handleAudioItemClick(index)}
              variant="link"
              className={classNames({
                'btn__link--is-selected':
                  isPlayerOpen && index === currentAudioId && !isNewPlaylist
              })}
            >
              <S.AudioName>{audio.title}</S.AudioName>
              <S.AudioDuration>
                {formatSecondsAsTime(audio.duration)}
              </S.AudioDuration>
            </Button>
          </S.AudioItem>
        ))}
      </S.AudioList>

      {audios.length > 20 && (
        <S.ShowMoreAudios onClick={() => setAudiosToShow(audiosToShow + 10)}>
          <ArrowDown />
        </S.ShowMoreAudios>
      )}
    </S.AudioListWrapper>
  );
};
