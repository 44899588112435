/* eslint-disable jsx-a11y/media-has-caption */
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  closePlayer,
  setPlayerCurrentAudio,
  setPlayerPaused,
  setPlayerPlaylist
} from 'store/ducks/player';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as BackwardIcon } from 'assets/icons/player/15s-backward.svg';
import { ReactComponent as ForwardIcon } from 'assets/icons/player/15s-forward.svg';
import { ReactComponent as BackIcon } from 'assets/icons/player/back.svg';
import { ReactComponent as NextIcon } from 'assets/icons/player/next.svg';
import { ReactComponent as PauseIcon } from 'assets/icons/player/pause.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/player/play.svg';
import { ReactComponent as ReplayIcon } from 'assets/icons/player/replay.svg';
import { playlistInitialState } from 'helpers/initialState';
import { playerSelector } from 'helpers/selectors';
import { formatSecondsAsTime } from 'utils/timeFormat';

import { PlayerTranscription } from './components/PlayerTranscription/PlayerTranscription';
import * as S from './Player.styled';
import { handlePlay } from './Player.utils';

export const Player = () => {
  const isIphone = !!navigator.userAgent.match(/iPhone/);

  const dispatch = useDispatch();
  const { isPlayerOpen, isPaused } = useSelector(playerSelector);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const tawkContainer = document.querySelector('body > div:last-child');

    if (tawkContainer) {
      const newZindex = isPlayerOpen
        ? 'display: block!important; z-index: 0!important'
        : 'display: block!important;';

      tawkContainer.setAttribute('style', newZindex);
    }

    setOpen(isPlayerOpen);
  }, [isPlayerOpen]);

  const {
    currentAudioId,
    currentAudio: { url: src, title, duration, transcription },
    playlist: { name: playlistName, audios }
  } = useSelector(playerSelector);

  const [playerOn, setPlayerOn] = useState(false);
  const [currentTime, setCurrentTime] = useState('00:00');
  const [endTime, setEndTime] = useState('00:00');
  const [audioReplay, setAudioReplay] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [source, setSource] = useState('');

  const audioRef = useRef<HTMLAudioElement>(document.createElement('audio'));
  const sliderRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    audioRef.current.autoplay = true;
    audioRef.current.load();
  }, []);

  useEffect(() => {
    const audio = audioRef.current;

    if (isOpen && audio && sliderRef.current && audio.paused) {
      handlePlay(audio);
      audio.loop = audioReplay;
      audio.playbackRate = playbackRate;
      setPlayerOn(true);
    }
  }, [isOpen, source]);

  useEffect(() => {
    const audio = audioRef.current;

    if (audio) {
      audio.loop = audioReplay;
    }
  }, [audioReplay]);

  const handlePlayback = () => {
    const audio = audioRef.current;

    if (playerOn) {
      audio?.pause();
    } else if (currentTime === '00:00') {
      audio?.load();
    } else {
      handlePlay(audio);
    }

    if (isPaused !== playerOn) {
      dispatch(setPlayerPaused(playerOn));
    }
    setPlayerOn(!playerOn);
  };

  useEffect(() => {
    if (isPaused === playerOn) {
      handlePlayback();
    }
  }, [isPaused, playerOn]);

  const handlePlayPauseIcon = () => (playerOn ? <PauseIcon /> : <PlayIcon />);

  const handleTimer = () => {
    if (!audioRef.current || Number.isNaN(audioRef.current.duration)) {
      setCurrentTime('00:00');
      setEndTime('00:00');
      return;
    }

    const audio = audioRef.current;
    const slider = sliderRef.current;

    if (audio) {
      if (slider && audio.duration !== +slider.value) {
        slider.value = String(audio.currentTime);
        const value =
          ((+slider.value - +slider.min) / (+slider.max - +slider.min)) * 100;
        slider.style.background = `linear-gradient(to right, #DBB200 0%, #DBB200 ${value}%, #7C8299 ${value}%, #7C8299 100%)`;
      }
    }

    setCurrentTime(formatSecondsAsTime(Math.floor(audio.currentTime)));
    setEndTime(formatSecondsAsTime(Math.floor(audio.duration)));
  };

  const handleSliderChange = (newValue: string) => {
    const audio = audioRef.current;

    if (audio) {
      audio.currentTime = +newValue;
    }
  };

  const handleCurrentAudio = (audioIndex: number) => {
    dispatch(setPlayerCurrentAudio(audioIndex));
  };

  const handleBackwardAdvance = () => {
    const audio = audioRef.current;

    if (audio) {
      audio.currentTime -= 15;
    }
  };

  const handleForwardAdvance = () => {
    const audio = audioRef.current;

    if (audio) {
      audio.currentTime += 15;
    }
  };

  const handlePlaybackRate = () => {
    const audio = audioRef.current;

    if (audio) {
      let newPlaybackRate = 1;

      if (playbackRate === 1) {
        newPlaybackRate = 1.2;
      }
      if (playbackRate === 1.2) {
        newPlaybackRate = 1.5;
      } else if (playbackRate === 1.5) {
        newPlaybackRate = 2;
      } else if (playbackRate === 2) {
        newPlaybackRate = 3;
      } else if (playbackRate === 3) {
        newPlaybackRate = 1;
      }

      setPlaybackRate(newPlaybackRate);
      audio.playbackRate = newPlaybackRate;
    }
  };

  const handleClose = () => {
    setPlayerOn(false);
    dispatch(setPlayerPlaylist(playlistInitialState));
    dispatch(setPlayerCurrentAudio(0));
    dispatch(closePlayer());
  };

  const handleCurrentEnded = () => {
    const audio = audioRef.current;

    if (audio && audio.ended && currentAudioId < audios.length - 1) {
      dispatch(setPlayerCurrentAudio(currentAudioId + 1));
    }
  };

  useEffect(() => {
    const audio = audioRef.current;
    const slider = sliderRef.current;

    if (audio) {
      audio.src = src;
      audio.ontimeupdate = handleTimer;
      audio.onended = handleCurrentEnded;
      setSource(src);

      if (isIphone && slider) {
        dispatch(setPlayerPaused(true));
        slider.value = '0';
        slider.style.background =
          'linear-gradient(to right, #DBB200 0%, #DBB200 0%, #7C8299 0%, #7C8299 100%)';
      }
    }
  }, [src]);

  return (
    <S.PlayerWrapper $isOpen={isOpen}>
      <PlayerTranscription
        isPlayerOpen={isOpen}
        transcription={transcription}
      />

      <S.PlayerContainer>
        <S.CloseButton
          variant="link"
          className="button__close"
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </S.CloseButton>

        <S.PlayerAudioMeta>
          <S.AudioTitle>{title}</S.AudioTitle>
          <S.PlaylistName>{playlistName}</S.PlaylistName>
          <S.AudioTimer>
            {currentTime}
            <S.Slider
              ref={sliderRef}
              type="range"
              name="points"
              onChange={(e) => handleSliderChange(e.target.value)}
              min="0"
              max={duration}
              className="slider"
            />
            {endTime}
          </S.AudioTimer>
        </S.PlayerAudioMeta>

        <S.Buttons>
          <S.ActionWithStatusButton
            variant="link"
            onClick={() => setAudioReplay(!audioReplay)}
            disabled={!src}
            $isActive={audioReplay}
          >
            <ReplayIcon />
          </S.ActionWithStatusButton>

          <S.PlayerActionButton
            variant="link"
            onClick={() => handleBackwardAdvance()}
            disabled={
              !src ||
              (audioRef.current ? audioRef.current.currentTime - 15 < 0 : false)
            }
            className="button--is-yellow"
          >
            <BackwardIcon />
          </S.PlayerActionButton>

          <S.PlayerActionButton
            variant="link"
            onClick={() => handleCurrentAudio(currentAudioId - 1)}
            disabled={!src || !(currentAudioId > 0)}
            className="button--is-yellow"
          >
            <BackIcon />
          </S.PlayerActionButton>

          <S.PlayerActionButton
            variant="link"
            onClick={() => handlePlayback()}
            disabled={!src}
            className="button--is-yellow"
          >
            {handlePlayPauseIcon()}
          </S.PlayerActionButton>

          <S.PlayerActionButton
            variant="link"
            onClick={() => handleCurrentAudio(currentAudioId + 1)}
            disabled={!src || currentAudioId === audios.length - 1}
            className="button--is-yellow"
          >
            <NextIcon />
          </S.PlayerActionButton>

          <S.PlayerActionButton
            variant="link"
            onClick={() => handleForwardAdvance()}
            disabled={
              !src ||
              !(
                audioRef.current &&
                audioRef.current.currentTime + 15 > audioRef.current.currentTime
              )
            }
            className="button--is-yellow"
          >
            <ForwardIcon />
          </S.PlayerActionButton>

          <S.PlayerActionButton
            variant="link"
            onClick={() => handlePlaybackRate()}
            disabled={!src}
            className="button--is-white"
          >
            {playbackRate}x
          </S.PlayerActionButton>
        </S.Buttons>
      </S.PlayerContainer>
    </S.PlayerWrapper>
  );
};
