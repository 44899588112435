import { Plan, Promotion } from 'helpers/models';

export enum PlanTypes {
  FETCH_PLANS_REQUEST = '@PLAN/FETCH_PLANS_REQUEST',
  FETCH_PLANS_SUCCESS = '@PLAN/FETCH_PLANS_SUCCESS',
  FETCH_PLANS_ERROR = '@PLAN/FETCH_PLANS_ERROR'
}

export interface PlanState {
  readonly id: number;
  readonly plans: Plan[];
  readonly promotions: Promotion[];
  readonly freeTrialPlan: Plan;
}

export type PlanAction = {
  type: PlanTypes;
  payload: {
    id: number;
    plans: Plan[];
    promotions: Promotion[];
    freeTrialPlan: Plan;
  };
};
