import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { setSelectedPlan } from 'store/ducks/account';

import wavesBackgroundMobile from 'assets/img/home/plans/waves-mobile.png';
import wavesBackground from 'assets/img/home/plans/waves.png';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from 'helpers/constants';
import { Plan } from 'helpers/models';
import { institutionalSelector } from 'helpers/selectors';
import useAuth from 'hooks/useAuth';
import useResponsive from 'hooks/useResponsive';

import { PlanViewer } from 'components';

import * as S from './styled';

const SubscriptionPlans = () => {
  const {
    home: { plans, promotions, freeTrialPlan }
  } = useSelector(institutionalSelector);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isMobile } = useResponsive();
  const { isUserAuthenticated } = useAuth();

  const backgroundUrl = useMemo(
    () => (isMobile ? wavesBackgroundMobile : wavesBackground),
    [isMobile]
  );

  const handlePlanSelection = (plan: Plan, promotionId: number | null) => {
    if (isUserAuthenticated) {
      navigate(PRIVATE_ROUTES.ACCOUNT);
    } else {
      dispatch(setSelectedPlan(plan, promotionId));
      navigate(PUBLIC_ROUTES.SIGNUP);
    }
  };

  return (
    <S.SubscriptionPlansWrapper backgroundUrl={backgroundUrl}>
      <S.Container>
        <S.PlansHeader>
          <h2>Planos de assinatura</h2>
          <h3>Experimente agora nossa assinatura</h3>
        </S.PlansHeader>

        <PlanViewer
          onPlanSelection={handlePlanSelection}
          plans={plans}
          promotions={promotions}
          trialPlan={freeTrialPlan}
          isHomePage
        />
      </S.Container>
    </S.SubscriptionPlansWrapper>
  );
};

export default SubscriptionPlans;
