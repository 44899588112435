import { CategoryState } from './types';

export const CATEGORY_INITIAL_STATE: CategoryState = {
  id: 0,
  one: {
    id: 0,
    label: '',
    name: '',
    description: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    superCategoryId: 0,
    playlists: [],
  },
  lastUpdates: {
    id: 0,
    label: '',
    name: '',
    description: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    superCategoryId: 0,
    playlists: [],
  },
  superCategory: {
    id: 0,
    name: '',
    categories: [],
  },
  loading: false,
}