import { NavigationProvider } from 'contexts/NavigationContext';

import ActivateAccountNavigation from './components/Navigation';

const ActivateAccountPage = () => (
  <NavigationProvider>
    <ActivateAccountNavigation />
  </NavigationProvider>
);

export default ActivateAccountPage;
