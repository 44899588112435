/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { fetchAccountRequest } from 'store/ducks/account';
import { setStudentRequestData } from 'store/ducks/student';
import { fetchInstitutionsRequest } from 'store/ducks/teachingInstitution';

import { ApplicationState } from 'helpers/types';
import useAuth from 'hooks/useAuth';

import { useNavigation } from 'contexts/NavigationContext';

import * as S from '../../styled';
import SendDocumentsConfirm from '../ConfirmForm/SendDocumentsConfirm';
import SendDocumentsForm from '../ConfirmForm/SendDocumentsForm';

const SendDocumentsNavigation = () => {
  const {
    state: { step }
  } = useNavigation();
  const { search } = useLocation();
  const [isWebview, setIsWebview] = useState(false);

  const { account, isAccountFetched } = useSelector(
    (state: ApplicationState) => state.account
  );

  const dispatch = useDispatch();
  const { token, userId } = useAuth();

  useEffect(() => {
    dispatch(fetchInstitutionsRequest());

    setIsWebview(search.includes('isWebview'));
  }, []);

  useEffect(() => {
    if (!isAccountFetched) {
      dispatch(fetchAccountRequest());
    }
  }, [isAccountFetched]);

  useEffect(() => {
    if (account.subscription) {
      dispatch(
        setStudentRequestData(account.subscription.studentRequestId, token)
      );
    }
  }, [account, token, userId]);

  const handleNextOnWebview = () => {
    const { ReactNativeWebView }: any = { ...window };

    const msg = JSON.stringify({
      email: account.email,
      password: account.password
    });

    ReactNativeWebView.postMessage(msg);
  };

  return (
    <S.SendDocumentsWrapper>
      {step === 1 && (
        <SendDocumentsForm
          isWebview={isWebview}
          handleNextOnWebview={handleNextOnWebview}
        />
      )}
      {step === 2 && (
        <SendDocumentsConfirm
          isWebview={isWebview}
          handleNextOnWebview={handleNextOnWebview}
        />
      )}
    </S.SendDocumentsWrapper>
  );
};

export default SendDocumentsNavigation;
