import { Fragment } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

import { removePopUpItem } from 'store/ducks/popUp';

import { ApplicationState, PopUpItem } from 'helpers/types';

import Link from 'components/Button/ButtonLink';
import Button from 'components/Button/HTMLButton';

import * as S from './PopUp.styles';

export const PopUp = () => {
  const { items } = useSelector((state: ApplicationState) => state.popUp);

  const dispatch = useDispatch();

  const getItemButton = (item: PopUpItem) => {
    if (!item.button) {
      return;
    }

    const { button } = item;

    const handleClose = () => {
      if (button.onClick) {
        button.onClick();
      }

      if (item.shouldClose) {
        dispatch(removePopUpItem(item.id));
      }
    };

    if (button.linkTo) {
      return (
        <Link
          to={button.linkTo}
          onClick={handleClose}
          variant={button.variant}
          className="popup__action-button"
        >
          {button.label}
        </Link>
      );
    }

    return (
      <Button
        onClick={handleClose}
        variant={button.variant}
        className="popup__action-button"
      >
        {button.label}
      </Button>
    );
  };

  const handleCloseButtonClick = (itemId: string) => {
    dispatch(removePopUpItem(itemId));
  };

  return items.length ? (
    <S.PopUpContainer>
      {items.map((item) => (
        <S.PopUpItem key={item.id} className="popup__item">
          <S.PopUpItemCloseButton
            type="button"
            onClick={() => handleCloseButtonClick(item.id)}
          >
            <FaTimes />
          </S.PopUpItemCloseButton>
          <S.PopUpItemDescription>{item.description}</S.PopUpItemDescription>

          {getItemButton(item)}
        </S.PopUpItem>
      ))}
    </S.PopUpContainer>
  ) : (
    <Fragment />
  );
};
