import { FormEvent, ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { ValidationError } from 'yup';

import { validateAccountRequest } from 'store/ducks/account';

import { PUBLIC_ROUTES } from 'helpers/constants';
import {
  passwordChecklistRulesInitialState,
  userDataInputErrorInitialState
} from 'helpers/initialState';
import { iAccount } from 'helpers/interfaces/iAccount';
import { accountSelector } from 'helpers/selectors';
import { PasswordChecklistRules, UserDataInputError } from 'helpers/types';
import { accountValidation } from 'helpers/validation/account';
import { useWebviewChecker } from 'hooks/useWebviewChecker';

import { ButtonLink as Link, HTMLButton as Button } from 'components/Button';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import InputMask from 'components/InputMask';
import PasswordChecklist from 'components/PasswordChecklist';
import SignHeader from 'components/SignHeader';
import {
  useNavigation,
  NavigationActionTypes
} from 'contexts/NavigationContext';

import * as GS from '../../styled';
import * as S from './styled';

const StepTwo = () => {
  const {
    account: acc,
    isLoading,
    hasError,
    hadSucceed,
    errorCode,
    errorMessage
  } = useSelector(accountSelector);

  const [account, setAccount] = useState<iAccount>(acc);
  const [passwordAgain, setPasswordAgain] = useState('');

  const [hasAcceptedUserTerms, setHasAcceptedUserTerms] = useState(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [disabledInputs, setDisabledInputs] = useState(true);

  const [passwordValidation, setPasswordValidation] =
    useState<PasswordChecklistRules>(passwordChecklistRulesInitialState);
  const [errors, setErrors] = useState<UserDataInputError>(
    userDataInputErrorInitialState
  );

  const dispatchRedux = useDispatch();
  const { dispatch: dispatchNavigation } = useNavigation();
  const { onWebview } = useWebviewChecker();

  useEffect(() => {
    setNextButtonDisabled(!hasAcceptedUserTerms);
  }, [hasAcceptedUserTerms]);

  useEffect(() => {
    if (hasError) {
      toast.error(errorMessage, { autoClose: false });

      if (errorCode === 409) {
        setErrors({ ...errors, existingUser: true });
      }
    } else if (hadSucceed) {
      dispatchNavigation({ type: NavigationActionTypes.INCREMENT });
    }
  }, [hasError, hadSucceed]);

  const handleUserDataSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    toast.dismiss();

    let errs: UserDataInputError = userDataInputErrorInitialState;

    try {
      accountValidation(account, passwordAgain, passwordValidation);

      dispatchRedux(validateAccountRequest(account));
    } catch (error) {
      if (error instanceof ValidationError) {
        error.inner.forEach((e) => {
          if (e.path) {
            errs = {
              ...errs,
              [`${e.path}`]: true
            };
          }

          toast.error(e.message, { autoClose: false });
        });
      }
    } finally {
      setErrors(errs);
    }
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setAccount({ ...account, password: e.target.value });
  };

  const handleChangePasswordAgain = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswordAgain(e.target.value);
  };

  useEffect(() => {
    setDisabledInputs(isLoading);
  }, [isLoading]);

  return (
    <GS.SignUpContainer className="container__form container__form--is-account-data">
      <S.SignUpUserData>
        <SignHeader title="Assinatura Playlegis" subtitle="Criar conta" />

        <GS.SignUpForm onSubmit={handleUserDataSubmit}>
          <Input
            type="text"
            id="name"
            name="name"
            placeholder="Nome completo"
            className="signup__input signup__input--is-name"
            value={account.name}
            onChange={(e) =>
              setAccount({
                ...account,
                name: e.target.value
              })
            }
            hasShadow
            disabled={disabledInputs}
            hasError={errors.name}
          />

          <Input
            type="text"
            id="email"
            name="email"
            placeholder="E-mail"
            className="signup__input signup__input--is-email"
            value={account.email}
            onChange={(e) =>
              setAccount({
                ...account,
                email: e.target.value
              })
            }
            hasShadow
            disabled={disabledInputs}
            hasError={errors.email || errors.existingUser}
          />

          <InputMask
            type="text"
            id="cpf"
            name="cpf"
            placeholder="CPF"
            className="signup__input signup__input--is-cpf"
            value={account.cpf}
            onChange={(e) =>
              setAccount({
                ...account,
                cpf: e.target.value.replace(/(\.|-)*/g, '')
              })
            }
            hasShadow
            mask="999.999.999-99"
            disabled={disabledInputs}
            hasError={errors.cpf || errors.existingUser}
          />

          <PasswordChecklist
            value={account.password!}
            onChange={handleChangePassword}
            valueAgain={passwordAgain}
            onChangeAgain={handleChangePasswordAgain}
            onChecklistChange={setPasswordValidation}
            disabled={disabledInputs}
            hasError={{
              password: errors.password || errors.passwordsDontMatch,
              passwordAgain: errors.passwordAgain || errors.passwordsDontMatch,
              passwordValidation: errors.passwordValidation
            }}
          />

          <Checkbox
            className="signup__checkbox checkbox"
            onClick={() => setHasAcceptedUserTerms(!hasAcceptedUserTerms)}
            isActive={hasAcceptedUserTerms}
          >
            <span className="checkbox__label">
              Eu aceito os{' '}
              {onWebview ? (
                ' Termos e Políticas de Privacidade '
              ) : (
                <Link to={PUBLIC_ROUTES.PRIVACY_POLICY} variant="link">
                  Termos e Políticas de Privacidade
                </Link>
              )}
              da Playlegis.
            </span>
          </Checkbox>

          <Button type="submit" variant="yellow" disabled={nextButtonDisabled}>
            Prosseguir
          </Button>
        </GS.SignUpForm>
      </S.SignUpUserData>

      {!onWebview && (
        <S.SignUpLoginLink>
          Já possui uma conta?{' '}
          <Link to={PUBLIC_ROUTES.LOGIN} variant="link">
            Faça Login
          </Link>
          .
        </S.SignUpLoginLink>
      )}
    </GS.SignUpContainer>
  );
};

export default StepTwo;
