import { StudentState } from './types';

export const STUDENT_INITIAL_STATE: StudentState = {
  files: [],
  teachingInstitutionId: 0,
  loading: false,
  isError: false,
  hadSucceed: false,
  errorMessage: '',
  errorCode: 0,
  studentRequestId: 0
};
