
import { ReactComponent as ApplePayIcon } from 'assets/icons/appStore.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg';
import { ReactComponent as GooglePlayIcon } from 'assets/icons/googlePlay.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram.svg';
import { ReactComponent as YoutubeIcon } from 'assets/icons/youtube.svg';

import { PRIVATE_ROUTES, PUBLIC_ROUTES, SOCIAL_NETWORKS, STORE_LINKS } from './constants';
import { Link, Menu } from './types';

export const headerMenu: Link[] = [
  { to: PUBLIC_ROUTES.HOME, label: 'Início' },
  { to: PUBLIC_ROUTES.AUDIOS, label: 'Áudios' },
  { to: PUBLIC_ROUTES.NEWS, label: 'Notícias' },
  { to: PUBLIC_ROUTES.SIGNUP, label: 'Cadastro' },
  { to: PUBLIC_ROUTES.LOGIN, label: 'Login', isButton: true },
];

export const authHeaderMenu: Link[] = [
  { to: PUBLIC_ROUTES.HOME, label: 'Início' },
  { to: PUBLIC_ROUTES.AUDIOS, label: 'Áudios' },
  { to: PUBLIC_ROUTES.NEWS, label: 'Notícias' },
];

export const authHeaderPopUpMenu: Link[] = [
  { to: PRIVATE_ROUTES.ACCOUNT, label: 'Minha Conta' },
  { to: PUBLIC_ROUTES.LOGOUT, label: 'Logout' },
];

export const serviceMenu: Menu = {
  title: 'Serviço',
  links: [
    { to: PUBLIC_ROUTES.CONTACT, label: 'Contato' },
    { to: PUBLIC_ROUTES.ABOUT, label: 'Sobre' },
    { to: PUBLIC_ROUTES.FAQ, label: 'Perguntas Frequentes' },
  ],
};

export const legalMenu: Menu = {
  title: 'Legal',
  links: [
    { to: PUBLIC_ROUTES.TERMS_OF_USE, label: 'Termos de uso' },
    { to: PUBLIC_ROUTES.PRIVACY_POLICY, label: 'Políticas de Privacidade' },
    { to: PUBLIC_ROUTES.COOKIES_POLICY, label: 'Política de Cookies' },
  ],
};

export const downloadMenu: Menu = {
  title: 'Download',
  links: [
    { to: STORE_LINKS.APP_STORE, label: 'App Store', icon: <ApplePayIcon /> },
    { to: STORE_LINKS.GOOGLE_PLAY, label: 'Google Play', icon: <GooglePlayIcon /> },
  ],
};

export const socialNetworksMenu: Menu = {
  title: 'Redes sociais',
  links: [
    { to: SOCIAL_NETWORKS.FACEBOOK, label: 'Facebook', icon: <FacebookIcon /> },
    { to: SOCIAL_NETWORKS.INSTAGRAM, label: 'Instagram', icon: <InstagramIcon /> },
    { to: SOCIAL_NETWORKS.YOUTUBE, label: 'Youtube', icon: <YoutubeIcon /> },
  ],
};

export const miniFooterMenu = (userLoggedIn: boolean): Menu => {
  const userRelatedLinks = userLoggedIn ? [
    { to: PRIVATE_ROUTES.ACCOUNT, label: 'Minha conta' },
    { to: PUBLIC_ROUTES.LOGOUT, label: 'Sair' },
  ] : [
    { to: PUBLIC_ROUTES.SIGNUP, label: 'Cadastro' },
    { to: PUBLIC_ROUTES.LOGIN, label: 'Login' },
  ];

  return {
    title: 'Menu',
    links: [
      { to: PUBLIC_ROUTES.TERMS_OF_USE, label: 'Termos de uso' },
      { to: PUBLIC_ROUTES.FAQ, label: 'Perguntas Frequentes' },
      ...userRelatedLinks,
    ],
  };
}

export const miniFooterMenuLoggedIn: Menu = {
  title: 'Menu',
  links: [
    { to: PUBLIC_ROUTES.TERMS_OF_USE, label: 'Termos de uso' },
    { to: PUBLIC_ROUTES.FAQ, label: 'Perguntas Frequentes' },
    { to: PUBLIC_ROUTES.SIGNUP, label: 'Cadastro' },
    { to: PUBLIC_ROUTES.LOGIN, label: 'Login' },
  ],
}

export const footerMenu = {
  serviceMenu,
  legalMenu,
  downloadMenu,
  socialNetworksMenu,
  miniFooterMenu,
};
