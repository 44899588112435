import { Category, SuperCategory } from 'helpers/models';

export enum CategoryTypes {
  FETCH_SUPER_CATEGORY_REQUEST = '@CATEGORY/FETCH_SUPER_CATEGORY_REQUEST',
  FETCH_SUPER_CATEGORY_SUCCESS = '@CATEGORY/FETCH_SUPER_CATEGORY_SUCCESS',
  FETCH_SUPER_CATEGORY_ERROR = '@CATEGORY/FETCH_SUPER_CATEGORY_ERROR',
  
  FETCH_SUPER_CATEGORY_LAST_UPDATES_REQUEST = '@CATEGORY/FETCH_SUPER_CATEGORY_LAST_UPDATES_REQUEST',
  FETCH_SUPER_CATEGORY_LAST_UPDATES_SUCCESS = '@CATEGORY/FETCH_SUPER_CATEGORY_LAST_UPDATES_SUCCESS',
  FETCH_SUPER_CATEGORY_LAST_UPDATES_ERROR = '@CATEGORY/FETCH_SUPER_CATEGORY_LAST_UPDATES_ERROR',
  
  TOGGLE_CATEGORY_LOADING_STATUS = '@CATEGORY/TOGGLE_CATEGORY_LOADING_STATUS',
}

export interface CategoryState {
  readonly id: number;
  readonly superCategory: SuperCategory;
  readonly lastUpdates: Category;
  readonly one: Category;
  readonly loading: boolean;
}

export type CategoryAction = {
  type: CategoryTypes;
  payload: {
    id: number;
    superCategory: SuperCategory;
    lastUpdates: Category;
    one: Category;
    loading: boolean;
  }
}