import styled from 'styled-components';

import {
  AudioList,
  AudioListWrapper,
  AudioItem,
  AudioName,
  AudioDuration,
} from 'containers/AudioList/styled';

export const SearchWrapper = styled.div`
  ${({ theme }) => theme.bp.Fluid};
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  padding-bottom: 100px;
`;

export const SearchContainer = styled.div`
  ${({ theme }) => theme.bp.Large};
`;

export const SearchResultType = styled.h3`
  margin-bottom: 24px;
`;

export const SearchPlaylistsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const SearchPlaylistsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
`;

export const SearchAudiosWrapper = styled(AudioListWrapper)``;

export const SearchAudiosList = styled(AudioList)``;
export const SearchAudioItem = styled(AudioItem)``;
export const SearchAudioName = styled(AudioName)``;
export const SearchAudioDuration = styled(AudioDuration)``;

export const NoResultsFound = styled.div``;