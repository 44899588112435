import { forwardRef, InputHTMLAttributes } from 'react';
import ReactInputMask from 'react-input-mask';

import classNames from 'classnames';

import * as S from './styled';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  mask: string | (string | RegExp)[];
  hasShadow?: boolean;
  icon?: string;
  hasError?: boolean;
  alwaysShowMask?: boolean;
  maskPlaceholder?: string | null;
}

const InputMask = forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref) => {
    const {
      hasShadow,
      className,
      icon,
      mask,
      value,
      onChange,
      hasError,
      alwaysShowMask,
      maskPlaceholder,
      ...rest
    } = props;

    return (
      <ReactInputMask
        mask={mask}
        value={value}
        onChange={onChange}
        alwaysShowMask={alwaysShowMask}
        maskPlaceholder={maskPlaceholder}
      >
        <S.InputWrapper
          className={classNames({
            [`${className}`]: className
          })}
        >
          <S.Input
            className={classNames({
              'input--has-shadow': hasShadow,
              'input--has-error': hasError
            })}
            ref={ref}
            $iconUrl={icon}
            {...rest}
          />
        </S.InputWrapper>
      </ReactInputMask>
    );
  }
);

InputMask.defaultProps = {
  hasShadow: false,
  icon: '',
  hasError: false,
  alwaysShowMask: false,
  maskPlaceholder: '_'
};

export default InputMask;
