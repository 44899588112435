
import styled, { css } from 'styled-components';

import {
  SlideInDownKeyframe,
  SlideInLeftKeyframe,
  SlideInRightKeyframe,
  SlideInUpKeyframe,
  SlideOutDownKeyframe,
  SlideOutLeftKeyframe,
  SlideOutRightKeyframe,
  SlideOutUpKeyframe
} from './keyframes';

const enterAnimationDuration = '0.3s';
const exitAnimationDuration = '0.5s';

const ToastCustomFlipAnimation = css`
  .audiolegis-transition-enter--top-left,
  .audiolegis-transition-enter--bottom-left {
    animation-name: ${SlideInLeftKeyframe};
    animation-duration: ${enterAnimationDuration};
  }

  .audiolegis-transition-enter--top-right,
  .audiolegis-transition-enter--bottom-right  {
    animation-name: ${SlideInRightKeyframe};
    animation-duration: ${enterAnimationDuration};
  }

  .audiolegis-transition-enter--top-center  {
    animation-name: ${SlideInDownKeyframe};
    animation-duration: ${enterAnimationDuration};
  }

  .audiolegis-transition-enter--bottom-center  {
    animation-name: ${SlideInUpKeyframe};
    animation-duration: ${enterAnimationDuration};
  }

  .audiolegis-transition-exit--top-left,
  .audiolegis-transition-exit--bottom-left {
    animation-name: ${SlideOutLeftKeyframe};
    animation-duration: ${exitAnimationDuration};
  }

  .audiolegis-transition-exit--top-right,
  .audiolegis-transition-exit--bottom-right  {
    animation-name: ${SlideOutRightKeyframe};
    animation-duration: ${exitAnimationDuration};
  }

  .audiolegis-transition-exit--top-center  {
    animation-name: ${SlideOutDownKeyframe};
    animation-duration: ${exitAnimationDuration};
  }

  .audiolegis-transition-exit--bottom-center  {
    animation-name: ${SlideOutUpKeyframe};
    animation-duration: ${exitAnimationDuration};
  }
`;

export const ToastWrapper = styled.div`
  .Toastify__toast {
    /* padding: 0px 16px;
    box-shadow: 0 0 10px ${({ theme }) => theme.colors.blueDark};

    @media (min-width: 768px) {
      border-radius: 10px;
    } */

    &-body {
      font-size: 14px;
      font-family: ${({ theme }) => theme.font.primary};
    }

    &--info {
      background-color: ${({ theme }) => theme.colors.blueLight};
    }


    .Toastify__progress-bar {
      &--default {
        background: ${({ theme }) => theme.colors.blueLight};
      }
      &--info {
        background: ${({ theme }) => theme.colors.whiteDarker};
      }
      &--success {
        background: ${({ theme }) => theme.colors.whiteDarker};
      }
      &--warning {
        background: ${({ theme }) => theme.colors.whiteDarker};
      }
      &--error {
        background: ${({ theme }) => theme.colors.whiteDarker};
      }
      &--dark {
        background: ${({ theme }) => theme.colors.whiteDarker};
      }
    }
  }

  ${ToastCustomFlipAnimation}
`;
