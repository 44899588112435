import { ReactNode } from 'react';

import { Action } from 'redux';
import { action } from 'typesafe-actions';

import { ItemButtonProps } from 'helpers/types/ItemButtonProps';

import { PopUpTypes } from './types';

export const addPopUpItem = (
  itemTitle: string,
  itemDescription: ReactNode,
  itemShouldClose: boolean,
  itemButton?: ItemButtonProps
): Action =>
  action(PopUpTypes.ADD_POP_UP_ITEM, {
    itemTitle,
    itemDescription,
    itemShouldClose,
    itemButton
  });

export const removePopUpItem = (popUpItemId: string): Action =>
  action(PopUpTypes.REMOVE_POP_UP_ITEM, { popUpItemId });

export const removeAllPopUpItems = (): Action =>
  action(PopUpTypes.REMOVE_ALL_POP_UP_ITEMS);
