import { ApplicationState } from './types';

export const overlaySelector = (state: ApplicationState) => state.overlay;

export const loadingSelector = (state: ApplicationState) => state.loading;

export const loginSelector = (state: ApplicationState) => state.login;

export const toastSelector = (state: ApplicationState) => state.toastify;

export const institutionalSelector = (state: ApplicationState) =>
  state.institutional;

export const categorySelector = (state: ApplicationState) => state.category;

export const filterSelector = (state: ApplicationState) => state.filter;

export const playlistSelector = (state: ApplicationState) => state.playlist;

export const audioSelector = (state: ApplicationState) => state.audio;

export const playerSelector = (state: ApplicationState) => state.player;

export const accountSelector = (state: ApplicationState) => state.account;

export const planSelector = (state: ApplicationState) => state.plan;

export const searchSelector = (state: ApplicationState) => state.search;

export const studentSelector = (state: ApplicationState) => state.student;

export const teachingInstitutionSelector = (state: ApplicationState) =>
  state.teachingInstitution;

export const recoverPasswordSelector = (state: ApplicationState) =>
  state.recoverPassword;
