import * as yup from 'yup';

import { SubscriptionUserData } from '../models';

const subscriptionUserDataSchema = yup.object().shape({
  phone: yup
    .string()
    .required('O campo Telefone é obrigatório')
    .min(
      10,
      'O telefone deve ser composto por 2 dígitos do código de área, e entre 8 e 9 dígitos para o número'
    ),
  address: yup.string().required('O campo Endereço é obrigatório'),
  city: yup.string().required('O campo Cidade é obrigatório'),
  state: yup.string().required('O campo Estado é obrigatório'),
  country: yup.string().required('O campo País é obrigatório')
});

export const subscriptionUserDataValidation = (
  subscriptionUserData: SubscriptionUserData
) =>
  subscriptionUserDataSchema.validateSync(subscriptionUserData, {
    abortEarly: false
  });
