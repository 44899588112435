import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SwiperCore, { Navigation, Scrollbar, A11y, Autoplay } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

import { setCategoryFilter } from 'store/ducks/filter';

import { ReactComponent as ArrowDown } from 'assets/icons/news/arrowDown.svg';
import { Category } from 'helpers/models';
import { ApplicationState } from 'helpers/types';
import useResponsive from 'hooks/useResponsive';

import { HTMLButton as Button } from 'components/Button';

import CategoryItem from '../SuperCategoryItem';
import * as S from './styled';

SwiperCore.use([Navigation, Scrollbar, A11y, Autoplay]);

const PlaylistCategories = () => {
  const { id: stateSuperCategoryId, categories: stateCategories } = useSelector(
    (state: ApplicationState) => state.category.superCategory
  );

  const { lastUpdates: stateLastUpdates } = useSelector(
    (state: ApplicationState) => state.category
  );

  const { isMobile } = useResponsive();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState<Category[]>(stateCategories);
  const [superCategoryId, setSuperCategoryId] = useState(stateSuperCategoryId);
  const [categoriesToShow, setCategoriesToShow] = useState(7);
  const [partials, setPartials] = useState<Category[]>([]);

  useEffect(() => {
    setSuperCategoryId(stateSuperCategoryId);
  }, [stateSuperCategoryId]);

  // @TODO: analisar esse monstrinho
  useEffect(() => {
    if (categoriesToShow > 7) {
      setCategoriesToShow(7);
    }

    if (partials.length) {
      setPartials([]);
    }

    if (superCategoryId !== stateSuperCategoryId) {
      setCategories(stateCategories);
    }
  }, [stateCategories]);

  useEffect(() => {
    if (partials.length !== categoriesToShow) {
      setPartials(categories.slice(0, categoriesToShow));
    }
  }, [categories, categoriesToShow]);

  const hasSeeMore = (length: number) => length > (isMobile ? 1 : 4);

  const getLastUpdates = () => {
    if (stateLastUpdates.playlists.length) {
      return (
        <S.CategoryContainer key="UltimasAtualizações">
          <S.CategoryHeader>
            <h3>Últimas atualizações</h3>
          </S.CategoryHeader>

          <CategoryItem category={stateLastUpdates} />
        </S.CategoryContainer>
      );
    }
  };

  return (
    <S.CategoriesWrapper>
      {getLastUpdates()}

      {partials.map((category) => (
        <S.CategoryContainer key={category.id}>
          <S.CategoryHeader>
            <h3>{category.name}</h3>
            {hasSeeMore(category.playlists.length) && (
              <Button
                onClick={() => dispatch(setCategoryFilter(category))}
                variant="link"
              >
                Ver todos
              </Button>
            )}
          </S.CategoryHeader>

          <CategoryItem category={category} />
        </S.CategoryContainer>
      ))}

      {categories.length > 7 && categoriesToShow <= categories.length && (
        <S.ShowMore onClick={() => setCategoriesToShow(categoriesToShow + 5)}>
          Ver mais
          <ArrowDown />
        </S.ShowMore>
      )}
    </S.CategoriesWrapper>
  );
};

export default PlaylistCategories;
