import { Home, AboutUs, Terms, FAQ, NewsArticle } from 'helpers/models';

export enum InstitutionalTypes {
  HOME_DATA_REQUEST = '@INSTITUTIONAL/HOME_DATA_REQUEST',
  HOME_DATA_SUCCESS = '@INSTITUTIONAL/HOME_DATA_SUCCESS',
  HOME_DATA_ERROR = '@INSTITUTIONAL/HOME_DATA_ERROR',
  HOME_LOADING = '@INSTITUTIONAL/HOME_LOADING',

  ABOUT_US_DATA_REQUEST = '@INSTITUTIONAL/ABOUT_US_DATA_REQUEST',
  ABOUT_US_DATA_SUCCESS = '@INSTITUTIONAL/ABOUT_US_DATA_SUCCESS',
  ABOUT_US_DATA_ERROR = '@INSTITUTIONAL/ABOUT_US_DATA_ERROR',

  TERMS_DATA_REQUEST = '@INSTITUTIONAL/TERMS_DATA_REQUEST',
  TERMS_DATA_SUCCESS = '@INSTITUTIONAL/TERMS_DATA_SUCCESS',
  TERMS_DATA_ERROR = '@INSTITUTIONAL/TERMS_DATA_ERROR',

  FAQ_DATA_REQUEST = '@INSTITUTIONAL/FAQ_DATA_REQUEST',
  FAQ_DATA_SUCCESS = '@INSTITUTIONAL/FAQ_DATA_SUCCESS',
  FAQ_DATA_ERROR = '@INSTITUTIONAL/FAQ_DATA_ERROR',

  NEWS_DATA_REQUEST = '@INSTITUTIONAL/NEWS_DATA_REQUEST',
  NEWS_DATA_SUCCESS = '@INSTITUTIONAL/NEWS_DATA_SUCCESS',
  NEWS_DATA_ERROR = '@INSTITUTIONAL/NEWS_DATA_ERROR',

  PLANS_DATA_REQUEST = '@INSTITUTIONAL/PLANS_DATA_REQUEST',
  PLANS_DATA_SUCCESS = '@INSTITUTIONAL/PLANS_DATA_SUCCESS',
  PLANS_DATA_ERROR = '@INSTITUTIONAL/PLANS_DATA_ERROR'
}

export interface InstitutionalState {
  readonly home: Home;
  readonly isHomeLoading: boolean;
  readonly aboutUs: AboutUs;
  readonly terms: Terms;
  readonly faq: FAQ[];
  readonly news: NewsArticle[];
}

export type InstitutionalAction = {
  type: InstitutionalTypes;
  payload: {
    home: Home;
    isHomeLoading: boolean;
    aboutUs: AboutUs;
    terms: Terms;
    faq: FAQ[];
    news: NewsArticle[];
  };
};
