import styled from 'styled-components';

export { AccountFormHeader } from '../../styled';

export const AvatarFormWrapper = styled.div`
  margin-bottom: 50px;
`;

export const AvatarForm = styled.div`
  display: flex;

  ${({ theme }) => theme.mq.lessThan('xs')`
    flex-direction: column;
  `}
`;

export const AvatarPreview = styled.div<{ $src: string }>`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin-right: 50px;
  background-image: url(${({ $src }) => $src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.colors.grey};

  ${({ theme }) => theme.mq.lessThan('xs')`
    margin-bottom: 24px;
  `}
`;

export const AccountAvatarActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .btn__link {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.white};
    transition: color 0.3s;

    svg {
      width: 22px;
      margin-right: 14px;

      path {
        transition: fill 0.3s;
      }
    }

    &:disabled {
      background-color: transparent;
      color: ${({ theme }) => theme.colors.greyLight};
      cursor: default;

      &:hover {
        color: ${({ theme }) => theme.colors.greyLight};
        text-decoration: none;

        svg {
          path {
            fill: ${({ theme }) => theme.colors.greyLight};
          }
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.yellow};

      svg {
        path {
          fill: ${({ theme }) => theme.colors.yellow};
        }
      }
    }
  }
`;

export const AvatarInputWrapper = styled.label`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  transition: color 0.3s;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.yellow};

    svg {
      path {
        fill: ${({ theme }) => theme.colors.yellow};
      }
    }
  }

  input {
    display: none;
  }

  svg {
    width: 22px;
    margin-right: 14px;

    path {
      transition: fill 0.3s;
    }
  }
`;