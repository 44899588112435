import { LoginResponse, LoginInfo } from 'helpers/types/Login';
import { LoginErrorData } from 'helpers/types/LoginErrorData';

export enum LoginTypes {
  LOGIN_REQUEST = '@login/LOGIN_REQUEST',
  LOGIN_SUCCESS = '@login/LOGIN_SUCCESS',
  LOGIN_ERROR = '@login/LOGIN_ERROR',

  LOGOUT_REQUEST = '@login/LOGOUT_REQUEST',
  LOGOUT_SUCCESS = '@login/LOGOUT_SUCCESS',
  LOGOUT_ERROR = '@login/LOGOUT_ERROR'
}

export interface LoginState {
  readonly input: LoginInfo;
  readonly response: LoginResponse;
  readonly loginError: boolean;
  readonly loginErrorData?: LoginErrorData;
  readonly logoutError: boolean;
  readonly logoutSuccess: boolean;
  readonly loading: boolean;
}
