import styled from 'styled-components';

export { AccountFormHeader } from '../../styled';

export const MySubscriptionWrapper = styled.div``;

export const AccountSubscription = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};
`;

export const PlanValue = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

export const PlanTestTime = styled.span`
  font-size: 16px;
  font-weight: 300;
`;

export const CancelSubscription = styled.span`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

export const CancelSubscriptionMessage = styled.span`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 30px;
  color: ${({ theme }) => theme.colors.white};
`;

export const CancelSubscriptionActions = styled.div`
  display: flex;
  justify-content: space-between;

  button.btn__line {
    flex-grow: 1;
    margin-right: 40px;
  }

  a.btn__yellow {
    flex-grow: 1;
  }
`;