import { Reducer } from 'redux';

import { ToastType } from 'helpers/types/Toast';

import { ToastifyTypes, ToastifyState } from './types';

export type Action = {
  type: ToastifyTypes;
  payload: {
    title: string;
    message: string;
    type: ToastType;
    visible: boolean;
  }
};

const INITIAL_STATE: ToastifyState = {
  title: '',
  message: '',
  type: 'none',
  visible: false,
};

const reducer: Reducer<ToastifyState, Action> = (
  state: ToastifyState = INITIAL_STATE,
  action: Action,
) => {
  switch (action.type) {
    case ToastifyTypes.SHOW_TOAST: {
      return {
        ...state,
        title: action.payload.title,
        message: action.payload.message,
        type: action.payload.type,
        visible: action.payload.visible,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;