import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedPlan } from 'store/ducks/account';
import { fetchPlansRequest } from 'store/ducks/plan';

import backgroundUrl from 'assets/img/signup/background.png';
import { Plan } from 'helpers/models';
import { planSelector, accountSelector } from 'helpers/selectors';

import { PlanViewer } from 'components';
import SignHeader from 'components/SignHeader';
import {
  useNavigation,
  NavigationActionTypes
} from 'contexts/NavigationContext';

import * as S from './styles';

const PlanSelection = () => {
  const { plans, promotions } = useSelector(planSelector);
  const { selectedPlan } = useSelector(accountSelector);

  const dispatchRedux = useDispatch();
  const { dispatch: dispatchNavigation } = useNavigation();

  useEffect(() => {
    if (selectedPlan.id) {
      dispatchNavigation({ type: NavigationActionTypes.RESET });
      dispatchNavigation({ type: NavigationActionTypes.INCREMENT });
    } else {
      dispatchRedux(fetchPlansRequest());
    }
  }, []);

  const handlePlanSelection = (plan: Plan, promotionId: number | null) => {
    dispatchRedux(setSelectedPlan(plan, promotionId));
    dispatchNavigation({ type: NavigationActionTypes.INCREMENT });
  };

  return (
    <S.ActivateAccountContainer>
      <SignHeader
        title="Assinatura Playlegis"
        subtitle="Confirme a escolha da sua assinatura"
      />

      <S.PlansList>
        <PlanViewer
          onPlanSelection={handlePlanSelection}
          plans={plans}
          promotions={promotions}
        />
      </S.PlansList>

      <img
        src={backgroundUrl}
        alt="Homem analisando escolhas"
        title="Imagem de fundo"
      />
    </S.ActivateAccountContainer>
  );
};

export default PlanSelection;
