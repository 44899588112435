import { Fragment, FunctionComponent } from 'react';

import useResponsive from 'hooks/useResponsive';

import iDesktopComponentProps from './interfaces/iDesktopComponentProps';

const DesktopComponent: FunctionComponent<iDesktopComponentProps> = ({
  children
}) => {
  const { isDesktop } = useResponsive();
  return isDesktop ? <Fragment>{children}</Fragment> : <Fragment />;
};

export default DesktopComponent;
