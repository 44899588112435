import { FunctionComponent } from 'react';
import { LinkProps } from 'react-router-dom';

import classNames from 'classnames';

import * as S from './styled';
import CustomProps from './utils';

type ButtonProps = LinkProps & CustomProps;

const Button: FunctionComponent<ButtonProps> = (props: ButtonProps) => {
  const { variant, size, hasIcon, className, children, ...rest } = props;

  return (
    <S.ButtonLink
      className={classNames({
        [`btn__${size}`]: size,
        [`btn__${variant}`]: variant,
        [`${className}`]: className,
        'btn--has-icon': hasIcon
      })}
      {...rest}
    >
      {children}
    </S.ButtonLink>
  );
};

Button.defaultProps = {
  variant: 'white',
  size: 'small',
  hasIcon: false
};

export default Button;
