import {
  FormEvent,
  FunctionComponent,
  InputHTMLAttributes,
  useEffect,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as Yup from 'yup';

import { setCategoryFilter } from 'store/ducks/filter';

import searchIcon from 'assets/icons/audios/search.svg';
import { PUBLIC_ROUTES } from 'helpers/constants';
import { categoryInitialState } from 'helpers/initialState';
import { searchSelector } from 'helpers/selectors';

import * as S from './styled';

interface SearchProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
}

const Search: FunctionComponent<SearchProps> = (props: SearchProps) => {
  const { placeholder } = props;
  const { terms } = useSelector(searchSelector);
  const navigate = useNavigate();
  const [term, setTerm] = useState('');
  const dispatch = useDispatch();

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      Yup.object()
        .shape({
          term: Yup.string().required(
            'Digite ao menos um termo no campo de pesquisa'
          )
        })
        .validateSync(
          { term },
          {
            abortEarly: false
          }
        );

      dispatch(setCategoryFilter(categoryInitialState));
      navigate(`${PUBLIC_ROUTES.SEARCH}/${term}`);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((e) => {
          toast.error(e.message);
        });
      }
    }
  };

  useEffect(() => {
    if (terms) {
      setTerm(terms);
    }
  }, [terms]);

  return (
    <form onSubmit={handleFormSubmit}>
      <S.SearchInput
        type="text"
        placeholder={placeholder}
        value={term}
        onChange={(e) => setTerm(e.target.value)}
        $icon={searchIcon}
      />
    </form>
  );
};

Search.defaultProps = {
  placeholder: 'Pesquisar'
};

export default Search;
