import { Reducer } from 'redux';

import {
  RecoverPasswordAction,
  RecoverPasswordTypes,
  RecoverPasswordState
} from './types';

const INITIAL_STATE: RecoverPasswordState = {
  token: '',
  email: '',
  password: '',
  loading: false
};

const reducer: Reducer<RecoverPasswordState, RecoverPasswordAction> = (
  state: RecoverPasswordState = INITIAL_STATE,
  action: RecoverPasswordAction
) => {
  switch (action.type) {
    case RecoverPasswordTypes.RECOVER_PASSWORD_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case RecoverPasswordTypes.RECOVER_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }

    case RecoverPasswordTypes.RECOVER_PASSWORD_ERROR: {
      return {
        ...state,
        loading: false
      };
    }

    case RecoverPasswordTypes.SET_NEW_PASSWORD_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case RecoverPasswordTypes.SET_NEW_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }

    case RecoverPasswordTypes.SET_NEW_PASSWORD_ERROR: {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
