export const PUBLIC_ROUTES = {
  HOME: '/',
  AUDIOS: '/audios',
  NEWS: '/noticias',
  SIGNUP: '/cadastro',
  LOGIN: '/login',
  RECOVER_PASSWORD: '/esqueci-minha-senha',
  LOGOUT: '/logout',
  CONTACT: '/contato',
  ABOUT: '/sobre',
  SEARCH: '/pesquisa',
  FAQ: '/perguntas-frequentes',
  TERMS_OF_USE: '/termos-de-uso',
  PRIVACY_POLICY: '/politicas-de-privacidade',
  COOKIES_POLICY: '/politica-de-cookies',
  CANCEL_SUCCESS: '/minha-conta/conta-cancelada'
};

export const PRIVATE_ROUTES = {
  ACCOUNT: '/minha-conta',
  CANCEL_SUBSCRIPTION: '/minha-conta/cancelar',
  PLAYLIST: '/playlist',
  ACTIVATE_ACCOUNT: '/minha-conta/ativar',
  REACTIVATE_ACCOUNT: '/minha-conta/reativar',
  REDIRECT: '/redirect',
  SEND_DOCUMENTS: '/minha-conta/enviar-documentos'
};

export const SOCIAL_NETWORKS = {
  FACEBOOK: 'https://facebook.com/playlegis',
  INSTAGRAM: 'https://www.instagram.com/playlegis',
  YOUTUBE: 'https://www.youtube.com/channel/UCEQtk7s73ERh9bhPTaQ5XuQ'
};

export const STORE_LINKS = {
  APP_STORE: 'https://apps.apple.com/br/app/playlegis/id1581013459',
  GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=com.playlegis'
};

export const SUPER_CATEGORY = {
  CONTEST: 1,
  OTHERS: 2
};
