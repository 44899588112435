export enum StudentTypes {
  CREATE_STUDENT_REQUEST = '@STUDENT/CREATE_STUDENT_REQUEST',
  CREATE_STUDENT_SUCCESS = '@STUDENT/CREATE_STUDENT_SUCCESS',
  CREATE_STUDENT_FAILURE = '@STUDENT/CREATE_STUDENT_FAILURE',

  SET_STUDENT_REQUEST_DATA = '@STUDENT/SET_STUDENT_REQUEST_DATA'
}

export interface StudentState {
  readonly teachingInstitutionId: number;
  readonly files: File[];
  readonly loading: boolean;
  readonly isError: boolean;
  readonly hadSucceed: boolean;
  readonly errorCode?: string | number;
  readonly errorMessage: string;
  readonly studentRequestId: number;
  readonly userToken?: string;
}

export type StudentAction = {
  type: StudentTypes;
  payload: StudentState & {
    studentRequestId: number;
    userToken?: string;
  };
};
