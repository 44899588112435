import { Reducer } from 'redux';

import { AudioTypes, AudioState, AudioAction } from './types';
import { AUDIO_INITIAL_STATE } from './utils';

const reducer: Reducer<AudioState, AudioAction> = (
  state: AudioState = AUDIO_INITIAL_STATE,
  action: AudioAction,
) => {
  switch (action.type) {
    case AudioTypes.SET_ACTIVE_AUDIO: {
      return {
        ...state,
        audio: action.payload.audio,
        playlistName: action.payload.playlistName,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
