import styled from 'styled-components';

export const PlaylistWrapper = styled.div`
  ${({ theme }) => theme.bp.Fluid};
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
`;

export const PlaylistContainer = styled.div`
  ${({ theme }) => theme.bp.Medium};

  ${({ theme }) => theme.mq.lessThan('sm')`
    padding-bottom: 100px;
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    padding-bottom: 180px;
  `}
`;