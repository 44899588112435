import styled from 'styled-components';

export const CancelForm = styled.form`
  max-width: 700px;
`;

export const CancelFormFieldset = styled.fieldset`
  max-width: 570px;

  legend {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 50px;
  }

  textarea {
    height: 175px;
    resize: none;
  }

  .btn__yellow {
    max-width: 300px;
    width: 100%;
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    legend {
      margin-bottom: 32px;
    }

    textarea {
      margin-bottom: 32px;
    }

    .btn__yellow {
      display: block;
      margin: 0 auto;
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    legend {
      margin-bottom: 50px;
    }

    textarea {
      margin-bottom: 30px;
    }
  `}
`;