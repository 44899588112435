import backgroundUrl from 'assets/img/signup/signup-complete.png';
import { PUBLIC_ROUTES } from 'helpers/constants';

import { ButtonLink as Link, HTMLButton as Button } from 'components/Button';
import { Mobile } from 'components/Responsive';
import SignHeader from 'components/SignHeader';

import * as S from './styled';

interface SendDocumentsConfirmProps {
  isWebview: boolean;
  handleNextOnWebview: () => void;
}

const SendDocumentsConfirm = (props: SendDocumentsConfirmProps) => {
  const { isWebview, handleNextOnWebview } = props;

  const handleRefresh = () => {
    window.location.replace(PUBLIC_ROUTES.AUDIOS);
  };

  return (
    <S.SendDocumentsConfirmContainer>
      <SignHeader
        title="Assinatura Playlegis"
        subtitle="Parabéns"
        className="signup__header signup__header--is-confirm"
        withBackButton={false}
      />

      <S.SendDocumentsConfirmMessage>
        <h3>Sua assinatura foi realizada com sucesso.</h3>

        <p>
          Agora você tem acesso completo aos nossos conteúdos. Bons estudos!
        </p>

        {isWebview ? (
          <Button onClick={handleNextOnWebview} variant="yellow">
            Acessar conteúdos
          </Button>
        ) : (
          <Button onClick={handleRefresh} variant="yellow">
            Acessar conteúdos
          </Button>
        )}

        <img
          src={backgroundUrl}
          alt="Pessoas comemorando"
          title="Assinatura realizada com sucesso"
        />
      </S.SendDocumentsConfirmMessage>

      <Mobile>
        {!isWebview && (
          <S.SendDocumentsFooter>
            Dúvidas?{' '}
            <Link to={PUBLIC_ROUTES.CONTACT} variant="link">
              Entre em contato
            </Link>
            .
          </S.SendDocumentsFooter>
        )}
      </Mobile>
    </S.SendDocumentsConfirmContainer>
  );
};

export default SendDocumentsConfirm;
