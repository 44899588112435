import styled from 'styled-components';

export const LoginWrapper = styled.div`
  ${({ theme }) => theme.bp.Fluid};
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
`;

export const LoginContainer = styled.div`
  ${({ theme }) => theme.bp.Large};
  max-width: 405px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  ${({ theme }) => theme.mq.lessThan('sm')`
    padding: 70px 0 90px;
  `}
`;

export const LoginContent = styled.div`
  text-align: center;

  > svg {
    margin-bottom: 80px;
  }

  h1 {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 18px;
  }

  p {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 50px;
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    margin-bottom: 80px;
  `}
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;

  fieldset {
    .login__input {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    margin-bottom: 30px;
  }

  .btn__yellow {
    width: 100%;
  }
`;

export const RecoverPasswordLink = styled.div`
  font-size: 14px;
  font-weight: 300;

  .btn__link {
    font-size: 14px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.white};
    text-decoration: underline;
    margin-left: 4px;

    &:hover {
      color: ${({ theme }) => theme.colors.yellow};
    }
  }
`;

export const SignupLink = styled.div`
  font-size: 14px;
  font-weight: 300;

  .btn__link {
    font-size: 14px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.white};
    text-decoration: underline;
    margin-left: 4px;

    &:hover {
      color: ${({ theme }) => theme.colors.yellow};
    }
  }
`;

export const LoginErrorMessage = styled.div`
  font-size: 12px;
  margin-top: 16px;
`;
