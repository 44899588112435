/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as yup from 'yup';

import { Contact } from '../models';

const contactSchema = yup.object().shape({
  name: yup.string()
    .required('O campo nome é obrigatório'),
  email: yup.string()
    .email('O formato digitado para o email é inválido')
    .required('O campo email é obrigatório'),
  phone: yup.string()
    .required('O campo telefone é obrigatório'),
  message: yup.string()
    .required('O campo mensagem é obrigatório'),
  acceptedTerm: yup.bool()
    .oneOf([false], 'Campo de verificação não selecionado'),
});

export const contactValidation = (
  contact: Contact
) => contactSchema.validateSync(
  contact, { abortEarly: false },
);