export enum StoreModule {
  ACCOUNT = 'ACCOUNT',
  AUDIO = 'AUDIO',
  CATEGORY = 'CATEGORY',
  CONTACT = 'CONTACT',
  FILTER = 'FILTER',
  INSTITUTIONAL = 'INSTITUTIONAL',
  LOADING = 'LOADING',
  LOGIN = 'LOGIN',
  LOGINPOPUP = 'LOGINPOPUP',
  OVERLAY = 'OVERLAY',
  PLAN = 'PLAN',
  PLAYER = 'PLAYER',
  PLAYLIST = 'PLAYLIST',
  POPUP = 'POPUP',
  REACTIVATEPOPUP = 'REACTIVATEPOPUP',
  RECOVERPASSWORD = 'RECOVERPASSWORD',
  SEARCH = 'SEARCH',
  STUDENT = 'STUDENT',
  TEACHINGINSTITUTION = 'TEACHINGINSTITUTION',
  TOASTIFY = 'TOASTIFY'
}
