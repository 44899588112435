import { Fragment, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import BackgroundPostHeader from 'assets/img/home/postHeader/background.png';
import MockupPostHeader from 'assets/img/playlegis-mockup.webp';
import { PUBLIC_ROUTES } from 'helpers/constants';
import { downloadMenu } from 'helpers/links';
import { ApplicationState, Link as MenuLinkType } from 'helpers/types';

import { ButtonLink as Link } from 'components/Button';
import { Mobile, Desktop } from 'components/Responsive';

import * as S from './HomePostHeader.styled';

const PostHeader = () => {
  const {
    home: { banner }
  } = useSelector((state: ApplicationState) => state.institutional);

  const getIcon = (link: MenuLinkType) => {
    if (link.icon) {
      return link.icon as ReactNode;
    }

    return <Fragment />;
  };

  return (
    <Fragment>
      <Desktop>
        <S.PostHeaderWrapper backgroundUrl={BackgroundPostHeader}>
          <S.Container>
            <S.Description>
              <h1>{banner.title}</h1>
              <h2>{banner.subtitle}</h2>
              <p>{banner.description}</p>

              <Link variant="yellow" to={PUBLIC_ROUTES.SIGNUP}>
                Se cadastrar agora
              </Link>
            </S.Description>

            <S.MockupAndStoresContainer>
              <img
                src={MockupPostHeader}
                alt="Mockup do site e do aplicativo da Playlegis"
                title="Mockup do site e do aplicativo da Playlegis"
              />
              <S.StoresIcons>
                {downloadMenu.links.map((link) => (
                  <a
                    key={link.label}
                    href={link.to}
                    title={link.label}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {getIcon(link)}
                  </a>
                ))}
              </S.StoresIcons>
            </S.MockupAndStoresContainer>
          </S.Container>
        </S.PostHeaderWrapper>
      </Desktop>
      <Mobile>
        <S.PostHeaderWrapper>
          <S.MockupAndStoresContainer>
            <img
              src={MockupPostHeader}
              alt="Mockup do site e do aplicativo da Playlegis"
              title="Mockup do site e do aplicativo da Playlegis"
            />
            <S.StoresIcons>
              {downloadMenu.links.map((link) => (
                <a
                  key={link.label}
                  href={link.to}
                  title={link.label}
                  target="_blank"
                  rel="noreferrer"
                >
                  {getIcon(link)}
                </a>
              ))}
            </S.StoresIcons>
          </S.MockupAndStoresContainer>

          <S.Container>
            <S.Description>
              <h1>{banner.title}</h1>
              <h2>{banner.subtitle}</h2>
              <p>{banner.description}</p>

              <Link variant="yellow" to={PUBLIC_ROUTES.SIGNUP}>
                Se cadastrar agora
              </Link>
            </S.Description>
          </S.Container>
        </S.PostHeaderWrapper>
      </Mobile>
    </Fragment>
  );
};

export default PostHeader;
