import { call, put } from 'redux-saga/effects';

import Connector from 'services/axios/axios';

import { StoreModule } from 'helpers/enum/StoreModule';
import { planInitialState } from 'helpers/initialState';
import {
  Differential,
  Home,
  MainBanner,
  Plan,
  Promotion,
  Testimonial
} from 'helpers/models';
import { ResponseGeneratorTyped } from 'helpers/types/ResponseGenerator';

import { addLoadingModule, removeLoadingModule } from '../loading/actions';
import {
  fetchHomeSuccess,
  fetchHomeError,
  fetchFaqSuccess,
  fetchFaqError,
  fetchAboutUsSuccess,
  fetchAboutUsError,
  fetchTermsSuccess,
  fetchNewsSuccess,
  fetchNewsError,
  fetchTermsError,
  setHomeLoading
} from './actions';

export function* fetchHome() {
  yield put(addLoadingModule(StoreModule.INSTITUTIONAL));
  yield put(setHomeLoading(true));

  try {
    const responseBanner: ResponseGeneratorTyped<MainBanner> = yield call(
      Connector().get,
      '/landing-page/banners'
    );

    const responseDifferentials: ResponseGeneratorTyped<Differential[]> =
      yield call(Connector().get, '/landing-page/our-differences');

    const responseTestimonials: ResponseGeneratorTyped<Testimonial[]> =
      yield call(Connector().get, '/landing-page/depositions');

    const { data: plansData }: ResponseGeneratorTyped<Plan[]> = yield call(
      Connector().get,
      '/landing-page/plans?status=ENABLED'
    );

    let freeTrialPlan = planInitialState;
    const promotions: Promotion[] = [];

    const plans: Plan[] = plansData
      ? plansData
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((plan) => {
            if (plan.name === '7 dias grátis') {
              freeTrialPlan = plan;
            }

            plan.promotions.forEach((promo) => {
              const promotion = {
                ...promo,
                plan
              };

              promotions.push(promotion);
            });

            return plan.name !== '7 dias grátis';
          })
      : [];

    const data: Home = {
      banner: responseBanner.data!,
      differentials: responseDifferentials.data || [],
      plans,
      promotions,
      freeTrialPlan,
      testimonials: responseTestimonials.data || []
    };

    yield put(fetchHomeSuccess(data));
  } catch (err) {
    yield put(fetchHomeError());
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONAL));
    yield put(setHomeLoading(false));
  }
}

export function* fetchAboutUs() {
  yield put(addLoadingModule(StoreModule.INSTITUTIONAL));

  try {
    const { data } = yield call(Connector().get, '/landing-page/about-us');

    yield put(fetchAboutUsSuccess(data));
  } catch (err) {
    yield put(fetchAboutUsError());
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONAL));
  }
}

export function* fetchTerms() {
  yield put(addLoadingModule(StoreModule.INSTITUTIONAL));

  try {
    const { data } = yield call(
      Connector().get,
      '/landing-page/terms-and-conditions'
    );

    yield put(fetchTermsSuccess(data));
  } catch (err) {
    yield put(fetchTermsError());
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONAL));
  }
}

export function* fetchFAQ() {
  yield put(addLoadingModule(StoreModule.INSTITUTIONAL));

  try {
    const { data } = yield call(
      Connector().get,
      '/landing-page/common-questions'
    );

    yield put(fetchFaqSuccess(data));
  } catch (err) {
    yield put(fetchFaqError());
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONAL));
  }
}

export function* fetchNews() {
  yield put(addLoadingModule(StoreModule.INSTITUTIONAL));

  try {
    const { data } = yield call(Connector().get, '/landing-page/news');

    yield put(fetchNewsSuccess(data));
  } catch (err) {
    yield put(fetchNewsError());
  } finally {
    yield put(removeLoadingModule(StoreModule.INSTITUTIONAL));
  }
}
