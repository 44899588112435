import { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  ToastContainer,
  ToastContainerProps as ToastProps,
  Slide,
  toast,
  cssTransition
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { ApplicationState } from 'helpers/types';

import * as S from './styled';

const Toast: FunctionComponent<ToastProps> = (props: ToastProps) => {
  const toastState = useSelector((state: ApplicationState) => state.toastify);

  useEffect(() => {
    switch (toastState.type) {
      case 'info':
        toast.info(toastState.message);
        break;
      case 'success':
        toast.success(toastState.message);
        break;
      case 'warning':
        toast.warning(toastState.message);
        break;
      case 'error':
        toast.error(toastState.message);
        break;
      case 'dark':
        toast.dark(toastState.message);
        break;
      case 'default':
        toast(toastState.message);
        break;
      default:
        break;
    }
  }, [toastState]);

  const customTransition = cssTransition({
    enter: 'audiolegis-transition-enter--top-right ',
    exit: 'audiolegis-transition-exit--top-right',
    collapse: false
  });

  return (
    <S.ToastWrapper>
      <ToastContainer
        transition={customTransition}
        theme="colored"
        {...props}
      />
    </S.ToastWrapper>
  );
};

export default Toast;
