import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { call, put } from 'redux-saga/effects';

import Connector from 'services/axios/axios';

import { StoreModule } from 'helpers/enum/StoreModule';
import { LoginResponse } from 'helpers/types';

import { addLoadingModule, removeLoadingModule } from '../loading/actions';
import {
  loginSuccess,
  loginError,
  logoutSuccess,
  logoutError
} from './actions';
import { Action } from './reducers';

interface Response<T> {
  data: T;
}

type SagaGenerator = Generator<unknown, void, Response<LoginResponse>>;

export function* login(action: Action): SagaGenerator {
  yield put(addLoadingModule(StoreModule.LOGIN));
  const { input, path } = action.payload;

  try {
    const response = yield call(Connector().post, 'sessions/app', input);

    const { token, expireIn, userId } = response.data;

    Cookies.set('al-web-token', token, { expires: new Date(expireIn) });
    localStorage.setItem('al-web-token-expires-in', `${expireIn}`);
    localStorage.setItem('al-web-user-id', `${userId}`);

    yield put(loginSuccess(response.data));

    const pathBlockList = ['minha-conta/cancelar'];

    if (pathBlockList.includes(path)) {
      window.location.replace('/audios');
    } else {
      window.location.replace(path);
    }
  } catch (error) {
    if (
      error instanceof AxiosError &&
      error.response &&
      error.response.data &&
      error.response.data.code === 403
    ) {
      yield put(loginError(error.response.data));
    } else {
      yield put(loginError());
    }
  } finally {
    yield put(removeLoadingModule(StoreModule.LOGIN));
  }
}

export function* logout(): SagaGenerator {
  yield put(addLoadingModule(StoreModule.LOGIN));

  try {
    yield call(Connector().delete, 'sessions/logout');

    Cookies.remove('al-web-token');
    localStorage.removeItem('al-web-token-expires-in');
    localStorage.removeItem('al-web-user-id');

    yield put(logoutSuccess());
  } catch (err) {
    yield put(logoutError());
  } finally {
    yield put(removeLoadingModule(StoreModule.LOGIN));
    window.location.href = '/';
  }
}
