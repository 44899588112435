import { planInitialState } from 'helpers/initialState';

import { InstitutionalState } from './types';

export const INSTITUTIONAL_INITIAL_STATE: InstitutionalState = {
  home: {
    banner: {
      id: 0,
      title: '',
      subtitle: '',
      description: ''
    },
    differentials: [],
    plans: [],
    promotions: [],
    testimonials: [],
    freeTrialPlan: planInitialState
  },
  aboutUs: {
    id: 0,
    description: ''
  },
  terms: {
    id: 0,
    termsOfUse: '',
    privacyPolicies: '',
    cookiesPolicy: ''
  },
  faq: [],
  news: [],
  isHomeLoading: false
};
