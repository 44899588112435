import { useEffect, useState } from 'react';

import Cookies from 'js-cookie';
import moment from 'moment';

const useAuth = () => {
  const cookieToken = Cookies.get('al-web-token') || '';
  const cookieTokenExpires = localStorage.getItem('al-web-token-expires-in');
  const cookieUserId = localStorage.getItem('al-web-user-id') || 0;

  const [token, setToken] = useState('');
  const [isUserAuthenticated, setUserAuthenticated] = useState(false);
  const [userId, setUserId] = useState(0);

  useEffect(() => {
    if (!token) {
      const isTokenValid = cookieTokenExpires
        ? moment().diff(moment(cookieTokenExpires), 'days') < 0
        : false;
      const userAuth = cookieToken ? isTokenValid : false;

      setToken(cookieToken);
      setUserAuthenticated(userAuth);
      setUserId(Number(cookieUserId));
    }
  }, [cookieToken]);

  return { token, isUserAuthenticated, userId };
};

export default useAuth;
