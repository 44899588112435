import { keyframes } from 'styled-components';

const nav = {
  show: keyframes`
    from {
      right: 999px;
    }
    to: {
      right: 0px;
    }
  `,

  hide: keyframes`
    from {
      right: 0px;
    }
    to: {
      right: 999px;
    }
  `,
};

export default { nav };
