import { Reducer } from 'redux';

import { ModalTypes, ModalState, ModalAction } from './types';
import { MODAL_INITIAL_STATE } from './utils';

const reducer: Reducer<ModalState, ModalAction> = (
  state: ModalState = MODAL_INITIAL_STATE,
  action: ModalAction
) => {
  switch (action.type) {
    case ModalTypes.OPEN_MODAL: {
      return {
        modalItem: {
          ...action.payload.modalItem,
          show: true
        }
      };
    }
    case ModalTypes.CLOSE_MODAL: {
      return {
        modalItem: MODAL_INITIAL_STATE.modalItem
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
