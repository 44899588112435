import { Fragment, FunctionComponent } from 'react';

import AuthNavbar from './AuthNavbar';
import Navbar from './Navbar';

interface iNavbarProps {
  renderPublic?: boolean;
  renderPrivate: boolean;
}

const GlobalNavbar: FunctionComponent<iNavbarProps> = (props) => {
  const { renderPublic, renderPrivate } = props;

  const getPrivate = () => {
    if (renderPrivate) {
      return <AuthNavbar />;
    }

    return <Fragment />;
  };

  return renderPublic ? <Navbar /> : getPrivate();
};

GlobalNavbar.defaultProps = {
  renderPublic: false
};

export default GlobalNavbar;
