import { Reducer } from 'redux';

import { StudentTypes, StudentState, StudentAction } from './types';
import { STUDENT_INITIAL_STATE } from './utils';

const reducer: Reducer<StudentState, StudentAction> = (
  state: StudentState = STUDENT_INITIAL_STATE,
  action: StudentAction
) => {
  switch (action.type) {
    case StudentTypes.CREATE_STUDENT_REQUEST: {
      return {
        ...state,
        files: action.payload.files,
        teachingInstituionId: action.payload.teachingInstitutionId,
        isError: false,
        loading: true,
        hadSucceed: false,
        errorMessage: '',
        errorCode: 0
      };
    }

    case StudentTypes.CREATE_STUDENT_FAILURE: {
      return {
        ...state,
        loading: false,
        hadSucceed: false,
        isError: true,
        errorMessage: action.payload.errorMessage,
        errorCode: action.payload.errorCode
      };
    }

    case StudentTypes.CREATE_STUDENT_SUCCESS: {
      return {
        ...state,
        isError: false,
        loading: false,
        hadSucceed: true
      };
    }

    case StudentTypes.SET_STUDENT_REQUEST_DATA: {
      return {
        ...state,
        studentRequestId: action.payload.studentRequestId,
        userToken: action.payload.userToken
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
