import { FormEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  deleteAccountAvatarRequest,
  updateAccountAvatarRequest
} from 'store/ducks/account';

import { ReactComponent as PaperClipIcon } from 'assets/icons/paperclip.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import { accountSelector } from 'helpers/selectors';

import { HTMLButton as Button } from 'components/Button';

import * as S from './styled';

const AvatarForm = () => {
  const dispatch = useDispatch();
  const { account } = useSelector(accountSelector);

  const avatarRef = useRef<HTMLInputElement>(null);

  const [preview, setPreview] = useState('');
  const [avatar, setAvatar] = useState<File>();

  useEffect(() => {
    if (account.avatar) {
      setPreview(account.avatar);
    }
  }, [account]);

  useEffect(() => {
    if (avatar) {
      dispatch(updateAccountAvatarRequest(avatar));
    }
  }, [avatar]);

  const handleAvatarChange = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      setAvatar(e.currentTarget.files[0]);
    }
  };

  const handleDeleteClick = () => {
    dispatch(deleteAccountAvatarRequest());
    setPreview('');

    if (avatarRef.current) {
      avatarRef.current.value = '';
    }
  };

  return (
    <S.AvatarFormWrapper>
      <S.AccountFormHeader>
        <h3>Foto</h3>
      </S.AccountFormHeader>

      <S.AvatarForm>
        <S.AvatarPreview $src={preview} title="Foto de perfil" />

        <S.AccountAvatarActions>
          <S.AvatarInputWrapper htmlFor="avatar">
            <input
              ref={avatarRef}
              type="file"
              name="avatar"
              id="avatar"
              onChange={handleAvatarChange}
            />
            <PaperClipIcon />
            Adicionar/mudar
          </S.AvatarInputWrapper>
          <Button
            type="button"
            variant="link"
            disabled={!account.avatar}
            onClick={handleDeleteClick}
          >
            <RemoveIcon />
            Remover
          </Button>
        </S.AccountAvatarActions>
      </S.AvatarForm>
    </S.AvatarFormWrapper>
  );
};

export default AvatarForm;
