import classNames from 'classnames';
import { SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ReactComponent as NavigationArrow } from 'assets/icons/navigation/arrow.svg';
import { Category } from 'helpers/models';
import useResponsive from 'hooks/useResponsive';

import CategoryThumbLink from 'components/CategoryThumbLink';
import { Desktop } from 'components/Responsive';

import * as S from './styled';

interface Props {
  category: Category;
}

const regexExp = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/gm;

const CategoryItem = ({ category }: Props) => {
  const { isMobile } = useResponsive();
  const navigationId = {
    prev: `swiper-navigation-prev-${category.id}-${Math.random()
      .toString()
      .replaceAll(regexExp, '')}`,
    next: `swiper-navigation-next-${category.id}-${Math.random()
      .toString()
      .replaceAll(regexExp, '')}`
  };

  const params: SwiperOptions = {
    autoplay: false,
    allowTouchMove: isMobile || !(category.playlists.length < 4),
    updateOnWindowResize: true,
    slidesPerView: 1.4,
    spaceBetween: 30,
    breakpoints: {
      // when window width is >= 768px
      576: {
        slidesPerView: 2,
        spaceBetween: 12,
        navigation: {
          prevEl: `#${navigationId.prev}`,
          nextEl: `#${navigationId.next}`
        }
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 15,
        navigation: {
          prevEl: `#${navigationId.prev}`,
          nextEl: `#${navigationId.next}`
        }
      },
      992: {
        slidesPerView: 4,
        spaceBetween: 30,
        navigation: {
          prevEl: `#${navigationId.prev}`,
          nextEl: `#${navigationId.next}`
        }
      }
    }
  };

  return (
    <S.CategoryItem>
      <Swiper {...params}>
        {category.playlists.map((playlist) => (
          <SwiperSlide key={playlist.id}>
            <CategoryThumbLink playlist={playlist} />
          </SwiperSlide>
        ))}
      </Swiper>

      <Desktop>
        <div
          id={navigationId.prev}
          className={classNames({
            'swiper-navigation swiper-navigation--is-prev': true,
            'swiper-button-is-disabled': category.playlists.length < 4
          })}
        >
          <NavigationArrow />
        </div>
        <div
          id={navigationId.next}
          className={classNames({
            'swiper-navigation swiper-navigation--is-next': true,
            'swiper-button-is-disabled': category.playlists.length < 4
          })}
        >
          <NavigationArrow />
        </div>
      </Desktop>
    </S.CategoryItem>
  );
};

export default CategoryItem;
