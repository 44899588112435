import { Action } from 'redux';
import { action } from 'typesafe-actions';

import { Home, AboutUs, Terms, FAQ, NewsArticle } from 'helpers/models';

import { InstitutionalTypes } from './types';

// Home
export const fetchHomeData = (): Action =>
  action(InstitutionalTypes.HOME_DATA_REQUEST);

export const fetchHomeSuccess = (home: Home): Action =>
  action(InstitutionalTypes.HOME_DATA_SUCCESS, { home });

export const fetchHomeError = (): Action =>
  action(InstitutionalTypes.HOME_DATA_ERROR);

// About Us
export const fetchAboutUsData = (): Action =>
  action(InstitutionalTypes.ABOUT_US_DATA_REQUEST);

export const fetchAboutUsSuccess = (aboutUs: AboutUs): Action =>
  action(InstitutionalTypes.ABOUT_US_DATA_SUCCESS, { aboutUs });

export const fetchAboutUsError = (): Action =>
  action(InstitutionalTypes.ABOUT_US_DATA_ERROR);

// Terms
export const fetchTermsData = (): Action =>
  action(InstitutionalTypes.TERMS_DATA_REQUEST);

export const fetchTermsSuccess = (terms: Terms): Action =>
  action(InstitutionalTypes.TERMS_DATA_SUCCESS, { terms });

export const fetchTermsError = (): Action =>
  action(InstitutionalTypes.TERMS_DATA_ERROR);

// FAQ
export const fetchFaqData = (): Action =>
  action(InstitutionalTypes.FAQ_DATA_REQUEST);

export const fetchFaqSuccess = (faq: FAQ[]): Action =>
  action(InstitutionalTypes.FAQ_DATA_SUCCESS, { faq });

export const fetchFaqError = (): Action =>
  action(InstitutionalTypes.FAQ_DATA_ERROR);

// News
export const fetchNewsData = (): Action =>
  action(InstitutionalTypes.NEWS_DATA_REQUEST);

export const fetchNewsSuccess = (news: NewsArticle[]): Action =>
  action(InstitutionalTypes.NEWS_DATA_SUCCESS, { news });

export const fetchNewsError = (): Action =>
  action(InstitutionalTypes.NEWS_DATA_ERROR);

export const setHomeLoading = (isLoading: boolean): Action =>
  action(InstitutionalTypes.HOME_LOADING, { isLoading });
