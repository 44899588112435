import { NavigationProvider } from 'contexts/NavigationContext';

import SignUpNavigation from './components/Navigation/Navigation';

const SignUpPage = () => (
  <NavigationProvider>
    <SignUpNavigation />
  </NavigationProvider>
);

export default SignUpPage;
