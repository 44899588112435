import { Fragment, ReactNode } from 'react';

import { PRIVATE_ROUTES, PUBLIC_ROUTES } from 'helpers/constants';
import { Link as LinkMenuType } from 'helpers/types';

const pagesWithMiniFooter = [
  PUBLIC_ROUTES.AUDIOS,
  PUBLIC_ROUTES.CONTACT,
  PUBLIC_ROUTES.NEWS,
  PUBLIC_ROUTES.SEARCH,
  PRIVATE_ROUTES.PLAYLIST,
  PRIVATE_ROUTES.ACCOUNT
];

const getIcon = (link: LinkMenuType) => {
  if (link.icon) {
    return link.icon as ReactNode;
  }

  return <Fragment />;
};

export default {
  pagesWithMiniFooter,
  getIcon
};
