/* eslint-disable @typescript-eslint/no-explicit-any */

export const verifyLengthAndApply = (
  value: string | number,
  maxLength: number,
  dataName: string,
  data: any,
  action: (data: any) => void
) => {
  if (value.toString().length <= maxLength) {
    action({
      ...data,
      [dataName]: value
    });
  }
};

export const verifyAndFormatCardNumber = (
  value: string | number,
  maxLength: number,
  dataName: string,
  data: any,
  action: (data: any) => void
) => {
  const serialized = value.toString().replaceAll(/[^0-9]/g, '');
  const matched = serialized.match(/[\d]{1,4}/g);

  if (value.toString().length <= maxLength) {
    action({
      ...data,
      [dataName]: serialized.length ? matched?.join(' ') : serialized
    });
  }
};
