import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Cookies from 'js-cookie';

import { accountSelector } from 'helpers/selectors';

import CategoryHeader from 'containers/PostHeader/PostHeader';

import SuperCategoryList from './components/SuperCategoryList';
import * as S from './styled';

const Audios = () => {
  const [isUserAuthenticated, setUserAuthenticated] = useState(false);
  const [categoryHeaderTitle] = useState('Leis em áudio');
  const [categoryHeaderSubtitle, setCategoryHeaderSubtitle] = useState('');
  const { account } = useSelector(accountSelector);

  useEffect(() => {
    const token = Cookies.get('al-web-token') || '';

    setUserAuthenticated(!!token);
  }, []);

  useEffect(() => {
    const message = isUserAuthenticated
      ? `Olá ${account.name.split(' ')[0]}, bons estudos!`
      : 'Conheça o nosso conteúdo';

    if (message !== categoryHeaderSubtitle) {
      setCategoryHeaderSubtitle(message);
    }
  }, [isUserAuthenticated, account]);

  useEffect(() => {
    document.body.style.backgroundColor = '#272F4C';

    const onUnmount = () => {
      document.body.style.backgroundColor = '#FFF';
    };

    return onUnmount;
  }, []);

  return (
    <S.AudiosWrapper>
      <S.AudiosContainer>
        <CategoryHeader
          title={categoryHeaderTitle}
          subtitle={categoryHeaderSubtitle}
          mode="SUPER_CATEGORY"
        />

        <SuperCategoryList />
      </S.AudiosContainer>
    </S.AudiosWrapper>
  );
};

export default Audios;
