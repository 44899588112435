import { NavigationProvider } from 'contexts/NavigationContext';

import SendDocumentsNavigation from './components/Navigation';

const SendDocumentsPage = () => (
  <NavigationProvider>
    <SendDocumentsNavigation />
  </NavigationProvider>
);

export default SendDocumentsPage;
