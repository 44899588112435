import { AxiosError, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

import { PRIVATE_ROUTES, PUBLIC_ROUTES } from 'helpers/constants';

const responseInterceptor = (response: AxiosResponse) => response;

const responseInterceptorError = (error: AxiosError) => {
  const status = error.response?.status;
  const currentPath = window.location.pathname;
  const whiteList =
    currentPath === PRIVATE_ROUTES.ACCOUNT ||
    currentPath === PUBLIC_ROUTES.LOGIN;

  if (status === 403 && !whiteList) {
    window.location.replace(PRIVATE_ROUTES.ACCOUNT);
  }

  if (status === 401 && currentPath !== PUBLIC_ROUTES.LOGIN) {
    Cookies.remove('al-web-token');
    localStorage.removeItem('al-web-token-expires-in');
    localStorage.removeItem('al-web-user-id');
    window.location.reload();
  }

  return Promise.reject(error);
};

export default { responseInterceptor, responseInterceptorError };
