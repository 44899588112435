import { SearchResponse } from 'helpers/models';

export enum SearchTypes {
  SEARCH_REQUEST = '@SEARCH/SEARCH_REQUEST',
  SEARCH_SUCCESS = '@SEARCH/SEARCH_SUCCESS',
  SEARCH_ERROR = '@SEARCH/SEARCH_ERROR',

  SET_SEARCHING = '@SEARCH/SET_SEARCHING'
}

export interface SearchState {
  readonly terms: string;
  readonly response: SearchResponse;
  readonly searching: boolean;
}

export type SearchAction = {
  type: SearchTypes;
  payload: {
    terms: string;
    response: SearchResponse;
    searching: boolean;
  }
}