import { toast } from 'react-toastify';

import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { call, put, select } from 'redux-saga/effects';

import Connector from 'services/axios/axios';

import { StoreModule } from 'helpers/enum/StoreModule';
import { iAccountSubscription } from 'helpers/interfaces/iAccountSubscription';
import { createPaymentTokenAction } from 'helpers/iuguActions';
import { ApplicationState, PaymentTokenActionResponse } from 'helpers/types';

import { addLoadingModule, removeLoadingModule } from '../loading';
import { logoutError, logoutSuccess } from '../login';
import { removeAllPopUpItems, removePopUpItem } from '../popUp';
import { setStudentRequestData } from '../student';
import {
  activateAccountFailure,
  activateAccountSuccess,
  cancelAccountFailure,
  cancelAccountSuccess,
  createAccountFailure,
  createAccountSuccess,
  deleteAccountAvatarFailure,
  deleteAccountAvatarSuccess,
  fetchAccountFailure,
  fetchAccountRequest,
  fetchAccountSuccess,
  reactivateAccountFailure,
  reactivateAccountSuccess,
  resetAccountState,
  updateAccountAvatarFailure,
  updateAccountAvatarSuccess,
  updateAccountCreditCardFailure,
  updateAccountCreditCardSuccess,
  updateAccountDataFailure,
  updateAccountDataSuccess,
  validateAccountFailure,
  validateAccountSuccess
} from './actions';
import { AccountAction } from './types';

export function* validateAccount() {
  yield put(addLoadingModule(StoreModule.ACCOUNT));

  try {
    const { account: accountState }: ApplicationState = yield select(
      (state: ApplicationState) => state
    );

    const { email, cpf } = accountState.account;

    yield call(
      Connector(false, false).get,
      `/users/validate?email=${email}&cpf=${cpf}`
    );

    yield put(validateAccountSuccess());
  } catch (error) {
    let errorMessage =
      'Falha ao verificar email e/ou CPF. Tente novamente, ou caso a falha persista, entre em contato com o nosso suporte.';
    let errorCode;

    if (error instanceof AxiosError) {
      if (error.response) {
        if (error.response.data) {
          errorMessage = error.response.data.message;
          errorCode = error.response.data.code;
        } else {
          errorCode = error.response.status;
        }
      }
    }

    yield put(validateAccountFailure(errorMessage, errorCode));
  } finally {
    yield put(removeLoadingModule(StoreModule.ACCOUNT));
  }
}

export function* fetchAccount() {
  yield put(addLoadingModule(StoreModule.ACCOUNT));

  try {
    const { data: account } = yield call(
      Connector(false, true).get,
      '/users/account'
    );

    yield put(fetchAccountSuccess(account));
  } catch (error) {
    let errorMessage =
      'Houve uma falha buscar dados da conta. Entre em contato conosco!';
    let errorCode;

    if (error instanceof AxiosError) {
      if (error.response) {
        if (error.response.data) {
          errorMessage = error.response.data.message;
          errorCode = error.response.data.code;
        } else {
          errorCode = error.response.status;
        }
      }
    }

    yield put(fetchAccountFailure(errorMessage, errorCode));
  } finally {
    yield put(removeLoadingModule(StoreModule.ACCOUNT));
  }
}

export function* createAccount(action: AccountAction) {
  const { creditCard } = action.payload;
  yield put(addLoadingModule(StoreModule.ACCOUNT));

  try {
    const {
      account: {
        account,
        subscriptionAddress,
        selectedPlan,
        selectedPromotionId
      }
    }: ApplicationState = yield select((state) => state);

    const newUser = {
      name: account.name,
      email: account.email,
      password: account.password,
      cpf: account.cpf,
      isAdmin: false
    };

    // Passo 1: Criação do usuário
    try {
      const { data } = yield call(
        Connector(false, false).post,
        '/users/create/app',
        newUser
      );

      const { token, expireIn, userId } = data;

      // Passo 2: Cadastro no Iugu
      try {
        const { iuguToken, error }: PaymentTokenActionResponse = yield call(
          createPaymentTokenAction,
          creditCard
        );

        window.dataLayer.push({
          event: 'SUBSCRIPTION_PAYMENT',
          transactionId: iuguToken,
          transactionTotal: selectedPlan.value,
          transactionCurrency: 'BRL',
          transactionMethod: 'creditCard'
        });

        if (error) {
          throw new Error(error);
        }

        // Passo 3: Ativação da assinatura
        try {
          const newSubscription = {
            userId,
            planId: selectedPlan.id,
            promotionId: selectedPromotionId,
            address: subscriptionAddress.address,
            city: subscriptionAddress.city,
            state: subscriptionAddress.state,
            country: subscriptionAddress.country,
            phone: `0${subscriptionAddress.phone}`,
            token: iuguToken
          };

          const { data }: { data: iAccountSubscription } = yield call(
            Connector(true, false, token).post,
            '/subscriptions',
            newSubscription
          );

          Cookies.set('al-web-token', token, {
            expires: new Date(expireIn)
          });
          localStorage.setItem('al-web-token-expires-in', `${expireIn}`);
          localStorage.setItem('al-web-user-id', `${userId}`);

          yield put(createAccountSuccess());
          yield put(setStudentRequestData(data.studentRequestId, token));
        } catch (subscriptionError) {
          let subscriptionErrorMessage =
            'Houve uma falha ao ativar a assinatura. Tente novamente ou caso tenha mais dúvidas, entre em contato com o nosso suporte.';
          let subscriptionErrorCode;

          if (subscriptionError instanceof AxiosError) {
            if (subscriptionError.response) {
              if (subscriptionError.response.data) {
                if (Array.isArray(subscriptionError.response.data.message)) {
                  const message = subscriptionError.response.data.message[0];
                  subscriptionErrorMessage = message;
                } else {
                  subscriptionErrorMessage =
                    subscriptionError.response.data.message;
                }
                subscriptionErrorCode = subscriptionError.response.data.code;
              } else {
                subscriptionErrorCode = subscriptionError.response.status;
              }
            }
          }

          yield put(
            createAccountFailure(
              subscriptionErrorMessage,
              subscriptionErrorCode
            )
          );
        }
      } catch (iuguError) {
        let iuguErrorMessage =
          'Houve uma falha ao processar sua forma de pagamento. Verifique os dados do cartão, tente novamente e em caso de dúvidas, entre em contato com o nosso suporte.';
        let iuguErrorCode;

        if (iuguError instanceof AxiosError) {
          if (iuguError.response) {
            if (iuguError.response.data) {
              iuguErrorMessage = iuguError.response.data.message;
              iuguErrorCode = iuguError.response.data.code;
            } else {
              iuguErrorCode = iuguError.response.status;
            }
          }
        }

        yield call(Connector(false, false, token).delete, '/users');

        yield put(createAccountFailure(iuguErrorMessage, iuguErrorCode));
      }
    } catch (userError) {
      let errorMessage =
        'Houve uma falha ao criar o usuário. Tente novamente ou caso tenha mais dúvidas, entre em contato com o nosso suporte.';
      let errorCode;

      if (userError instanceof AxiosError) {
        if (userError.response) {
          if (userError.response.data) {
            errorMessage = userError.response.data.message;
            errorCode = userError.response.data.code;
          } else {
            errorCode = userError.response.status;
          }
        }
      }

      yield put(createAccountFailure(errorMessage, errorCode));
    }
  } catch (generalError) {
    let generalErrorMessage =
      'Houve uma falha ao criar a sua conta. Tente novamente ou caso tenha mais dúvidas, entre em contato com o nosso suporte.';
    let generalErrorCode;

    if (generalError instanceof AxiosError) {
      if (generalError.response) {
        if (generalError.response.data) {
          generalErrorMessage = generalError.response.data.message;
          generalErrorCode = generalError.response.data.code;
        } else {
          generalErrorCode = generalError.response.status;
        }
      }
    }

    yield put(createAccountFailure(generalErrorMessage, generalErrorCode));
  } finally {
    yield put(removeLoadingModule(StoreModule.ACCOUNT));
  }
}

export function* activateAccount(action: AccountAction) {
  const { creditCard } = action.payload;
  yield put(addLoadingModule(StoreModule.ACCOUNT));

  const token = Cookies.get('al-web-token');
  const userId = localStorage.getItem('al-web-user-id') || '';

  try {
    const {
      account: { subscriptionAddress, selectedPlan, selectedPromotionId }
    }: ApplicationState = yield select((state) => state);

    // Passo 1: Cadastro no Iugu
    try {
      const { iuguToken, error }: PaymentTokenActionResponse = yield call(
        createPaymentTokenAction,
        creditCard
      );

      window.dataLayer.push({
        event: 'SUBSCRIPTION_PAYMENT',
        transactionId: iuguToken,
        transactionTotal: selectedPlan.value,
        transactionCurrency: 'BRL',
        transactionMethod: 'creditCard'
      });

      if (error) {
        throw new Error(error);
      }

      // Passo 2: Ativação da assinatura
      try {
        const newSubscription = {
          userId: Number(userId),
          planId: selectedPlan.id,
          promotionId: selectedPromotionId,
          address: subscriptionAddress.address,
          city: subscriptionAddress.city,
          state: subscriptionAddress.state,
          country: subscriptionAddress.country,
          phone: `0${subscriptionAddress.phone}`,
          token: iuguToken
        };

        const { data }: { data: iAccountSubscription } = yield call(
          Connector(true, false, token).post,
          '/subscriptions',
          newSubscription
        );

        yield put(activateAccountSuccess());
        yield put(setStudentRequestData(data.studentRequestId, token));
      } catch (subscriptionError) {
        let subscriptionErrorMessage =
          'Houve uma falha ao criar a assinatura. Tente novamente ou caso tenha mais dúvidas, entre em contato com o nosso suporte.';
        let subscriptionErrorCode;

        if (subscriptionError instanceof AxiosError) {
          if (subscriptionError.response) {
            if (subscriptionError.response.data) {
              if (Array.isArray(subscriptionError.response.data.message)) {
                const message = subscriptionError.response.data.message[0];
                subscriptionErrorMessage = message;
              } else {
                subscriptionErrorMessage =
                  subscriptionError.response.data.message;
              }
              subscriptionErrorCode = subscriptionError.response.data.code;
            } else {
              subscriptionErrorCode = subscriptionError.response.status;
            }
          }
        }

        yield put(
          activateAccountFailure(
            subscriptionErrorMessage,
            subscriptionErrorCode
          )
        );
      }
    } catch (iuguError) {
      let iuguErrorMessage =
        'Houve uma falha ao processar sua forma de pagamento. Verifique os dados do cartão, tente novamente e em caso de dúvidas, entre em contato com o nosso suporte.';
      let iuguErrorCode;

      if (iuguError instanceof AxiosError) {
        if (iuguError.response) {
          if (iuguError.response.data) {
            iuguErrorMessage = iuguError.response.data.message;
            iuguErrorCode = iuguError.response.data.code;
          } else {
            iuguErrorCode = iuguError.response.status;
          }
        }
      }

      yield put(activateAccountFailure(iuguErrorMessage, iuguErrorCode));
    }
  } catch (generalError) {
    let generalErrorMessage =
      'Houve uma falha ao ativar a sua conta. Tente novamente ou caso tenha mais dúvidas, entre em contato com o nosso suporte.';
    let generalErrorCode;

    if (generalError instanceof AxiosError) {
      if (generalError.response) {
        if (generalError.response.data) {
          generalErrorMessage = generalError.response.data.message;
          generalErrorCode = generalError.response.data.code;
        } else {
          generalErrorCode = generalError.response.status;
        }
      }
    }

    yield put(activateAccountFailure(generalErrorMessage, generalErrorCode));
  } finally {
    yield put(removeLoadingModule(StoreModule.ACCOUNT));
  }
}

export function* reactivateAccount(action: AccountAction) {
  const { creditCard } = action.payload;
  yield put(addLoadingModule(StoreModule.ACCOUNT));

  const token = Cookies.get('al-web-token');
  const userId = localStorage.getItem('al-web-user-id') || '';

  try {
    const {
      account: { account, selectedPlan, selectedPromotionId }
    }: ApplicationState = yield select((state) => state);

    // Passo 1: Cadastro no Iugu
    try {
      const { iuguToken, error }: PaymentTokenActionResponse = yield call(
        createPaymentTokenAction,
        creditCard
      );

      window.dataLayer.push({
        event: 'SUBSCRIPTION_PAYMENT',
        transactionId: iuguToken,
        transactionTotal: selectedPlan.value,
        transactionCurrency: 'BRL',
        transactionMethod: 'creditCard'
      });

      if (error) {
        throw new Error(error);
      }

      // Passo 2: Reativação da assinatura
      try {
        const newSubscription = {
          userId: Number(userId),
          hubId: account.subscription!.hubId,
          planId: selectedPlan.id,
          promotionId: selectedPromotionId,
          address: account.subscription!.address,
          city: account.subscription!.city,
          state: account.subscription!.state,
          country: account.subscription!.country,
          phone: account.subscription!.phone,
          token: iuguToken
        };

        const { data }: { data: iAccountSubscription } = yield call(
          Connector(true, false, token).put,
          '/subscriptions/reactivate',
          newSubscription
        );

        yield put(reactivateAccountSuccess());
        yield put(setStudentRequestData(data.studentRequestId, token));
      } catch (subscriptionError) {
        let subscriptionErrorMessage =
          'Houve uma falha ao criar a assinatura. Tente novamente ou caso tenha mais dúvidas, entre em contato com o nosso suporte.';
        let subscriptionErrorCode;

        if (subscriptionError instanceof AxiosError) {
          if (subscriptionError.response) {
            if (subscriptionError.response.data) {
              if (Array.isArray(subscriptionError.response.data.message)) {
                const message = subscriptionError.response.data.message[0];
                subscriptionErrorMessage = message;
              } else {
                subscriptionErrorMessage =
                  subscriptionError.response.data.message;
              }
              subscriptionErrorCode = subscriptionError.response.data.code;
            } else {
              subscriptionErrorCode = subscriptionError.response.status;
            }
          }
        }

        yield put(
          reactivateAccountFailure(
            subscriptionErrorMessage,
            subscriptionErrorCode
          )
        );
      }
    } catch (iuguError) {
      let iuguErrorMessage =
        'Houve uma falha ao processar sua forma de pagamento. Verifique os dados do cartão, tente novamente e em caso de dúvidas, entre em contato com o nosso suporte.';
      let iuguErrorCode;

      if (iuguError instanceof AxiosError) {
        if (iuguError.response) {
          if (iuguError.response.data) {
            iuguErrorMessage = iuguError.response.data.message;
            iuguErrorCode = iuguError.response.data.code;
          } else {
            iuguErrorCode = iuguError.response.status;
          }
        }
      }

      yield put(reactivateAccountFailure(iuguErrorMessage, iuguErrorCode));
    }
  } catch (generalError) {
    let generalErrorMessage =
      'Houve uma falha ao ativar a sua conta. Tente novamente ou caso tenha mais dúvidas, entre em contato com o nosso suporte.';
    let generalErrorCode;

    if (generalError instanceof AxiosError) {
      if (generalError.response) {
        if (generalError.response.data) {
          generalErrorMessage = generalError.response.data.message;
          generalErrorCode = generalError.response.data.code;
        } else {
          generalErrorCode = generalError.response.status;
        }
      }
    }

    yield put(reactivateAccountFailure(generalErrorMessage, generalErrorCode));
  } finally {
    yield put(removeLoadingModule(StoreModule.ACCOUNT));
  }
}

export function* cancelAccount(action: AccountAction) {
  yield put(addLoadingModule(StoreModule.ACCOUNT));
  const { cancelationReason } = action.payload;

  try {
    yield call(Connector(true).put, '/subscriptions/cancel', {
      cancelationReason
    });

    const token = Cookies.get('al-web-token');

    Cookies.remove('al-web-token');
    localStorage.removeItem('al-web-token-expires-in');
    localStorage.removeItem('al-web-user-id');

    try {
      yield call(Connector(false, false, token).delete, 'sessions/logout');

      yield put(logoutSuccess());
    } catch (err) {
      yield put(logoutError());
    }

    yield put(resetAccountState());
    yield put(removeAllPopUpItems());
    yield put(cancelAccountSuccess());
  } catch (error) {
    let errorMessage =
      'Houve uma falha ao cancelar sua assinatura. Entre em contato conosco';
    let errorCode;

    if (error instanceof AxiosError) {
      if (error.response) {
        if (error.response.data) {
          errorMessage = error.response.data.message;
          errorCode = error.response.data.code;
        } else {
          errorCode = error.response.status;
        }
      }
    }

    yield put(cancelAccountFailure(errorMessage, errorCode));
  } finally {
    yield put(removeLoadingModule(StoreModule.ACCOUNT));
  }
}

export function* updateAccountCreditCard(action: AccountAction) {
  yield put(addLoadingModule(StoreModule.ACCOUNT));
  const { creditCard } = action.payload;

  try {
    const { iuguToken, error }: PaymentTokenActionResponse = yield call(
      createPaymentTokenAction,
      creditCard
    );

    if (error) {
      throw new Error(error);
    }

    yield call(Connector(true).put, '/subscriptions/update-payment-method', {
      token: iuguToken
    });

    toast.success('Cartão de crédito atualizado com sucesso');

    yield put(updateAccountCreditCardSuccess());
    yield put(fetchAccountRequest());
  } catch (error) {
    let errorMessage =
      'Houve uma falha ao atualizar sua forma de pagamento. Entre em contato conosco!';
    let errorCode;

    if (error instanceof AxiosError) {
      if (error.response) {
        if (error.response.data) {
          errorMessage = error.response.data.message;
          errorCode = error.response.data.code;
        } else {
          errorCode = error.response.status;
        }
      }
    }

    toast.error(errorMessage);

    yield put(updateAccountCreditCardFailure(errorMessage, errorCode));
    yield put(removeLoadingModule(StoreModule.ACCOUNT));
  }
}

export function* updateAccountData(action: AccountAction) {
  yield put(addLoadingModule(StoreModule.ACCOUNT));

  try {
    const { updateAccount } = action.payload;

    const newUserData = {
      password: updateAccount.currentPassword,
      data: updateAccount.newPassword
        ? {
            name: updateAccount.name,
            email: updateAccount.email,
            password: updateAccount.newPassword,
            phone: `0${updateAccount.phone}`
          }
        : {
            name: updateAccount.name,
            email: updateAccount.email,
            phone: `0${updateAccount.phone}`
          }
    };

    yield call(Connector().put, '/users/profile', newUserData);

    toast.success('Dados atualizados com sucesso');

    yield put(updateAccountDataSuccess());
    yield put(fetchAccountRequest());
  } catch (error) {
    let errorMessage =
      'Houve uma falha ao atualizar as informações da sua conta. Tente novamente ou caso tenha mais dúvidas, entre em contato com o nosso suporte.';
    let errorCode;

    if (error instanceof AxiosError) {
      if (error.response) {
        if (error.response.data) {
          errorMessage = error.response.data.message;
          errorCode = error.response.data.code;
        } else {
          errorCode = error.response.status;
        }
      }
    }

    toast.error(errorMessage);

    yield put(updateAccountDataFailure(errorMessage, errorCode));
    yield put(removeLoadingModule(StoreModule.ACCOUNT));
  }
}

export function* updateAccountAvatar(action: AccountAction) {
  yield put(addLoadingModule(StoreModule.ACCOUNT));

  try {
    const {
      account: { account }
    }: ApplicationState = yield select((state: ApplicationState) => state);
    const { avatar } = action.payload;

    const user = new FormData();

    user.append('avatar', avatar);

    yield call(Connector().put, `/users/upload-avatar?id=${account.id}`, user);

    toast.success('Foto de perfil atualizada com sucesso');

    yield put(updateAccountAvatarSuccess());
    yield put(fetchAccountRequest());
  } catch (error) {
    let errorMessage =
      'Houve uma falha ao atualizar a foto de perfil da sua conta. Tente novamente ou caso tenha mais dúvidas, entre em contato com o nosso suporte.';
    let errorCode;

    if (error instanceof AxiosError) {
      if (error.response) {
        if (error.response.data) {
          errorMessage = error.response.data.message;
          errorCode = error.response.data.code;
        } else {
          errorCode = error.response.status;
        }
      }
    }

    toast.error(errorMessage);

    yield put(updateAccountAvatarFailure(errorMessage, errorCode));
    yield put(removeLoadingModule(StoreModule.ACCOUNT));
  }
}

export function* deleteAccountAvatar() {
  yield put(addLoadingModule(StoreModule.ACCOUNT));

  try {
    const {
      account: { account }
    }: ApplicationState = yield select((state: ApplicationState) => state);

    yield call(Connector().delete, `/users/unset-avatar?id=${account.id}`);

    toast.success('Foto de perfil removida com sucesso');

    yield put(deleteAccountAvatarSuccess());
    yield put(fetchAccountRequest());
  } catch (error) {
    let errorMessage =
      'Houve uma falha ao remover a foto de perfil da sua conta. Tente novamente ou caso tenha mais dúvidas, entre em contato com o nosso suporte.';
    let errorCode;

    if (error instanceof AxiosError) {
      if (error.response) {
        if (error.response.data) {
          errorMessage = error.response.data.message;
          errorCode = error.response.data.code;
        } else {
          errorCode = error.response.status;
        }
      }
    }

    toast.error(errorMessage);

    yield put(deleteAccountAvatarFailure(errorMessage, errorCode));
    yield put(removeLoadingModule(StoreModule.ACCOUNT));
  }
}
