import { Action } from 'redux';
import { action } from 'typesafe-actions';

import { Plan, Promotion } from 'helpers/models';

import { PlanTypes } from './types';

export const fetchPlansRequest = (): Action =>
  action(PlanTypes.FETCH_PLANS_REQUEST);

export const fetchPlansSuccess = (
  plans: Plan[],
  promotions: Promotion[],
  freeTrialPlan: Plan
): Action =>
  action(PlanTypes.FETCH_PLANS_SUCCESS, { plans, promotions, freeTrialPlan });

export const fetchPlansError = (): Action =>
  action(PlanTypes.FETCH_PLANS_ERROR);
