/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { ApplicationState } from 'helpers/types';

import { useNavigation } from 'contexts/NavigationContext';

import CreditCardForm from '../CreditCardForm';
import PlanSelection from '../PlanSelection';
import RegularPlanConfirm from '../RegularPlanConfirm/RegularPlanConfirm';
import SpecialPlanDocumentForm from '../SpecialPlanDocumentForm/SpecialPlanDocumentForm';
import * as S from './styles';

const ReactivateNavigation = () => {
  const {
    state: { step }
  } = useNavigation();

  const { account, selectedPlan } = useSelector(
    (state: ApplicationState) => state.account
  );

  const { search } = useLocation();

  const [isWebview, setIsWebview] = useState(false);

  useEffect(() => {
    setIsWebview(search.includes('isWebview'));
  }, []);

  const handleNextOnWebview = () => {
    const { ReactNativeWebView }: any = { ...window };

    const msg = JSON.stringify({
      email: account.email,
      password: account.password
    });

    ReactNativeWebView.postMessage(msg);
  };

  const getStepComponent = () => {
    switch (step) {
      case 1:
        return <PlanSelection />;
      case 2:
        return <CreditCardForm />;
      case 3:
        if (selectedPlan.id === 2) {
          return (
            <SpecialPlanDocumentForm
              isWebview={isWebview}
              handleNextOnWebview={handleNextOnWebview}
            />
          );
        }

        return (
          <RegularPlanConfirm
            isWebview={isWebview}
            handleNextOnWebview={handleNextOnWebview}
          />
        );
      default:
        break;
    }
  };

  return (
    <S.ReactivateAccountNavigationWrapper>
      {getStepComponent()}
    </S.ReactivateAccountNavigationWrapper>
  );
};

export default ReactivateNavigation;
