import { Status } from './enums';
import { iAccount } from './interfaces/iAccount';
import { iAccountSubscriptionPlan } from './interfaces/iAccountSubscriptionPlan';
import {
  Category,
  User,
  UserEdit,
  Audio,
  Plan,
  Playlist,
  SearchResponse,
  TeachingInstitution,
  Contact,
  NewsArticle,
  StudentRequest,
  Subscription,
  SubscriptionUserData
} from './models';
import {
  CreditCardInputError,
  StudentRequestInputError,
  IuguCreditCard,
  IuguPaymentExtraInfo,
  PasswordChecklistRules,
  UserDataInputError,
  UserEditInputError,
  RecoverPasswordInputError
} from './types';

export const categoryInitialState: Category = {
  id: 0,
  label: '',
  name: '',
  description: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  superCategoryId: 0,
  playlists: []
};

export const playlistInitialState: Playlist = {
  id: 0,
  name: '',
  description: '',
  status: Status.DISABLED,
  coverUrl: '',
  coverUrlFormat: '',
  removed: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  removedAt: new Date(),
  userId: 0,
  audios: [],
  categories: [],
  favorite: false,
  duration: 0
};

export const audioInitialState: Audio = {
  id: 0,
  title: '',
  fileName: '',
  description: '',
  transcription: '',
  url: '',
  status: Status.DISABLED,
  removed: false,
  download: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  removedAt: new Date(),
  audioFolderOrder: 0,
  userId: 0,
  folderId: 0,
  audioPlaylistOrder: 0,
  parentId: 0,
  duration: 0,
  version: 0
};

export const userEditInitialState: UserEdit = {
  password: '',
  data: {
    name: '',
    email: '',
    phone: '',
    password: ''
  }
};

export const planInitialState: Plan = {
  id: 0,
  name: '',
  description: '',
  value: 0,
  status: 'DISABLED',
  promotions: []
};

export const accountSubscriptionPlanInitialState: iAccountSubscriptionPlan = {
  id: 0,
  name: '',
  value: 0
};

export const searchInitialState: SearchResponse = {
  audios: [],
  playlists: []
};

export const creditCardInitialState: IuguCreditCard = {
  number: '',
  month: '',
  year: '',
  first_name: '',
  last_name: '',
  verification_value: ''
};

export const paymentExtraInfoInitialState: IuguPaymentExtraInfo = {
  bin: '',
  brand: '',
  display_number: '',
  holder_name: '',
  month: 0,
  year: 0
};

export const institutionInitialState: TeachingInstitution = {
  id: 0,
  name: '',
  cnpj: '',
  code: ''
};

export const passwordChecklistRulesInitialState: PasswordChecklistRules = {
  minLength: false,
  hasNumber: false,
  match: false
};

export const userDataInputErrorInitialState: UserDataInputError = {
  name: false,
  cpf: false,
  email: false,
  password: false,
  passwordAgain: false,
  passwordValidation: false,
  passwordsDontMatch: false,
  existingUser: false
};

export const userEditInputErrorInitialState: UserEditInputError = {
  name: false,
  email: false,
  phone: false,
  currentPassword: false,
  password: false,
  passwordAgain: false,
  passwordValidation: false,
  passwordsDontMatch: false
};

export const creditCardInputErrorInitialState: CreditCardInputError = {
  number: false,
  month: false,
  year: false,
  verification_value: false,
  fullname: false,
  fulldate: false
};

export const studentRequestInputErrorInitialState: StudentRequestInputError = {
  files: false,
  teachingInstitution: false
};

export const contactInitialState: Contact = {
  name: '',
  email: '',
  phone: '',
  message: '',
  acceptedTerm: false
};

export const articleInitialState: NewsArticle = {
  id: 0,
  title: '',
  description: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  coverFilename: '',
  publish: false,
  coverUrl: ''
};

export const userInitialState: User = {
  id: 0,
  name: '',
  email: '',
  cpf: '',
  avatar: '',
  password: '',
  isAdmin: false,
  removed: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  removedAt: new Date()
};

export const subscriptionUserDataInitialState: SubscriptionUserData = {
  address: '',
  city: '',
  state: '',
  country: '',
  phone: ''
};

export const subscriptionInitialState: Subscription = {
  id: 0,
  createdAt: new Date(),
  updatedAt: new Date(),
  canceledAt: new Date(),
  subscriptionStatus: 'ENABLED',
  expiresTrialIn: new Date(),
  cancelationReason: '',
  subsIdHubPayment: 0,
  promotionId: 0,
  walletId: 0,
  userId: 0,
  planId: 0,
  teachingInstitutionId: 0,
  userData: {
    address: '',
    city: '',
    country: '',
    phone: '',
    state: ''
  },
  hubId: ''
};

export const studentRequestInitialState: StudentRequest = {
  files: [],
  teachingInstitution: institutionInitialState
};

export const recoverPasswordInputErrorInitialState: RecoverPasswordInputError =
  {
    email: false,
    token: false,
    password: false,
    passwordAgain: false,
    passwordValidation: false,
    passwordsDontMatch: false
  };

export const accountInitialState: iAccount = {
  id: 0,
  name: '',
  email: '',
  cpf: '',
  avatar: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  removedAt: new Date(),
  studentDocumentSended: null,
  isAdmin: false,
  password: ''
};
