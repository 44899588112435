import styled from 'styled-components';

import { RichTextSection } from 'components/RichText';

export const NewsArticleWrapper = styled.div`
  ${({ theme }) => theme.bp.Fluid};
  padding-bottom: 108px;
`;

export const NewsArticleContainer = styled.div`
  ${({ theme }) => theme.bp.Large};
`;

export const NewsArticle = styled.article`
  max-width: 770px;
`;

export const NewsArticleHeader = styled.header`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  .btn__link {
    margin-bottom: 30px;
    font-weight: 300;
    font-size: 14px;

    svg {
      margin-right: 6px;

      path {
        fill: none;
      }
    }
  }

  .news-article__date {
    font-size: 14px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.yellow};
    margin-bottom: 8px;
  }

  .news-article__title {
    font-weight: 900;
  }
    

  ${({ theme }) => theme.mq.lessThan('sm')`
    .news-article__title {
      font-size: 24px;
      line-height: 32px;
    }
  `}
  
  ${({ theme }) => theme.mq.greaterThan('sm')`
    .news-article__title {
      font-size: 32px;
      line-height: 47px;
    }
  `}
  }
`;

export const NewsArticleCover = styled.img`
  margin-bottom: 32px;
  border-radius: 8px;
  width: 100%;
`;

export const NewsArticleContent = styled(RichTextSection)`
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 32px;
`;
