import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';

import classNames from 'classnames';

import { fetchAccountRequest } from 'store/ducks/account';
import { setOverlayActive } from 'store/ducks/overlay';


import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
import { ReactComponent as PlaylegisYellowLogo } from 'assets/icons/playlegis-logo-yellow.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from 'helpers/constants';
import { authHeaderMenu, authHeaderPopUpMenu } from 'helpers/links';
import { overlaySelector, accountSelector } from 'helpers/selectors';

import { ButtonLink as Button } from 'components/Button';
import { Mobile, Desktop } from 'components/Responsive';
import UserMenu from 'components/UserMenu';

import * as S from './styled';

const pagesWithLightMode = [
  PUBLIC_ROUTES.FAQ,
  PUBLIC_ROUTES.PRIVACY_POLICY,
  PUBLIC_ROUTES.COOKIES_POLICY,
  PUBLIC_ROUTES.TERMS_OF_USE,
  PUBLIC_ROUTES.ABOUT,
  PUBLIC_ROUTES.NEWS
];

const AuthNavbar = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { isOverlayActive } = useSelector(overlaySelector);
  const { account } = useSelector(accountSelector);

  const [currentLocation, setCurrentLocation] = useState(location.pathname);
  const [isLightMode, setIsLightMode] = useState(false);

  useEffect(() => {
    dispatch(fetchAccountRequest());
  }, []);

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location]);

  useEffect(() => {
    if (currentLocation.match('noticias')) {
      setIsLightMode(true);
    } else {
      setIsLightMode(pagesWithLightMode.includes(currentLocation));
    }
  }, [currentLocation]);

  const toggleDisplay = () => {
    if (!isOverlayActive) {
      dispatch(setOverlayActive(true));
    } else {
      dispatch(setOverlayActive(false));
    }
  };

  return (
    <S.NavbarContainer
      className={classNames({
        'nav--light-mode': isLightMode,
        'nav--dark-mode': !isLightMode
      })}
    >
      <S.Row>
        <S.Logo
          className={classNames({
            'logo--is-light': !isLightMode && !isOverlayActive,
            'logo--is-dark': isLightMode || (!isLightMode && isOverlayActive)
          })}
        >
          <Link to="/">
            <PlaylegisYellowLogo />
          </Link>
        </S.Logo>

        <S.Nav
          className={classNames({
            'nav--is-open': isOverlayActive,
            'nav--is-closed': !isOverlayActive
          })}
        >
          <Mobile>
            <div className="nav__wrapper">
              {authHeaderMenu?.map(({ label, to }) => (
                <S.Link
                  key={label}
                  className={classNames({
                    active: currentLocation === to
                  })}
                  to={to}
                  onClick={toggleDisplay}
                >
                  <div className="nav__item">{label}</div>
                </S.Link>
              ))}
              {authHeaderPopUpMenu?.map(({ label, to }) => (
                <S.Link
                  key={label}
                  className={classNames({
                    active: currentLocation === to,
                    'link--has-separator': to === PRIVATE_ROUTES.ACCOUNT
                  })}
                  to={to}
                  onClick={toggleDisplay}
                >
                  <div className="nav__item">{label}</div>
                </S.Link>
              ))}
            </div>
          </Mobile>
          <Desktop>
            {authHeaderMenu?.map(({ label, to, isButton }) =>
              !isButton ? (
                <S.Link
                  key={label}
                  className={classNames({
                    active: currentLocation === to
                  })}
                  to={to}
                  $isLightMode={isLightMode}
                >
                  <div className="nav__item">{label}</div>
                </S.Link>
              ) : (
                <Button
                  key={label}
                  to={to}
                  variant={isLightMode ? 'blue' : 'white'}
                  hasIcon
                >
                  <div className="nav__item">
                    <UserIcon />
                    {label}
                  </div>
                </Button>
              )
            )}

            <UserMenu
              avatar={account.avatar}
              name={account.name.split(' ')[0]}
              colorMode={isLightMode ? 'light' : 'dark'}
            />
          </Desktop>
        </S.Nav>

        <Mobile>
          <S.Link
            className={classNames({
              active: true,
              'link--is-small': true
            })}
            to={PRIVATE_ROUTES.ACCOUNT}
          >
            {account.avatar ? (
              <div className="nav__user-avatar">
                <img
                  src={account.avatar}
                  alt="Foto de perfil do usuário"
                  title="Foto de perfil do usuário"
                />
              </div>
            ) : (
              <div className="nav__user-initials">
                {account.name.slice(0, 1)}
              </div>
            )}
          </S.Link>

          <S.NavDisplaySwitch
            onClick={toggleDisplay}
            className={classNames({
              'nav--is-open': isOverlayActive,
              'nav--is-closed': !isOverlayActive
            })}
            $isLightMode={isLightMode}
          >
            {isOverlayActive ? <CloseIcon /> : <MenuIcon />}
          </S.NavDisplaySwitch>
        </Mobile>
      </S.Row>
    </S.NavbarContainer>
  );
};

export default AuthNavbar;
