import Lottie from 'react-lottie-player';
import { useSelector } from 'react-redux';

import animationData from 'assets/animations/loading.json';
import { loadingSelector } from 'helpers/selectors';

import * as S from './styled';

const LoadingOverlay = () => {
  const { modules } = useSelector(loadingSelector);

  return modules.length > 0 ? (
    <S.LoadingOverlayWrapper>
      <S.LoadingOverlayContainer>
        <Lottie
          play
          loop
          animationData={animationData}
          style={{ width: '100px', height: '100px' }}
          rendererSettings={{
            preserveAspectRatio: 'xMidYMid slice'
          }}
        />
      </S.LoadingOverlayContainer>
    </S.LoadingOverlayWrapper>
  ) : null;
};

export default LoadingOverlay;
