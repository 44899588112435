import { FormEvent, useEffect, useRef, useState } from 'react';
import Recaptcha from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { ValidationError as YupValidationError } from 'yup';

import { sendContactData } from 'store/ducks/contact';

import { contactInitialState } from 'helpers/initialState';
import { Contact } from 'helpers/models';
import { contactValidation } from 'helpers/validation/contact';

import Button from 'components/Button/HTMLButton';
import Input from 'components/Input';
import Textarea from 'components/Textarea';

import * as S from './styled';

const ContactPage = () => {
  const dispatch = useDispatch();
  const [contact, setContact] = useState<Contact>(contactInitialState);
  const recaptchaRef = useRef<Recaptcha>(null);
  const [captchaSuccess, setCaptchaSuccess] = useState(false);

  // Scrolls to top smoothly
  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });

    document.body.style.backgroundColor = '#272F4C';

    const onUnmount = () => {
      document.body.style.backgroundColor = '#FFF';
    };

    return onUnmount;
  }, []);

  const handleContactFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      contactValidation(contact);

      dispatch(sendContactData({ ...contact, acceptedTerm: captchaSuccess }));

      toast.success('Dados de contato enviados com sucesso');

      setContact(contactInitialState);
      setCaptchaSuccess(false);
      recaptchaRef.current?.reset();
    } catch (error) {
      if (error instanceof YupValidationError) {
        error.inner.forEach((e, index) => {
          toast.error(e.message);
        });
      }
    }
  };

  return (
    <S.ContactWrapper>
      <S.ContactContainer>
        <S.ContactHeader>
          <h1>Contato</h1>
          <h2>Fale diretamente conosco e tire suas dúvidas</h2>
          <p>
            Você pode nos enviar as suas dúvidas, sugestões, críticas e os
            elogios também. Sinta-se a vontade!
          </p>
        </S.ContactHeader>

        <S.ContactForm onSubmit={handleContactFormSubmit}>
          <div className="contact__input-group">
            <S.FormFieldset>
              <Input
                type="text"
                id="name"
                name="name"
                placeholder="Nome"
                className="contact__input contact__input--is-name"
                value={contact.name}
                onChange={(e) =>
                  setContact({
                    ...contact,
                    name: e.target.value
                  })
                }
              />

              <Input
                type="email"
                id="email"
                name="email"
                placeholder="E-mail"
                className="contact__input contact__input--is-email"
                value={contact.email}
                onChange={(e) =>
                  setContact({
                    ...contact,
                    email: e.target.value
                  })
                }
              />

              <Input
                type="tel"
                id="phone"
                name="phone"
                placeholder="Telefone com DDD"
                className="contact__input contact__input--is-phone"
                value={contact.phone}
                onChange={(e) =>
                  setContact({
                    ...contact,
                    phone: e.target.value
                  })
                }
              />
            </S.FormFieldset>

            <S.FormFieldset>
              <Textarea
                placeholder="Mensagem..."
                className="contact__textarea"
                value={contact.message}
                onChange={(e) =>
                  setContact({
                    ...contact,
                    message: e.target.value
                  })
                }
              />
            </S.FormFieldset>
          </div>

          <S.ContactFormBottom>
            <Button type="submit" variant="yellow" disabled={!captchaSuccess}>
              Enviar
            </Button>

            <Recaptcha
              ref={recaptchaRef}
              sitekey="6LeKJ-4bAAAAAFkChRgolKueo38vGFP6KlT67Uin"
              onChange={(token) => setCaptchaSuccess(!!token)}
              hl="pt-BR"
            />
          </S.ContactFormBottom>
        </S.ContactForm>
      </S.ContactContainer>
    </S.ContactWrapper>
  );
};

export default ContactPage;
