export const formatSecondsAsTime = (secs: number) => {
  const hr = Math.floor(secs / 3600);
  let min: (number | string) = Math.floor((secs - (hr * 3600)) / 60);
  let sec: (number | string) = Math.floor(secs - (hr * 3600) - (min * 60));
  if (min < 10) {
    min = `0${min}`;
  }
  if (sec < 10) {
    sec = `0${sec}`;
  }
  return `${min}:${sec}`;
};

export const formatSecondsAsHourTime = (secs: number) => {
  const time = (new Date(secs * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/);
  return time && time[0];
};