import { Reducer } from 'redux';

import { audioInitialState } from 'helpers/initialState';

import { PlayerTypes, PlayerState, PlayerAction } from './types';
import { PLAYER_INITIAL_STATE } from './utils';

const reducer: Reducer<PlayerState, PlayerAction> = (
  state: PlayerState = PLAYER_INITIAL_STATE,
  action: PlayerAction,
) => {
  switch (action.type) {
    case PlayerTypes.OPEN_PLAYER: {
      return {
        ...state,
        isPlayerOpen: action.payload.isPlayerOpen,
      };
    }
    case PlayerTypes.CLOSE_PLAYER: {
      return {
        ...state,
        isPlayerOpen: action.payload.isPlayerOpen,
      };
    }
    case PlayerTypes.SET_CURRENT_PLAYLIST: {
      return {
        ...state,
        playlist: action.payload.playlist,
      };
    }
    case PlayerTypes.SET_CURRENT_AUDIO: {
      const currentAudio = state.playlist.audios[
        action.payload.currentAudioId
      ] || audioInitialState;

      return {
        ...state,
        currentAudioId: action.payload.currentAudioId,
        currentAudio,
      };
    }
    case PlayerTypes.SET_PLAYER_PAUSED: {
      return {
        ...state,
        isPaused: action.payload.isPaused,
      }
    }
    default: {
      return state;
    }
  }
};

export default reducer;
