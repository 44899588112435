import styled from 'styled-components';

export const AccountWrapper = styled.section`
  ${({ theme }) => theme.bp.Fluid};
  background-color: ${({ theme }) => theme.colors.blue};
`;

export const AccountContainer = styled.div`
  ${({ theme }) => theme.bp.Large};
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mq.lessThan('md')`
    padding-bottom: 100px;
  `};

  ${({ theme }) => theme.mq.greaterThan('md')`
    padding-bottom: 200px;
  `};
`;

export const AccountHeader = styled.header`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colors.yellow};
    text-transform: uppercase;
  }

  h2 {
    font-size: 32px;
    font-weight: 900;
    margin-bottom: 50px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const AccountFormHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  h3 {
    font-size: 18px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
  }

  .btn__link {
    font-size: 16px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const AccountForm = styled.form`
  .input__user,
  .input__card {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`;

export const FormGroup = styled.div`
  display: flex;

  ${({ theme }) => theme.mq.lessThan('sm')`
    flex-direction: column;
  `}
`;

export const AccountColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => theme.mq.greaterThan('sm')`
    max-width: 400px;

    &:not(:last-child) {
      margin-right: 100px;
    }
  `}
`;