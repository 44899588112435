import { takeLatest, all, AllEffect, ForkEffect } from 'redux-saga/effects';

import * as AccountSagas from './ducks/account/sagas';
import { AccountTypes } from './ducks/account/types';
import * as CategorySagas from './ducks/category/sagas';
import { CategoryTypes } from './ducks/category/types';
import * as ContactSagas from './ducks/contact/sagas';
import { ContactTypes } from './ducks/contact/types';
import * as InstitutionalSagas from './ducks/institutional/sagas';
import { InstitutionalTypes } from './ducks/institutional/types';
import * as LoginSagas from './ducks/login/sagas';
import { LoginTypes } from './ducks/login/types';
import * as PlanSagas from './ducks/plan/sagas';
import { PlanTypes } from './ducks/plan/types';
import * as PlaylistSagas from './ducks/playlist/sagas';
import { PlaylistTypes } from './ducks/playlist/types';
import * as RecoverPasswordSagas from './ducks/recoverPassword/sagas';
import { RecoverPasswordTypes } from './ducks/recoverPassword/types';
import * as SearchSagas from './ducks/search/sagas';
import { SearchTypes } from './ducks/search/types';
import * as StudentSagas from './ducks/student/sagas';
import { StudentTypes } from './ducks/student/types';
import * as TeachingInstitutionSagas from './ducks/teachingInstitution/sagas';
import { TeachingInstitutionTypes } from './ducks/teachingInstitution/types';

export default function* rootSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  unknown,
  unknown
> {
  return yield all([
    takeLatest(
      RecoverPasswordTypes.RECOVER_PASSWORD_REQUEST,
      RecoverPasswordSagas.recoverPassword
    ),
    takeLatest(
      RecoverPasswordTypes.SET_NEW_PASSWORD_REQUEST,
      RecoverPasswordSagas.setNewPassword
    ),
    takeLatest(LoginTypes.LOGIN_REQUEST, LoginSagas.login),
    takeLatest(LoginTypes.LOGOUT_REQUEST, LoginSagas.logout),
    takeLatest(
      InstitutionalTypes.HOME_DATA_REQUEST,
      InstitutionalSagas.fetchHome
    ),
    takeLatest(
      InstitutionalTypes.ABOUT_US_DATA_REQUEST,
      InstitutionalSagas.fetchAboutUs
    ),
    takeLatest(
      InstitutionalTypes.TERMS_DATA_REQUEST,
      InstitutionalSagas.fetchTerms
    ),
    takeLatest(
      InstitutionalTypes.FAQ_DATA_REQUEST,
      InstitutionalSagas.fetchFAQ
    ),
    takeLatest(
      InstitutionalTypes.NEWS_DATA_REQUEST,
      InstitutionalSagas.fetchNews
    ),
    takeLatest(ContactTypes.SEND_CONTACT_REQUEST, ContactSagas.sendContact),
    takeLatest(
      CategoryTypes.FETCH_SUPER_CATEGORY_REQUEST,
      CategorySagas.fetchHome
    ),
    takeLatest(
      CategoryTypes.FETCH_SUPER_CATEGORY_LAST_UPDATES_REQUEST,
      CategorySagas.fetchLastUpdates
    ),
    takeLatest(
      PlaylistTypes.FETCH_PLAYLIST_REQUEST,
      PlaylistSagas.fetchPlaylist
    ),
    takeLatest(PlanTypes.FETCH_PLANS_REQUEST, PlanSagas.fetchPlans),
    takeLatest(SearchTypes.SEARCH_REQUEST, SearchSagas.searchTerms),
    takeLatest(
      TeachingInstitutionTypes.FETCH_INSTITUTIONS_REQUEST,
      TeachingInstitutionSagas.fetchInstitutions
    ),
    takeLatest(StudentTypes.CREATE_STUDENT_REQUEST, StudentSagas.createStudent),
    takeLatest(
      AccountTypes.VALIDATE_ACCOUNT_REQUEST,
      AccountSagas.validateAccount
    ),
    takeLatest(AccountTypes.FETCH_ACCOUNT_REQUEST, AccountSagas.fetchAccount),
    takeLatest(AccountTypes.CREATE_ACCOUNT_REQUEST, AccountSagas.createAccount),
    takeLatest(
      AccountTypes.REACTIVATE_ACCOUNT_REQUEST,
      AccountSagas.reactivateAccount
    ),
    takeLatest(AccountTypes.CANCEL_ACCOUNT_REQUEST, AccountSagas.cancelAccount),
    takeLatest(
      AccountTypes.UPDATE_ACCOUNT_CREDIT_CARD_REQUEST,
      AccountSagas.updateAccountCreditCard
    ),
    takeLatest(
      AccountTypes.UPDATE_ACCOUNT_DATA_REQUEST,
      AccountSagas.updateAccountData
    ),
    takeLatest(
      AccountTypes.UPDATE_ACCOUNT_AVATAR_REQUEST,
      AccountSagas.updateAccountAvatar
    ),
    takeLatest(
      AccountTypes.DELETE_ACCOUNT_AVATAR_REQUEST,
      AccountSagas.deleteAccountAvatar
    )
  ]);
}
