export const blue = '#272F4C';
export const blueLight = '#343F65';
export const blueLighter = '#91A2DB';
export const blueDark = '#1B2034';
export const blueDarker = 'rgba(39,47,76,0.32)';
export const blue3 = '#484F69';
export const yellow = '#DBB200';
export const yellowDark = '#CFA800';
export const yellowDarker = 'rgba(219,178,0,0.32)';
export const grey = '#7C8299';
export const greyLight = '#DFDFDF';
export const greyLighter = '#F2F2F2';
export const greyDark = '#BEBEBE';
export const white = '#fff';
export const whiteDarker = 'rgba(255,255,255,0.32)';
export const error = '#FF3A3A';
export const blueOpacity1 = 'rgba(219, 178, 0, 0.32)';
export const greyOpacity1 = 'rgba(0, 0, 0, 0.52)';