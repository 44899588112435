import { call, put } from 'redux-saga/effects';

import Connector from 'services/axios/axios';

import { StoreModule } from 'helpers/enum/StoreModule';

import { addLoadingModule, removeLoadingModule } from '../loading/actions';
import { sendContactSuccess, sendContactError } from './actions';
import { ContactAction } from './types';

export function* sendContact(action: ContactAction) {
  yield put(addLoadingModule(StoreModule.CONTACT));
  const { contact } = action.payload;

  try {
    yield call(Connector().post, '/landing-page/contacts', contact);

    yield put(sendContactSuccess());
  } catch (err) {
    yield put(sendContactError());
  } finally {
    yield put(removeLoadingModule(StoreModule.CONTACT));
  }
}
