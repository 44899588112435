import { useDispatch, useSelector } from 'react-redux';

import { PUBLIC_ROUTES } from 'helpers/constants';
import { recoverPasswordSelector, studentSelector } from 'helpers/selectors';

import { ButtonLink as Link } from 'components/Button';
import { useNavigation } from 'contexts/NavigationContext';

import * as S from '../../styled';
import EmailForm from '../EmailForm/EmailForm';
import TokenPasswordForm from '../TokenPasswordForm/TokenPasswordForm';

const RecoverPasswordNavigation = () => {
  const {
    state: { step }
  } = useNavigation();

  return (
    <S.RecoverPasswordWrapper>
      <S.RecoverPasswordContainer>
        {step === 1 && <EmailForm />}
        {step === 2 && <TokenPasswordForm />}

        <S.LoginLink>
          <Link to={PUBLIC_ROUTES.LOGIN} variant="link">
            Lembrei minha senha
          </Link>
        </S.LoginLink>
      </S.RecoverPasswordContainer>
    </S.RecoverPasswordWrapper>
  );
};

export default RecoverPasswordNavigation;
