/* eslint-disable react/display-name */
import { forwardRef, TextareaHTMLAttributes } from 'react';

import classNames from 'classnames';

import * as S from './styled';

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  hasShadow?: boolean;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props: TextareaProps, ref) => {
    const { hasShadow, className, children, ...rest } = props;

    return (
      <S.TextAreaWrapper
        className={classNames({
          [`${className}`]: className,
          'input--has-shadow': hasShadow
        })}
        ref={ref}
        {...rest}
      >
        {children}
      </S.TextAreaWrapper>
    );
  }
);

TextArea.defaultProps = {
  hasShadow: false
};

export default TextArea;
