import styled from 'styled-components';

export const CategoryWrapper = styled.div`
  ${({ theme }) => theme.bp.Fluid};
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mq.greaterThan('md')`
    padding-bottom: 100px;
  `};
`;

export const CategoryContainer = styled.div`
  ${({ theme }) => theme.bp.Large};
  padding-bottom: 100px;

  ${({ theme }) => theme.mq.lessThan('sm')`
    .post-header__input-title {
      margin-bottom: 0;

      h2 {
        margin-bottom: 0;
      }
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
  `}
`;
