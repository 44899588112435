import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export const useWebviewChecker = () => {
  const { search } = useLocation();
  const [onWebview, setOnWebview] = useState(true);
  
  useEffect(() => {
    if (!search.includes('isWebview')) {
      setOnWebview(false);
    }
  }, [search]);

  return { onWebview };
}; 