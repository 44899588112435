import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ApplicationState } from 'helpers/types';

const Overlay = () => {
  const isOverlayActive = useSelector(
    (state: ApplicationState) => state.overlay.isOverlayActive
  );

  useEffect(() => {
    if (isOverlayActive) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isOverlayActive]);

  return <Fragment />;
};

export default Overlay;
