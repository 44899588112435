import styled from 'styled-components';

export const CancelSubscriptionWrapper = styled.div`
  ${({ theme }) => theme.bp.Fluid};
  background-color: ${({ theme }) => theme.colors.blue};

  ${({ theme }) => theme.mq.lessThan('sm')`
    padding-top: 28px!important;

    &:not(.is--success) {
      padding-bottom: 100px!important;
    }
    
    &.is--success {
      padding-bottom: 35px!important;
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`

    &:not(.is--success) {
      padding-top: 82px;
      padding-bottom: 155px;
    }
    
    &.is--success {
      padding-top: 40px;
      padding-bottom: 6px;
    }
  `}
`;

export const CancelSubscriptionContainer = styled.div`
  ${({ theme }) => theme.bp.Large};

  &.is--success {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const CancelSubscriptionHeader = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: 8px;
    font-weight: 300;
    line-height: 32px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.yellow};
  }

  h2 {
    font-weight: 900;
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    margin-bottom: 16px;

    h1 {
      font-size: 14px;
    }

    h2 {
      font-size: 24px;
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    margin-bottom: 67px;

    h1 {
      font-size: 14px;
    }

    h2 {
      font-size: 32px;
    }
  `}

  &.cancel-subscription__header {
    width: 100%;
  }
`;

export const SubscriptionSuccessMessage = styled.div`
  width: 100%;
  margin-bottom: 28px;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.white};
`;

export const SubscriptionSuccessPicture = styled.img`
  ${({ theme }) => theme.mq.lessThan('sm')`
    width: 100%;
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    width: fit-content;
  `}
`;