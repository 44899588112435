import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import moment from 'moment';

import { fetchNewsData } from 'store/ducks/institutional';

import { ReactComponent as ArrowLeft } from 'assets/icons/news/arrowLeft.svg';
import { PUBLIC_ROUTES } from 'helpers/constants';
import { articleInitialState } from 'helpers/initialState';
import { NewsArticle } from 'helpers/models';
import * as types from 'helpers/types';

import { ButtonLink as Link } from 'components/Button';

import { NewsArticleShareButtons } from './componentes/ShareButtons';
import * as S from './styled';

type NewsArticlesParams = {
  articleId: string;
};

const NewsArticles = () => {
  const { news } = useSelector(
    (state: types.ApplicationState) => state.institutional
  );

  const dispatch = useDispatch();
  const { articleId } = useParams<NewsArticlesParams>();
  const [article, setArticle] = useState<NewsArticle>(articleInitialState);

  useEffect(() => {
    if (!news.length) {
      dispatch(fetchNewsData());
    }
  }, []);

  useEffect(() => {
    if (articleId) {
      const search = news.find((news) => news.id === +articleId);

      if (search) {
        setArticle(search);
      }
    }
  }, [news, articleId]);

  return (
    <S.NewsArticleWrapper>
      <S.NewsArticleContainer>
        <S.NewsArticle>
          <S.NewsArticleHeader>
            <Link to={PUBLIC_ROUTES.NEWS} variant="link">
              <ArrowLeft />
              Voltar
            </Link>

            <span className="news-article__date">
              {moment(article.createdAt).format('DD-MM-YY')}
            </span>

            <h1 className="news-article__title">{article.title}</h1>
          </S.NewsArticleHeader>

          <S.NewsArticleCover
            src={article.coverUrl}
            alt="Capa da publicação"
            title={article.title}
          />

          <S.NewsArticleContent
            dangerouslySetInnerHTML={{ __html: article.description }}
          />

          <NewsArticleShareButtons
            title={article.title}
            description={article.description}
          />
        </S.NewsArticle>
      </S.NewsArticleContainer>
    </S.NewsArticleWrapper>
  );
};

export default NewsArticles;
