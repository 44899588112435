import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PUBLIC_ROUTES } from 'helpers/constants';
import * as types from 'helpers/types';

import CategoryThumbLink from 'components/CategoryThumbLink';

import * as S from './styled';

const filterSelector = (state: types.ApplicationState) => state.filter;

const CategoryList = () => {
  const { selectedCategory } = useSelector(filterSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedCategory.id) {
      navigate(PUBLIC_ROUTES.AUDIOS);
    }
  }, [selectedCategory]);

  return (
    <S.CategoryListWrapper>
      <S.CategoryListContainer>
        {selectedCategory.playlists.map((playlist) => (
          <CategoryThumbLink key={playlist.id} playlist={playlist} />
        ))}
      </S.CategoryListContainer>
    </S.CategoryListWrapper>
  );
};

export default CategoryList;
