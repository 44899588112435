/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as yup from 'yup';

import { LoginInfo } from '../types';

const loginSchema = yup.object().shape({
  email: yup.string()
    .email('O formato digitado para o email é inválido')
    .required('O campo email é obrigatório'),
  password: yup.string()
    .required('O campo senha é obrigatório'),
});

const loginValidation = (login: LoginInfo) => loginSchema.validateSync(
  login, { abortEarly: false },
);

export default loginValidation;