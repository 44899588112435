/* eslint-disable no-console */
import { IuguCreditCard, CustomWindow } from './types';

const { Iugu } = (window as unknown) as CustomWindow;

export const createPaymentTokenAction = async (creditCard: IuguCreditCard) => {
  const iuguCreditCard = Iugu.CreditCard(
    creditCard.number,
    creditCard.month,
    creditCard.year,
    creditCard.first_name,
    creditCard.last_name,
    creditCard.verification_value
  );

  return new Promise(
    (resolve, _) => {
      Iugu.createPaymentToken(iuguCreditCard, (response) => {
        if (response.errors) {
          if (process.env.NODE_ENV === 'development') {
            console.log('[CreatePaymentTokenResponse]', response.errors);
          }

          resolve({
            iuguToken: '',
            error: '400',
            extra_info: null
          });
        } else {
          resolve({
            iuguToken: response.id,
            error: null,
            extra_info: response.extra_info
          });
        }
      });
    }
  );
};