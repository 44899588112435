import { Action } from 'redux';
import { action } from 'typesafe-actions';

import { Playlist } from 'helpers/models';

import { PlaylistTypes } from './types';

export const fetchPlaylistData = (id: number): Action => (
  action(PlaylistTypes.FETCH_PLAYLIST_REQUEST, { id })
);

export const fetchPlaylistSuccess = (playlist: Playlist): Action => (
  action(PlaylistTypes.FETCH_PLAYLIST_SUCCESS, { playlist })
);

export const fetchPlaylistError = (): Action => (
  action(PlaylistTypes.FETCH_PLAYLIST_ERROR)
);