import { createContext, useContext, useMemo, useReducer } from 'react';

import {
  NavigationDispatch,
  NavigationAction,
  NavigationActionTypes,
  NavigationProviderProps,
  NavigationState
} from './types';

const NavigationContext = createContext<
  { state: NavigationState; dispatch: NavigationDispatch } | undefined
>(undefined);

function navigationReducer(state: NavigationState, action: NavigationAction) {
  switch (action.type) {
    case NavigationActionTypes.INCREMENT:
      return { step: state.step + 1 };
    case NavigationActionTypes.DECREMENT:
      return { step: state.step - 1 };
    case NavigationActionTypes.RESET:
      return { step: 1 };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export const NavigationProvider = (props: NavigationProviderProps) => {
  const [state, dispatch] = useReducer(navigationReducer, { step: 1 });
  const { children } = props;

  const navigationProviderValueMemo = useMemo(
    () => ({ state, dispatch }),
    [state, dispatch]
  );

  return (
    <NavigationContext.Provider value={navigationProviderValueMemo}>
      {children}
    </NavigationContext.Provider>
  );
};

export function useNavigation() {
  const context = useContext(NavigationContext);

  if (context === undefined) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }

  return context;
}

export * from './types';
