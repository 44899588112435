import { Action } from 'redux';
import { action } from 'typesafe-actions';

import { Contact } from 'helpers/models';

import { ContactTypes } from './types';

// Home
export const sendContactData = (contact: Contact): Action => (
  action(ContactTypes.SEND_CONTACT_REQUEST, { contact })
);

export const sendContactSuccess = (): Action => (
  action(ContactTypes.SEND_CONTACT_SUCCESS)
);

export const sendContactError = (): Action => (
  action(ContactTypes.SEND_CONTACT_ERROR)
);