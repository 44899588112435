import { useMediaQuery } from 'react-responsive';

type Responsive = {
  isMobile: boolean;
  isDesktop: boolean;
};

const useResponsive = (): Responsive => {
  const isDesktop = useMediaQuery({ minWidth: '769px' });
  const isMobile = useMediaQuery({ maxWidth: '768px' });

  return { isMobile, isDesktop };
};

export default useResponsive;
