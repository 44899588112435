import * as yup from 'yup';

import { PasswordChecklistRules } from 'helpers/types';
import { validateCpf } from 'utils/validateCpf';

import { User, UserEdit } from '../models';

const userSchema = yup.object().shape({
  name: yup.string().required('O campo nome é obrigatório'),
  email: yup
    .string()
    .email('O formato digitado para o email é inválido')
    .required('O campo email é obrigatório'),
  cpf: yup.string().required('O campo CPF é obrigatório'),
  password: yup.string().required('O campo senha é obrigatório'),
  passwordAgain: yup
    .string()
    .required('O campo confirmar a senha é obrigatório')
});

export const userValidation = (
  user: User,
  passwordAgain: string,
  passwordRules: PasswordChecklistRules
) => {
  const { ValidationError } = yup;
  const errors = [];

  try {
    userSchema.validateSync({ ...user, passwordAgain }, { abortEarly: false });
  } catch (yupError) {
    if (yupError instanceof ValidationError) {
      errors.push(...yupError.inner);
    }
  }

  if (user.cpf && !validateCpf(user.cpf)) {
    errors.push(new ValidationError('O CPF digitado é inválido', '', 'cpf'));
  }

  if (user.password && passwordAgain && !passwordRules.match) {
    errors.push(
      new ValidationError('As senhas não conferem', '', 'passwordsDontMatch')
    );
  }

  if (!passwordRules.minLength) {
    errors.push(
      new ValidationError(
        'A senha deve conter ao menos 8 caracteres',
        '',
        'passwordValidation'
      )
    );
  }

  if (!passwordRules.hasNumber) {
    errors.push(
      new ValidationError(
        'A senha deve conter ao menos 1 número',
        '',
        'passwordValidation'
      )
    );
  }

  if (errors.length) {
    throw new ValidationError(errors);
  }
};

const userDataSchema = yup.object().shape({
  name: yup.string().required('O campo nome é obrigatório'),
  email: yup
    .string()
    .email('O formato digitado para o email é inválido')
    .required('O campo email é obrigatório'),
  phone: yup
    .string()
    .required('O campo Telefone é obrigatório')
    .min(
      10,
      'O telefone deve ser composto por 2 dígitos do código de área, e entre 8 e 9 dígitos para o número'
    ),
  password: yup.string()
});

export const userEditValidation = (
  user: UserEdit,
  hasChangedPassword: boolean,
  passwordAgain: string,
  passwordRules: PasswordChecklistRules
) => {
  const { ValidationError } = yup;
  const errors = [];

  try {
    userDataSchema.validateSync(user.data, { abortEarly: false });
  } catch (yupError) {
    if (yupError instanceof ValidationError) {
      errors.push(...yupError.inner);
    }
  }

  if (hasChangedPassword) {
    if (!user.password) {
      errors.push(
        new ValidationError(
          'O campo confirmar a senha atual é obrigatório',
          '',
          'currentPassword'
        )
      );
    }

    if (!user.data.password) {
      errors.push(
        new ValidationError('O campo nova senha é obrigatório', '', 'password')
      );
    }

    if (!passwordAgain) {
      errors.push(
        new ValidationError(
          'O campo confirmar a nova senha é obrigatório',
          '',
          'passwordAgain'
        )
      );
    }

    if (user.data.password && passwordAgain && !passwordRules.match) {
      errors.push(
        new ValidationError('As senhas não conferem', '', 'passwordsDontMatch')
      );
    }

    if (!passwordRules.minLength) {
      errors.push(
        new ValidationError(
          'A senha deve conter ao menos 8 caracteres',
          '',
          'passwordValidation'
        )
      );
    }

    if (!passwordRules.hasNumber) {
      errors.push(
        new ValidationError(
          'A senha deve conter ao menos 1 número',
          '',
          'passwordValidation'
        )
      );
    }
  } else if (!user.password) {
    errors.push(
      new ValidationError('O campo senha é obrigatório', '', 'currentPassword')
    );
  }

  if (errors.length) {
    throw new ValidationError(errors);
  }
};
