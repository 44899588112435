import { Reducer } from 'redux';

import {
  InstitutionalState,
  InstitutionalTypes,
  InstitutionalAction
} from './types';
import { INSTITUTIONAL_INITIAL_STATE } from './utils';

const reducer: Reducer<InstitutionalState, InstitutionalAction> = (
  state: InstitutionalState = INSTITUTIONAL_INITIAL_STATE,
  action: InstitutionalAction
) => {
  switch (action.type) {
    case InstitutionalTypes.HOME_DATA_SUCCESS: {
      return {
        ...state,
        home: action.payload.home
      };
    }
    case InstitutionalTypes.HOME_LOADING: {
      return {
        ...state,
        isHomeLoading: action.payload.isHomeLoading
      };
    }
    case InstitutionalTypes.ABOUT_US_DATA_SUCCESS: {
      return {
        ...state,
        aboutUs: action.payload.aboutUs
      };
    }
    case InstitutionalTypes.TERMS_DATA_SUCCESS: {
      return {
        ...state,
        terms: action.payload.terms
      };
    }
    case InstitutionalTypes.FAQ_DATA_SUCCESS: {
      return {
        ...state,
        faq: action.payload.faq
      };
    }
    case InstitutionalTypes.NEWS_DATA_SUCCESS: {
      return {
        ...state,
        news: action.payload.news
      };
    }
    case InstitutionalTypes.PLANS_DATA_SUCCESS: {
      return {
        ...state,
        home: action.payload.home
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
