import { Contact } from 'helpers/models';

export enum ContactTypes {
  SEND_CONTACT_REQUEST = '@CONTACT/SEND_CONTACT_REQUEST',
  SEND_CONTACT_SUCCESS = '@CONTACT/SEND_CONTACT_SUCCESS',
  SEND_CONTACT_ERROR = '@CONTACT/SEND_CONTACT_ERROR',
}

export interface ContactState {
  readonly contact: Contact;
}

export type ContactAction = {
  type: ContactTypes;
  payload: {
    contact: Contact;
  }
}