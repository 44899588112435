import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import * as yup from 'yup';

import { setNewPasswordRequest } from 'store/ducks/recoverPassword';

import { ReactComponent as Logo } from 'assets/icons/playlegis-icon.svg';
import {
  passwordChecklistRulesInitialState,
  recoverPasswordInputErrorInitialState
} from 'helpers/initialState';
import { loadingSelector } from 'helpers/selectors';
import {
  PasswordChecklistRules,
  RecoverPasswordInputError
} from 'helpers/types';
import { validateRecoverStep2 } from 'helpers/validation/recoverPassword';
import { showValidationErrorToast } from 'utils/validationError';

import { HTMLButton as Button, ButtonLink as Link } from 'components/Button';
import Input from 'components/Input';
import PasswordChecklist from 'components/PasswordChecklist';

import * as S from '../../styled';

const RecoverStepTwo = () => {
  const { modules } = useSelector(loadingSelector);

  const dispatch = useDispatch();

  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [passwordValidation, setPasswordValidation] =
    useState<PasswordChecklistRules>(passwordChecklistRulesInitialState);
  const [errors, setErrors] = useState<RecoverPasswordInputError>(
    recoverPasswordInputErrorInitialState
  );
  const [disabledInputs, setDisabledInputs] = useState(false);

  const handleFormSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    toast.dismiss();

    let validationErrors: RecoverPasswordInputError =
      recoverPasswordInputErrorInitialState;

    try {
      validateRecoverStep2(token, password, passwordAgain, passwordValidation);

      dispatch(setNewPasswordRequest(token, password));
    } catch (error) {
      validationErrors = showValidationErrorToast<RecoverPasswordInputError>(
        error,
        validationErrors
      );
    } finally {
      setErrors(validationErrors);
    }
  };

  const handleTokenChange = (e: ChangeEvent<HTMLInputElement>) => {
    setToken(e.target.value);
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleChangePasswordAgain = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswordAgain(e.target.value);
  };

  useEffect(() => {
    setDisabledInputs(modules.length > 0);
  }, [modules]);

  return (
    <S.RecoverPasswordContent>
      <Logo />

      <h1>Esqueci minha senha</h1>
      <p>
        Enviamos um código para seu e-mail, insira-o abaixo e crie sua nova
        senha
      </p>

      <S.RecoverPasswordForm method="post" onSubmit={handleFormSubmit}>
        <fieldset>
          <Input
            type="text"
            value={token}
            onChange={handleTokenChange}
            className="login__input login__input--is-token"
            placeholder="Token"
            disabled={modules.length > 0}
            hasError={errors.token}
          />

          <PasswordChecklist
            value={password}
            onChange={handleChangePassword}
            valueAgain={passwordAgain}
            onChangeAgain={handleChangePasswordAgain}
            onChecklistChange={setPasswordValidation}
            disabled={disabledInputs}
            hasError={{
              password: errors.password || errors.passwordsDontMatch,
              passwordAgain: errors.passwordAgain || errors.passwordsDontMatch,
              passwordValidation: errors.passwordValidation
            }}
          />
        </fieldset>

        <Button type="submit" variant="yellow">
          Salvar alteração
        </Button>
      </S.RecoverPasswordForm>
    </S.RecoverPasswordContent>
  );
};

export default RecoverStepTwo;
