/* eslint-disable react/no-danger */

import { NavigationProvider } from 'contexts/NavigationContext';

import ReactivateAccountNavigation from './components/Navigation/Navigation';

const ReactivateAccountPage = () => (
  <NavigationProvider>
    <ReactivateAccountNavigation />
  </NavigationProvider>
);

export default ReactivateAccountPage;
