import { Fragment, FunctionComponent } from 'react';

import useResponsive from 'hooks/useResponsive';

import iMobileComponentProps from './interfaces/iMobileComponentProps';

const MobileComponent: FunctionComponent<iMobileComponentProps> = ({
  children
}) => {
  const { isMobile } = useResponsive();
  return isMobile ? <Fragment>{children}</Fragment> : <Fragment />;
};

export default MobileComponent;
