import { Reducer } from 'redux';

import { CategoryState, CategoryTypes, CategoryAction } from './types';
import { CATEGORY_INITIAL_STATE } from './utils';

const reducer: Reducer<CategoryState, CategoryAction> = (
  state: CategoryState = CATEGORY_INITIAL_STATE,
  action: CategoryAction,
) => {
  switch (action.type) {
    case CategoryTypes.FETCH_SUPER_CATEGORY_REQUEST: {
      return {
        ...state,
        id: action.payload.id,
        loading: true,
      };
    }
    case CategoryTypes.FETCH_SUPER_CATEGORY_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case CategoryTypes.FETCH_SUPER_CATEGORY_SUCCESS: {
      return {
        ...state,
        superCategory: action.payload.superCategory,
        loading: false,
      };
    }
    case CategoryTypes.FETCH_SUPER_CATEGORY_LAST_UPDATES_SUCCESS: {
      return {
        ...state,
        lastUpdates: action.payload.lastUpdates,
      };
    }
    default: {
      return state;
    }
  }
}

export default reducer;