/* eslint-disable camelcase */
import * as yup from 'yup';

import { CustomWindow, IuguCreditCard } from 'helpers/types';

const { Iugu } = window as unknown as CustomWindow;

const cardSchema = yup.object().shape({
  number: yup.string().required('O campo número do cartão é obrigatório'),
  month: yup.string().required('O campo mês é obrigatório'),
  year: yup.string().required('O campo ano é obrigatório'),
  verification_value: yup.string().required('O campo CVV é obrigatório')
});

export const creditCardValidation = (card: IuguCreditCard) => {
  const { ValidationError } = yup;
  const month = Number(card.month);
  const year = Number(card.year);
  const { verification_value, brand, first_name, last_name, number } = card;
  const errors = [];

  if (!first_name && !last_name) {
    errors.push(
      new ValidationError(
        'O campo nome do titular é obrigatório',
        '',
        'fullname'
      )
    );
  }

  if (first_name && !last_name) {
    errors.push(
      new ValidationError('Informe seu nome completo', '', 'fullname')
    );
  }

  if (number !== '') {
    if (!Iugu.utils.validateCreditCardNumber(number)) {
      errors.push(
        new ValidationError('O número do cartão é inválido', '', 'number')
      );
    } else if (!brand) {
      errors.push(
        new ValidationError('A bandeira do cartão não é aceita', '', 'number')
      );
    } else if (
      verification_value !== '' &&
      !Iugu.utils.validateCVV(verification_value, brand)
    ) {
      errors.push(
        new ValidationError(
          'O número de verificação é inválido para a bandeira do cartão',
          '',
          'verification_value'
        )
      );
    }
  }

  const datesTyped = card.month !== '' && card.year !== '';

  if (datesTyped && !Iugu.utils.validateExpiration(month, year)) {
    errors.push(
      new ValidationError('A data informada é inválida', '', 'fulldate')
    );
  }

  try {
    cardSchema.validateSync(card, { abortEarly: false });
  } catch (yupError) {
    if (yupError instanceof ValidationError) {
      errors.push(...yupError.inner);
    }
  }

  if (errors.length) {
    throw new ValidationError(errors);
  }
};
