import styled from 'styled-components';

export const ThumbButton = styled.button`
  width: 100%;
`;

export const AudioItem = styled.div`
  position: relative;
  width: 100%;
  max-width: 270px;
  height: 90px;
`;

export const AudioInfo = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 13px 9px 7px 20px;
  background-color: rgba(39, 47, 76, 0.7);
  border-radius: 8px;
  text-align: left;
`;

export const AudioInfoLeft = styled.div`
  flex-grow: 1;
  padding-right: 12px;

  h4 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: 300;
  }
`;

export const AudioInfoRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  span {
    font-size: 12px;
    font-weight: 300;
  }
`;

interface AudioThumbProps {
  coverUrl: string;
}

export const AudioThumb = styled.div.attrs((props: AudioThumbProps) => ({
  coverUrl: props.coverUrl || ''
}))`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  background-image: url(${(props) => props.coverUrl});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.05);
`;
