import { NavigationProvider } from 'contexts/NavigationContext';

import RecoverPasswordNavigation from './components/Navigation';

const RecoverPasswordPage = () => (
  <NavigationProvider>
    <RecoverPasswordNavigation />
  </NavigationProvider>
);

export default RecoverPasswordPage;
