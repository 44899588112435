import { action } from 'typesafe-actions';

import { TeachingInstitution } from 'helpers/models';

import { TeachingInstitutionTypes } from './types';

export const fetchInstitutionsRequest = () =>
  action(TeachingInstitutionTypes.FETCH_INSTITUTIONS_REQUEST);

export const fetchInstitutionsSuccess = (
  teachingInstitutions: TeachingInstitution[]
) =>
  action(TeachingInstitutionTypes.FETCH_INSTITUTIONS_SUCCESS, {
    teachingInstitutions
  });

export const fetchInstitutionsFailure = (error: boolean) =>
  action(TeachingInstitutionTypes.FETCH_INSTITUTIONS_FAILURE, { error });
