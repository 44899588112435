import styled from 'styled-components';

export const SelectWrapper = styled.div`
  position: relative;
  margin-bottom: 15px;
`;

interface SelectInputProps {
  $iconUrl?: string;
}

export const SelectInput = styled.input<SelectInputProps>`
  width: 100%;
  padding: 14px 18px;
  position: relative;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  color: ${({ theme }) => theme.colors.blue};
  background-color: ${({ theme }) => theme.colors.white};
  border: 0;
  border-radius: 8px;
  box-shadow: 0px 0px 8px ${({ theme }) => theme.colors.greyLight};
  cursor: pointer;
  transition: box-shadow 0.3s, border-radius 0.3s;

  &.select__input {
    &--is-option-list-open {
      border-radius: 8px 8px 0 0;
    }

    &--has-error {
      border: 1px solid ${({ theme }) => theme.colors.error};
      background-color: #f3dfdf;
    }
  }


  ${({ $iconUrl }) => $iconUrl && `
    background-image: url(${$iconUrl});
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 97%;
  `}
`;

export const SelectDropdown = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 8px ${({ theme }) => theme.colors.greyLight};
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s, z-index 0.3s;
  max-height: 320px;
  width: 100%;
  overflow-y: scroll;

  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.colors.blue} ${({ theme }) => theme.colors.greyLighter};

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.greyLighter};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    width: 1px;
    padding: 2px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.blue};
    transition: background-color 0.3s;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.colors.blueDark};
  }

  &.select__dropdown-list {
    &--is-open {
      opacity: 1;
      z-index: 10;
    }
  }
`;

export const SelectSearch = styled.div`
  padding: 12px 16px;
  border-radius: 0;
  background-color: ${({ theme }) => theme.colors.greyLighter};
  border-bottom: 1px solid ${({ theme }) => theme.colors.greyDark};
  position: sticky;
  top: 0;
  width: 100%;

  input {
    border: 1px solid ${({ theme }) => theme.colors.greyDark};
    border-radius: 4px;
    padding: 8px;
    width: 100%;
  }
`;

export const SelectOptionList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const OptionButton = styled.button`
  padding: 14px 18px;
  transition: background-color 0.3s, color 0.3s;
  text-align: left;
  border-radius: 0;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.yellow};
  }
`;