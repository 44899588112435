import { createGlobalStyle } from 'styled-components';

import { normalize, reset } from './global/_reset';

const GlobalStyle = createGlobalStyle`
  ${normalize};
  ${reset};

  html {
    height: 100%;
    display: table;
  }

  html, body {
    width: 100%;
    margin: 0px;
    padding: 0px;
  }

  body {
    font-family: ${({ theme }) => theme.font.primary};
    display: table-cell;
    -webkit-font-smoothing: antialiased !important;
    color: ${({ theme }) => theme.colors.blue};
    background: ${({ theme }) => theme.colors.white};
    transition: all 0.1s linear;

    &.no-scroll {
      overflow: hidden;
    }
  }

  body, #root {
    height: 100%;
    min-height: 100%;
    position: relative;
  }

  input, textarea, button, a {
    outline: none;
  }
`;

export default GlobalStyle;
