import styled from 'styled-components';

export const TextAreaWrapper = styled.textarea`
  width: 100%;
  padding: 14px 18px;
  font-size: 14px;
  font-weight: 300;
  border: 0;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.blue};

  &.textarea--has-shadow {
    box-shadow: 0px 0px 8px ${({ theme }) => theme.colors.blueDarker};
  }
`;