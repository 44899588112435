/* eslint-disable no-console */
import { FormEvent, Fragment, KeyboardEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastOptions } from 'react-toastify';

import { ValidationError } from 'yup';

import { updateAccountCreditCardRequest } from 'store/ducks/account';

import {
  verifyAndFormatCardNumber,
  verifyLengthAndApply
} from 'helpers/helpers';
import {
  creditCardInitialState,
  creditCardInputErrorInitialState
} from 'helpers/initialState';
import { accountSelector } from 'helpers/selectors';
import { CreditCardInputError, IuguCreditCard } from 'helpers/types';
import { creditCardValidation } from 'helpers/validation/creditCard';
import { useIugu } from 'hooks/useIugu';
import { getCompleteCard } from 'utils/getCompleteCard';

import { HTMLButton as Button } from 'components/Button';
import Input from 'components/Input';

import * as S from './styled';

const AccountCreditCardForm = () => {
  const dispatch = useDispatch();

  const { hasError, errorCode, hadSucceed, isLoading } =
    useSelector(accountSelector);
  const { account } = useSelector(accountSelector);

  const [errors, setErrors] = useState<CreditCardInputError>(
    creditCardInputErrorInitialState
  );
  const [card, setCard] = useState<IuguCreditCard>(creditCardInitialState);
  const [fullName, setFullName] = useState('');
  const [isEditable, setEditable] = useState(false);

  const Iugu = useIugu();

  useEffect(() => {
    if (process.env.REACT_APP_IUGU_MODE === 'TEST') {
      Iugu.setTestMode(true);
    }

    Iugu.setAccountID('E5548FCA165037A49A21A7934D51AD8E');
  }, []);

  useEffect(() => {
    if (isEditable) {
      setFullName('');
      setCard(creditCardInitialState);
    } else {
      const paymentCard = account.subscription?.paymentCard;
      if (paymentCard) {
        setFullName(paymentCard.name);
        setCard({
          ...creditCardInitialState,
          number: paymentCard.number,
          month: String(paymentCard.month),
          year: String(paymentCard.year)
        });
      }
    }
  }, [isEditable, account]);

  useEffect(() => {
    if (hasError && errorCode === '-1') {
      toast.error('Falha ao registrar cartão', { autoClose: false });
    } else if (hadSucceed) {
      setEditable(false);
    }
  }, [hasError, hadSucceed]);

  const handleEditAction = () => {
    setEditable(!isEditable);
  };

  const handleCancelAction = () => {
    setEditable(!isEditable);
    setCard(creditCardInitialState);
  };

  const handleSubmitAction = (event?: FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }

    const unformatCardNumber = card.number.replaceAll(' ', '');

    const toastOptions: ToastOptions = { autoClose: false };
    toast.dismiss();

    let errs: CreditCardInputError = creditCardInputErrorInitialState;

    try {
      const completedCard = getCompleteCard(
        { ...card, number: unformatCardNumber },
        fullName
      );
      creditCardValidation(completedCard);

      dispatch(updateAccountCreditCardRequest(completedCard));
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log('[CardDataSubmitError]', error);
      }

      if (error instanceof ValidationError) {
        error.inner.forEach((e) => {
          if (e.path) {
            errs = { ...errs, [`${e.path}`]: true };
          }

          toast.error(e.message, toastOptions);
        });
      }
    } finally {
      setErrors(errs);
    }
  };

  const onEnterKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmitAction();
    } else {
      return true;
    }
  };

  if (account.subscription?.subscriptionStatus === 'CANCELED') {
    return <Fragment />;
  }

  return account.isAdmin ? (
    <Fragment />
  ) : (
    <S.AccountBillingData>
      <S.AccountFormHeader>
        <h3>Cartão de crédito</h3>

        <S.AccountBillingActions>
          <Button variant="link" onClick={handleEditAction}>
            Alterar
          </Button>
        </S.AccountBillingActions>
      </S.AccountFormHeader>

      <S.AccountForm
        onSubmit={handleSubmitAction}
        onKeyDown={onEnterKeyDown}
        noValidate
      >
        <Input
          type="text"
          id="fullname"
          name="fullname"
          placeholder="Nome do titular"
          className="input__card input__card--is-fullname"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          disabled={!isEditable}
          hasError={!isEditable && errors.fullname}
        />

        <Input
          type="text"
          id="cardNumber"
          name="cardNumber"
          placeholder="Número do cartão"
          className="input__card input__card--is-card-number"
          value={card.number}
          onChange={(e) =>
            verifyAndFormatCardNumber(
              e.target.value,
              19,
              'number',
              card,
              setCard
            )
          }
          disabled={!isEditable}
          hideSpinButton
          hasError={!isEditable && errors.number}
        />

        <S.InputGroup>
          <Input
            type="number"
            id="cardMonth"
            name="cardMonth"
            max={2}
            placeholder="MM"
            className="input__card input__card--is-card-month"
            value={card.month}
            onChange={(e) =>
              verifyLengthAndApply(e.target.value, 2, 'month', card, setCard)
            }
            disabled={!isEditable}
            hideSpinButton
            hasError={(!isEditable && errors.month) || errors.fulldate}
          />

          <Input
            type="number"
            id="cardYear"
            name="cardYear"
            max={4}
            placeholder="AAAA"
            className="input__card input__card--is-card-year"
            value={card.year}
            onChange={(e) =>
              verifyLengthAndApply(e.target.value, 4, 'year', card, setCard)
            }
            disabled={!isEditable}
            hideSpinButton
            hasError={(!isEditable && errors.year) || errors.fulldate}
          />

          <Input
            type="number"
            id="verificationValue"
            name="verificationValue"
            max={4}
            placeholder="CVV"
            className="input__card input__card--is-verification-value"
            value={card.verification_value}
            onChange={(e) =>
              verifyLengthAndApply(
                e.target.value,
                4,
                'verification_value',
                card,
                setCard
              )
            }
            disabled={!isEditable}
            hideSpinButton
            hasError={!isEditable && errors.verification_value}
          />
        </S.InputGroup>

        {isEditable && (
          <S.ButtonGroup>
            <Button variant="line" onClick={handleCancelAction}>
              Cancelar
            </Button>

            <Button variant="yellow" type="submit">
              Salvar
            </Button>
          </S.ButtonGroup>
        )}
      </S.AccountForm>
    </S.AccountBillingData>
  );
};

export default AccountCreditCardForm;
