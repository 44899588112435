import styled from 'styled-components';

export const CategoryListWrapper = styled.div``;

export const CategoryListContainer = styled.div`
  display: grid;
  gap: 30px;

  ${({ theme }) => theme.mq.lessThan('sm')`
    grid-template-columns: repeat(1, 1fr);
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    grid-template-columns: repeat(4, 1fr);
  `}
`;