import { ValidationError } from 'yup';

import { StudentRequest } from 'helpers/models/StudentRequest';

export const studentRequestValidation = (studentRequest: StudentRequest) => {
  const { files, teachingInstitution } = studentRequest;

  const errors = [];
  let error;

  if (files.length < 1) {
    error = new ValidationError(
      'Selecione ao menos um arquivo',
      '',
      'files'
    );

    errors.push(error);
  }

  if (teachingInstitution.id === 0) {
    error = new ValidationError(
      'Selecione uma instituição de ensino',
      '',
      'teachingInstitution'
    );

    errors.push(error);
  }

  if (errors.length) {
    throw new ValidationError(errors);
  }
}