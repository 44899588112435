import { Fragment, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';

import {
  fetchSuperCategoryData,
  fetchSuperCategoryLastUpdatesData
} from 'store/ducks/category';
import { setCategoryFilter } from 'store/ducks/filter';
import { searchRequest } from 'store/ducks/search';

import { ReactComponent as NavigationArrow } from 'assets/icons/navigation/arrowLeft.svg';
import { PUBLIC_ROUTES, SUPER_CATEGORY } from 'helpers/constants';
import { categoryInitialState } from 'helpers/initialState';
import { Category } from 'helpers/models';
import {
  categorySelector,
  filterSelector,
  searchSelector
} from 'helpers/selectors';

import { ButtonLink as Link } from 'components/Button';
import { FilterSelector } from 'components/FilterSelector';
import { Mobile, Desktop } from 'components/Responsive';
import Search from 'components/Search';

import TypeToggler from './components/TypeToggler';
import * as S from './PostHeader.styled';

interface Props {
  title: string;
  subtitle: string;
  mode: 'PLAYLIST' | 'CATEGORY' | 'SUPER_CATEGORY';
}

const PostHeader = (props: Props) => {
  const { title, subtitle, mode } = props;

  const navigate = useNavigate();
  const token = Cookies.get('al-web-token');

  const {
    superCategory: { id: stateSuperCategoryId, categories }
  } = useSelector(categorySelector);
  const { terms } = useSelector(searchSelector);

  const { selectedCategory } = useSelector(filterSelector);

  const [superCategory, setSuperCategory] = useState(stateSuperCategoryId);
  const [superCategoriesList] = useState(
    Object.values(SUPER_CATEGORY).sort((a, b) => b - a)
  );

  const dispatch = useDispatch();

  const handleFilterSelect = (category: Category) => {
    dispatch(setCategoryFilter(category));

    if (terms) {
      dispatch(searchRequest(''));
    }
  };

  useEffect(() => {
    if (!superCategory) {
      setSuperCategory(SUPER_CATEGORY.OTHERS);
    }
  }, []);

  useEffect(() => {
    if (mode === 'SUPER_CATEGORY') {
      if (superCategory !== stateSuperCategoryId) {
        dispatch(fetchSuperCategoryData(superCategory));
        dispatch(fetchSuperCategoryLastUpdatesData());
      }

      dispatch(setCategoryFilter(categoryInitialState));
    }
  }, [superCategory]);

  useEffect(() => {
    if (selectedCategory.id) {
      navigate(`${PUBLIC_ROUTES.AUDIOS}/${selectedCategory.id}`);
    }
  }, [selectedCategory]);

  return (
    <S.PostHeaderWrapper>
      {mode === 'CATEGORY' && (
        <S.PostHeaderRow className="post-header__back-button">
          <Link to={PUBLIC_ROUTES.AUDIOS} variant="link">
            <NavigationArrow />
            Voltar
          </Link>
        </S.PostHeaderRow>
      )}
      <S.PostHeaderRow>
        <h1>{title}</h1>
      </S.PostHeaderRow>
      <S.PostHeaderRow className="post-header__input-title">
        <h2>{subtitle}</h2>

        <S.PostHeaderInputs>
          <Mobile>
            {mode === 'SUPER_CATEGORY' && (
              <Fragment>
                <S.PostHeaderRow className="post-header__type-toggler">
                  <TypeToggler
                    typeState={superCategory}
                    typeHandler={setSuperCategory}
                    typeList={superCategoriesList}
                  />
                </S.PostHeaderRow>
                <FilterSelector
                  placeholder="Escolher uma categoria"
                  selected={selectedCategory}
                  onSelection={handleFilterSelect}
                  categories={categories}
                />
                {token && <Search />}
              </Fragment>
            )}
          </Mobile>
          <Desktop>
            <FilterSelector
              placeholder="Escolher uma categoria"
              selected={selectedCategory}
              onSelection={handleFilterSelect}
              categories={categories}
            />
            {token && <Search />}
          </Desktop>
        </S.PostHeaderInputs>
      </S.PostHeaderRow>
      <Desktop>
        {mode === 'SUPER_CATEGORY' && (
          <S.PostHeaderRow>
            <TypeToggler
              typeState={superCategory}
              typeHandler={setSuperCategory}
              typeList={superCategoriesList}
            />
          </S.PostHeaderRow>
        )}
      </Desktop>
    </S.PostHeaderWrapper>
  );
};

export default memo(PostHeader);
