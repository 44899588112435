import styled from 'styled-components';

export const ContactWrapper = styled.div`
  ${({ theme }) => theme.bp.Fluid};
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
`;

export const ContactContainer = styled.div`
  ${({ theme }) => theme.bp.Large};
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mq.lessThan('sm')`
    padding-top: 26px;
    padding-bottom: 100px;    
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    padding-top: 40px;
    padding-bottom: 240px;
  `}
`;

export const ContactHeader = styled.header`
  h1 {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.yellow};
  }

  h2 {
    margin-bottom: 16px;
    font-weight: 900;
  }

  p {
    font-size: 16px;
    font-weight: 300;
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    margin-bottom: 40px;

    h2 {
      font-size: 24px;
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    margin-bottom: 60px;

    h2 {
      font-size: 32px;
    }
  `}
`;

export const ContactForm = styled.form`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mq.lessThan('sm')`
    align-items: center;

    .contact__input-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    .contact__input-group {
      width: 72%;
      display: flex;
      margin-bottom: 30px;
    }
  `}
`;

export const FormFieldset = styled.fieldset`
  .contact__textarea {
    resize: none;
    width: 100%;
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    &:first-child {
      width: 100%;
    }
    
    &:last-child {
      width: 100%;  
    }

    .contact__input {
      margin-bottom: 15px;
    }

    .contact__textarea {
      height: 175px;
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    &:first-child {
      width: 50%;  
      margin-right: 15px;
    }

    &:last-child {
      width: 50%;  
    }

    .contact__input {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    .contact__textarea {
      height: 100%;
    }
  `}
`;

export const ContactFormBottom = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .btn__yellow {
    width: 100%;
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    flex-direction: column;

    .btn__yellow {
      margin-bottom: 30px;
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    .btn__yellow {
      max-width: 300px;
      margin-right: 30px;
    }
  `}
`;