import { Reducer } from 'redux';

import { generateNewUID } from 'helpers/generateUID';
import { PopUpItem } from 'helpers/types/PopUpItem';

import { PopUpTypes, PopUpState, PopUpAction } from './types';

const INITIAL_STATE: PopUpState = {
  items: []
};

const reducer: Reducer<PopUpState, PopUpAction> = (
  state: PopUpState = INITIAL_STATE,
  action: PopUpAction
) => {
  switch (action.type) {
    case PopUpTypes.ADD_POP_UP_ITEM: {
      if (state.items.find((item) => item.title === action.payload.itemTitle)) {
        return state;
      }

      const newItem: PopUpItem = {
        id: generateNewUID(),
        title: action.payload.itemTitle,
        shouldClose: action.payload.itemShouldClose,
        description: action.payload.itemDescription,
        button: action.payload.itemButton
      };

      return {
        items: [...state.items, newItem]
      };
    }
    case PopUpTypes.REMOVE_POP_UP_ITEM: {
      return {
        items: state.items.filter(
          (item) => item.id !== action.payload.popUpItemId
        )
      };
    }
    case PopUpTypes.REMOVE_ALL_POP_UP_ITEMS: {
      return {
        items: []
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
