import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as NavigationArrow } from 'assets/icons/navigation/arrowLeft.svg';
import { playlistSelector } from 'helpers/selectors';

import { HTMLButton as Button } from 'components/Button';
import { Desktop } from 'components/Responsive';

import * as S from './styled';

const PlaylistHeader = () => {
  const navigate = useNavigate();
  const {
    playlist: { name, description, coverUrl }
  } = useSelector(playlistSelector);

  return (
    <S.PlaylistHeaderWrapper>
      <S.PlaylistBackButtonWrapper>
        <Button onClick={() => navigate(-1)} variant="link">
          <NavigationArrow />
          Voltar
        </Button>
      </S.PlaylistBackButtonWrapper>

      <S.PlaylistHeaderContent>
        <S.PlaylistInfo>
          <S.PlaylistSubtitle>Playlist</S.PlaylistSubtitle>
          <S.PlaylistTitle>{name}</S.PlaylistTitle>
          <S.PlaylistDescription>{description}</S.PlaylistDescription>
        </S.PlaylistInfo>
        <Desktop>
          <S.PlaylistCover>
            <S.PlaylistCoverFilter />
            <S.PlaylistCoverImage $url={coverUrl} />
          </S.PlaylistCover>
        </Desktop>
      </S.PlaylistHeaderContent>
    </S.PlaylistHeaderWrapper>
  );
};

export default PlaylistHeader;
