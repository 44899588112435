import { Audio, Playlist } from 'helpers/models';

export enum PlayerTypes {
  OPEN_PLAYER = '@PLAYER/OPEN_PLAYER',
  CLOSE_PLAYER = '@PLAYER/CLOSE_PLAYER',

  SET_CURRENT_PLAYLIST = '@PLAYER/SET_CURRENT_PLAYLIST',
  SET_CURRENT_AUDIO = '@PLAYER/SET_CURRENT_AUDIO',
  SET_PLAYER_PAUSED = '@PLAYER/SET_PLAYER_PAUSED',

  PLAYER_ERROR = '@PLAYER/PLAYER_ERROR',
  PLAYER_SUCCESS = '@PLAYER/PLAYER_SUCCESS',
}

export interface PlayerState {
  readonly isPlayerOpen: boolean;
  readonly playlist: Playlist;
  readonly currentAudioId: number;
  readonly currentAudio: Audio;
  readonly isPaused: boolean;
}

export type PlayerAction = {
  type: PlayerTypes;
  payload: {
    isPlayerOpen: false,
    playlist: Playlist;
    currentAudioId: number;
    currentAudio: Audio;
    isPaused: boolean;
  }
}