import styled from 'styled-components';

type PostHeaderProps = {
  backgroundUrl?: string;
};

export const PostHeaderWrapper = styled.header<PostHeaderProps>`
  ${({ theme }) => theme.bp.Fluid};
  background-image: ${(props) => `url("${props.backgroundUrl}")`};
  background-color: ${({ theme }) => theme.colors.blue};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 880px;
  color: #fff;

  ${({ theme }) => theme.mq.lessThan('md')`
    > img {
      margin-top: 30px;
      width: 100%;
    }
  `}
`;

export const Row = styled.div`
  display: flex;
`;

export const Container = styled.div`
  ${({ theme }) => theme.bp.Medium};
  display: flex;
  position: relative;

  ${({ theme }) => theme.mq.lessThan('sm')`
    padding: 24px 0 76px;
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    padding: 60px 0 100px;
  `}
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;

  h1,
  h2 {
    display: block;
  }

  h1 {
    margin-bottom: 8px;
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.yellow};
  }

  h2 {
    margin-bottom: 24px;
    font-weight: 900;
  }

  p {
    margin-bottom: 64px;
    font-size: 18px;
    font-weight: 300;
  }

  .btn__yellow {
    width: 300px;
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    .btn__yellow {
      width: 100%;
    }
  `}

  ${({ theme }) => theme.mq.lessThan('md')`
    h2 {
      font-size: 30px;
    }

    .btn__yellow {
      align-self: center;
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('md')`
    width: 42%;

    h2 {
      font-size: 40px;
    }
  `}
`;

export const MockupAndStoresContainer = styled.div`
  display: flex;
  flex-direction: column;

  img {
    max-width: 610px;
    width: 100%;
  }

  ${({ theme }) => theme.mq.lessThan('md')`
    margin-bottom: 20px;

    svg {
      max-width: 100px;
    }
  `}
`;

export const StoresIcons = styled.div`
  display: flex;
  justify-content: flex-end;

  a {
    &:not(:last-child) {
      margin-right: 13px;
    }

    svg {
      width: 100%;
      height: 48px;
    }
  }
`;
