import { useState } from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import { authHeaderPopUpMenu } from 'helpers/links';

import * as S from './styled';

interface UserMenuProps {
  avatar?: string;
  name: string;
  colorMode: 'light' | 'dark';
}

const UserMenu = (props: UserMenuProps) => {
  const { avatar, name, colorMode } = props;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <S.UserMenuWrapper
      $isOpen={isMenuOpen}
      onMouseEnter={() => !isMenuOpen && setIsMenuOpen(true)}
      onMouseLeave={() => isMenuOpen && setIsMenuOpen(false)}
    >
      <S.MenuButton type="button" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        {avatar ? (
          <div className="nav__user-avatar">
            <img
              src={avatar}
              alt="Foto de perfil do usuário"
              title="Foto de perfil do usuário"
            />
          </div>
        ) : (
          <div className="nav__user-initials">{name.slice(0, 1)}</div>
        )}

        <span
          className={classNames('nav__user-name', {
            'nav__user-name--is-light-mode':
              colorMode === 'light' && !isMenuOpen
          })}
        >
          {name}
        </span>

        <ArrowDownIcon />
      </S.MenuButton>

      <S.MenuListWrapper $isOpen={isMenuOpen}>
        <S.MenuList>
          {authHeaderPopUpMenu.map(({ label, to }) => (
            <S.MenuItem key={label}>
              <Link to={to}>{label}</Link>
            </S.MenuItem>
          ))}
        </S.MenuList>
      </S.MenuListWrapper>
    </S.UserMenuWrapper>
  );
};

UserMenu.defaultProps = {
  avatar: ''
};

export default UserMenu;
