import { Fragment, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import classNames from 'classnames';

import CancelationEmojiPicture from 'assets/img/signup/signup-error.png';
import { ApplicationState } from 'helpers/types';

import CancelForm from './components/Form';
import * as S from './styled';

const CancelSubscriptionPage = () => {
  const { hadSucceed, hasError, isLoading, errorMessage } = useSelector(
    (state: ApplicationState) => state.account
  );

  const [hasSubmited, setSubmitted] = useState(false);

  const isSuccess = useMemo(
    () => hasSubmited && hadSucceed && !isLoading,
    [hadSucceed, isLoading]
  );
  const isError = useMemo(
    () => hasSubmited && hasError && !isLoading,
    [hasError, isLoading]
  );

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
    }
  }, [isError]);

  const getHeader = () => {
    const className = isError || isSuccess ? 'cancel-subscription__header' : '';

    const title = isError ? 'Falha ao efetuar cancelamento' : 'Cancelamento';

    let description = 'Poxa, que pena que você está nos deixando...';

    if (isSuccess) {
      description = 'Esperamos te ver em breve novamente...';
    }
    if (isError) {
      description = 'Tente novamente';
    }

    return (
      <S.CancelSubscriptionHeader className={className}>
        <h1>{title}</h1>
        <h2>{description}</h2>
      </S.CancelSubscriptionHeader>
    );
  };

  const getBody = () => {
    if (isSuccess) {
      return (
        <Fragment>
          <S.SubscriptionSuccessMessage>
            Cancelamento realizado, você receberá um e-mail com os detalhes.
          </S.SubscriptionSuccessMessage>

          <S.SubscriptionSuccessPicture
            src={CancelationEmojiPicture}
            alt="Pessoa lamentando"
            title="Assinatura cancelada com sucesso"
          />
        </Fragment>
      );
    }

    if (isError) {
      <S.SubscriptionSuccessPicture
        src={CancelationEmojiPicture}
        alt="Pessoa lamentando"
        title="Falha ao cancelar assinatura"
      />;
    }

    return <CancelForm onSubmit={() => setSubmitted(true)} />;
  };

  return (
    <S.CancelSubscriptionWrapper
      className={classNames({
        'is--success': isSuccess
      })}
    >
      <S.CancelSubscriptionContainer
        className={classNames({
          'is--success': isSuccess
        })}
      >
        {getHeader()}
        {getBody()}
      </S.CancelSubscriptionContainer>
    </S.CancelSubscriptionWrapper>
  );
};

export default CancelSubscriptionPage;
