import styled from 'styled-components';

export const ReactivatePayment = styled.div`
  flex-grow: 1;
`;

export const ReactivateFooter = styled.div`
  text-align: center;
  font-weight: 300;
  font-size: 14px;
  
  .btn__link {
    font-weight: 300;
    text-decoration: underline;
    font-size: 14px;

    &:hover {
      color: ${({ theme }) => theme.colors.yellow};
    } 
  }
`;

export const ReactivatePaymentSectionTitle = styled.div`
  font-size: 18px;
  font-weight: 700;

  &.payment__title {
    &--is-details {
      margin-bottom: 20px;
    }

    &--is-resume {
      margin-top: 40px;
    }
  }
`;

export const ReactivatePaymentData = styled.div`
  text-align: center;
`;

export const ReactivatePaymentDescription = styled.p`
  font-size: 16px;
  font-weight: 300;

  &.is-resume {
    margin-top: 16px;
    margin-bottom: 30px;
  }

  &.is-notice {
    margin-top: 40px;
    margin-bottom: 75px;
  }

  ${({ theme }) => theme.mq.greaterThan('sm')`
    max-width: 365px;
  `}
`;

export const InputGroupRow = styled.div`
  display: flex;
  width: 100%;

  .reactivate__input {
    margin-bottom: 0;

    &:not(:last-child) {
      margin-right: 30px;
    }
  }
`;