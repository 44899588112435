export enum RecoverPasswordTypes {
  RECOVER_PASSWORD_REQUEST = '@REGISTER/RECOVER_PASSWORD_REQUEST',
  RECOVER_PASSWORD_SUCCESS = '@REGISTER/RECOVER_PASSWORD_SUCCESS',
  RECOVER_PASSWORD_ERROR = '@REGISTER/RECOVER_PASSWORD_ERROR',

  SET_NEW_PASSWORD_REQUEST = '@REGISTER/SET_NEW_PASSWORD_REQUEST',
  SET_NEW_PASSWORD_SUCCESS = '@REGISTER/SET_NEW_PASSWORD_SUCCESS',
  SET_NEW_PASSWORD_ERROR = '@REGISTER/SET_NEW_PASSWORD_ERROR'
}

export interface RecoverPasswordState {
  readonly token: string;
  readonly email: string;
  readonly password: string;
  readonly loading: boolean;
}

export type RecoverPasswordAction = {
  type: RecoverPasswordTypes;
  payload: RecoverPasswordState;
};
