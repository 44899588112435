import styled from 'styled-components';

import { RichTextSection } from 'components/RichText';

export const AboutWrapper = styled.div`
  ${({ theme }) => theme.bp.Fluid};
  display: flex;
  flex-direction: column;
  margin: 40px 0 35px;
`;

export const AboutContainer = styled.div`
  ${({ theme }) => theme.bp.Large};
`;

export const AboutHeader = styled.header`
  display: flex;
  flex-direction: column-reverse;

  h2 {
    font-weight: 900;
    color: ${({ theme }) => theme.colors.blue};
  }

  h3 {
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.yellow};
    margin-bottom: 8px;
  }

  ${({ theme }) => theme.mq.lessThan('sm')`
    h2 {
      font-size: 24px;
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    h2 {
      font-size: 32px;
    }
  `}
`;

export const AboutContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AboutDescription = styled(RichTextSection)`
  margin: 50px 0 50px;

  p {
    font-size: 20px;
    font-weight: 300;
  }
`;

export const AboutRow = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${({ theme }) => theme.mq.lessThan('sm')`
    margin-bottom: 144px;

    .btn__blue {
      width: 100%;
      padding: 18px 20px;
      margin-bottom: 28px;
    }

    img {
      width: 100%;
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('sm')`
    .btn__blue {
      margin-top: 28px;
      width: 300px;
      position: absolute;
    }

    img {
      max-width: 630px;
      align-self: flex-end;
    }
  `}
`;