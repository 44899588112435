import { Link as RouterLink } from 'react-router-dom';

import styled from 'styled-components';

export const Link = styled(RouterLink)`
  display: flex;
`;

export const MiniFooterWrapper = styled.div`
  ${({ theme }) => theme.bp.Fluid};
  background-color: ${({ theme }) => theme.colors.blueDark};
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mq.greaterThan('md')`
    position: absolute;
    bottom: 0;
  `}
`;

export const MiniFooterContainer = styled.div`
  ${({ theme }) => theme.bp.Medium};
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mq.lessThan('md')`
    padding: 24px 0 16px;
    flex-direction: column;
  `}

  ${({ theme }) => theme.mq.greaterThan('md')`
    padding: 10px 0 15px;
  `}
`;

export const MiniFooterGeneral = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  p {
    font-size: 12px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.greyDark};
  }
`;

export const MiniFooterMenu = styled.div`
  nav {
    a {
      transition: color 0.3s;
      font-weight: 700;

      &:hover {
        color: ${({ theme }) => theme.colors.yellow};
      }
    }
  }

  ${({ theme }) => theme.mq.lessThan('md')`
    margin-bottom: 24px;

    nav {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      a {
        margin: 8px 0;
      }
    }
  `}

  ${({ theme }) => theme.mq.greaterThan('md')`
    nav {
      display: flex;

      a {
        margin: 16px 0;

        &:not(:last-child) {
          margin-right: 60px;
        }
      }
    }
  `}
`;

export const MiniFooterStores = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 0;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }

  a {
    &:first-child {
      margin-right: 6px;
    }

    svg {
      width: 115px;
      height: auto;
    }
  }

  ${({ theme }) => theme.mq.lessThan('md')`
    margin-bottom: 38px;  
  `}
`;
