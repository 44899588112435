import { useLocation } from 'react-router';

import { ReactComponent as EmailShareIcon } from 'assets/icons/share/email.svg';
import { ReactComponent as FacebookShareIcon } from 'assets/icons/share/facebook.svg';
import { ReactComponent as TelegramShareIcon } from 'assets/icons/share/telegram.svg';
import { ReactComponent as TwitterShareIcon } from 'assets/icons/share/twitter.svg';
import { ReactComponent as WhatsappShareIcon } from 'assets/icons/share/whatsapp.svg';

import * as S from './styled';

interface NewsArticleShareButtonsProps {
  title: string;
  description: string;
}

const NewsArticleShareButtons = (props: NewsArticleShareButtonsProps) => {
  const { title, description } = props;

  const { pathname } = useLocation();

  return (
    <S.ShareButtonsContainer>
      <S.ShareButtonsTitle>Compartilhar</S.ShareButtonsTitle>

      <S.ShareButtonsGroup>
        <a
          href={`whatsapp://send?text=${title} - https://playlegis.com.br${pathname}`}
          data-action="share/whatsapp/share"
          target="_blank"
          rel="noreferrer"
        >
          <WhatsappShareIcon />
        </a>
        <a
          href={`https://t.me/share/url?url=https://playlegis.com.br${pathname}&text=${title}`}
          target="_blank"
          rel="noreferrer"
        >
          <TelegramShareIcon />
        </a>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=https://playlegis.com.br${pathname}`}
          target="_blank"
          rel="noreferrer"
        >
          <FacebookShareIcon />
        </a>
        <a
          href={`http://twitter.com/share?url=https://playlegis.com.br${pathname}&text=${title}`}
          target="_blank"
          rel="noreferrer"
        >
          <TwitterShareIcon />
        </a>
        <a
          href={`mailto:?subject=Playlegis - ${title}&amp;body=${title} - https://playlegis.com.br${pathname}`}
          target="_blank"
          rel="noreferrer"
        >
          <EmailShareIcon />
        </a>
      </S.ShareButtonsGroup>
    </S.ShareButtonsContainer>
  );
};

export { NewsArticleShareButtons };
