import {
  FormEvent,
  FunctionComponent,
  InputHTMLAttributes,
  useEffect,
  useState
} from 'react';

import classNames from 'classnames';

import * as S from './styled';

interface FileInputProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: string;
  files: File[];
  setFiles: (files: File[]) => void;
  hasError?: boolean;
}

const FileInput: FunctionComponent<FileInputProps> = (
  props: FileInputProps
) => {
  const {
    placeholder,
    className,
    icon,
    id,
    files,
    setFiles,
    hasError,
    ...rest
  } = props;

  const [label, setLabel] = useState(placeholder);

  useEffect(() => {
    if (!files.length) {
      setLabel(placeholder);
    }

    if (files.length === 1) {
      setLabel(`${files.length} arquivo selecionado`);
    }

    if (files.length > 1) {
      setLabel(`${files.length} arquivos selecionados`);
    }
  }, [files]);

  const handleFileSelection = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      setFiles(Array.from(e.currentTarget.files).map((file) => file));
    }
  };

  return (
    <S.FileInputWrapper
      className={classNames({
        'file-input__wrapper': true,
        [`${className}`]: className
      })}
    >
      <input
        type="file"
        id={id}
        placeholder={placeholder}
        onChange={handleFileSelection}
        {...rest}
      />

      <S.FileInputLabel
        className={classNames({
          'file-input': true,
          'file-input--has-error': hasError
        })}
        htmlFor={id}
        $iconUrl={icon}
      >
        {label}
      </S.FileInputLabel>
    </S.FileInputWrapper>
  );
};

FileInput.defaultProps = {
  icon: '',
  hasError: false
};

export default FileInput;
