import { ButtonHTMLAttributes, forwardRef } from 'react';

import classNames from 'classnames';

import * as S from './styled';
import CustomProps from './utils';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & CustomProps;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, ref) => {
    const { variant, size, hasIcon, className, children, ...rest } = props;

    return (
      <S.HTMLButton
        className={classNames({
          [`btn__${size}`]: size,
          [`btn__${variant}`]: variant,
          [`${className}`]: className,
          'btn--has-icon': hasIcon
        })}
        ref={ref}
        {...rest}
      >
        {children}
      </S.HTMLButton>
    );
  }
);

Button.defaultProps = {
  variant: 'white',
  size: 'small',
  hasIcon: false
};

export default Button;
