import { action } from 'typesafe-actions';

import { StudentTypes } from './types';

export const createStudentRequest = (
  files: File[],
  teachingInstitutionId: number
) =>
  action(StudentTypes.CREATE_STUDENT_REQUEST, {
    files,
    teachingInstitutionId
  });

export const createStudentSuccess = () =>
  action(StudentTypes.CREATE_STUDENT_SUCCESS);

export const createStudentFailure = (
  errorMessage: string,
  errorCode?: string | number
) => action(StudentTypes.CREATE_STUDENT_FAILURE, { errorMessage, errorCode });

export const setStudentRequestData = (
  studentRequestId: number,
  userToken?: string
) =>
  action(StudentTypes.SET_STUDENT_REQUEST_DATA, {
    studentRequestId,
    userToken
  });
